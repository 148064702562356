import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { CourseActions, CourseTestActions } from '@portal/actions';
import { COURSE_TEST } from '@portal/actions/actionTypes';
import { setShowTestHistory } from '@portal/actions/courseTest';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as StorageService from '@portal/services/storage';

import Btn from '@portal/components/v1/Btn/Btn';
import LockedContent from '@portal/components/v1/ContentComponents/LockedContent/LockedContent';
import StatusDeadline from '@portal/components/v1/ContentComponents/StatusDeadline/StatusDeadline';
import CourseTestFeedback from '../CourseTestFeedback/CourseTestFeedback';
import CourseTestObjectiveQuestion from '../CourseTestObjectiveQuestion/CourseTestObjectiveQuestion';
import CourseTestUploadQuestion from '../CourseTestUploadQuestion/CourseTestUploadQuestion';

import { TasksStatus } from '@portal/enum/taskStatus';
import { translate } from '@portal/services/i18n';
import { IRouteParams } from '@portal/models/route-params';
import RenderCondition from '@portal/utils/renderCondition';
import { CourseTestType } from '@portal/enum/courseTestType';
import { BUTTON_SIZE } from '@portal/enum/buttonSize';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { ProductType } from '@portal/enum/productType';
import { ProfileType } from '@portal/enum/profileType';
import moment from 'moment';
import { getCookie } from '@portal/services/cookie';

interface IQuestionAnswers {
  questionId: string;
  answersSelected: string[];
  rightOptionsNumber: number;
  hasPendingAnswer: boolean;
}

const PanelCourseTest: React.FC = () => {
  const dispatch = useDispatch();

  const { courseTest, courseTestDone, viewTestUserHistory } = useReduxState().courseTest;
  const { courseProgress } = useReduxState().course;
  const { me } = useReduxState().auth;
  const { localAmount } = useReduxState().loading;

  const { testId, courseId } = useParams<IRouteParams>();

  const [questionAnswers, setQuestionAnswers] = useState<IQuestionAnswers[]>([]);

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';
  const virtualGroupId = getCookie('virtual-group-id');

  const isDone = courseProgress?.test?.testStatus === TasksStatus.DONE;
  const isRedo = courseProgress?.test?.testStatus === TasksStatus.REDO;

  const isReviewTutor = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;
  const isTeacherCourse =
    [ProfileType.TEACHER, ProfileType.COORDINATOR]?.includes(me?.profileType ?? 0) &&
    productType === ProductType.LMS_EDUCATOR;

  const contentFinished = courseProgress?.modules
    ?.flatMap((module) => module.contents)
    ?.every((content) => content.isDone);

  useEffect(() => {
    if (testId && !isReviewTutor && me?.profileType) {
      dispatch(CourseTestActions.getStudentTest(testId, () => {}, true));

      dispatch(CourseTestActions.getCourseTestById(testId, true));
    }
  }, [courseProgress, testId, isReviewTutor, me?.profileType]);

  const setSelectedOptions = (items: string[], questionId: string) => {
    const newItem = {
      answersSelected: items,
      questionId: questionId,
      rightOptionsNumber: 0,
      hasPendingAnswer: false,
    };

    const verifyExistItem = questionAnswers?.filter((item) => item?.questionId === questionId);

    const filterArray = questionAnswers?.filter((item) => item?.questionId !== questionId);

    if (!verifyExistItem) {
      setQuestionAnswers((item) => [...item, newItem]);
    } else {
      setQuestionAnswers([...filterArray, newItem]);
    }
  };
  const handleSubmit = useCallback(() => {
    const answerstoSend = questionAnswers.map((question) => {
      return {
        questionId: question.questionId,
        answer: question.answersSelected.toString(),
      };
    });

    dispatch(
      CourseTestActions.sendAnswers(
        courseTest.id,
        answerstoSend,
        () => {
          dispatch(CourseActions.getCourseProgress({ idCourse: courseId }));
          dispatch({
            type: COURSE_TEST,
            payload: {},
          });

          dispatch(setShowTestHistory(false));

          setQuestionAnswers([]);
        },
        true
      )
    );
  }, [courseId, courseTest.id, questionAnswers]);

  const checkIfContains = useCallback((status: number, listToCheck: number[]) => {
    return listToCheck.includes(status);
  }, []);

  const renderHeaderDone = () => {
    return (
      <RenderCondition condition={isDone}>
        <div className="final-evaluation-section__header-questions">
          <div
            className="final-evaluation-section__header-questions__back"
            onClick={() => dispatch(setShowTestHistory(false))}
          >
            <ArrowLeftOutlined />
            <span>{translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.HEADER.BACK')}</span>
          </div>

          {isDone && (
            <Btn
              title={translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.HEADER.CONTINUE')}
              size={BUTTON_SIZE.MEDIUM}
              disabled
            />
          )}
        </div>
      </RenderCondition>
    );
  };

  const renderConditionButton = () => {
    if (courseTest?.type === CourseTestType.MULTIPLE_QUESTIONS) {
      if (isRedo && !viewTestUserHistory) {
        return false;
      }

      if (isRedo && viewTestUserHistory) {
        return true;
      }
    }

    if (courseTest?.type === CourseTestType.FILE_UPLOAD_ANSWER) {
      if (isRedo) {
        return true;
      }
    }

    if (
      checkIfContains(courseProgress?.test?.testStatus, [
        TasksStatus.NOT_AVAILABLE,
        TasksStatus.WAITING_REVIEW,
        TasksStatus.DONE,
      ])
    ) {
      return false;
    }

    if (courseProgress?.courseUser?.length && courseProgress?.courseUser?.[0]?.isDone) {
      return false;
    }

    return true;
  };

  const answeresUserList = courseTestDone?.questions?.[0]?.answers;

  const schedule = courseProgress?.test?.schedule?.[0];
  const virtualGroup = courseProgress?.virtualGroup?.filter((item) => item.id === virtualGroupId)?.[0];

  const isBlock =
    (schedule &&
      (moment().isBefore(schedule?.startAt) || (virtualGroup?.finalDateBlock && moment().isAfter(schedule?.endAt)))) ||
    (!contentFinished && isTeacherCourse);

  return (
    <div className="final-evaluation-section">
      {isBlock ? (
        <LockedContent schedule={schedule} isSatisfactionSurvey={isTeacherCourse && !contentFinished} />
      ) : (
        <>
          <div className="final-evaluation-section__header">
            {courseTestDone.id && answeresUserList?.[answeresUserList?.length - 1]?.createdAt ? (
              <StatusDeadline
                isDone
                label={courseProgress.test?.title || translate('PAGES.CONTENT.FINAL_EVALUATION.LABEL_STATUS')}
                doneDate={answeresUserList?.[answeresUserList?.length - 1]?.createdAt}
              />
            ) : (
              courseProgress.id && (
                <StatusDeadline
                  isDone={false}
                  label={courseProgress.test?.title || translate('PAGES.CONTENT.FINAL_EVALUATION.LABEL_STATUS')}
                  statusDeadlienDate={courseProgress?.test?.schedule?.[0]?.endAt as Date}
                />
              )
            )}
          </div>

          <RenderCondition
            condition={
              courseTest?.type === CourseTestType.MULTIPLE_QUESTIONS && (isDone || isRedo) && !viewTestUserHistory
            }
          >
            <CourseTestFeedback />
          </RenderCondition>

          <RenderCondition
            condition={
              courseTest?.type === CourseTestType.MULTIPLE_QUESTIONS && (viewTestUserHistory || (!isDone && !isRedo))
            }
          >
            {renderHeaderDone()}
            <div className="final-evaluation-section__question-list">
              <RenderCondition condition={!isDone}>
                {courseTest?.questions?.length &&
                  courseTest?.questions?.map((question) => (
                    <CourseTestObjectiveQuestion
                      key={question.id}
                      question={question}
                      description={question.description}
                      options={question.options}
                      setSelectedOptions={(items: string[]) => setSelectedOptions(items, question.id)}
                    />
                  ))}
              </RenderCondition>

              <RenderCondition condition={isDone}>
                {courseTestDone?.questions?.length &&
                  courseTestDone?.questions?.map((question) => (
                    <CourseTestObjectiveQuestion
                      key={question.id}
                      question={question}
                      description={question.description}
                      options={question.options}
                      setSelectedOptions={(items: string[]) => setSelectedOptions(items, question.id)}
                      showCorectQuestions={isDone}
                    />
                  ))}
              </RenderCondition>
            </div>
          </RenderCondition>

          <RenderCondition condition={courseTest?.type === CourseTestType.FILE_UPLOAD_ANSWER}>
            <div className="final-evaluation-section__upload-file">
              {courseTest?.questions?.length &&
                courseTest?.questions?.map((question) => (
                  <CourseTestUploadQuestion
                    key={question.id}
                    question={question}
                    setFileAnswer={(value: string) =>
                      setQuestionAnswers([
                        {
                          answersSelected: [value],
                          hasPendingAnswer: false,
                          questionId: question.id,
                          rightOptionsNumber: 0,
                        },
                      ])
                    }
                  />
                ))}
            </div>
          </RenderCondition>
          <RenderCondition condition={renderConditionButton()}>
            <div className="final-evaluation-section__submit">
              <Btn
                title={translate('PAGES.CONTENT.FINAL_EVALUATION.SUBMIT_BUTTON')}
                disabled={
                  questionAnswers?.filter((item) => item.answersSelected?.length > 0)?.length !==
                  courseTest?.questions?.length
                }
                size={BUTTON_SIZE.FULL}
                onClick={handleSubmit}
                isLoading={localAmount > 0}
              />
            </div>
          </RenderCondition>
        </>
      )}
    </div>
  );
};

export default PanelCourseTest;
