import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';
import { BREAKPOINTS } from '@portal/enum/breakpoints';
import { MARKER_STATUS } from '@portal/enum/markerStatus';
import { ModuleContent } from '@portal/models/reducers';
import { IRouteParams } from '@portal/models/route-params';
import { getContentStatusBySchedule } from '@portal/utils/isAvailableBySchedule';

import { useReduxState } from '@portal/hooks/useReduxState';
import { isDashTutor } from '@portal/utils/isDashTutor';
import SideBarActivityMarker from '../SideBarActivityMarker/SideBarActivityMarker';
import SideBarDropdownCheck from '../SideBarDropdownCheck/SideBarDropdownCheck';

interface IProps {
  contents: ModuleContent[];
  moduleId: string;
}

const SideBarDropdownClass: React.FC<IProps> = ({ contents, moduleId }) => {
  const [widthScreen, setWidthScreen] = useState(document.body.clientWidth);

  const history = useHistory();

  const { courseId, activityId, contentId } = useParams<IRouteParams>();

  const {
    auth: { me },
  } = useReduxState();

  const { MD } = BREAKPOINTS;

  const isTutor = isDashTutor(me?.profileType);

  const setWidthOnResize = () => {
    setWidthScreen(document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', setWidthOnResize);

    return () => window.removeEventListener('resize', setWidthOnResize);
  }, [setWidthOnResize]);

  const returnTimeLineStyle = (index: number, length: number) => {
    if (index === 0) {
      return ' sidebar-dropdown-class__class__inner--start';
    } else if (index < length) {
      return ' sidebar-dropdown-class__class__inner--middle';
    } else {
      return ' sidebar-dropdown-class__class__inner--end';
    }
  };

  const handleClassClick = useCallback(
    (contentIdProp: string) => {
      history.push(
        `${getStackPath('CONTENT')}${widthScreen <= MD ? '/mobile' : ''}/${courseId}/${moduleId}/${contentIdProp}`
      );
    },
    [MD, courseId, history, moduleId, widthScreen]
  );

  const handleActivityClick = useCallback(
    (contentIdProp: string, activityIdParam: string) => {
      history.push(
        `${getStackPath('CONTENT')}${
          widthScreen <= MD ? '/mobile' : ''
        }/${courseId}/${moduleId}/${contentIdProp}/${activityIdParam}`
      );
    },
    [MD, courseId, history, moduleId, widthScreen]
  );

  const statusDropdown = (isDone: boolean | null, content: ModuleContent) => {
    if (isDone) {
      return MARKER_STATUS.MARKED;
    }

    if (isTutor) {
      return MARKER_STATUS.NONE;
    }

    if (content && content.schedule && content.schedule.length > 0) {
      return getContentStatusBySchedule(content.schedule[0]);
    }

    return MARKER_STATUS.NONE;
  };

  return (
    <div className="sidebar-dropdown-class">
      {contents.length > 0 &&
        contents.map((content, index) => {
          const classCurrent = sessionStorage.getItem('classCurrent');

          if (!classCurrent) {
            if (content.id === contentId) sessionStorage.setItem('classCurrent', (index + 1).toString());
          }

          return (
            <div key={content.id}>
              <div
                onClick={() => {
                  handleClassClick(content.id);
                  sessionStorage.setItem('classCurrent', (index + 1).toString());
                }}
              >
                <div
                  className={`sidebar-dropdown-class__class${
                    content.id === contentId && !activityId ? ' sidebar-dropdown-class__class__inner--selected' : ''
                  }`}
                >
                  <div className={`sidebar-dropdown-class__class__inner${returnTimeLineStyle(index, contents.length)}`}>
                    <SideBarDropdownCheck
                      status={statusDropdown(content.isDone, content)}
                      selected={content.id === contentId && !activityId}
                    />
                    <span
                      className={`sidebar-dropdown-class__class__inner__title
                ${
                  content.id === contentId && !activityId
                    ? ' sidebar-dropdown-class__class__inner__title--selected'
                    : ''
                }
                `}
                    >
                      {content.title}
                    </span>
                  </div>
                </div>
              </div>
              {content.activities.length > 0 &&
                content.activities.map((activity, activityIndex) => {
                  const activityCurrent = sessionStorage.getItem('activityCurrent');

                  if (!activityCurrent) {
                    if (activity.id === activityId) {
                      sessionStorage.setItem('activityCurrent', (activityIndex + 1).toString());
                    }
                  }

                  return (
                    <div
                      key={content.id.concat(activity.id)}
                      onClick={() => {
                        handleActivityClick(content.id, activity.id);
                        sessionStorage.setItem('classCurrent', (index + 1).toString());
                        sessionStorage.setItem('activityCurrent', (activityIndex + 1).toString());
                      }}
                    >
                      <div
                        className={`sidebar-dropdown-class__activity${
                          activity.id === activityId ? ' sidebar-dropdown-class__activity--selected' : ''
                        }`}
                      >
                        <div className="sidebar-dropdown-class__activity__inner">
                          <SideBarActivityMarker status={activity.status} count={activity.pendingReviewCount} />
                          <span
                            className={`sidebar-dropdown-class__activity__title${
                              activity.id === activityId ? ' sidebar-dropdown-class__activity__title--selected' : ''
                            }`}
                          >
                            {activity.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}

      <hr className="sidebar-dropdown-class__divisor" />
    </div>
  );
};

export default SideBarDropdownClass;
