import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { BrandActions } from '@portal/actions';

import { AllCourses, ModalStartOnboarding, ModalStepsOnboarding, MyGroups } from '@portal/components';

import {
  SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING,
  SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING,
} from '@portal/actions/actionTypes';
import { useReduxState } from '@portal/hooks/useReduxState';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { setThemeBrand, setThemeProduct } from '@portal/utils/setTheme';
import * as StorageService from '~/services/storage';

import { BrandDomain, ProductType, UserLog } from '@portal/enum';
import { useCreateUserLog, useGetUserLog } from '@portal/hooks';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const HomePage: React.FC = () => {
  const dispatch = useDispatch();

  const pathname = location.pathname.replace('/', '');
  let productId = pathname.split('/')[1];

  const {
    auth: { me },
    brand: { brandConfig, brandProductConfig },
    group: { groupsAll },
    course: { mineCourses },
  } = useReduxState();

  const isEducator = isDashTutor(me?.profileType);

  const hasGroups = (!!mineCourses?.rows?.length && !isEducator) || (!!groupsAll?.rows?.length && isEducator);

  const { data: userLogs, isFetched } = useGetUserLog(
    {
      idReferral: productId,
      type:
        brandProductConfig?.product === ProductType.LMS_STUDENT ? UserLog.VIEW_LMS_STUDENT : UserLog.VIEW_LMS_EDUCATOR,
    },
    { enabled: !!productId && !!brandProductConfig?.product }
  );
  const { mutate: createLog } = useCreateUserLog();

  if (!productId) {
    const getBrandProduct = StorageService.getItem('brandProductsConfig');
    productId = getBrandProduct?.id;
  }

  const setShowModalStartOnboarding = (state: boolean) => {
    dispatch({
      payload: state,
      type: SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING,
    });
  };

  const setShowModalStepsOnboarding = (state: boolean) => {
    dispatch({
      payload: state,
      type: SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING,
    });
  };

  const onOkModalStartOnboarding = () => {
    setShowModalStartOnboarding(false);
    setShowModalStepsOnboarding(true);
    document?.getElementById('navbar-lms')?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (
      brandProductConfig?.id &&
      hasGroups &&
      brandConfig?.domainUrl !== BrandDomain.PGS &&
      !userLogs?.length &&
      isFetched
    ) {
      setShowModalStartOnboarding(true);
      createLog({
        idReferral: brandProductConfig?.id,
        type:
          brandProductConfig?.product === ProductType.LMS_STUDENT
            ? UserLog.VIEW_LMS_STUDENT
            : UserLog.VIEW_LMS_EDUCATOR,
      });
    }
  }, [brandProductConfig?.id, brandProductConfig?.product, hasGroups, brandConfig?.domainUrl, userLogs, isFetched]);

  useEffect(() => {
    if (!brandConfig) {
      dispatch(
        BrandActions.getProductsConfig((config) => {
          setThemeBrand(config);
        })
      );
    }
  }, [brandConfig]);

  useEffect(() => {
    if (productId && !brandProductConfig?.id) {
      dispatch(
        BrandActions.getBrandProductsConfig(productId, (config) => {
          setThemeProduct(config);
        })
      );
    }
  }, [productId, brandProductConfig?.id]);

  return (
    <Container fluid className="home-page__page-container">
      {isEducator && pathname.includes('/groups') ? <MyGroups /> : <AllCourses />}

      <ModalStartOnboarding onCancel={() => setShowModalStartOnboarding(false)} onOk={onOkModalStartOnboarding} />
      <ModalStepsOnboarding
        onCancel={() => {
          setShowModalStepsOnboarding(false);
          document?.getElementById('navbar')?.scrollIntoView({ behavior: 'smooth' });
        }}
      />
    </Container>
  );
};

export default HomePage;
