import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { CalendarOutlined, SettingOutlined } from '@ant-design/icons';
import { Tooltip } from '@mui/material';

import { Btn, GroupModal } from '@portal/components';
import EnrollmentGroup from '../../Groups/EnrollmentGroup/EnrollmentGroup';

import { getRouteStackPath, getStackPath } from '@portal/config/routes';
import { BUTTON_SIZE, ProfileType } from '@portal/enum';
import { useReduxState } from '@portal/hooks';
import { VirtualGroup } from '@portal/models/module';
import { deleteCookie, getCookie, setCookie, translate } from '@portal/services';

import { IcClass } from '@portal/assets/Icon';
import { Constants } from '@portal/config/env';

interface IProps {
  cardData: VirtualGroup;
  isActive?: boolean;
  isInFocus?: boolean;
  buttonsInFocus?: boolean;
}

const Card: React.FC<IProps> = ({ cardData, isActive, isInFocus, buttonsInFocus }) => {
  const history = useHistory();
  const { me } = useReduxState().auth;

  const { id, name, capacity, users, course } = cardData;

  const [showModal, setShowModal] = useState(false);
  const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);

  const handleRedirectToGroupDetails = useCallback(
    (courseId: string) => {
      if (courseId) {
        history.push(`${getRouteStackPath('GROUPS', 'DETAILS')}/${courseId}`);
      }
    },
    [history]
  );

  const handleRedirectToCourseDetails = useCallback(
    (courseId: string) => {
      deleteCookie('virtual-group-id', '/', Constants.brandCookieDomain);

      if (courseId) {
        history.push(`${getStackPath('COURSE_DETAILS')}/${courseId}`);
      }
    },
    [history]
  );

  const isSaePartnership = me?.isSaePartnership || getCookie('user-integration');

  const redirectToContentPage = () => {
    setCookie('virtual-group-id', id, Constants.brandCookieDomain as string);
    history.push(`/content/${course.id}`);
  };

  return (
    <div
      className="cardGroup-container"
      {...(isInFocus && {
        style: { zIndex: 99999, backgroundColor: 'white', position: 'relative' },
      })}
    >
      <div>
        <div className="cardGroup-container__image-card">
          <div className="cardGroup-container__background-image__title">
            <span>{course.title}</span>
          </div>

          <img src={course.photoUrl} alt="background" className="cardGroup-container__background-image" />

          <div className="icon-container row icon-container__between-justified"></div>
        </div>
      </div>
      <div className="cardGroup-container__description">
        <div className="cardGroup-container__description__info">
          <div>
            <span className="font-weight-bold">{translate('PAGES.GROUPS.GROUPCARD.INFOS.GROUP')}</span>
            <span>{name}</span>
          </div>
          <div>
            <span className="font-weight-bold">{translate('PAGES.GROUPS.GROUPCARD.INFOS.REGISTRATIONS')}</span>
            <span>{users.length}</span>
          </div>
          <div>
            <span className="font-weight-bold">{translate('PAGES.GROUPS.GROUPCARD.INFOS.VACANCIES')}</span>
            <span>{capacity}</span>
          </div>
        </div>

        <div
          className="cardGroup-container__description__button-wrapper align-buttons"
          {...(buttonsInFocus && {
            style: {
              zIndex: 99999,
              backgroundColor: 'white',
              position: 'relative',
              borderRadius: '8px',
            },
          })}
        >
          <div>
            <span className="cardGroup-container__see-details" onClick={() => handleRedirectToCourseDetails(course.id)}>
              {translate('PAGES.GROUPS.GROUPCARD.BUTTONS.SEE_DETAILS')}
            </span>
          </div>

          <div className="row gap space-between-icons">
            {[ProfileType.ADVISER, ProfileType.COORDINATOR].includes(Number(me?.profileType)) &&
              isActive &&
              !isSaePartnership && (
                <Btn variant="secondary" size={BUTTON_SIZE.SMALLEST} onClick={() => setShowEnrollmentModal(true)}>
                  <Tooltip title={translate('PAGES.GROUPS.GROUPCARD.TOOLTIP.EDIT_GROUP')}>
                    <div className="space-between-icons__align-buttons">
                      <SettingOutlined />
                    </div>
                  </Tooltip>
                </Btn>
              )}
            {isActive && (
              <Btn variant="secondary" size={BUTTON_SIZE.SMALLEST} onClick={() => setShowModal(true)}>
                <Tooltip title={translate('PAGES.GROUPS.GROUPCARD.TOOLTIP.EDIT_SCHEDULE')}>
                  <div className="space-between-icons__align-buttons">
                    <CalendarOutlined />
                  </div>
                </Tooltip>
              </Btn>
            )}
            <Btn variant="secondary" size={BUTTON_SIZE.SMALLEST} onClick={() => handleRedirectToGroupDetails(id)}>
              <Tooltip title={translate('PAGES.GROUPS.GROUPCARD.TOOLTIP.SHOW_STUDENTS')}>
                <div className="space-between-icons__align-buttons">
                  <IcClass />
                </div>
              </Tooltip>
            </Btn>
            <Btn variant="primary" size={BUTTON_SIZE.SMALLER} onClick={redirectToContentPage}>
              {translate('PAGES.GROUPS.GROUPCARD.BUTTONS.ENTER')}
            </Btn>
          </div>
        </div>
      </div>
      {showModal && <GroupModal openModal={showModal} closeModal={setShowModal} cardData={cardData} />}
      {showEnrollmentModal && (
        <EnrollmentGroup openModal={showEnrollmentModal} closeModal={setShowEnrollmentModal} id={id} />
      )}
    </div>
  );
};

export default Card;
