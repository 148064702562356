import { useEffect, useState } from 'react';

const useWindowSize = (): number | undefined => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobile = windowSize.width < 1000 ? windowSize.width : undefined;

  return mobile;
};

export default useWindowSize;
