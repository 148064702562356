import { Dispatch } from 'redux';

import { BrandConfig } from '@portal/models/module';
import brandAPI from '@portal/repositories/brand';
import * as MessageService from '~/services/message';
import * as StorageService from '~/services/storage';

import { BRAND_CONFIG, BRAND_PLAYLIST, BRAND_PRODUCT_CONFIG } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getProductsConfig =
  (callback: (payload: BrandConfig) => void, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await brandAPI.getProductsConfig();

      StorageService.setItem('productsConfig', payload);

      dispatch({
        payload,
        type: BRAND_CONFIG,
      });
      callback(payload);
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getBrandProductsConfig =
  (id: string, callback: (payload: BrandConfig) => void, isLocalLoading?: boolean) =>
    async (dispatch: Dispatch) => {
      dispatch(increaseLoading(!!isLocalLoading));
      try {
        const payload = await brandAPI.getBrandProductsConfig(id);

        StorageService.removeItem('brandProductsConfig');
        StorageService.setItem('brandProductsConfig', payload);

        dispatch({
          payload,
          type: BRAND_PRODUCT_CONFIG,
        });
        callback(payload);
      } catch (err) {
        MessageService.error(err.message);
      } finally {
        dispatch(decreaseLoading(!!isLocalLoading));
      }
    };

export const getPlaylist =
  (id: string, isLocalLoading?: boolean) =>
    async (dispatch: Dispatch) => {
      dispatch(increaseLoading(!!isLocalLoading));
      try {
        const payload = await brandAPI.getPlaylist(id);

        dispatch({
          payload,
          type: BRAND_PLAYLIST,
        });
      } catch (err) {
        MessageService.error(err.message);
      } finally {
        dispatch(decreaseLoading(!!isLocalLoading));
      }
    };