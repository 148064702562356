import { ConfigPortal } from '@portal/models/module';
import { CONFIG_PORTAL } from '~/actions/actionTypes';

const initialState: reducers.ConfigReducer = {
  configPortal: {} as ConfigPortal,
};

const configReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CONFIG_PORTAL:
      state = {
        ...state,
        configPortal: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default configReducer;
