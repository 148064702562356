import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ChatActions } from '@portal/actions';
import { CHAT_MESSAGES } from '@portal/actions/actionTypes';
import ContactProfile from '@portal/components/v1/ChatComponents/ContactProfile/ContactProfile';
import Conversation from '@portal/components/v1/ChatComponents/Conversation/Conversation';
import InputChat from '@portal/components/v1/ChatComponents/InputChat/InputChat';
import { useReduxState } from '@portal/hooks/useReduxState';
import ChatRequests from '@portal/repositories/chat';
import { getQueryParams } from '@portal/utils/getQueryParams';

const ChatPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParameters = getQueryParams();
  const {
    auth: { me },
    chat: { chats, hasSearch, searchedChats },
  } = useReduxState();
  const [selectedChat, setSelectedChat] = useState<string>('');
  const [privateChatId, setPrivateChatId] = useState<string>('');
  const [questionId, setQuestionId] = useState<string>('');

  useEffect(() => {
    const questionIdSearched = searchParameters.get('questionId');
    const feedCreatorIdSearched = searchParameters.get('feedCreatorId');

    if (questionIdSearched) {
      setQuestionId(questionIdSearched);
    }

    if (feedCreatorIdSearched && !selectedChat) {
      setSelectedChat(feedCreatorIdSearched);
    } else if (chats.length > 0 && !selectedChat) {
      setSelectedChat(chats[0]?.id);
    }
  }, [chats, dispatch, searchParameters, selectedChat]);

  useEffect(() => {
    (async () => {
      if (selectedChat) {
        const { id } = await ChatRequests.createChat([selectedChat], me?.id as string);
        setPrivateChatId(id);
        dispatch(ChatActions.getChatMessages(id));
        if (window.location.search) {
          history.replace(window.location.pathname);
        }
      }
    })();
  }, [dispatch, me, selectedChat]);

  useEffect(() => {
    let checkNewMessages: NodeJS.Timeout;
    if (privateChatId) {
      checkNewMessages = setInterval(() => {
        dispatch(ChatActions.reloadMessages(privateChatId));
      }, 20000);
    }

    return () => {
      if (checkNewMessages) {
        clearInterval(checkNewMessages);
      }
      dispatch({
        type: CHAT_MESSAGES,
        payload: [],
      });
    };
  }, [dispatch, privateChatId]);

  const handleSendMassage = (message?: string, photoUrl?: string) => {
    try {
      dispatch(
        ChatActions.sendMessage(
          {
            privateChatId: privateChatId as string,
            message,
            userId: me?.id as string,
            photoUrl,
            metadata: questionId,
          },
          true
        )
      );

      if (questionId) {
        history.replace(window.location.pathname);
        setQuestionId('');
      }
    } catch (err) {
      // err;
    }
  };

  return (
    <Container fluid="lg" className="chat-container p-0">
      <Row>
        <Col xl={3} lg={3} md={3} sm={4} xs={12} className="chat-container__contact-list p-0">
          {!hasSearch &&
            chats.map((chat) => (
              <Row key={chat.id}>
                <Col className="p-0">
                  <ContactProfile
                    id={chat.id}
                    isSelected={selectedChat === chat.id}
                    participant={chat as unknown as models.Participant}
                    setSelectedChat={setSelectedChat}
                  />
                </Col>
              </Row>
            ))}
          {hasSearch &&
            searchedChats.map((chat) => (
              <Row key={chat.id}>
                <Col className="p-0">
                  <ContactProfile
                    id={chat.id}
                    isSelected={selectedChat === chat.id}
                    participant={chat as unknown as models.Participant}
                    setSelectedChat={setSelectedChat}
                  />
                </Col>
              </Row>
            ))}
        </Col>
        <Col xl={9} lg={9} md={9} sm={8} className="p-0 d-none d-sm-block">
          <Row className="chat-container__message-list">
            <Col className="p-0">
              <Conversation />
            </Col>
          </Row>
          <Row className="chat-container__input m-0">
            <Col className="m-0 p-0">
              <InputChat
                handleReload={() => dispatch(ChatActions.reloadMessages(privateChatId))}
                onSubmit={handleSendMassage}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatPage;
