import { getInstance } from './instance';

const MODULE_BASE_URL = '/journey';

const JourneyApi = {
  getAllJourney: async (brandId: string, productsIds?: string[]): Promise<models.Journey[]> => {
    const instance = getInstance({ api: 'journey' });
    const { data } = await instance.get(`${MODULE_BASE_URL}/${brandId}/list-journeys`, {
      params: {
        productList: productsIds?.join(','),
      },
    });

    return data;
  },
};

export default JourneyApi;
