/* eslint-disable max-len */
import React from 'react';

const IcDeclaration = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9995 3.14856C8.40453 3.14856 6.30091 5.25219 6.30091 7.84714C6.30091 10.4421 8.40453 12.5457 10.9995 12.5457C13.5944 12.5457 15.6981 10.4421 15.6981 7.84714C15.6981 5.25219 13.5944 3.14856 10.9995 3.14856ZM4.66113 7.84714C4.66113 4.34657 7.49891 1.50879 10.9995 1.50879C14.5001 1.50879 17.3378 4.34657 17.3378 7.84714C17.3378 11.3477 14.5001 14.1855 10.9995 14.1855C7.49891 14.1855 4.66113 11.3477 4.66113 7.84714Z"
        fill="#CE3B87"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.879 11.6693C14.3279 11.6098 14.74 11.9254 14.7996 12.3743L15.7535 19.564C15.7949 19.8761 15.654 20.1843 15.3908 20.3572C15.1277 20.53 14.7889 20.5369 14.5189 20.3749L10.999 18.2629L7.47904 20.3749C7.20907 20.5369 6.8702 20.53 6.60706 20.3571C6.34393 20.1843 6.20301 19.876 6.24446 19.5639L7.19837 12.382C7.25799 11.9332 7.6702 11.6176 8.11907 11.6772C8.56794 11.7368 8.88348 12.1491 8.82387 12.5979L8.09391 18.0937L10.5771 16.6038C10.8368 16.448 11.1612 16.448 11.4208 16.6038L13.9043 18.0939L13.1741 12.5899C13.1145 12.141 13.4301 11.7289 13.879 11.6693Z"
        fill="#CE3B87"
      />
    </svg>
  );
};

export default IcDeclaration;
