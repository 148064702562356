import TutorContentRequest from '@portal/repositories/tutorContent';
import * as MessageService from '~/services/message';

import { SET_TUTOR_CONTENT_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getTutorContentByProductId = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const response = await TutorContentRequest.getTutorContentByProductId(id);
    dispatch({
      payload: response.data,
      type: SET_TUTOR_CONTENT_DETAIL,
    });
  } catch (err: any) {
    MessageService.error(err?.response?.message || 'errors.tutorContent.invalidCredentials');
  } finally {
    dispatch(decreaseLoading());
  }
};

export const clearTutorContent = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: SET_TUTOR_CONTENT_DETAIL,
  });
};