import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Modal } from 'antd';

import { getGroupScheduleById } from '@portal/actions/group';
import { GROUPS_SCHEDULE_BY_ID } from '@portal/actions/actionTypes';

import { translate } from '@portal/services/i18n';
import { useReduxState } from '@portal/hooks/useReduxState';
import GroupModalContent from './GroupModalContent.tsx/GroupModalContent';
import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface IProps {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  cardData: models.VirtualGroup;
}

const GroupModal: React.FC<IProps> = ({ openModal, closeModal, cardData }) => {
  const dispatch = useDispatch();

  const {
    group: { groupSchedule },
  } = useReduxState();

  const { name, id, course } = cardData;
  const { title, photoUrl } = course;

  useEffect(() => {
    dispatch({
      payload: null,
      type: GROUPS_SCHEDULE_BY_ID,
    });
    dispatch(getGroupScheduleById(id));
  }, [id, dispatch]);

  return (
    <Modal
      visible={openModal}
      onCancel={() => closeModal(false)}
      footer={null}
      style={{ top: 20 }}
      bodyStyle={{ padding: 0 }}
      width={800}
    >
      <div className="home-details__modal-container">
        <Row>
          <Col>
            <img
              className="home-details__modal-container__banner"
              src={photoUrl ? photoUrl : EmptyBanner}
              alt="empty banner"
            />
          </Col>
        </Row>

        <Row className="home-details__modal-container__spacing">
          <Col>
            <Row>
              <h1 className="home-details__modal-container__title">{title}</h1>
            </Row>
            <Row>
              <p className="home-details__modal-container__subtitle">
                <span className="home-details__modal-container__bold">
                  {translate('PAGES.GROUPS.GROUPCARD.INFOS.GROUP')}
                </span>
                <span>{name}</span>
              </p>
            </Row>

            {groupSchedule && <GroupModalContent id={id} groupSchedule={groupSchedule} closeModal={closeModal} />}
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default GroupModal;
