import React from 'react';

interface IRenderHTML {
  htmlText: string;
}

const RenderHTML = ({ htmlText }: IRenderHTML) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlText }} />;
};

export default RenderHTML;
