import { IUserLog } from '@portal/types';
import { getInstance } from './instance';

const baseUrl = '/user-log';

export const userLogApi = {
  createLog: async (body: Pick<IUserLog, 'idReferral' | 'type'>): Promise<void> => {
    const instance = getInstance({ api: 'new' });

    await instance.post(baseUrl, body);
  },

  getLog: async (params: Pick<IUserLog, 'idReferral' | 'type'>): Promise<IUserLog[]> => {
    const instance = getInstance({ api: 'new' });

    const { data } = await instance.get(baseUrl, { params });

    return data;
  },
};
