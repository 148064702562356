import React from 'react';

import { colors } from '@portal/styles/colors';

interface IProps {
  variant?: 'todo' | 'done' | 'blocked' | 'redo' | 'pending-review' | 'reproved' | string;
}

const Circle: React.FC<IProps> = ({ variant }) => {
  const styledDefault = {
    borderRadius: '50%',
    height: '0.5rem',
    width: '0.5rem',
    marginLeft: '0.5rem',
  };

  const styledCircle = () => {
    switch (variant) {
      case 'todo':
        return colors?.todoParameters;
      case 'done':
        return colors?.doneParameters;
      case 'redo':
        return colors?.primaryOrange;
      case 'pending-review':
        return colors?.primaryYellow;
      case 'reproved':
        return colors?.primaryRed;
      case 'blocked':
        return colors?.secondaryRed;
      case 'waiting':
        return colors?.secondaryYellow;

      default:
        return colors?.todoParameters;
    }
  };

  return <div style={{ ...styledDefault, backgroundColor: styledCircle() }} />;
};

export default Circle;
