/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction, Dispatch } from 'redux';

import { Constants } from '@portal/config/env';
import { getStackPath } from '@portal/config/routes';
import AuthRequests from '~/repositories/auth';
import { setAuthorizationHeader } from '~/repositories/instance';
import * as CookieService from '~/services/cookie';
import * as MessageService from '~/services/message';
import * as StorageService from '~/services/storage';

import { AUTH_CHECK_LOGGED, AUTH_LOGIN, AUTH_ME } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const logout = () => {
  const { domainUrl } = StorageService.getItem('productsConfig');
  const isLocalHost = window.location.hostname === 'localhost';

  CookieService.deleteCookie('portal-token', '/', isLocalHost ? 'localhost' : domainUrl);
  CookieService.deleteCookie('user-integration', '/', Constants.brandCookieDomain);
  StorageService.removeItem('courseId-redirect');

  window.location.replace(`${Constants.portalUrl}/login`);
};

export const checkIsLogged = (token: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    setAuthorizationHeader(token as string);
    dispatch({
      payload: token,
      type: AUTH_LOGIN,
    });
    dispatch(getMe() as unknown as AnyAction);
  } finally {
    dispatch({
      type: AUTH_CHECK_LOGGED,
    });

    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getRedirectAuthenticatedUser =
  (credentials: models.AuthResponse, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      setAuthorizationHeader(credentials.token as string);

      dispatch(getMe() as unknown as AnyAction);

      dispatch({
        credentials,
        type: AUTH_LOGIN,
      });

      StorageService.setItem('session-token', credentials);
      MessageService.success('PAGES.AUTH.LOGIN.MESSAGES.WELCOME');

      window.location.href = getStackPath('HOME');
    } catch (err) {
      if (err && err.response) {
        MessageService.error(err.response.message);
      } else if (err && err.message) {
        MessageService.error('PAGES.AUTH.LOGIN.MESSAGES.INVALID');
      }
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getMe = (isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));

  try {
    const payload = await AuthRequests.me();

    dispatch({
      payload,
      type: AUTH_ME,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};
