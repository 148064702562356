import { Dispatch } from 'redux';

import activityAPI from '@portal/repositories/activityApi';
import * as MessageService from '~/services/message';

import { ACTIVITY_DETAILS, ACTIVITY_QUESTIONS_ANSWERED, SPECIFIC_QUESTION } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getActivityDetails = (id: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));

  try {
    const payload: models.ActivityDetails = await activityAPI.getById(id);

    dispatch({
      payload,
      type: ACTIVITY_DETAILS,
    });
  } catch (err) {
    MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const sendActivityAnswers =
  (id: string, answerList: models.ActivityAnswerQuestion[], callback?: () => void, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      await activityAPI.sendAnswerQuestion(id, answerList);

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getAnsweredQuestions =
  (id: string, callback?: () => void, params?: advancedFilterModels.ActivityAnswersFilter, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.ActivityAnswered = await activityAPI.getQuestionAnswered(id, params);

      dispatch({
        payload,
        type: ACTIVITY_QUESTIONS_ANSWERED,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getSpecificQuestion =
  (questionId: string, callback?: () => void, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.Questions = await activityAPI.getSpecificQuestion(questionId);

      dispatch({
        payload,
        type: SPECIFIC_QUESTION,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
