import { CLOSURE_TASK } from '@portal/actions/actionTypes';

const initialState: reducers.ClosureTaskReducer = {
  closureTaskAnswered: {} as reducers.ClosureTaskAnswered,
};

const activityReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case CLOSURE_TASK:
      state = {
        ...state,
        closureTaskAnswered: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;

};

export default activityReducer;