import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { Image, Modal, ModalProps, Progress } from 'antd';

import { SET_STEP_ACTIVE_LMS_ONBOARDING } from '@portal/actions/actionTypes';

import { Box, Button, Flex, Grid, IcArrowLeft } from '@portal/components/v2';
import { Btn } from '@portal/components/v1';
import { CalendarOutlined } from '@ant-design/icons';
import { IcClass } from '@portal/assets/Icon';

import {
  ArrayStepsLmsOnboardingElective,
  ArrayStepsLmsOnboardingLmsEducator,
  ArrayStepsLmsOnboardingStudent,
  ArrayStepsLmsOnboardingTutor,
  BUTTON_SIZE,
  ProductType,
  ProfileType,
  StepsLmsOnboardingElective,
  StepsLmsOnboardingLmsEducator,
  StepsLmsOnboardingStudent,
  StepsLmsOnboardingTutor,
  arrayAdminProfiles,
  arrayEducatorProfiles,
  firstStepsLmsOnboarding,
} from '@portal/enum';
import { useReduxState } from '@portal/hooks';
import { translate } from '@portal/services';
import { RenderCondition } from '@portal/utils';
import returnPronounWithCourseTerm from '@portal/utils/returnPronounWithCourseTerm';
import useWindowSize from '@portal/utils/getSizeWindow';

import './ModalStepsOnboarding.less';

interface IModalStartOnboarding extends Omit<ModalProps, 'footer' | 'width' | 'closeIcon' | 'onCancel' | 'open'> {
  onCancel?: () => void;
}

const ModalStepsOnboarding = ({ onCancel, ...rest }: IModalStartOnboarding) => {
  const dispatch = useDispatch();
  const isMobile = useWindowSize();

  const {
    lmsOnboarding: { stepActive, showModalStepsOnboarding, showModalStartOnboarding },
    brand: { brandProductConfig: lmsInfos, brandPlaylist },
    auth: { me: userInfos },
    course: { mineCourses, otherCourses },
    lmsTrail: { listWithCourses: lmsTrailList },

    tutorContent,
  } = useReduxState();

  const isElective = window.location.pathname.includes('content');
  const isStudent = userInfos?.profileType === ProfileType.STUDENT;
  const isTutor =
    !!userInfos?.profileType &&
    arrayEducatorProfiles.includes(userInfos?.profileType) &&
    lmsInfos?.product === ProductType.LMS_STUDENT &&
    !isElective;
  const isLmsEducator =
    !!userInfos?.profileType &&
    arrayEducatorProfiles.includes(userInfos?.profileType) &&
    lmsInfos?.product === ProductType.LMS_EDUCATOR;
  const isAdmin = !!userInfos?.profileType && arrayAdminProfiles.includes(userInfos?.profileType);

  const hasGroups = !!mineCourses?.rows?.length;
  const hasVideos = !!brandPlaylist?.[0]?.videos?.length;
  const uniqueTrail = lmsTrailList?.length === 1;

  const menuLms = document.getElementById('navbar-lms-menu');
  const navbarLms = document?.getElementById('navbar-lms');
  const navbar = document?.getElementById('navbar');
  const banner = document?.getElementById('banner');
  const cardGroup = document?.getElementById('card-group');
  const tabsGroups = document?.getElementById('tabs-groups');
  const lmsTrail = document?.getElementById('lms-trail');

  const arraySteps = () => {
    if (isStudent) {
      return ArrayStepsLmsOnboardingStudent;
    }

    if (isTutor) {
      return ArrayStepsLmsOnboardingTutor;
    }

    if (isElective) {
      return ArrayStepsLmsOnboardingElective;
    }

    return ArrayStepsLmsOnboardingLmsEducator;
  };

  const isFirstStep = firstStepsLmsOnboarding.includes(stepActive);
  const isFinalStep = stepActive === arraySteps()?.length;

  useEffect(() => {
    if (showModalStepsOnboarding && !showModalStartOnboarding && !isMobile) {
      if (isFirstStep) {
        navbarLms?.scrollIntoView({ behavior: 'smooth' });
      }

      if (isStudent) {
        if (stepActive === StepsLmsOnboardingStudent.ELECTIVES) {
          document?.getElementById(hasGroups ? 'mine-courses' : 'navbar-lms')?.scrollIntoView({ behavior: 'smooth' });
        }

        if (stepActive === StepsLmsOnboardingStudent.SEE_TUTORIAL) {
          navbar?.scrollIntoView({ behavior: 'smooth' });
        }
      }

      if (isTutor) {
        if (stepActive === StepsLmsOnboardingTutor.MY_GROUPS) {
          if (hasVideos) {
            navbarLms?.scrollIntoView({ behavior: 'smooth' });
          } else {
            banner?.scrollIntoView({ behavior: 'smooth' });
          }
        }

        if (stepActive === StepsLmsOnboardingTutor.VIDEOS) {
          navbarLms?.scrollIntoView({ behavior: 'smooth' });
        }

        if (
          (stepActive === StepsLmsOnboardingTutor.CARD_GROUP || stepActive === StepsLmsOnboardingTutor.CONFIG_GROUP) &&
          !isAdmin
        ) {
          cardGroup?.scrollIntoView({ behavior: 'smooth' });
        }

        if (
          (stepActive === StepsLmsOnboardingTutor.CARD_GROUP || stepActive === StepsLmsOnboardingTutor.CONFIG_GROUP) &&
          isAdmin
        ) {
          tabsGroups?.scrollIntoView({ behavior: 'smooth' });
        }

        if (stepActive === StepsLmsOnboardingTutor.SEE_TUTORIAL) {
          banner?.scrollIntoView({ behavior: 'smooth' });
        }
      }

      if (isLmsEducator) {
        if (stepActive === StepsLmsOnboardingLmsEducator.CERTIFICATES) {
          navbarLms?.scrollIntoView({ behavior: 'smooth' });
        }

        if (stepActive === StepsLmsOnboardingLmsEducator.SEE_TUTORIAL) {
          banner?.scrollIntoView({ behavior: 'smooth' });
        }

        if (stepActive === StepsLmsOnboardingLmsEducator.TRAILS) {
          lmsTrail?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [
    isMobile,
    showModalStepsOnboarding,
    showModalStartOnboarding,
    stepActive,
    navbar,
    navbarLms,
    banner,
    lmsTrail,
    cardGroup,
  ]);

  useEffect(() => {
    if (showModalStepsOnboarding && isMobile) {
      if (isFirstStep && !!menuLms) {
        menuLms.scrollLeft = 0;
        navbarLms?.scrollIntoView({ behavior: 'smooth' });
      }

      if (isFinalStep && !!menuLms) {
        menuLms.scrollLeft = 10000;
        navbarLms?.scrollIntoView({ behavior: 'smooth' });
      }

      if (isStudent) {
        if (stepActive === StepsLmsOnboardingStudent.ELECTIVES) {
          document?.getElementById(hasGroups ? 'navbar-lms' : 'banner')?.scrollIntoView({ behavior: 'smooth' });
        }
      }

      if (isTutor) {
        if (stepActive === StepsLmsOnboardingTutor.MY_GROUPS) {
          banner?.scrollIntoView({ behavior: 'smooth' });
        }

        if (
          (stepActive === StepsLmsOnboardingTutor.CARD_GROUP || stepActive === StepsLmsOnboardingTutor.CONFIG_GROUP) &&
          !isAdmin
        ) {
          cardGroup?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [isMobile, showModalStepsOnboarding, stepActive, isStudent]);

  const setStepActive = (step: number) => {
    dispatch({ payload: step, type: SET_STEP_ACTIVE_LMS_ONBOARDING });
  };

  const onClose = () => {
    onCancel?.();
    setStepActive(1);
  };

  const onClickNext = () => {
    if (isFinalStep) {
      onClose();

      return;
    }

    if (isTutor && stepActive === StepsLmsOnboardingTutor.NAVIGATION_MENU && !hasVideos) {
      setStepActive(StepsLmsOnboardingTutor.MY_GROUPS);

      return;
    }

    if (isLmsEducator && stepActive === StepsLmsOnboardingLmsEducator.CERTIFICATES && !lmsInfos?.trailList) {
      setStepActive(StepsLmsOnboardingLmsEducator.SEE_TUTORIAL);

      return;
    }

    setStepActive(stepActive + 1);
  };

  const onClickPrevious = () => {
    if (isTutor && stepActive === StepsLmsOnboardingTutor.MY_GROUPS && !hasVideos) {
      setStepActive(StepsLmsOnboardingTutor.NAVIGATION_MENU);

      return;
    }

    if (isLmsEducator && stepActive === StepsLmsOnboardingLmsEducator.SEE_TUTORIAL && !lmsInfos?.trailList) {
      setStepActive(StepsLmsOnboardingLmsEducator.CERTIFICATES);

      return;
    }

    setStepActive(stepActive - 1);
  };

  const getProfileTextRoute = () => {
    if (isStudent) {
      return 'STUDENT';
    }

    if (isTutor) {
      return 'TUTOR';
    }

    if (isLmsEducator) {
      return 'LMS_EDUCATOR';
    }

    return 'ELECTIVE';
  };

  const renderI18n = (
    restRoute: string | number,
    params?: { myCourses?: string; termCourseSingular?: string; termCourse?: string; playlistTitle?: string }
  ) => {
    return (
      <Translate
        value={`PAGES.GROUPS.GROUPS_PAGE.MODAL_STEPS_ONBOARDING.DESCRIPTIONS.${getProfileTextRoute()}.${String(
          restRoute
        )}`}
        dangerousHTML
        className="modal-steps-onboarding__description"
        {...params}
      />
    );
  };

  const getClassNameStep = () => {
    if (isStudent) {
      switch (stepActive) {
        case StepsLmsOnboardingStudent.NAVIGATION_MENU:
          return `navigation-menu${hasGroups ? '--has-groups' : ''}`;

        case StepsLmsOnboardingStudent.ELECTIVES:
          return `electives${hasGroups ? '--has-groups' : ''}`;

        case StepsLmsOnboardingStudent.SEE_TUTORIAL:
          return 'see-tutorial';
      }
    }

    if (isTutor) {
      switch (stepActive) {
        case StepsLmsOnboardingTutor.NAVIGATION_MENU:
          return `navigation-menu-tutor${hasVideos ? '--has-videos' : ''}`;

        case StepsLmsOnboardingTutor.VIDEOS:
          return 'videos';

        case StepsLmsOnboardingTutor.MY_GROUPS:
          return 'my-groups';

        case StepsLmsOnboardingTutor.SEE_TUTORIAL:
          return 'see-tutorial';
      }
    }

    if (isTutor && !isAdmin) {
      switch (stepActive) {
        case StepsLmsOnboardingTutor.CARD_GROUP:
        case StepsLmsOnboardingTutor.CONFIG_GROUP:
          return 'card-group';
      }
    }

    if (isTutor && isAdmin) {
      switch (stepActive) {
        case StepsLmsOnboardingTutor.CARD_GROUP:
        case StepsLmsOnboardingTutor.CONFIG_GROUP:
          return 'card-group--admin';
      }
    }

    if (isLmsEducator) {
      switch (stepActive) {
        case StepsLmsOnboardingLmsEducator.NAVIGATION_MENU:
          return `navigation-menu${hasGroups || !uniqueTrail ? '--has-groups' : ''}`;

        case StepsLmsOnboardingLmsEducator.CERTIFICATES:
          return `certificates${hasGroups || !uniqueTrail ? '--has-groups' : ''}`;

        case StepsLmsOnboardingLmsEducator.TRAILS:
          return `trails${uniqueTrail ? '--unique-trail' : ''}`;

        case StepsLmsOnboardingLmsEducator.SEE_TUTORIAL:
          return 'see-tutorial';
      }
    }

    if (isElective) {
      switch (stepActive) {
        case StepsLmsOnboardingElective.SEE_TUTORIAL:
          return 'see-tutorial-elective';
      }
    }
  };

  const stepProgress =
    isLmsEducator && stepActive === StepsLmsOnboardingLmsEducator.SEE_TUTORIAL && !lmsInfos?.trailList
      ? stepActive - 1
      : stepActive;

  return (
    <Modal
      {...rest}
      open={showModalStepsOnboarding}
      width={800}
      onCancel={onClose}
      footer={false}
      closeIcon
      bodyStyle={{ padding: 0 }}
      className={`modal-steps-onboarding--step__${getClassNameStep()}`}
      centered={isElective && !isFinalStep}
    >
      <Box className="modal-steps-onboarding__body">
        <RenderCondition condition={!isFirstStep && !!isMobile}>
          <Flex onClick={onClickPrevious} className="modal-steps-onboarding__body__button-previous">
            <IcArrowLeft />
          </Flex>
        </RenderCondition>

        {renderI18n(stepActive, {
          termCourse: returnPronounWithCourseTerm()?.courseTerm?.toUpperCase(),
          myCourses: returnPronounWithCourseTerm()?.my?.toUpperCase(),
          playlistTitle: brandPlaylist?.[0]?.title,
        })}
        <RenderCondition
          condition={isTutor && stepActive === StepsLmsOnboardingTutor.NAVIGATION_MENU && tutorContent.detail?.isActive}
        >
          {renderI18n('MATERIAL')}
        </RenderCondition>

        <RenderCondition condition={isTutor && stepActive === StepsLmsOnboardingTutor.CONFIG_GROUP}>
          <Grid className="modal-steps-onboarding__description__config-group-items">
            <div className="modal-steps-onboarding__description__config-group-items__left">
              <span className="modal-steps-onboarding__description__config-group-items__see-details">
                {renderI18n('CARD_GROUP_BUTTONS.SEE_DETAILS.LABEL')}
              </span>
            </div>

            <span>
              {renderI18n('CARD_GROUP_BUTTONS.SEE_DETAILS.DESCRIPTION', {
                termCourseSingular: returnPronounWithCourseTerm()?.courseTerm?.toLowerCase()?.slice(0, -1),
              })}
            </span>

            <div className="modal-steps-onboarding__description__config-group-items__left">
              <Btn variant="secondary" size={BUTTON_SIZE.SMALLEST}>
                <div className="modal-steps-onboarding__description__config-group-items__icon">
                  <CalendarOutlined />
                </div>
              </Btn>
            </div>

            <span> {renderI18n('CARD_GROUP_BUTTONS.TIMELINE')}</span>

            <div className="modal-steps-onboarding__description__config-group-items__left">
              <Btn variant="secondary" size={BUTTON_SIZE.SMALLEST}>
                <div className="modal-steps-onboarding__description__config-group-items__icon">
                  <IcClass />
                </div>
              </Btn>
            </div>

            <span> {renderI18n('CARD_GROUP_BUTTONS.STUDENTS')}</span>

            <div className="modal-steps-onboarding__description__config-group-items__left">
              <Btn variant="primary" size={BUTTON_SIZE.SMALLER}>
                {translate('PAGES.GROUPS.GROUPCARD.BUTTONS.ENTER')}
              </Btn>
            </div>

            <span> {renderI18n('CARD_GROUP_BUTTONS.ENTER')}</span>
          </Grid>
        </RenderCondition>

        <RenderCondition condition={isElective && !isFinalStep}>
          <Flex className="modal-steps-onboarding__gif-container">
            <Image
              className="modal-steps-onboarding__gif"
              src={`/assets/img/onboarding-lms-course-${isMobile ? 'mobile-' : ''}${stepActive}.gif`}
              width={isMobile ? 220 : 600}
            />

            <span className="modal-steps-onboarding__gif-description">Animação ilustrativa.</span>
          </Flex>
        </RenderCondition>

        <Box className="modal-steps-onboarding__footer">
          <Flex className="modal-steps-onboarding__footer__navigation">
            <Box>
              <RenderCondition condition={!isFinalStep}>
                <Button
                  size="lg"
                  label={translate('SHARED.CLOSE')}
                  onClick={onClose}
                  variant="secondary"
                  primaryColor={lmsInfos?.primaryColor}
                  className="modal-steps-onboarding__footer__navigation__button"
                />
              </RenderCondition>
            </Box>

            <Flex className="modal-steps-onboarding__footer__navigation__right">
              <RenderCondition condition={!isFirstStep && !isMobile}>
                <Button
                  size="lg"
                  label={translate('SHARED.PREVIOUS')}
                  variant="secondary"
                  primaryColor={lmsInfos?.primaryColor}
                  onClick={onClickPrevious}
                  className="modal-steps-onboarding__footer__navigation__button"
                />
              </RenderCondition>

              <Button
                size="lg"
                label={isFinalStep ? translate('SHARED.CLOSE') : translate('SHARED.NEXT')}
                primaryColor={lmsInfos?.primaryColor}
                onClick={onClickNext}
                className="modal-steps-onboarding__footer__navigation__button"
              />
            </Flex>
          </Flex>

          <Flex className="modal-steps-onboarding__footer__progress-bar">
            <Progress
              percent={(stepActive / arraySteps()?.length) * 100}
              showInfo={false}
              style={{ width: isMobile ? '100%' : '15rem' }}
              strokeColor={lmsInfos?.primaryColor}
            />
            <span style={{ color: lmsInfos?.primaryColor, whiteSpace: 'nowrap' }}>
              {stepProgress} {translate('SHARED.OF')}{' '}
              {arraySteps()?.length - ((isTutor && !hasVideos) || (isLmsEducator && !lmsInfos?.trailList) ? 1 : 0)}
            </span>
          </Flex>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalStepsOnboarding;
