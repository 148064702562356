import { getInstance } from './instance';

const chatAPI = {
  createChat: async (userIdList: string[], actorId: string) => {
    const instance = getInstance();
    userIdList.push(actorId);

    const { data } = await instance.post('/private-chat', {
      userIdList,
    });

    return data;
  },
  sendMessage: async (privateChatId: string, message?: string, photoUrl?: string, metadata?: string) => {
    const instance = getInstance();

    const { data } = await instance.post('/user-private-chat-message', {
      privateChatId,
      message,
      photoUrl,
      metadata,
    });

    return data;
  },
  getChats: async () => {
    const instance = getInstance();
    const {
      data: { rows },
    } = await instance.get('/user/teachers');

    return rows;
  },

  getParticipants: async (privateChatId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/private-chat/${privateChatId}`);

    return data;
  },

  getChatMessages: async (privateChatId: string) => {
    const instance = getInstance();
    const { data } = await instance.get('/user-private-chat-message', {
      params: {
        privateChatId,
      },
    });

    return data;
  },
};

export default chatAPI;
