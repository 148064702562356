// eslint-disable-next-line max-len
import { SET_JOURNEY_ALL } from '~/actions/actionTypes';

const getInitialState = (): reducers.JourneyReducer => ({
  list: null,
});

const journeyReducer = (
  state = getInitialState(),
  action: { type: string; payload: never }
): reducers.JourneyReducer => {
  switch (action.type) {
    case SET_JOURNEY_ALL:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default journeyReducer;
