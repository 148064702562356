import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router';

import { CourseActions } from '@portal/actions';

import { PanelCourseTest, PanelCourseTestTutor } from '@portal/components/v1/CourseTest';
import { PanelSatisfactionSurvey } from '@portal/components/v1/SatisfactionSurvey';
import RenderContent from '@portal/components/v1/ContentComponents/RenderContent/RenderContent';
import ActivitySection from '@portal/components/v1/ContentComponents/ActivitySection/ActivitySection';
import NavigationButtons from '@portal/components/v1/ContentComponents/NavigationButtons/NavigationButtuns';
import ActivitySectionTutor from '@portal/components/v1/ContentComponents/ActivitySectionTutor/ActivitySectionTutor';

import { getStackPath } from '@portal/config/routes';
import { ProductType } from '@portal/enum/productType';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { IRouteParams } from '@portal/models/route-params';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as StorageService from '@portal/services/storage';

const ContentMobilePage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { courseId } = useParams<IRouteParams>();

  const {
    course: { courseProgress },
    auth: { me },
  } = useReduxState();

  const isTutor = isDashTutor(me?.profileType) || null;
  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  useEffect(() => {
    if (!courseProgress.id && !!isTutor && productType === ProductType.LMS_STUDENT) {
      dispatch(CourseActions.getCourseProgress({ idCourse: courseId, isTutor: true }));
    } else if (!courseProgress.id && productType === ProductType.LMS_STUDENT) {
      dispatch(CourseActions.getCourseProgress({ idCourse: courseId }));
    }
  }, [isTutor, courseProgress, courseId]);

  const handleBackClick = useCallback(() => {
    history.push(`${getStackPath('CONTENT')}/${courseId}`);
  }, [courseId, history]);

  return (
    <div className="content-mobile-container">
      <div className="content-mobile-container__header">
        <button className="content-mobile-container__header__back-icon" onClick={handleBackClick}>
          <ArrowLeftOutlined />
        </button>
        <span className="content-mobile-container__header__label">{courseProgress.title}</span>
      </div>
      <Switch>
        <Route path={`${getStackPath('CONTENT')}/mobile/:courseId/test/:testId`} exact>
          {isTutor && productType === ProductType.LMS_STUDENT ? <PanelCourseTestTutor /> : <PanelCourseTest />}
        </Route>
        <Route path={`${getStackPath('CONTENT')}/mobile/:courseId/satisfaction-survey`} exact>
          <PanelSatisfactionSurvey />
        </Route>
        <Route path={`${getStackPath('CONTENT')}/mobile/:courseId/:moduleId/:contentId`} exact>
          <RenderContent />
        </Route>
        <Route path={`${getStackPath('CONTENT')}/mobile/:courseId/:moduleId/:contentId/:activityId`} exact>
          {isTutor && productType === ProductType.LMS_STUDENT ? <ActivitySectionTutor /> : <ActivitySection />}
        </Route>
      </Switch>
      <div className="content-mobile-container__navigation">
        <NavigationButtons />
      </div>
    </div>
  );
};

export default ContentMobilePage;
