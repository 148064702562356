import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Divider, Progress, Skeleton } from 'antd';

import { SidebarContentActions } from '@portal/actions';

import { ReactComponent as HideIcon } from '@portal/assets/svg/content/sidebar/ic_hide_sidebar.svg';

import { useReduxState } from '@portal/hooks/useReduxState';
import { translate } from '@portal/services/i18n';
import { getCourseProgressPercent } from '@portal/utils/getCourseProgressPercent';
import RenderCondition from '@portal/utils/renderCondition';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { IRouteParams } from '@portal/models/route-params';

const SideBarContentCard: React.FC = () => {
  const dispatch = useDispatch();

  const {
    course: { courseProgress },
    sidebarContent: { isHide },
    auth: { me },
    loading: { localAmount },
  } = useReduxState();

  const { courseId } = useParams<IRouteParams>();

  const activitiesCount = courseProgress?.courseUser?.[0]?.activitiesCount ?? 0;
  const activitiesDoneCount = courseProgress?.courseUser?.[0]?.activitiesDoneCount ?? 0;

  const isTutor = isDashTutor(me?.profileType);
  const isLoading = localAmount === 1 && (!courseProgress.photoUrl || courseProgress.id !== courseId);

  const result = {
    completed: activitiesDoneCount,
    total: activitiesCount,
  };

  const handleHide = () => {
    dispatch(SidebarContentActions.setIsHide(true));
  };

  return (
    <div className="sidebar-card">
      <RenderCondition condition={isLoading}>
        <Skeleton.Input className="sidebar-card__img-skeleton" />
        <Skeleton className="sidebar-card__img-skeleton" />
      </RenderCondition>

      <RenderCondition condition={!isLoading}>
        <div className="sidebar-card__img-container">
          <img className="sidebar-card__img-background" src={courseProgress.photoUrl} alt="background" />
        </div>
        <div className="sidebar-card__content">
          <div className="sidebar-card__content__header">
            <div className="sidebar-card__content__header__label">{courseProgress.title}</div>
            <div className="sidebar__container__hide-icon-container" style={isHide ? { visibility: 'hidden' } : {}}>
              <div className="sidebar__container__hide-icon-container__button" onClick={handleHide}>
                <HideIcon className="sidebar__container__hide-icon-container__button__icon" />
              </div>
            </div>
          </div>

          <RenderCondition condition={!isTutor && activitiesCount > 0}>
            <Progress
              type="line"
              percent={getCourseProgressPercent(activitiesCount, activitiesDoneCount)}
              strokeColor={document.documentElement.style.getPropertyValue('@primary-color-lms')}
              trailColor="rgba(114, 139, 151, 0.2)"
              strokeWidth={15}
              width={40}
              showInfo={false}
            />
            <div className="sidebar-card__content__progress__classes">
              <span>{translate('COMPONENTS.SIDEBARCONTENT.CARD.PROGRESS.COMPLETED', result)}</span>
            </div>
          </RenderCondition>

          <Divider />
        </div>
      </RenderCondition>
    </div>
  );
};

export default SideBarContentCard;
