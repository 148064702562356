import { getInstance } from './instance';

const PushNotificationApi = {
  getReport: async (params: advancedFilterModels.PushNotificationAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/notification', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/notification/${id}`);

    return data;
  },

  add: async (params: models.PushNotification) => {
    const instance = await getInstance();
    const { data } = await instance.post('/notification', params);

    return data;
  },

  addUpload: async (params: FormData) => {
    const instance = await getInstance();
    const { data } = await instance.post('/notification/upload', params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`/notification/${id}`);

    return data;
  },
};

export default PushNotificationApi;
