import { i18nReducer } from 'react-redux-i18n';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import academicYearReducer from './academicYear';
import activityReducer from './activity';
import authReducer from './auth';
import bannerReducer from './banner';
import brandReducer from './brand';
import chatReducer from './chat';
import closureTaskReducer from './closureTask';
import config from './config';
import contentReducer from './content';
import courseReducer from './course';
import courseTestReducer from './courseTest';
import groupReducer from './group';
import homeNotificationReducer from './homeNotification';
import journeyReducer from './journey';
import lmsTrailReducer from './lmsTrail';
import loadingReducer from './loading';
import notificationReducer from './notification';
import pushNotificationReducer from './pushNotification';
import satisfactionSurveyReducer from './satisfactionSurvey';
import sidebarContentReducer from './sidebarContent';
import topBarReducer from './topBar';
import tutorContentReducer from './tutorContent';
import tutorViewReducer from './tutorView';
import userReducer from './user';
import lmsOnboardingReducer from './lmsOnboarding';

const appReducer = combineReducers({
  auth: authReducer,
  i18n: i18nReducer,
  loading: loadingReducer,
  user: userReducer,
  pushNotification: pushNotificationReducer,
  course: courseReducer,
  activity: activityReducer,
  content: contentReducer,
  courseTest: courseTestReducer,
  closureTask: closureTaskReducer,
  banner: bannerReducer,
  chat: chatReducer,
  satisfactionSurvey: satisfactionSurveyReducer,
  topBar: topBarReducer,
  notification: notificationReducer,
  sidebarContent: sidebarContentReducer,
  brand: brandReducer,
  group: groupReducer,
  tutorView: tutorViewReducer,
  tutorContent: tutorContentReducer,
  homeNotification: homeNotificationReducer,
  config,
  academicYear: academicYearReducer,
  lmsTrail: lmsTrailReducer,
  journey: journeyReducer,
  lmsOnboarding: lmsOnboardingReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

const storeCreator = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
  )
);

export default storeCreator;
