import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Popover } from 'antd';

import { logout } from '~/actions/auth';
import { userActions } from '~/actions/v2';

import { Avatar, Box, Flex } from '~/components/v2';

import { useGetUserBrands, useReduxState } from '~/hooks';
import { translate } from '~/services';

import './PopoverChangeBrand.less';

const PopoverChangeBrand = () => {
  const dispatch = useDispatch();
  const {
    auth: { me },
    brand: { brandConfig },
  } = useReduxState();

  const { data: userBrands, refetch } = useGetUserBrands();

  useEffect(() => {
    if (me?.idSchool) {
      refetch();
    }
  }, [me?.idSchool]);

  const hasBrand = !!userBrands?.filter((brand) => brand?.id !== brandConfig?.id)?.length;

  return (
    <Box className="popover-change-brand">
      <Popover
        placement="bottomRight"
        content={
          <Flex className="popover-change-brand__content">
            <Flex className="popover-change-brand__list">
              {userBrands
                ?.filter((brand) => brand?.id !== brandConfig?.id)
                ?.map((brand) => (
                  <Box
                    className="popover-change-brand__list__item"
                    key={brand?.id}
                    onClick={() =>
                      brandConfig?.id !== brand?.id &&
                      dispatch(userActions.changeActiveBrand(brand, me?.profileType as number))
                    }
                  >
                    <Avatar
                      className="popover-change-brand__list__avatar"
                      imageUrl={brand?.logoIconUrl}
                      variant="secondary"
                    />
                  </Box>
                ))}
            </Flex>

            <span
              {...(hasBrand && { style: { marginTop: '2rem' } })}
              className="popover-change-brand__logout"
              onClick={() => dispatch(logout())}
            >
              {translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.LOGOUT')}
            </span>
          </Flex>
        }
        trigger="click"
      >
        <Box className="popover-change-brand__trigger">
          <Avatar
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
            imageUrl={brandConfig?.logoIconUrl}
            size="sm"
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default PopoverChangeBrand;
