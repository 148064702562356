import { IBrand } from '@portal/types';
import { getInstance } from './instance';

const BASE_URL = '/user';

const UserApi = {
  getReport: async (params: advancedFilterModels.UserAdvancedFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(BASE_URL, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  getUsersActivities: async (
    idActivity: string,
    idVirtualGruop: string,
    params: advancedFilterModels.UserActivityFilter
  ) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/activity/${idActivity}/group/${idVirtualGruop}`, {
      params,
    });

    return data;
  },

  getTeacherRevisionBySchool: async (idSchool: string) => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.get(`/teacher-home/${idSchool}/revision`);

    return data;
  },

  getUsersActivitiesHistory: async (
    idActivity: string,
    idVirtualGruop: string,
    params: advancedFilterModels.UserActivityFilter
  ) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/activity/${idActivity}/history/${idVirtualGruop}`, {
      params,
    });

    return data;
  },

  getProducts: async () => {
    const instance = getInstance({ api: 'new', version: 'v2' });
    const { data } = await instance.get(`${BASE_URL}/products`);

    return data;
  },

  add: async (params: models.User) => {
    const instance = getInstance();
    const { data } = await instance.post('${BASE_URL}/admin', params);

    return data;
  },

  update: async (id: string, params: models.User) => {
    const instance = getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  },

  updateStudantPassword: async (userId: string, params: models.UpdatePassword) => {
    const instance = getInstance();

    const { data } = await instance.put(`${BASE_URL}/student/${userId}`, params);

    return data;
  },

  updateUserPassword: async (userId: string, params: models.UpdatePassword) => {
    const instance = getInstance();

    const { data } = await instance.put(`${BASE_URL}/${userId}`, params);

    return data;
  },

  updateUserAvatar: async (file: string) => {
    const instance = getInstance();

    const { data } = await instance.post('${BASE_URL}/selfie', {
      photoBase64: file,
    });

    return data.profilePictureUrl;
  },

  getPendingTest: async (courseId: string, productId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/course-closure-task/${courseId}`, {
      params: {
        productId,
      },
    });

    return data;
  },

  changeSchool: async (idUser: string, infos: models.ParamsChangeSchool) => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.put(`user/${idUser}/change-school`, {
      ...infos,
    });

    return data;
  },

  getJourneyProducts: async (): Promise<string[]> => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.get(`${BASE_URL}/product-list`);

    return data;
  },

  getUserBrands: async () => {
    const instance = getInstance({ api: 'new', version: 'v2' });

    const { data } = await instance.get<IBrand[]>(`${BASE_URL}/brands`);

    return data;
  },

  changeActiveBrand: async (idBrand: string) => {
    const instance = getInstance({ api: 'new', version: 'v2' });

    const { data } = await instance.get<{ token: string; refreshToken: string }>(
      `${BASE_URL}/${idBrand}/change-active-brand`
    );

    return data;
  },
};

export default UserApi;
