import { BrandConfig } from '@portal/models/module';

export const setThemeBrand = (brandConfig: BrandConfig): void => {
  // set theme brand
  document.documentElement.style.setProperty(
    '--primary-color-brand',
    brandConfig?.primaryColor || '#728B97'
  );
  document.documentElement.style.setProperty(
    '--secondary-color-brand',
    brandConfig?.secondaryColor || '#B8C4CB'
  );
};

export const setThemeProduct = (productConfig: BrandConfig): void => {
  // set theme product
  document.documentElement.style.setProperty(
    '--primary-color-lms',
    productConfig?.primaryColor || '#728B97'
  );
  document.documentElement.style.setProperty(
    '--secondary-color-lms',
    productConfig?.secondaryColor || '#B8C4CB'
  );
};
