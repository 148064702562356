import { TagFilters } from '@portal/models/advanced-filters';

import { getInstance } from './instance';

const courseAPI = {
  getAllCourses: async (params: advancedFilterModels.MineCourses) => {
    const instance = getInstance();
    const { data } = await instance.get('/course/', { params });

    return data;
  },

  getMineCourses: async (params: advancedFilterModels.MineCourses) => {
    const instance = getInstance();
    const { data } = await instance.get('/course/my', { params });

    return data;
  },

  getOtherCourses: async (params: advancedFilterModels.OtherCourses) => {
    const instance = getInstance();
    const { data } = await instance.get('/course/available', { params });

    return data;
  },

  getConcludedCourses: async (params: advancedFilterModels.ConcludedCourses) => {
    const instance = getInstance();
    const { data } = await instance.get('/course/my-completed', { params });

    return data;
  },

  getCourseDetails: async (id: string, params?: advancedFilterModels.CourseDetailsFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course/${id}`, { params });

    return data;
  },

  getCourseProgress: async (idCourse: string, isTutor?: boolean, params?: { virtualGroupId?: string }) => {
    const instance = getInstance();

    if (isTutor) {
      const { data } = await instance.get(`/course/complete-with-activities/${idCourse}`, {
        params,
      });

      return data;
    } else {
      const { data } = await instance.get(`/course/${idCourse}/progress`);

      return data;
    }
  },

  accessCourse: async (id: string, brandProductId: string) => {
    try {
      const instance = getInstance();
      const { data } = await instance.put(`/course/${id}/add-student`, {
        brandProductId,
      });

      return data;
    } catch (err) {
      throw err;
    }
  },

  addTeacher: async (id: string, brandProductId: string, schoolId: string) => {
    try {
      const instance = getInstance();
      const { data } = await instance.put(`/course/${id}/add-professor-student`, { brandProductId, schoolId });

      return data;
    } catch (err) {
      throw err;
    }
  },

  getCourseUsers: async (idCourse: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course/${idCourse}/users`);

    return data;
  },

  getFilterTags: async (params?: TagFilters) => {
    const instance = getInstance();
    const { data } = await instance.get('/tag', { params });

    return data;
  },

  getUserMinutesProgress: async (id: string[]) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course/${id}/user-minutes-progress`);

    return data;
  },

  getTagById: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/tag/${id}`);

    return data;
  },

  getPendingActivities: async () => {
    const instance = getInstance();
    const { data } = await instance.get('/course/pending-activities');

    return data;
  },
};

export default courseAPI;
