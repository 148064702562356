/* eslint-disable max-len */
import React from 'react';

const IcUniqueArrowLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="60px"
      height="30px"
      fill="currentColor"
      viewBox="0 0 13 22"
    >
      <path
        fill="currentColor"
        d="M.618 10.205l9.11-9.11c.44-.44 1.152-.44 1.592 0l1.062 1.063c.439.438.44 1.15.002 1.589L5.164 11l7.22 7.254c.437.44.436 1.15-.002 1.59l-1.063 1.062c-.44.439-1.152.439-1.59 0l-9.11-9.11a1.125 1.125 0 010-1.591z"
      ></path>
    </svg>
  );
};

export default IcUniqueArrowLeft;
