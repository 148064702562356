import React, { useState } from 'react';
import { Input } from 'antd';

import { translate } from '@portal/services/i18n';

import { emailValidate } from '~/utils/format';
import * as MessageService from '~/services/message';
import { InitialStateAuth } from '~/utils/inititalState';

import EmptyImg from '~/assets/png/empty.png';
import ArcoLogo from '~/assets/svg/ic_arco_logo.svg';
import { ReactComponent as Eye } from '~/assets/svg/eye.svg';
import { ReactComponent as EyeOff } from '~/assets/svg/eye-off.svg';

import './Login.less';

const Login = (): JSX.Element => {
  const [authInfo, setAuthInfo] = useState<models.AuthRequest>(InitialStateAuth);

  async function handleLogin() {
    if (authInfo.email === '') {
      return MessageService.error(translate('errors.login.validation.email'));
    }

    if (authInfo.email && !emailValidate(authInfo.email)) {
      return MessageService.error(translate('errors.login.validation.emailValidation'));
    }

    if (authInfo.password.trim() === '') {
      return MessageService.error(translate('errors.login.validation.password'));
    }
  }

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__container__image">
          <img src={EmptyImg} alt="Logo" />
        </div>

        <div className="login__container__content">
          <div className="login__container__content__logo">
            <img src={ArcoLogo} alt="Logo" width={150} height={100} />
          </div>
          <div className="login__container__content__input">
            <div className="login__container__content__input__email">
              <Input
                type="text"
                placeholder={translate('routing.login.items.components.input.email')}
                onChange={(e) =>
                  setAuthInfo({
                    ...authInfo,
                    email: e.target.value,
                  })
                }
                value={authInfo?.email}
                style={{
                  borderRadius: '8px',
                  border: '0.5px solid #dce2e4',
                  height: '60px',
                }}
              />
            </div>
            <div className="login__container__content__input__password">
              <Input.Password
                placeholder={translate('routing.login.items.components.input.password')}
                onChange={(e) =>
                  setAuthInfo({
                    ...authInfo,
                    password: e.target.value,
                  })
                }
                value={authInfo?.password}
                iconRender={(visible) => (visible ? <Eye /> : <EyeOff />)}
                style={{
                  borderRadius: '8px',
                  border: '0.5px solid #dce2e4',
                  height: '60px',
                }}
                onKeyPress={(e) => (e.charCode === 13 ? handleLogin() : null)}
              />
            </div>
          </div>
          <div className="login__container__content__buttons">
            <div>
              <button className="login__container__content__buttons__button" onClick={() => handleLogin()}>
                {translate('routing.login.items.components.button.login')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
