import {
  DECREASE_LOADING,
  DECREASE_LOCAL_LOADING,
  INCREASE_LOADING,
  INCREASE_LOCAL_LOADING,
} from '~/actions/actionTypes';
import { LoadingReducer } from '~/models/reducers';

const initialState: reducers.LoadingReducer = {
  amount: 0,
  localAmount: 0,
};

const loadingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INCREASE_LOADING:
      state = {
        ...state,
        amount: state.amount + 1,
      };
      break;
    case INCREASE_LOCAL_LOADING:
      state = {
        ...state,
        localAmount: state.amount + 1,
      };
      break;
    case DECREASE_LOADING:
      state = {
        ...state,
        amount: state.amount - 1,
      };
      break;
    case DECREASE_LOCAL_LOADING:
      state = {
        ...state,
        localAmount: state.amount - 1,
      };
      break;
    default:
      return state;
  }

  return state;
};

export const isLoading = (state: LoadingReducer) => {
  return state.amount > 0;
};

export const isLocalLoading = (state: LoadingReducer) => {
  return state.localAmount > 0;
};

export default loadingReducer;
