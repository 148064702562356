import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';
import { BREAKPOINTS } from '@portal/enum/breakpoints';
import { SatisfactionSurveyStatus } from '@portal/enum/satisfactionSurvey';
import { TasksStatus } from '@portal/enum/taskStatus';
import { useReduxState } from '@portal/hooks/useReduxState';
import { IRouteParams } from '@portal/models/route-params';
import { translate } from '@portal/services/i18n';
import { isAvailableBySchedule } from '@portal/utils/isAvailableBySchedule';
import { isUUID } from '@portal/utils/isUUID';

import SidebarDropDownFinalTaskItem from './SidebarDropDownFinalTaskItem/SidebarDropDownFinalTaskItem';

const SideBarDropdownFinalTasks: React.FC = () => {
  const [widthScreen, setWidthScreen] = useState(document.body.clientWidth);

  const { courseProgress } = useReduxState().course;

  const history = useHistory();
  const match = useRouteMatch<{ url: string }>();
  const { courseId } = useParams<IRouteParams>();

  const { MD } = BREAKPOINTS;

  const [isExpanded, setIsExpanded] = useState(false);

  const setWidthOnResize = useCallback(() => {
    setWidthScreen(document.body.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setWidthOnResize);

    return () => window.removeEventListener('resize', setWidthOnResize);
  }, [setWidthOnResize]);

  const handleTestClick = useCallback((testIdProp: string) => {
    if (widthScreen > MD) {
      history.push(`${getStackPath('CONTENT')}/${courseId}/test/${testIdProp}`);

      return;
    }
    history.push(
      `${getStackPath('CONTENT')}/mobile/${courseId}/test/${testIdProp}`
    );
  }, []);

  const handleSatisfactionSurveyClick = useCallback(() => {
    if (widthScreen > MD) {
      history.push(
        `${getStackPath('CONTENT')}/${courseId}/satisfaction-survey`
      );

      return;
    }
    history.push(
      `${getStackPath('CONTENT')}/mobile/${courseId}/satisfaction-survey`
    );
  }, [MD, courseId, courseProgress, history, widthScreen]);

  const getLastId = useCallback(() => {
    const url = window.location.pathname;

    return url.split('/').pop();
  }, []);

  useEffect(() => {
    const url = window.location.pathname;
    const currentModuleId = url.split('/')[3];
    if (!isUUID(currentModuleId)) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [match]);

  return (
    <div className="sidebar-dropdown-final-task">
      <Accordion
        onClick={() => setIsExpanded(!isExpanded)}
        expanded={isExpanded}
      >
        <AccordionSummary expandIcon={<DownOutlined />}>
          <div className="sidebar-dropdown-final-task__label-container">
            <div className="sidebar-dropdown-final-task__label-container__status">
              {courseProgress?.test?.testStatus === TasksStatus.DONE &&
                courseProgress?.courseUser?.[0]?.satisfactionSurveyStatus ===
                  SatisfactionSurveyStatus.DONE && (
                  <div className="sidebar-dropdown-final-task__label-container__status__check">
                    <CheckOutlined />
                  </div>
                )}
            </div>
            <span className="sidebar-dropdown-final-task__label-container__label">
              {translate('COMPONENTS.SIDEBARCONTENT.FINAL_TASK_DROPDOWN')}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {courseProgress?.test && (
            <SidebarDropDownFinalTaskItem
              label={
                courseProgress?.test?.title ||
                translate('PAGES.CONTENT.FINAL_EVALUATION.SIDEBAR.TITLE')
              }
              onClick={() => handleTestClick(courseProgress.test.id)}
              index={1}
              status={courseProgress?.test?.testStatus || 2}
              endIndex={2}
              selected={courseProgress.test?.id === getLastId()}
              disabled={
                courseProgress?.test?.testStatus ===
                  TasksStatus.NOT_AVAILABLE ||
                (courseProgress.test &&
                  courseProgress.test.schedule &&
                  courseProgress.test.schedule.length > 0 &&
                  !isAvailableBySchedule(courseProgress.test.schedule[0]))
              }
              count={Number(courseProgress?.test?.pendingTestReviewCount)}
            />
          )}
          <SidebarDropDownFinalTaskItem
            label={translate('PAGES.CONTENT.SATISFACTION_INDEX.SIDEBAR.TITLE')}
            onClick={handleSatisfactionSurveyClick}
            status={
              (courseProgress &&
                courseProgress.courseUser &&
                courseProgress.courseUser[0].satisfactionSurveyStatus) ||
              2
            }
            endIndex={2}
            index={2}
            selected={match.url.includes('/satisfaction-survey')}
            disabled={
              courseProgress &&
              courseProgress.courseUser &&
              (!courseProgress.courseUser[0].satisfactionSurveyStatus ||
                courseProgress.courseUser[0].satisfactionSurveyStatus ===
                  SatisfactionSurveyStatus.BLOCKED)
            }
            isSatisfactionSurvey={true}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SideBarDropdownFinalTasks;
