import React, { ElementType } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as HelpIcon } from '~/assets/Icon/help.svg';
import {
  Box,
  Button,
  Flex,
  IcBell,
  IcChat,
  IcHouse,
  IcShape,
  IcWindow,
  MenuHamburguerMobile,
  PopoverChangeBrand,
  PopoverNotifications,
  PopoverProducts,
  PopoverSchoolManagement,
} from '~/components/v2';
import OptionNavbar from './OptionNavbar';

import { Constants } from '~/config/env';
import { OptionsNavbar, ProfileType, arrayAdminProfiles, arrayProfilesWithAccess } from '~/enum';
import { useReduxState } from '~/hooks';
import { getCookie, translate } from '~/services';
import { RenderCondition } from '~/utils';
import useWindowSize from '@portal/utils/getSizeWindow';

import './Navbar.less';

interface IOptionsNavbar {
  key: number;
  title: string;
  link?: string;
  action?: () => void;
  icon: ElementType;
  permissions: ProfileType[];
  isHidden?: boolean;
}

const Navbar = () => {
  const isMobile = useWindowSize();

  const {
    auth: { me },
    config,
  } = useReduxState();

  const history = useHistory();

  const isPartnership = me?.isSaePartnership || me?.isSasPartnership || getCookie('user-integration');
  const isAdmin = !!me?.profileType && arrayAdminProfiles.includes(me?.profileType);

  const renderI18n = (restRoute: string) => {
    return translate(`COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.${restRoute}`);
  };

  const optionsNavbar: IOptionsNavbar[] = [
    {
      key: OptionsNavbar.START,
      title: renderI18n('START'),
      icon: IcHouse,
      link: `${Constants.portalUrl}/home`,
      permissions: arrayProfilesWithAccess,
    },
    {
      key: OptionsNavbar.FAQ,
      title: renderI18n('FAQ'),
      link: `${config?.configPortal?.faq}`,
      icon: HelpIcon,
      permissions: arrayProfilesWithAccess,
    },
    {
      key: OptionsNavbar.CHAT,
      title: renderI18n('CHAT'),
      link: `${Constants.portalUrl}/chat`,
      icon: IcChat,
      permissions: [ProfileType.TEACHER, ProfileType.STUDENT],
      isHidden: !config?.configPortal?.showChat || !!isMobile,
    },
    {
      key: OptionsNavbar.NOTIFICATIONS,
      title: renderI18n('NOTIFICATIONS'),
      icon: IcBell,
      permissions: arrayProfilesWithAccess,
      isHidden: !!isMobile,
    },
    {
      key: OptionsNavbar.PRODUCTS,
      title: renderI18n('PRODUCTS'),
      icon: IcWindow,
      permissions: arrayProfilesWithAccess,
    },
    {
      key: OptionsNavbar.PERFIL,
      title: renderI18n('PROFILE'),
      link: `${Constants.portalUrl}/perfil/${me?.id}`,
      icon: IcShape,
      action: () => history.push(`${Constants.portalUrl}/perfil/${me?.id}`),
      permissions: arrayProfilesWithAccess,
      isHidden: !!isMobile,
    },
  ];

  const optionsWithPopover = [OptionsNavbar.NOTIFICATIONS, OptionsNavbar.PRODUCTS];

  return (
    <Flex id="navbar" className="navbar" {...(isAdmin && { style: { zIndex: 999, position: 'fixed' } })}>
      <Flex className="navbar__content">
        <RenderCondition condition={!!isMobile}>
          <MenuHamburguerMobile />
        </RenderCondition>
        <RenderCondition condition={isAdmin && !isPartnership}>
          <PopoverSchoolManagement>
            <Box>
              <Button
                label={renderI18n('SCHOOL_MANAGEMENT')}
                variant="secondary"
                type="button"
                style={{ border: 'none', padding: !isMobile ? '0 5rem' : '0 2rem' }}
                size={!isMobile ? 'md' : 'sm'}
              />
            </Box>
          </PopoverSchoolManagement>
        </RenderCondition>
        <Flex
          className="navbar__options"
          style={{ width: !(!!isMobile && isAdmin) ? '100%' : 'fit-content', gap: !isMobile ? '3.5rem' : '1.5rem' }}
        >
          <RenderCondition condition={!isPartnership && !(!!isMobile && isAdmin)}>
            {optionsNavbar
              .filter(({ permissions }) => !!me?.defaultProfileType && permissions.includes(me?.defaultProfileType))
              .map(({ key, icon: Icon, title, link, isHidden }) => (
                <RenderCondition condition={!isHidden} key={key}>
                  <RenderCondition condition={!optionsWithPopover.includes(key)}>
                    <>
                      <OptionNavbar title={title} link={link} Icon={Icon} />
                    </>
                  </RenderCondition>

                  <RenderCondition condition={key === OptionsNavbar.NOTIFICATIONS}>
                    <PopoverNotifications>
                      <>
                        <OptionNavbar title={title} link={link} Icon={Icon} />
                      </>
                    </PopoverNotifications>
                  </RenderCondition>

                  <RenderCondition condition={key === OptionsNavbar.PRODUCTS}>
                    <PopoverProducts>
                      <>
                        <OptionNavbar title={title} link={link} Icon={Icon} />
                      </>
                    </PopoverProducts>
                  </RenderCondition>
                </RenderCondition>
              ))}
          </RenderCondition>

          <PopoverChangeBrand />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Navbar;
