import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { DatePicker, Form, Switch } from 'antd';
import moment, { Moment } from 'moment';
import { Box, Typography } from '@mui/material';

import { updateGroupSchedule } from '@portal/actions/group';

import { Btn } from '@portal/components';

import { translate } from '@portal/services/i18n';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getCookie } from '@portal/services/cookie';
import RenderCondition from '@portal/utils/renderCondition';
import { BUTTON_SIZE } from '@portal/enum/buttonSize';
import { formatDate } from '@portal/utils/formatDate';
import { ScheduleDate } from '@portal/models/module';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface IProps {
  id: string;
  groupSchedule: models.GroupSchedule;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DATEFORMAT = 'DD/MM/YYYY';

const GroupModalContent: React.FC<IProps> = ({ id, groupSchedule, closeModal }) => {
  const dispatch = useDispatch();
  const {
    auth: { me },
    brand: { brandProductConfig },
  } = useReduxState();

  const [schedulesDates, setSchedulesDates] = useState<models.ScheduleDate[]>([]);
  const [enrollment, setEnrollment] = useState<models.IPropsenrollment>();
  const [courseTestDates, setCourseTestDates] = useState<models.ICourseTestDates>();
  const [finalDateBlock, setFinalDateBlock] = useState<boolean>(false);

  const { modules } = groupSchedule;

  const courseTestEndAt = groupSchedule?.test?.schedule?.[0]?.endAt;
  const courseTestStartAt = groupSchedule?.test?.schedule?.[0]?.startAt;
  const groupEnrollmentStartAt = groupSchedule?.groupvirtual?.enrollmentStartDate;
  const groupEnrollmentEndAt = groupSchedule?.groupvirtual?.enrollmentEndDate;

  useEffect(() => {
    if (groupEnrollmentStartAt && groupEnrollmentEndAt) {
      setEnrollment({
        enrollmentStartDate: groupEnrollmentStartAt,
        enrollmentEndDate: groupEnrollmentEndAt,
      });
    }

    if (courseTestStartAt && courseTestEndAt) {
      setCourseTestDates({
        testStartAt: courseTestStartAt as string,
        testEndAt: courseTestEndAt as string,
      });
    }

    if (groupSchedule?.groupvirtual?.finalDateBlock) {
      setFinalDateBlock(true);
    }
  }, [groupSchedule]);

  const onChangeSchedules = (date: Moment | null, prop: 'startAt' | 'endAt', schedule: ScheduleDate) => {
    const existsSchedule = schedulesDates.filter((o) => o.id !== schedule?.id) || [];

    if (date) {
      schedule[prop] = prop === 'startAt' ? formatDate.startOfDay(date) : formatDate.endOfDay(date);
    } else {
      schedule[prop] = '';
    }

    setSchedulesDates([...existsSchedule, { ...schedule }]);
  };

  const onFinish = () => {
    dispatch(
      updateGroupSchedule(id, {
        finalDateBlock,
        contentScheduleDateList: schedulesDates,
        ...enrollment,
        ...courseTestDates,
      })
    );
    closeModal(false);
  };

  const isSaePartnership = me?.isSaePartnership ?? getCookie('user-integration');

  return (
    <div>
      <Form {...{ onFinish }}>
        <RenderCondition condition={!isSaePartnership}>
          <Row className="mt-2 mb-2">
            <span className="home-details__modal-container__enrollmentDates">
              {translate('PAGES.GROUPS.GROUPS_MODAL.SUBTITLE')}
            </span>
          </Row>

          <Col className="d-flex justify-content-start p-0 home-details__gap column-responsive">
            <div className="home-details__modal-container__dataPicker">
              <DatePicker
                bordered={false}
                style={{ width: '100%' }}
                placeholder={translate('PAGES.GROUPS.GROUPS_MODAL.PLACE_HOLDER')}
                format={DATEFORMAT}
                value={enrollment?.enrollmentStartDate ? moment(enrollment?.enrollmentStartDate) : null}
                onChange={(value) =>
                  setEnrollment({
                    ...enrollment,
                    enrollmentStartDate: value ? formatDate.startOfDay(value) : '',
                  })
                }
              />
            </div>
            <div className="home-details__modal-container__dataPicker">
              <DatePicker
                bordered={false}
                style={{ width: '100%' }}
                placeholder={translate('PAGES.GROUPS.GROUPS_MODAL.PLACE_HOLDER')}
                format={DATEFORMAT}
                value={enrollment?.enrollmentEndDate ? moment(enrollment?.enrollmentEndDate) : null}
                onChange={(value) =>
                  setEnrollment({
                    ...enrollment,
                    enrollmentEndDate: value ? formatDate.endOfDay(value) : '',
                  })
                }
              />
            </div>
          </Col>
        </RenderCondition>

        <Row>
          <p className="mt-4">{translate('PAGES.GROUPS.GROUPS_MODAL.PARAGRAPH')}</p>
        </Row>

        <Box borderRadius="15px" bgcolor={brandProductConfig?.primaryColor} color="white" p="1rem">
          <Typography fontWeight="bold">{translate('PAGES.GROUPS.GROUPS_MODAL.FINAL_DATE_BLOCK.TITLE')}</Typography>
          <Typography fontSize="0.75rem" mt="0.8rem" mb="1rem">
            {translate('PAGES.GROUPS.GROUPS_MODAL.FINAL_DATE_BLOCK.DESCRIPTION')}
          </Typography>

          <Row>
            <Switch onChange={() => setFinalDateBlock(!finalDateBlock)} checked={finalDateBlock} />
            <Typography ml="0.5rem">
              {finalDateBlock
                ? translate('PAGES.GROUPS.GROUPS_MODAL.FINAL_DATE_BLOCK.ON')
                : translate('PAGES.GROUPS.GROUPS_MODAL.FINAL_DATE_BLOCK.OFF')}
            </Typography>
          </Row>
        </Box>

        {modules.map((item: models.ScheduleModule, index: number) => (
          <div key={index}>
            <Row className="home-details__modal-container__subtitle">
              <span className="mt-4 home-details__modal-container__bold">{item.title}</span>
            </Row>

            {item.contents.map((item: models.Content, index: number) => (
              <Row className="mt-4 column-responsive align-items-center" key={index}>
                <Col>{item.title}</Col>
                <Col>
                  <span>
                    {translate('PAGES.GROUPS.GROUPS_MODAL.START_AT')} <span className="font-weight-bold">*</span>
                  </span>
                  <Form.Item name={`start${item.schedule[0]?.id}`}>
                    <div className="home-details__modal-container__dataPicker">
                      <DatePicker
                        style={{ width: '100%' }}
                        bordered={false}
                        value={item.schedule[0]?.startAt ? moment(item.schedule[0].startAt) : null}
                        placeholder={translate('PAGES.GROUPS.GROUPS_MODAL.PLACE_HOLDER')}
                        format={DATEFORMAT}
                        onChange={(value) => onChangeSchedules(value, 'startAt', item?.schedule[0])}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col>
                  <span>
                    {translate('PAGES.GROUPS.GROUPS_MODAL.END_AT')} <span className="font-weight-bold">*</span>
                  </span>
                  <Form.Item name={`end${item.schedule[0]?.id}`}>
                    <div className="home-details__modal-container__dataPicker">
                      <DatePicker
                        bordered={false}
                        style={{ width: '100%' }}
                        value={item.schedule[0]?.endAt ? moment(item.schedule[0].endAt) : null}
                        placeholder={translate('PAGES.GROUPS.GROUPS_MODAL.PLACE_HOLDER')}
                        format={DATEFORMAT}
                        onChange={(value) => onChangeSchedules(value, 'endAt', item?.schedule[0])}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </div>
        ))}

        <RenderCondition condition={!!groupSchedule?.test}>
          <Row className="home-details__modal-container__subtitle">
            <span className="mt-4 home-details__modal-container__bold">{groupSchedule?.test?.title}</span>
          </Row>

          <Row className="mt-4 column-responsive align-items-center">
            <Col>
              <div className="home-details__modal-container__dataPicker">
                <DatePicker
                  bordered={false}
                  style={{ width: '100%' }}
                  placeholder={translate('PAGES.GROUPS.GROUPS_MODAL.PLACE_HOLDER')}
                  format={DATEFORMAT}
                  value={courseTestDates?.testStartAt ? moment(courseTestDates?.testStartAt) : null}
                  onChange={(value) =>
                    setCourseTestDates({
                      ...courseTestDates,
                      testStartAt: value ? formatDate.startOfDay(value) : '',
                    })
                  }
                />
              </div>
            </Col>
            <Col>
              <div className="home-details__modal-container__dataPicker">
                <DatePicker
                  bordered={false}
                  style={{ width: '100%' }}
                  placeholder={translate('PAGES.GROUPS.GROUPS_MODAL.PLACE_HOLDER')}
                  format={DATEFORMAT}
                  value={courseTestDates?.testEndAt ? moment(courseTestDates?.testEndAt) : null}
                  onChange={(value) =>
                    setCourseTestDates({
                      ...courseTestDates,
                      testEndAt: value ? formatDate.endOfDay(value) : '',
                    })
                  }
                />
              </div>
            </Col>
          </Row>
        </RenderCondition>

        <Col className="home-details__btn-area column-responsive__reverse">
          <span className="home-details__modal-container__cancel" onClick={() => closeModal(false)}>
            {translate('COMPONENTS.GROUP_MODAL.CANCEL_BUTTON')}
          </span>
          <Btn variant="primary" type="submit" size={BUTTON_SIZE.FULL}>
            <span className="home-details__modal-container__submit">
              {translate('COMPONENTS.GROUP_MODAL.UPDATE_BUTTON')}
            </span>
          </Btn>
        </Col>
      </Form>
    </div>
  );
};

export default GroupModalContent;
