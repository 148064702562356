import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';

import utilsAPI from '@portal/repositories/utils';
import { translate } from '@portal/services/i18n';
import * as MessageService from '~/services/message';

import DragDropIcon from '~/assets/svg/ic_drag_drop.svg';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const DragAndDrop: React.FC<IProps> = ({ value, onChange, disabled }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileMetadata, setFileMetadata] = useState<File | null>();
  const [attachmentURL, setAttachmentURL] = useState(value);
  const maxFileSize = useMemo(() => {
    return 100;
  }, []);

  useEffect(() => {
    onChange(attachmentURL);
  }, [attachmentURL]);

  const removeAttachment = useCallback(() => {
    setAttachmentURL('');
    setFileMetadata(null);
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      const file = acceptedFiles[0];
      if (file.size > maxFileSize * 1000000) {
        return MessageService.warn('PAGES.CONTENT.ACTIVITY_SECTION.MESSAGES.MAX_SIZE_REACHED', { size: maxFileSize });
      }
      try {
        setIsUploading(true);
        const response = await utilsAPI.sendFile(file);

        if (response.url) {
          setAttachmentURL(response.url);
          setFileMetadata(file);
          MessageService.success('SHARED.MESSAGES.QUESTION_ANSWER.UPLOAD_SUCCESSFULLY');
        }
      } catch (err: any) {
        MessageService.error(err.message);
      } finally {
        setIsUploading(false);
      }
    },
    [maxFileSize]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <div className="input-attachment-container">
      <div
        {...getRootProps()}
        className={`input-attachment-container__draggable_container ${
          disabled ? 'input-attachment-container__draggable_container--disabled' : ''
        }`}
      >
        <div className="input-attachment-container__draggable_container__notUploading">
          <input {...getInputProps()} disabled={disabled} />
          <img
            className="input-attachment-container__draggable_container__icon"
            src={DragDropIcon}
            alt="attachment icon"
          />
          <span className="input-attachment-container__draggable_container__notUploading__label">
            {!isUploading && fileMetadata && fileMetadata.name}
            {!isUploading && !fileMetadata && (
              <>
                {isDragActive ? (
                  translate('COMPONENTS.DRAG_AND_DROP.DROPZONE.DRAG_ACTIVE')
                ) : (
                  <div>
                    <span>{translate('COMPONENTS.DRAG_AND_DROP.DROPZONE.DROPZONE_LABEL')}</span>
                  </div>
                )}
              </>
            )}

            {!isUploading && fileMetadata && <CloseOutlined onClick={removeAttachment} />}

            {isUploading && (
              <>
                {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.SENDING_ATTACHMENT')}
                <SyncOutlined spin />
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DragAndDrop;
