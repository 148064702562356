import React, { useCallback, useEffect, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { getStackPath } from '@portal/config/routes';
import { useReduxState } from '@portal/hooks/useReduxState';
import { CourseModule, CourseProgress, ModuleContent } from '@portal/models/reducers';
import { IRouteParams } from '@portal/models/route-params';
import { translate } from '@portal/services/i18n';
import { isBeforeBySchedule } from '@portal/utils/isAvailableBySchedule';
import * as MessageService from '~/services/message';
import useWindowSize from '@portal/utils/getSizeWindow';

const NavigationButtons: React.FC = () => {
  const { courseProgress } = useReduxState().course;
  const history = useHistory();
  const [currentModule, setCurrentModule] = useState({ index: -1, id: '' });
  const [currentContent, setCurrentContent] = useState({ index: -1, id: '' });
  const [currentActivity, setCurrentActivity] = useState({ index: -1, id: '' });

  const { courseId, moduleId, contentId, activityId } = useParams<IRouteParams>();

  const match = useRouteMatch();

  useEffect(() => {
    if (courseProgress?.id) {
      let moduleInit: CourseModule = {} as CourseModule;
      let contentInit: ModuleContent = {} as ModuleContent;

      courseProgress.modules.forEach((moduleItem, index) => {
        if (moduleItem?.id === moduleId) {
          setCurrentModule({ index, id: moduleItem?.id });
          moduleInit = moduleItem;
        }
      });

      if (moduleInit?.id) {
        moduleInit.contents.forEach((content, index) => {
          if (content?.id === contentId) {
            setCurrentContent({ index, id: content?.id });
            contentInit = content;
          }
        });
      }

      if (activityId && contentInit?.id) {
        contentInit.activities.forEach((activity, index) => {
          if (activity?.id === activityId) {
            setCurrentActivity({ index, id: activity?.id });
          }
        });
      }
      if (!activityId) {
        setCurrentActivity({ index: -1, id: '' });
      }
      if (!moduleId) {
        setCurrentModule({ index: -1, id: '' });
      }

      if (!contentId) {
        setCurrentContent({ index: -1, id: '' });
      }
    }
  }, [activityId, contentId, courseProgress, moduleId]);

  const routeContent = useWindowSize() ? `${getStackPath('CONTENT')}/mobile` : getStackPath('CONTENT');

  const redirectToContent = useCallback(
    (courseProgressProp: CourseProgress, next: boolean) => {
      if (currentModule?.id && currentContent?.id) {
        if (next) {
          // -=-=-=- try to find the next activity  -=-=-=-
          const activity =
            courseProgressProp?.modules?.[currentModule?.index]?.contents?.[currentContent.index]?.activities?.[
              currentActivity.index + 1
            ];

          // -=-=-=- if the next activity was found, redirect to next activity url -=-=-=-
          if (activity && activity?.id) {
            history.push(`${routeContent}/${courseId}/${currentModule?.id}/${currentContent?.id}/${activity?.id}`);

            return;
          }
          // -=-=-=- if doesn't have next activity, try to find the next content in the current module -=-=-=-
          const content = courseProgressProp.modules[currentModule.index].contents[currentContent.index + 1];

          if (content?.id) {
            // -=-=-=- if has next content and it is available, redirect to next content url  -=-=-=-
            if (!isBeforeBySchedule(content?.schedule?.[0]?.startAt) || !content?.schedule) {
              history.push(`${routeContent}/${courseId}/${currentModule?.id}/${content?.id}`);

              return;
            } else {
              // -=-=-=- if the next content is not available, show the bellow alert -=-=-=-
              MessageService.warn('PAGES.CONTENT.NAVIGATION.MESSAGES.NEXT_CONTENT_NOT_RELEASED');

              return;
            }
          }
          // -=-=-=- if doen't has next content and next activity, try to find next module -=-=-=-
          const nextModule = courseProgressProp.modules[currentModule.index + 1];
          if (nextModule) {
            if (nextModule?.id && nextModule?.contents?.length) {
              // -=-=-=- if the next content was found and first content is available, redirect to content url -=-=-=-
              if (
                !isBeforeBySchedule(nextModule?.contents?.[0]?.schedule?.[0]?.startAt) ||
                !nextModule?.contents?.[0]?.schedule
              ) {
                const content = nextModule?.contents?.[0];
                history.push(`${routeContent}/${courseId}/${nextModule?.id}/${content?.id}`);

                return;
              } else {
                // -=-=-=- if the first content of next module is not available, show the bellow message -=-=-=-
                MessageService.warn('PAGES.CONTENT.NAVIGATION.MESSAGES.NEXT_CONTENT_NOT_RELEASED');

                return;
              }
            }
          }

          if (courseProgressProp?.test?.id) {
            // -=-=-=- if the next module was not found, redirect to closure task if it is available -=-=-=-
            history.push(`${routeContent}/${courseId}/test/${courseProgressProp?.test?.id}`);

            return;
          }
        }

        // -=-=-=- if the navigation is to prev, the algorithm stars here -=-=-=-
        if (currentActivity?.id) {
          // -=-=-=- try to find the prev activity -=-=-=-
          const activity =
            courseProgressProp.modules[currentModule.index].contents[currentContent.index].activities[
              currentActivity.index - 1
            ];

          // -=-=-=- if the prev activity was found, redirect to activity url -=-=-=-
          if (activity && activity?.id) {
            history.push(`${routeContent}/${courseId}/${currentModule?.id}/${currentContent?.id}/${activity?.id}`);

            return;
          }
          // -=-=-=- if the prev activity was not found, redirect to prev content -=-=-=-
          history.push(`${routeContent}/${courseId}/${currentModule?.id}/${currentContent?.id}`);

          return;
        }

        // -=-=-=- try to find the prev content -=-=-=-
        const content = courseProgressProp.modules[currentModule.index].contents[currentContent.index - 1];
        if (content && content?.id) {
          const lastActivity = content.activities[content.activities?.length - 1];

          // -=-=-=- if the prev content and the last activity was found, redirect to the prev activity url  -=-=-=-
          if (lastActivity && lastActivity?.id) {
            history.push(`${routeContent}/${courseId}/${currentModule?.id}/${content?.id}/${lastActivity?.id}`);

            return;
          }
          // -=-=-=- if the prev activity was not found, redirect to the prev content url  -=-=-=-
          history.push(`${routeContent}/${courseId}/${currentModule?.id}/${content?.id}`);

          return;
        }

        // -=-=-=- if the prev content and prev activity was not found, try to find the prev module  -=-=-=-

        const previousModule = courseProgressProp.modules[currentModule.index - 1];

        if (previousModule && previousModule?.id) {
          const lastContent = previousModule.contents[previousModule.contents?.length - 1];
          if (lastContent && lastContent?.id) {
            const lastActivity = lastContent.activities[lastContent.activities?.length - 1];

            // -=-=-=- if the prev module, prev content and prev activity was found, redirect to activity url  -=-=-=-
            if (lastActivity) {
              history.push(`${routeContent}/${courseId}/${previousModule?.id}/${lastContent?.id}/${lastActivity?.id}`);

              return;
            }

            // -=-=-=- if the prev activity was not found, redirect to content url  -=-=-=-
            history.push(`${routeContent}/${courseId}/${previousModule?.id}/${lastContent?.id}`);

            return;
          }
        }
      }

      const module = courseProgressProp?.modules?.[0];

      const content = module?.contents?.[0];

      history.push(`${routeContent}/${courseId}/${module?.id}/${content?.id}`);
    },
    [courseId, currentActivity, currentContent, currentModule, history, match]
  );

  return (
    <div className="navigation-buttons">
      <div className="navigation-buttons__button-container">
        <Route path={`${getStackPath('CONTENT')}/:courseId/:moduleId/:contentId`} exact>
          <button
            className="navigation-buttons__button-container__button"
            onClick={() => redirectToContent(courseProgress, false)}
          >
            <span className="navigation-buttons__button-container__button__icon">
              <LeftOutlined />
            </span>
            <span className="navigation-buttons__button-container__button__label">
              {translate('PAGES.CONTENT.NAVIGATION.BACK')}
            </span>
          </button>
        </Route>
      </div>
      <div className="navigation-buttons__button-container">
        <button
          onClick={() => redirectToContent(courseProgress, true)}
          className="navigation-buttons__button-container__button"
        >
          <span className="navigation-buttons__button-container__button__label">
            {translate('PAGES.CONTENT.NAVIGATION.NEXT')}
          </span>
          <span className="navigation-buttons__button-container__button__icon">
            <RightOutlined />
          </span>
        </button>
      </div>
    </div>
  );
};

export default NavigationButtons;
