export const SatisfactionSurveyQuestionAnswerType = {
  ALTERNATIVE: 1,
  DESCRIPTIVE: 2,
  ALTERNATIVE_DESCRIPTIVE: 3,
};

export const SatisfactionSurveyQuestionChoiceType = {
  SINGLE: 1,
  MULTIPLE: 2,
};

export const SatisfactionSurveyStatus = {
  BLOCKED: 1,
  PENDING: 2,
  DONE: 3,
};

export const SatisfactionSurveyType = {
  CERTIFICATE: 1,
  DECLARATION: 2,
};

export const SatisfactionStatusArray = [
  { id: SatisfactionSurveyStatus.PENDING, name: 'A fazer' },
  { id: SatisfactionSurveyStatus.DONE, name: 'Concluído' },
  { id: SatisfactionSurveyStatus.BLOCKED, name: 'Bloqueada' },
];

export const getStatusSatisfaction = (
  value: string | number
): string | undefined => {
  return SatisfactionStatusArray.find((x) => x.id === value)?.name;
};
