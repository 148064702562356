import { Constants } from '@portal/config/env';
import { ProfileType } from '@portal/enum/profileType';

export const getJourneyUrlByProfileType = (profileType: ProfileType, journeyId: string): string => {
  switch (profileType) {
    case ProfileType.ADMIN:
      return `${Constants.journeyUrl}/journeyMap/${journeyId}`;

    case ProfileType.STUDENT:
      return `${Constants.journeyUrl}/studentJourneyMap/${journeyId}`;

    case ProfileType.COORDINATOR:
    case ProfileType.ADVISER:
    case ProfileType.TEACHER:
      return `${Constants.journeyUrl}/schoolJourneyMap/${journeyId}`;

    default:
      return '';
  }
};
