import React, { useCallback } from 'react';

import { translate } from '@portal/services/i18n';

import SidebarDropdownTaskStatus from '../../SidebarDropdownTaskStatus/SidebarDropdownTaskStatus';

interface IProps {
  status?: number;
  label: string;
  onClick: () => void;
  index: number;
  endIndex: number;
  disabled?: boolean;
  selected?: boolean;
  isSatisfactionSurvey?: boolean;
  count?: number;
}

const SidebarDropDownFinalTaskItem: React.FC<IProps> = ({
  label,
  status,
  onClick,
  index,
  disabled,
  endIndex,
  selected,
  isSatisfactionSurvey,
  count,
}) => {
  const getTimeLinePosition = useCallback((prefix: string, indexProp: number, endIndexProp: number) => {
    if (indexProp === 0) {
      return ` ${prefix.concat('__start-line')}`;
    } else if (indexProp !== endIndexProp) {
      return ` ${prefix.concat('__middle-line')}`;
    } else if (indexProp === endIndexProp) {
      return ` ${prefix.concat('__end-line')}`;
    }

    return '';
  }, []);

  return (
    <div
      className={`sidebar-final-task-item${disabled ? ' sidebar-final-task-item__inner--disabled' : ''}
      ${selected ? ' sidebar-final-task-item__inner--selected' : ''}
      ${selected && disabled && 'sidebar-final-task-item__inner--selected__locked'}
      `}
      title={disabled ? translate('PAGES.CONTENT.SIDEBAR.DROPDOWN.DETAILS.TITLE') : ''}
    >
      <div
        className={`sidebar-final-task-item__inner${getTimeLinePosition(
          'sidebar-final-task-item__inner',
          index,
          endIndex
        )}`}
      >
        <div className="sidebar-final-task-item__inner__button-container" onClick={() => (onClick ? onClick() : null)}>
          <div className="sidebar-final-task-item__inner__button-container__content">
            <SidebarDropdownTaskStatus isSatisfactionSurvey={isSatisfactionSurvey} status={status} count={count} />
            <span
              className={`sidebar-final-task-item__inner__button-container__content__label${
                disabled && selected ? ' sidebar-final-task-item__inner__button-container__content__label__locked' : ''
              }`}
            >
              {label}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarDropDownFinalTaskItem;
