import {
  CHANGED_SCHOOL,
  PENDING_TEST_LIST,
  USERS_ACTIVITIES,
  USERS_ACTIVITIES_HISTORY,
  USERS_ACTIVITIES_HISTORY_LOADING,
  USER_DETAIL,
  USER_PRODUCTS,
  USER_REPORT,
  USER_TEACHER_REVIEWS,
} from '~/actions/actionTypes';

const initialState: reducers.UserReducer = {
  detail: null,
  list: [],
  listCount: 0,
  userActivities: {} as reducers.UsersActivities,
  userActivitiesHistory: {} as reducers.UsersActivitiesHistory,
  pendingTestList: [],
  products: null,
  tutorRevision: null,
  changedSchool: false,
  isLoading: false,
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case USER_REPORT:
      state = {
        ...state,
        list: action.payload.rows,
        listCount: action.payload.count,
      };
      break;

    case USERS_ACTIVITIES:
      state = {
        ...state,
        userActivities: action.payload,
      };
      break;

    case USERS_ACTIVITIES_HISTORY:
      state = {
        ...state,
        userActivitiesHistory: action.payload,
      };
      break;

    case USERS_ACTIVITIES_HISTORY_LOADING:
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;

    case PENDING_TEST_LIST:
      state = {
        ...state,
        pendingTestList: action.payload,
      };
      break;

    case USER_PRODUCTS:
      state = {
        ...state,
        products: action.payload,
      };
      break;

    case USER_TEACHER_REVIEWS:
      state = {
        ...state,
        tutorRevision: action.payload,
      };
      break;

    case CHANGED_SCHOOL:
      state = {
        ...state,
        changedSchool: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default userReducer;
