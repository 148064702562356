import { Dispatch } from 'redux';

import configAPI from '@portal/repositories/config';
import * as MessageService from '~/services/message';
import * as StorageService from '~/services/storage';

import { CONFIG_PORTAL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getConfigPortal =
  (isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await configAPI.getConfigPortal();

      StorageService.setItem('configPortal', payload);

      dispatch({
        payload,
        type: CONFIG_PORTAL,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
