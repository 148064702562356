import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import ProfilePage from './ProfilePage/ProfilePage';

const ProfileNavigationStack = () => {
  return (
    <Switch>
      <Route path="/">
        <PanelContent
          pageTitle={translate('PAGES.PROFILE.TITLE')}
          pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
          removeMarginTop
        >
          <ProfilePage />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default ProfileNavigationStack;
