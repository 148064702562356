import { QuestionCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ActivityActions } from '@portal/actions';
import { translate } from '@portal/services/i18n';

interface IProps {
  me?: boolean;
  seen?: boolean;
  message: string;
  time: string;
  metadata: string;
  photoUrl?: string;
  toggleModal: () => void;
}

const MessageContainer: React.FC<IProps> = ({ me, message, time, metadata, toggleModal, photoUrl }) => {
  const dispatch = useDispatch();
  const handleOpenQuestion = useCallback(() => {
    if (metadata) {
      dispatch(ActivityActions.getSpecificQuestion(metadata));
      toggleModal();
    }
  }, [dispatch, metadata, toggleModal]);

  return (
    <Row className={`d-flex flex-column message-container${me ? ' align-items-end' : ''}`}>
      <Col
        className={`col-7  message-container__message${me ? ' message-container__message--me' : ''}`}
        style={metadata ? { cursor: 'pointer' } : {}}
        onClick={handleOpenQuestion}
      >
        {photoUrl && (
          <img
            className="message-container__message__photo"
            src={photoUrl}
            onClick={() => window.open(photoUrl, '_blank')}
            alt={translate('PAGES.CHAT.PHOTO.FAIL_LOADING')}
          />
        )}
        <span>{message}</span>
        {metadata && (
          <div className="message-container__message__info">
            <QuestionCircleOutlined />
          </div>
        )}
      </Col>
      <Col className="col-7 d-flex justify-content-end message-container__status">{time}</Col>
    </Row>
  );
};

export default MessageContainer;
