import { SET_TUTOR_CONTENT_DETAIL } from '../actions/actionTypes';

const getInitialState = (): reducers.TutorContentReducer => ({
  detail: null,
});

const tutorContentReducer = (
  state = getInitialState(),
  action: { type: string; payload: never }
): reducers.TutorContentReducer => {
  switch (action.type) {
    case SET_TUTOR_CONTENT_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default tutorContentReducer;
