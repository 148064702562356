import React from 'react';

import { Box, Flex, IcBroadcast } from '@portal/components';
import { Constants } from '@portal/config/env';

import './CardBroadcast.less';

const CardBroadcast = () => {
  return (
    <Flex className="card-broadcast" onClick={() => window.open(`${Constants.portalUrl}/broadcast`)}>
      <Box className="card-broadcast__icon">
        <IcBroadcast />
      </Box>

      <span className="card-broadcast__title">LIVES</span>
    </Flex>
  );
};

export default CardBroadcast;
