import React from 'react';

import UserIcon from '~/assets/svg/panel-top-bar/ic_user.svg';

interface IProps {
  userImgUrl: string | undefined | null;
}

const ProfileImg: React.FC<IProps> = ({ userImgUrl }) => {
  return (
    <div className="profileImg-container">
      <img
        className="profileImg-container __image--user-icon"
        src={userImgUrl || UserIcon}
        alt="user icon"
      />
    </div>
  );
};

export default ProfileImg;
