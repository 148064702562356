import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TopBarActions } from '@portal/actions';
import { Loading } from '~/components';
import { REACT_APP_APPLICATION_NAME } from '~/config/env';
import { useReduxState } from '@portal/hooks/useReduxState';

interface IProps {
  pageTitle?: string;
  pageDescription?: string;
  children?: any;
  removeMarginTop?: boolean;
  topBarItemSelected?: number;
}

const PanelContent: React.FC<IProps> = ({ children, removeMarginTop, pageTitle, topBarItemSelected }) => {
  const isLoading = useSelector((state: reducers.rootReducer) => state.loading.amount);

  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const { brand } = useReduxState();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [location]);

  useEffect(() => {
    if (topBarItemSelected !== undefined) {
      dispatch(TopBarActions.setNavigationItem(topBarItemSelected));
    }
  }, [dispatch, topBarItemSelected]);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {pageTitle}
          {brand?.brandProductConfig?.name ? ` - ${brand?.brandProductConfig?.name}` : ' ...'}
        </title>
      </Helmet>

      <Row
        className={`panel-content-container
      ${removeMarginTop ? 'panel-content-container--remove-margin' : ''}`}
      >
        {(loading || isLoading > 0) && <Loading />}
        {children}
      </Row>
    </React.Fragment>
  );
};

export default PanelContent;
