import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { TAB_MENU_ITEMS } from '@portal/enum/tabMenuItems';
import { PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import HomePage from './HomePage/HomePage';

const CoursesNavigationStack = () => {
  return (
    <Switch>
      <Route path="/home/:id?">
        <PanelContent
          topBarItemSelected={TAB_MENU_ITEMS.HOME}
          pageTitle={translate('PAGES.HOME.TITLE')}
          pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
        >
          <HomePage />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default CoursesNavigationStack;
