import {
  AUTH_CHECK_LOGGED,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ME,
  COURSE_TYPE_LOGGED,
  UPDATE_AVATAR,
} from '~/actions/actionTypes';

const initialState: reducers.AuthReducer = {
  checkLogged: false,
  me: null,
  authToken: {
    accessToken: null,
    refreshToken: null,
    token: null,
  },
  courseType: 0,
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AUTH_LOGIN:
      state = {
        ...state,
        authToken: action.payload,
      };
      break;
    case AUTH_CHECK_LOGGED:
      state = {
        ...state,
        checkLogged: true,
      };
      break;
    case AUTH_ME:
      state = {
        ...state,
        me: action.payload,
      };
      break;
    case UPDATE_AVATAR:
      state = {
        ...state,
        me: {
          ...(state.me as models.User),
          profilePictureUrl: action.payload,
        },
      };
      break;
    case AUTH_LOGOUT:
      state = {
        checkLogged: false,
        me: null,
        authToken: {
          accessToken: null,
          refreshToken: null,
          token: null,
        },
        courseType: -1,
      };
      break;
      case COURSE_TYPE_LOGGED:
      state = {
        ...state,
        courseType: action.payload,
      };
      break;
    default:
      return state;
  }

  return state;
};

export default authReducer;
