import { Dispatch } from 'redux';

import virtualGroupAPI from '@portal/repositories/virtualGroup';
import * as MessageService from '~/services/message';

import { decreaseLoading, increaseLoading } from './loading';

export const sendReportVirtualGroupStudents =
  (virtualGroupId: string, addressee: string, { onSuccess }: { onSuccess?: () => void }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(increaseLoading(true));
    try {
      const response = await virtualGroupAPI.sendReport(virtualGroupId, addressee);

      if (onSuccess && response) {
        onSuccess();
      }
    } catch (err) {
      MessageService.error('Não foi possível enviar o relatório');
    } finally {
      dispatch(decreaseLoading(true));
    }
  };
