import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { TAB_MENU_ITEMS } from '@portal/enum/tabMenuItems';
import { getRouteStackPath } from '@portal/config/routes';
import { PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import Groups from './GroupsPage/Groups';

const GroupsNavigationStack = () => {
  return (
    <Switch>
      <Route path={`${getRouteStackPath('GROUPS', 'DETAILS')}/:id`}>
        <PanelContent topBarItemSelected={TAB_MENU_ITEMS.HOME} pageTitle={translate('PAGES.HOME.TITLE')}>
          <Groups />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default GroupsNavigationStack;
