import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { Collapse } from 'antd';

import { CourseActions, CourseTestActions, UserCommentActions } from '@portal/actions';

import Btn from '@portal/components/v1/Btn/Btn';
import CollapseUser from '@portal/components/v1/UI/Molecules/CollapseUser/CollapseUser';
import AdvancedCollapse from '@portal/components/v1/UI/Atoms/AdvancedCollapse/AdvancedCollapse';
import StatusDeadline from '@portal/components/v1/ContentComponents/StatusDeadline/StatusDeadline';
import CourseTestUploadQuestion from '../CourseTestUploadQuestion/CourseTestUploadQuestion';
import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import ReviewTutor from '@portal/components/v1/UI/Molecules/ReviewTutor/ReviewTutor';

import { translate } from '@portal/services/i18n';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as StorageService from '@portal/services/storage';
import { REVIEW_TUTOR_TYPE } from '@portal/enum/reviewTutorType';
import { BUTTON_SIZE } from '@portal/enum/buttonSize';
import RenderCondition from '@portal/utils/renderCondition';
import { CircularProgress } from '@mui/material';
import { IRouteParams } from '@portal/models/route-params';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { ProductType } from '@portal/enum/productType';
import { getCookie } from '@portal/services/cookie';
import { CourseTestType } from '@portal/enum/courseTestType';
import CourseTestObjectiveQuestion from '../CourseTestObjectiveQuestion/CourseTestObjectiveQuestion';
import { TasksStatus } from '@portal/enum/taskStatus';
import { REVIEW_STATUS_TYPE } from '@portal/enum/reviewStatusType';

const PanelCourseTestTutor: React.FC = () => {
  const { Panel } = Collapse;

  const { testId } = useParams<IRouteParams>();
  const dispatch = useDispatch();

  const [collapseSection, setCollapseSection] = useState<string[]>(['0']);
  const [idUser, setIdUser] = useState<string>('');
  const [valueStatusQuestion, setValueStatusQuestion] = useState<number>();
  const [commentTutor, setCommentTutor] = useState('');

  const {
    auth: { me },
    loading: { localAmount },
    courseTest: { courseTestReviews, courseTestReviewsAnswered, courseTest, courseTestHistory },
    course: { courseProgress },
  } = useReduxState();

  const productType = StorageService.getItem('brandProductsConfig')?.product ?? '';
  const virtualGroupId = getCookie('virtual-group-id') ?? '';

  const isTutorDashTutor = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;

  useEffect(() => {
    if (testId && me?.profileType) {
      dispatch(CourseTestActions.getCourseTestById(testId, true));
    }
  }, [testId, me?.profileType]);

  useEffect(() => {
    if (testId && isTutorDashTutor && me?.profileType && virtualGroupId) {
      dispatch(CourseTestActions.getReviews(testId, virtualGroupId));
      dispatch(CourseTestActions.getHistory(testId, virtualGroupId));
    }
  }, [testId, me?.profileType, isTutorDashTutor, virtualGroupId]);

  useEffect(() => {
    if (idUser && testId && me?.idSchool) {
      dispatch(
        CourseTestActions.getAnsweredReviews(
          testId,
          () => {},
          {
            schoolId: me?.idSchool,
            userId: idUser,
          },
          true
        )
      );

      setCommentTutor('');
      setValueStatusQuestion(undefined);
    }
  }, [idUser, testId, me?.idSchool]);

  useEffect(() => {
    if (collapseSection?.includes(REVIEW_TUTOR_TYPE.HISTORIC) && courseTestReviewsAnswered?.testStatus) {
      switch (courseTestReviewsAnswered?.testStatus) {
        case TasksStatus.DONE:
          setValueStatusQuestion(REVIEW_STATUS_TYPE.YES);
          break;
        case TasksStatus.REDO:
          setValueStatusQuestion(REVIEW_STATUS_TYPE.ANOTHER_ANSWER);
          break;
      }
    }
  }, [courseTestReviewsAnswered]);

  const callBackReview = () => {
    dispatch(CourseTestActions.getReviews(testId, virtualGroupId));
    setCommentTutor('');
    setValueStatusQuestion(undefined);

    dispatch(
      CourseTestActions.getAnsweredReviews(
        testId,
        () => {},
        {
          schoolId: me?.idSchool,
          userId: idUser,
        },
        true
      )
    );

    dispatch(
      CourseActions.getCourseProgress({
        idCourse: courseProgress?.id,
        isTutor: true,
      })
    );

    dispatch(CourseTestActions.getHistory(testId, virtualGroupId));
  };

  const handleReviewSubmit = async (answerId: string) => {
    dispatch(
      UserCommentActions.SendUserComment(
        [
          {
            comment: commentTutor,
            referralId: answerId,
            teacherResponseType: valueStatusQuestion as number,
            type: 2,
          },
        ],
        { activityId: testId, userId: idUser },
        false,
        callBackReview
      )
    );
  };

  return (
    <main className="activity-section">
      <div className="activity-section__status-deadline">
        <StatusDeadline
          isDone
          label={courseProgress.test?.title || translate('PAGES.CONTENT.FINAL_EVALUATION.LABEL_STATUS')}
        />
      </div>

      <AdvancedCollapse
        onChange={(key: Array<string>) => {
          setCollapseSection([key[key?.length - 1]]), setIdUser('');
        }}
        keyActive={collapseSection}
        bordered={false}
      >
        <Panel
          className="collapse-container__panel"
          header={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.SOLUTION')}
          key={REVIEW_TUTOR_TYPE.SOLUTION}
        >
          <div className="panel-course-test-tutor__solution">
            <h1 className="panel-course-test-tutor__solution__title">{courseTest?.title}</h1>

            {courseTestReviews?.courseTestWithQuestions?.questions?.map((question: any) => (
              <div key={question.id}>
                <>
                  <RenderCondition condition={courseTest?.type === CourseTestType.FILE_UPLOAD_ANSWER}>
                    <CourseTestUploadQuestion key={question.id} question={question} isDisabled isSolution />
                    <RenderCondition condition={question?.answer}>
                      <div className="mt-4">
                        <span className="answer-question-container__review-tutor__expected-answer">
                          {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.EXPECTED_ANSWER')}
                          <RenderHTML htmlText={question?.answer} />
                        </span>
                      </div>
                    </RenderCondition>
                  </RenderCondition>
                  <RenderCondition condition={courseTest?.type === CourseTestType.MULTIPLE_QUESTIONS}>
                    <CourseTestObjectiveQuestion
                      key={question.id}
                      question={question}
                      options={question?.options}
                      description={question?.description}
                      isDisabled
                      showCorectQuestions
                    />
                  </RenderCondition>
                </>
              </div>
            ))}
          </div>
        </Panel>

        {courseTest?.type === CourseTestType.FILE_UPLOAD_ANSWER && courseTest?.hasRevision && virtualGroupId && (
          <Panel
            className="activity-section__collapse-container__panel"
            header={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.REVIEW')}
            key={REVIEW_TUTOR_TYPE.PENDING_REVIEW}
            extra={
              <div className="activity-section__collapse-container__panel__extra__notification">
                {courseTestReviews?.courseTestAnswersToReview?.length}
              </div>
            }
          >
            <CollapseUser data={courseTestReviews?.courseTestAnswersToReview} setIdUser={setIdUser} idUser={idUser}>
              <RenderCondition condition={localAmount !== 1}>
                {courseTestReviewsAnswered?.questions?.map((question) => (
                  <>
                    <CourseTestUploadQuestion key={question.id} question={question} isDisabled />
                    <ReviewTutor
                      statusValue={valueStatusQuestion}
                      onChangeStatus={setValueStatusQuestion}
                      commentValue={commentTutor}
                      onChangeComment={setCommentTutor}
                      isCourseTest
                      isUserCollapse
                    />
                    <div className="mt-5 mb-3">
                      <Btn
                        title={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.SEND_COMMENTS')}
                        disabled={!valueStatusQuestion}
                        onClick={() => handleReviewSubmit(question?.answers?.[question?.answers?.length - 1]?.id)}
                        size={BUTTON_SIZE.FULL}
                      />
                    </div>
                  </>
                ))}
              </RenderCondition>
              <RenderCondition condition={localAmount === 1}>
                <div className="activity-section__collapse-container__panel__loading__inner">
                  <CircularProgress color="primary" size={50} />
                </div>
              </RenderCondition>
            </CollapseUser>
          </Panel>
        )}

        {!!virtualGroupId && (
          <Panel
            className="activity-section__collapse-container__panel"
            header={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.HISTORY_TEST')}
            key={REVIEW_TUTOR_TYPE.HISTORIC}
          >
            <CollapseUser data={courseTestHistory?.courseTestAnswersHistory} setIdUser={setIdUser} idUser={idUser}>
              <RenderCondition condition={localAmount !== 1}>
                {courseTestReviewsAnswered?.questions?.map((question: any) => (
                  <>
                    <RenderCondition condition={courseTest?.type === CourseTestType.FILE_UPLOAD_ANSWER}>
                      <CourseTestUploadQuestion key={question.id} question={question} isDisabled />
                      <RenderCondition condition={courseTest?.hasRevision}>
                        <ReviewTutor
                          statusValue={valueStatusQuestion}
                          onChangeStatus={setValueStatusQuestion}
                          commentValue={commentTutor}
                          onChangeComment={setCommentTutor}
                          isHistoric
                          disabled
                          isCourseTest
                        />
                      </RenderCondition>
                    </RenderCondition>
                    <RenderCondition condition={courseTest?.type === CourseTestType.MULTIPLE_QUESTIONS}>
                      <CourseTestObjectiveQuestion
                        key={question.id}
                        question={question}
                        options={question?.options}
                        description={question?.description}
                        isDisabled
                        showCorectQuestions
                      />
                    </RenderCondition>
                  </>
                ))}
              </RenderCondition>
              <RenderCondition condition={localAmount === 1}>
                <div className="activity-section__collapse-container__panel__loading__inner">
                  <CircularProgress color="primary" size={50} />
                </div>
              </RenderCondition>
            </CollapseUser>
          </Panel>
        )}
      </AdvancedCollapse>
    </main>
  );
};

export default PanelCourseTestTutor;
