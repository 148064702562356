import { Dispatch } from 'redux';

import { ICourseTestAnswerRepository } from '@portal/models/repositories';
import courseTestAPI from '@portal/repositories/courseTest';
import * as MessageService from '~/services/message';

import {
  COURSE_TEST,
  COURSE_TEST_DONE,
  COURSE_TEST_HISTORY,
  COURSE_TEST_REVIEWS,
  COURSE_TEST_REVIEWS_ANSWERED,
  COURSE_TEST_VIEW_HISTORY,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getCourseTestById = (id: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));

  try {
    const payload: models.CourseTest = await courseTestAPI.getCourseTestById(id);

    dispatch({
      payload,
      type: COURSE_TEST,
    });
  } catch (err) {
    MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const sendAnswers =
  (
    id: string,
    answers: ICourseTestAnswerRepository[],
    callback?: (response: models.CourseTestAnswered) => void,
    isLocalLoading?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const response = await courseTestAPI.sendAnswers(id, answers);

      if (callback) {
        callback(response);
      }

      MessageService.success('PAGES.CONTENT.FINAL_EVALUATION.MESSAGES.SEND_SUCCESS');
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getStudentTest =
  (testId: string, callback?: () => void, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.CourseTestDone = await courseTestAPI.getStudentTest(testId);
      dispatch({
        payload,
        type: COURSE_TEST_DONE,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getReviews =
  (testId: string, virtualGroupId?: string, callback?: () => void, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.CourseTestDone = await courseTestAPI.getReviews(testId, virtualGroupId);
      dispatch({
        payload,
        type: COURSE_TEST_REVIEWS,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getHistory =
  (testId: string, virtualGroupId?: string, callback?: () => void, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.CourseTestDone = await courseTestAPI.getHistory(testId, virtualGroupId);
      dispatch({
        payload,
        type: COURSE_TEST_HISTORY,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getAnsweredReviews =
  (id: string, callback?: () => void, params?: advancedFilterModels.ActivityAnswersFilter, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.ActivityAnswered = await courseTestAPI.getAnsweredReviews(id, params);

      dispatch({
        payload,
        type: COURSE_TEST_REVIEWS_ANSWERED,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const setShowTestHistory = (value: boolean) => (dispatch: Dispatch) => {
  dispatch({
    payload: value,
    type: COURSE_TEST_VIEW_HISTORY,
  });
};
