import { getInstance } from './instance';

const MODULE_BASE_URL = '/config';

const configAPI = {
  getConfigPortal: async () => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.get(MODULE_BASE_URL);

    return data;
  },
};

export default configAPI;
