export function setCookie(key: string, data: string, domain: string, exdays?: number): void {
  const date = new Date();
  date.setTime(date.getTime() + (exdays || 1) * 24 * 60 * 60 * 1000);

  const expires = 'expires=' + date.toUTCString();
  document.cookie = key + '=' + data + ';' + expires + ';domain=' + domain + ';path=/';
}

export function getCookie(key: string): string {
  const name = key + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const decodedCookieArray = decodedCookie.split(';');

  for (let of = 0; of < decodedCookieArray.length; of++) {
    let cookieElement = decodedCookieArray[of];
    while (cookieElement.charAt(0) === ' ') {
      cookieElement = cookieElement.substring(1);
    }
    if (cookieElement.indexOf(name) === 0) {
      return cookieElement.substring(name.length, cookieElement.length);
    }
  }

  return '';
}

export function deleteCookie(key: string, path?: string, domain?: string): void {
  if (getCookie(key)) {
    document.cookie =
      key +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}
