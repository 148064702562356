import React, { Fragment } from 'react';

export const RenderCondition = ({
  children,
  condition,
}: {
  children: React.ReactNode;
  condition?: boolean;
}): JSX.Element => {
  if (!condition) return <Fragment></Fragment>;
  return <Fragment>{children}</Fragment>;
};

export default RenderCondition;
