import { HOME_BANNERS } from '~/actions/actionTypes';

const initialState: reducers.BannerReducer = {
  homeBanners: [],
};

const bannerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HOME_BANNERS:
      state = {
        ...state,
        homeBanners: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default bannerReducer;
