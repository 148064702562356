import { Dispatch } from 'redux';

import { ILmsTrail } from '@portal/models/module';
import lmsTrailApi from '@portal/repositories/lmsTrail';
import * as MessageService from '~/services/message';

import { LMS_TRAIL_LIST_WITH_COURSES } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getLmsTrailsWithCourses =
  (
    params: { idBrandProduct: string; trailCourseName?: string },
    options?: { onSuccess?: (data: ILmsTrail[]) => void }
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(increaseLoading());
    try {
      const payload = await lmsTrailApi.getWithCourses({ idBrandProduct: params?.idBrandProduct });

      if (params?.trailCourseName) {
        const filterTrailCourses = payload?.rows
          ?.filter((trail) =>
            trail?.trailCourses?.some((trailCourse) =>
              trailCourse?.course?.title?.toLowerCase()?.includes(params?.trailCourseName?.toLowerCase() as string)
            )
          )
          ?.map((trail) => ({
            ...trail,
            trailCourses: trail?.trailCourses?.filter((trailCourse) =>
              trailCourse?.course?.title?.toLowerCase()?.includes(params?.trailCourseName?.toLowerCase() as string)
            ),
          }));

        dispatch({
          payload: filterTrailCourses,
          type: LMS_TRAIL_LIST_WITH_COURSES,
        });

        options?.onSuccess?.(filterTrailCourses);
      } else {
        dispatch({
          payload: payload?.rows,
          type: LMS_TRAIL_LIST_WITH_COURSES,
        });

        options?.onSuccess?.(payload?.rows);
      }
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading());
    }
  };
