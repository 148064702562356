import { getInstance } from './instance';

const MODULE_BASE_URL = '/user-comment';

const UserCommentApi = {
  sendUserComment: async (userComment: models.UserComment[], params?: advancedFilterModels.SendUserCommentFilter) => {
    const instance = getInstance();
    const { data } = await instance.post(`${MODULE_BASE_URL}`, userComment, {
      params,
    });

    return data;
  },
};

export default UserCommentApi;
