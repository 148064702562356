import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import { PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import CoursesDetailsPage from './CourseDetailsPage/CourseDetailsPage';

const CourseDetailsNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('COURSE_DETAILS', 'COURSE_ID')}>
        <PanelContent
          pageTitle={translate('PAGES.COURSE_DETAILS.TITLE')}
          pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
          removeMarginTop
        >
          <CoursesDetailsPage />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default CourseDetailsNavigationStack;
