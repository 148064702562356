/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getInstance } from './instance';

const MODULE_BASE_URL = '/tutor-content';

const TutorContent = {
  getTutorContentByProductId: async (id: string) => {
    const instance = getInstance();
    const data = await instance.get(`${MODULE_BASE_URL}/${id}`);

    return data;
  },

  createTutorContent: async (studentClass: models.TutorContent) => {
    const instance = getInstance();
    const { data } = await instance.post(`${MODULE_BASE_URL}`, studentClass);

    return data;
  },

  updateTutorContent: async (id: string, studentClass: models.TutorContent) => {
    const instance = getInstance();
    const { data } = await instance.put(`${MODULE_BASE_URL}/${id}`, studentClass);

    return data;
  },

  deleteTutorContent: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.delete(`${MODULE_BASE_URL}/${id}`);

    return data;
  },
};

export default TutorContent;
