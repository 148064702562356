import React from 'react';

import { Moment } from 'moment';

import RenderStatusDate from '@portal/components/v1/RenderStatusDate/RenderStatusDate';

interface IStatusDeadlineProps {
  isDone: boolean;
  doneDate?: Date | string | Moment;
  label?: string;
  statusDeadlienDate?: Date | string | Moment;
}

const StatusDeadline: React.FC<IStatusDeadlineProps> = ({
  isDone = false,
  label = '',
  doneDate,
  statusDeadlienDate,
}) => {
  return (
    <div className="statusDeadline-container">
      <div className="statusDeadline-container__content">
        <span className="statusDeadline-container__content__label">{label}</span>
        <div className="statusDeadline-container__content__date">
          {isDone
            ? doneDate && <RenderStatusDate isAnswerDate date={doneDate.toString()} />
            : statusDeadlienDate && <RenderStatusDate isContentDeadline date={statusDeadlienDate.toString()} />}
        </div>
      </div>
    </div>
  );
};

export default StatusDeadline;
