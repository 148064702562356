/* eslint-disable max-len */
import React from 'react';
const IcUniqueArrowRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="60px"
      height="30px"
      fill="currentColor"
      viewBox="0 0 12 19"
    >
      <path
        fill="currentColor"
        d="M11.278 10.482l-7.855 7.855a.97.97 0 01-1.371 0l-.917-.916a.97.97 0 01-.001-1.37l6.225-6.255L1.134 3.54a.97.97 0 01.001-1.37l.917-.916a.97.97 0 011.371 0l7.855 7.855a.97.97 0 010 1.372z"
      ></path>
    </svg>
  );
};

export default IcUniqueArrowRight;
