import { SatisfactionSurveyAnswerAPI } from '@portal/models/module';

import { getInstance } from './instance';

const satisfactionSurveyAPI = {
  getById: async (satisfactionSurveyId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/satisfaction-survey/${satisfactionSurveyId}`);

    return data;
  },

  sendAnswers: async (satisfactionSurveyId: string, answerData: SatisfactionSurveyAnswerAPI[], courseId: string) => {
    const instance = getInstance();
    const { data } = await instance.put(`/satisfaction-survey/${satisfactionSurveyId}/send-answers`, {
      courseId,
      answers: answerData,
    });

    return data;
  },
};

export default satisfactionSurveyAPI;
