import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useCallback } from 'react';

import { MARKER_STATUS } from '@portal/enum/markerStatus';
import { translate } from '@portal/services/i18n';

interface IProps {
  status?: number;
  selected?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
}

const SideBarDropdownCheck: React.FC<IProps> = ({ status, selected, backgroundColor }) => {
  const getStatusElement = useCallback((statusProps?: number, selectedProp?: boolean) => {
    const checkedElement = (
      <div className="check-container__check">
        <CheckOutlined style={{ color: `${selectedProp ? '#fff' : '#000'}` }} />
      </div>
    );

    const warningElement = (
      <div className="check-container__check check-container__check--warning">
        <ExclamationOutlined />
      </div>
    );

    const lateElement = (
      <div className="check-container__check check-container__check--late">
        <ExclamationOutlined />
      </div>
    );

    switch (statusProps) {
      case MARKER_STATUS.MARKED:
        return checkedElement;
      case MARKER_STATUS.WARNING:
        return warningElement;
      case MARKER_STATUS.LATE:
        return lateElement;
      default:
        return null;
    }
  }, []);

  const getTooltipTitle = useCallback((statusProp?: number) => {
    switch (statusProp) {
      case MARKER_STATUS.WARNING:
        return translate('PAGES.CONTENT.SIDEBAR.DROPDOWN.DETAILS.STATUS.CONTENT.PENDING');
      case MARKER_STATUS.LATE:
        return translate('PAGES.CONTENT.SIDEBAR.DROPDOWN.DETAILS.STATUS.CONTENT.LATE');

      default:
        return '';
    }
  }, []);

  const Marker = (
    <div
      style={backgroundColor ? { backgroundColor } : {}}
      className={`check-container${selected ? ' check-container--selected' : ''}`}
    >
      {getStatusElement(status, selected)}
    </div>
  );

  const tooltip = (
    <Tooltip placement="topLeft" title={getTooltipTitle(status)}>
      {Marker}
    </Tooltip>
  );

  return status === MARKER_STATUS.WARNING || status === MARKER_STATUS.LATE ? tooltip : Marker;
};

export default SideBarDropdownCheck;
