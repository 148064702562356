import React, { ComponentProps } from 'react';

export const IcHouse = (rest: ComponentProps<'svg'>) => {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M16.0325 7.99005C15.8547 8.4745 15.4144 8.78758 14.9101 8.78758H14.4998V14.8295C14.4998 15.1327 14.2592 15.3788 13.9628 15.3788H10.7407V11.5339C10.7407 10.0196 9.53621 8.78758 8.05567 8.78758C6.57512 8.78758 5.3706 10.0196 5.3706 11.5339V15.3788H2.14852C1.85209 15.3788 1.61151 15.1327 1.61151 14.8295V8.78758H1.2007C0.696442 8.78758 0.256091 8.47505 0.0783399 7.9906C-0.0994114 7.50614 0.0310828 6.97336 0.411288 6.63391L6.68306 0.559016C7.45314 -0.186339 8.65873 -0.186339 9.42827 0.559016L15.7151 6.64819C16.0792 6.97281 16.2102 7.5056 16.0325 7.99005Z"
        fill="currentColor"
      />
    </svg>
  );
};
