import { getInstance } from './instance';

const AuthApi = {
  me: async () => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.get('/auth/me');

    return data;
  },
};

export default AuthApi;
