import React, { useCallback, useState } from 'react';

import { connect } from 'react-redux';
import { Drawer, Dropdown } from 'antd';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';

import ContentTopBar from './ContentTopBar';

import { useReduxState } from '~/hooks';
import { translate } from '~/services';
import useWindowSize from '~/utils/getSizeWindow';
import { IcHamburguerMenu } from '~/assets/Icon';
import { AuthActions } from '~/actions';
import { getCookie } from '~/services';

interface IProps {
  user?: models.User | null;
}

const PanelContentTopBar: React.FC<IProps> = (props: IProps) => {
  const mobile = useWindowSize();

  const [visible, setVisible] = useState(false);
  const isLocalLoading = useReduxState().loading.localAmount;

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const {
    auth: { me },
    notification: { notifications },
  } = useReduxState();

  const isSaePartnership = me?.isSaePartnership || getCookie('user-integration');

  return (
    <>
      <Drawer title="Notificações" placement="right" onClose={onClose} visible={visible} width={400}>
        {isLocalLoading > 0 ? (
          <div className="notification-modal__notification__empty">
            <CircularProgress color="secondary" size={60} />
          </div>
        ) : (
          <Container className="p-0 notification-modal__notification">
            <Row className="d-flex flex-column">
              {notifications.length > 0 ? (
                notifications.map((item) => (
                  <Col key={item.id} className="p-0 notification-modal__notification__item">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="notification-modal__notification__item__title">{item.notification.title}</span>
                      <span className="m-2">{moment(item.notification.sendDate).format('DD/MM/YYYY')}</span>
                    </div>
                    <p className="notification-modal__notification__item__description">
                      {item.notification.description}
                    </p>
                  </Col>
                ))
              ) : (
                <div>{translate('COMPONENTS.NOTIFICATION_POPOVER.EMPTY_NOTIFICATION')}</div>
              )}
            </Row>
          </Container>
        )}
      </Drawer>
      <Row className="d-flex align-items-center panel-content-top-bar">
        <div className="d-flex w-100 align-items-center justify-content-between">
          {!isSaePartnership && (
            <div
              className={
                mobile ? 'pl-1' : 'd-flex align-items-center justify-content-between panel-content-top-bar__left'
              }
            >
              <div className="panel-content-top-bar__menu__dropdown-menu">
                <Dropdown overlay={<ContentTopBar isExpansive />} placement="bottomLeft" arrow trigger={['click']}>
                  <span className="panel-content-top-bar__menu__dropdown-menu__icon">
                    <IcHamburguerMenu />
                  </span>
                </Dropdown>
              </div>
            </div>
          )}
          <ContentTopBar />
        </div>
      </Row>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(AuthActions.logout()),
});

export default connect(null, mapDispatchToProps)(PanelContentTopBar);
