/* eslint-disable no-unused-vars */
export enum BrandDomain {
  PROGRAMA_PLENO = 'programapleno.com.br',
  ESCOLA_DA_INTELIGENCIA = 'escoladainteligencia.com.br',
  GENIO = 'geniodasfinancas.com.br',
  MESTRE = 'mestredosmestres.com.br',
  MARALTO = 'maralto.com.br',
  NAVE = 'naveavela.com.br',
  PGS = 'pgsbrasil.com.br',
}

export const brandsBeyond: string[] = [
  BrandDomain.ESCOLA_DA_INTELIGENCIA,
  BrandDomain.PROGRAMA_PLENO,
  BrandDomain.MESTRE,
  BrandDomain.NAVE,
];

export const brandsNoShowRevision: string[] = [BrandDomain.MESTRE, BrandDomain.GENIO, BrandDomain.MARALTO];

export const brandsPortalMarketing: string[] = [
  BrandDomain.ESCOLA_DA_INTELIGENCIA,
  BrandDomain.PROGRAMA_PLENO,
  BrandDomain.GENIO,
  BrandDomain.MESTRE,
  BrandDomain.NAVE,
  BrandDomain.PGS,
  BrandDomain.MARALTO,
];
