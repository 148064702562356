import { Dispatch } from 'redux';

import { TOP_BAR_NAVIGATION } from './actionTypes';

export const setNavigationItem = (id: number) => async (dispatch: Dispatch) => {

  dispatch({
    payload: id,
    type: TOP_BAR_NAVIGATION,
  });

};