import React from 'react';

import UserIcon from '@portal/assets/svg/panel-top-bar/ic_user.svg';

interface IProps {
  thumbSrc: string;
  name: string;
  description: string;
  creatorId: string;
}

const Feedback: React.FC<IProps> = ({ description, name, thumbSrc }) => {
  return (
    <div className="feedback">
      <div className="feedback__thumb">
        <img src={thumbSrc || UserIcon} alt="thumb" />
      </div>
      <div className="feedback__info">
        <span className="feedback__info__name">{name}</span>
        <div>
          <span className="feedback__info__description">{description}</span>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
