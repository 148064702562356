/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

const IcFilter: React.FunctionComponent = (props: any) => (
  <SvgIcon {...props} width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.82 1.17003C27.6601 0.819444 27.4023 0.522518 27.0777 0.314956C26.753 0.107394 26.3753 -0.00196634 25.99 2.67654e-05H1.99001C1.60683 -0.00191859 1.23116 0.106253 0.907691 0.311672C0.58422 0.517091 0.32657 0.81111 0.165393 1.15875C0.00421508 1.50639 -0.0537045 1.89301 -0.00147375 2.27262C0.050757 2.65222 0.210938 3.00884 0.460013 3.30003L9.98001 14.38V23C9.97868 23.1826 10.0274 23.3621 10.1208 23.519C10.2142 23.6759 10.3488 23.8042 10.51 23.89C10.6553 23.9645 10.8167 24.0023 10.98 24C11.1784 24.0034 11.3734 23.9477 11.54 23.84L17.54 19.84C17.6771 19.7474 17.7891 19.6221 17.8659 19.4755C17.9427 19.3289 17.9819 19.1655 17.98 19V14.38L27.5 3.30003C27.751 3.01253 27.9145 2.65921 27.9712 2.28178C28.0279 1.90436 27.9755 1.51859 27.82 1.17003Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IcFilter;
