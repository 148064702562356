import { DownOutlined } from '@ant-design/icons';
import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { COURSE_TYPE } from '@portal/enum/courseType';
import { MARKER_STATUS } from '@portal/enum/markerStatus';
import { useReduxState } from '@portal/hooks/useReduxState';
import { CourseModule, ModuleContent } from '@portal/models/reducers';
import { translate } from '@portal/services/i18n';

import SideBarDropdownCheck from './SideBarDropdownCheck/SideBarDropdownCheck';
import SideBarDropdownClass from './SideBarDropdownClass/SideBarDropdownClass';
import { isDashTutor } from '@portal/utils/isDashTutor';
import RenderCondition from '@portal/utils/renderCondition';
import * as StorageService from '~/services/storage';
import { ProductType } from '@portal/enum/productType';

interface IProps {
  index: number;
  module: CourseModule;
}

const SideBarDropdown: React.FC<IProps> = ({ index, module }) => {
  const { courseType, me } = useReduxState().auth;

  const match = useRouteMatch<{ url: string }>();
  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const isTutor = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const url = window.location.pathname;
    const currentModuleId = url.split('/')[3];

    if (currentModuleId === module.id) {
      setIsExpanded(true);
      sessionStorage.setItem('unitCurrent', index.toString());
    } else {
      setIsExpanded(false);
    }
  }, [match]);

  const getConcludedNumberContent = useCallback((contentList: ModuleContent[]) => {
    const numberOfConcludedContent = contentList.reduce((previousValue, currentValue) => {
      if (currentValue.isDone) {
        return previousValue + 1;
      }

      return previousValue;
    }, 0);

    return numberOfConcludedContent;
  }, []);

  return (
    <div className="sidebar-dropdown">
      <Accordion expanded={isExpanded}>
        <AccordionSummary
          onClick={() => {
            setIsExpanded(!isExpanded);
            sessionStorage.setItem('unitCurrent', index.toString());
          }}
          expandIcon={<DownOutlined />}
        >
          <div className="sidebar-dropdown__summary">
            <div className="sidebar-dropdown__summary__module">
              <SideBarDropdownCheck status={module.isDone ? MARKER_STATUS.MARKED : MARKER_STATUS.NONE} />
              <div className="sidebar-dropdown__summary__module__info">
                <div className="sidebar-dropdown__summary__module__info__header">
                  {courseType === COURSE_TYPE.TRAIL
                    ? translate('PAGES.CONTENT.SIDEBAR.DROPDOWN.LABEL.HEADER_TECER', {
                        index,
                      })
                    : translate('PAGES.CONTENT.SIDEBAR.DROPDOWN.LABEL.HEADER', {
                        index,
                      })}
                </div>
                <div className="sidebar-dropdown__summary__module__info__title">{module.title}</div>
              </div>
            </div>
            <div
              className={`sidebar-dropdown__summary__progress${
                module.isDone ? 'sidebar-dropdown__summary__progress--completed' : ''
              }`}
            >
              <RenderCondition condition={!isTutor}>
                {`${getConcludedNumberContent(module.contents)}/${module.contents.length}`}
              </RenderCondition>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="sidebar-dropdown__details-container">
            <SideBarDropdownClass contents={module.contents} moduleId={module.id} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SideBarDropdown;
