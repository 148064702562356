import { getInstance } from './instance';

const groupsAPI = {
  getAllGroups: async (params: advancedFilterModels.GroupsByTutor) => {
    const instance = getInstance();
    const { data } = await instance.get('/virtual-group/', {
      params,
    });

    return data;
  },

  getGroupsByTutor: async (params: advancedFilterModels.GroupsByTutor) => {
    const instance = getInstance();
    const { data } = await instance.get('/virtual-group/list-groups-by-tutor', {
      params,
    });

    return data;
  },

  getStudentsByGroups: async (GroupId: string, params: advancedFilterModels.BaseFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(`/virtual-group/${GroupId}/students`, {
      params,
    });

    return data;
  },

  getGroupsById: async (GroupId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/virtual-group/${GroupId}`);

    return data;
  },

  getGroupScheduleById: async (GroupId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/virtual-group/${GroupId}/course-content-schedule`);

    return data;
  },

  getDetailsUserElectives: async (GroupId: string, userId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/virtual-group/${GroupId}/user-elective-details?userId=${userId}`);

    return data;
  },

  addStudent: async (GroupId: string, userIdList: string[]) => {
    const instance = getInstance();
    const { data } = await instance.put(`virtual-group/${GroupId}/add-students`, {
      userIdList,
    });

    return data;
  },

  updateGroup: async (id: string, params: models.CreateGroup) => {
    const instance = getInstance();
    const { data } = await instance.put(`/virtual-group/${id}`, params);

    return data;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateGroupSchedule: async (GroupId: string, params: any) => {
    const instance = getInstance();
    const { data } = await instance.put(`/virtual-group/${GroupId}/course-content-schedule`, {
      ...params,
    });

    return data;
  },

  removeStudent: async (GroupId: string, userIdList: string[]) => {
    const instance = getInstance();
    const { data } = await instance.put(`virtual-group/${GroupId}/remove-students`, {
      userIdList,
    });

    return data;
  },

  createGroup: async (params: models.CreateGroup) => {
    const instance = getInstance();
    const { data } = await instance.post('/virtual-group', params);

    return data;
  },

  closeGroup: async (GroupId: string, params: { brandProductId: string }) => {
    const instance = getInstance();
    const { data } = await instance.delete(`virtual-group/${GroupId}/close`, {
      params,
    });

    return data;
  },
};

export default groupsAPI;
