import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { translate } from '@portal/services/i18n';
import { IRouteParams } from '@portal/models/route-params';

const RedirectPage: React.FC = () => {
  const history = useHistory();
  const waitTime = 5;
  const [timer, setTimer] = useState(waitTime);
  const { courseId } = useParams<IRouteParams>();

  useEffect(() => {
    let timeLeft = waitTime;
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft = timeLeft - 1;
        setTimer((state) => state - 1);
      } else {
        clearInterval(interval);
        history.push(`/content/${courseId}`);
      }
    }, 1000);
  }, [history, courseId]);

  return (
    <div className="redirect-container">
      <div>
        <h4>{translate('PAGES.REDIRECT.TEXT_TITLE')}</h4>
      </div>
    </div>
  );
};

export default RedirectPage;
