import { getInstance } from './instance';

const contentAPI = {
  getContentById: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/content/${id}`);

    return data;
  },

  getContentScheduleById: async (id: string, params: { virtualGroupId?: string }) => {
    const instance = getInstance();
    const { data } = await instance.get(`/content/${id}/schedule`, {
      params,
    });

    return data;
  },

  getContentProgressByUser: async (id: string, userId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/content/${id}/progression/${userId}`);

    return data;
  },
};

export default contentAPI;
