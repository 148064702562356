import { Dispatch } from 'redux';

import AcademicYearApi from '@portal/repositories/academicYears';
import * as MessageService from '~/services/message';

import { ACADEMIC_YEAR_LIST } from './actionTypes';

export const getAllAcademicYears =
  (params?: { segmentKey?: number }) => async (dispatch: Dispatch) => {
    try {
      const payload = await AcademicYearApi.getAllAcademicYears(params);
      dispatch({
        payload,
        type: ACADEMIC_YEAR_LIST,
      });
    } catch (err) {
      MessageService.error('Não foi possível listar os anos escolares');
    }
  };
