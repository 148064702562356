/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

const IcWindow: React.FunctionComponent = (props: any) => (
  <SvgIcon {...props} fontSize="1rem" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.19299 11.2626H3.1435L3.1435 16.3121L8.193 16.3121L8.19299 11.2626ZM3.1435 10.1309C2.51844 10.1309 2.01172 10.6376 2.01172 11.2626V16.3121C2.01172 16.9372 2.51844 17.4439 3.1435 17.4439H8.193C8.81806 17.4439 9.32478 16.9372 9.32478 16.3121V11.2626C9.32478 10.6376 8.81806 10.1309 8.19299 10.1309H3.1435Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7252 2.7309H11.6757V7.7804H16.7252V2.7309ZM11.6757 1.59912C11.0507 1.59912 10.5439 2.10584 10.5439 2.7309V7.7804C10.5439 8.40546 11.0507 8.91218 11.6757 8.91218H16.7252C17.3503 8.91218 17.857 8.40546 17.857 7.7804V2.7309C17.857 2.10584 17.3503 1.59912 16.7252 1.59912H11.6757Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5439 11.2626C10.5439 10.6376 11.0507 10.1309 11.6757 10.1309H16.7252C17.3503 10.1309 17.857 10.6376 17.857 11.2626V16.3121C17.857 16.9372 17.3503 17.4439 16.7252 17.4439H11.6757C11.0507 17.4439 10.5439 16.9372 10.5439 16.3121V11.2626Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.01172 2.73091C2.01172 2.10584 2.51844 1.59912 3.1435 1.59912H8.19299C8.81806 1.59912 9.32478 2.10584 9.32478 2.7309V7.7804C9.32478 8.40546 8.81806 8.91218 8.193 8.91218H3.1435C2.51844 8.91218 2.01172 8.40546 2.01172 7.7804V2.73091Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IcWindow;
