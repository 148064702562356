import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ChatActions } from '@portal/actions';
import Conversation from '@portal/components/v1/ChatComponents/Conversation/Conversation';
import InputChat from '@portal/components/v1/ChatComponents/InputChat/InputChat';
import { useReduxState } from '@portal/hooks/useReduxState';
import { IRouteParams } from '@portal/models/route-params';
import { getQueryParams } from '@portal/utils/getQueryParams';

const ConversationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { me } = useReduxState().auth;
  const { privateChatId } = useParams<IRouteParams>();

  const searchParameters = getQueryParams();

  const handleSendMassage = (message?: string, photoUrl?: string) => {
    const questionIdSearched = searchParameters.get('questionId');

    dispatch(
      ChatActions.sendMessage(
        {
          privateChatId: privateChatId as string,
          message,
          photoUrl,
          metadata: questionIdSearched || '',
          userId: me?.id as string,
        },
        true
      )
    );

    if (questionIdSearched) {
      history.replace(window.location.pathname);
    }
  };

  return (
    <Container fluid="lg" className="conversation-container p-0">
      <Col className="p-0">
        <Row className="conversation-container__message-list">
          <Col className="p-0">
            <Conversation />
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col className="m-0 conversation-container__input">
            <InputChat onSubmit={handleSendMassage} />
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default ConversationPage;
