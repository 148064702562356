import axios from 'axios';
import { Dispatch } from 'redux';

import contentAPI from '@portal/repositories/content';
import { getMimeTypeByExtension } from '@portal/utils/mimeTypes';
import * as MessageService from '~/services/message';

import { COURSE_CONTENT, COURSE_CONTENT_PROGRESS, COURSE_CONTENT_SCHEDULE } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getContentById =
  (id: string, isLocalLoading?: boolean, options?: { onError?: () => void }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.ContentCourse = await contentAPI.getContentById(id);

      dispatch({
        payload,
        type: COURSE_CONTENT,
      });
    } catch {
      options?.onError?.();
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getContentScheduleById =
  (
    id: string,
    virtualGroupId: string,
    isLocalLoading?: boolean,
    options?: { onSuccess?: () => void; onError?: () => void }
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.ContentCourseSchedule = await contentAPI.getContentScheduleById(id, { virtualGroupId });

      dispatch({
        payload,
        type: COURSE_CONTENT_SCHEDULE,
      });

      options?.onSuccess?.();
    } catch (err) {
      options?.onError?.();
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getContentProgressByUser =
  (id: string, userId: string, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload: models.ContentCourseSchedule = await contentAPI.getContentProgressByUser(id, userId);

      dispatch({
        payload,
        type: COURSE_CONTENT_PROGRESS,
      });
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const downloadContent =
  (ref: React.MutableRefObject<HTMLAnchorElement | null>, url: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(increaseLoading());
    try {
      const responseUrl = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });

      const extension = url.split('.').pop();

      const blob = new Blob([responseUrl.data], {
        type: getMimeTypeByExtension(`.${extension}`),
      });

      ref?.current?.setAttribute('href', window.URL.createObjectURL(blob));

      ref?.current?.click();
    } catch {
      MessageService.error('Não foi possível realizar o download do conteúdo.');
    } finally {
      dispatch(decreaseLoading());
    }
  };
