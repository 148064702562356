import React, { Key, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Modal, Table } from 'antd';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Circle from '../Circle/Circle';

import { translate } from '@portal/services/i18n';
import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getContentProgressByUser } from '@portal/actions/content';
import { GroupDetailsElectiveStatusModules } from '@portal/models/reducers';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import returnPronounWithCourseTerm from '@portal/utils/returnPronounWithCourseTerm';
import { getUserActionStatusType } from '@portal/enum/userActionType';

interface IProps {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StatusCourseModal: React.FC<IProps> = ({ openModal, closeModal }) => {
  const { user, group, content, loading } = useReduxState();
  const [expandedRow, setExpandedRow] = useState<string>();

  const dispatch = useDispatch();

  const expandedRowRender = () => {
    const columns = [
      {
        title: translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.ACTIVITY'),
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.STATUS'),
        dataIndex: 'status',
        key: 'status',
        render: (status: number) => (
          <div className="status">
            <span>{getUserActionStatusType(status)?.text}</span>
            <Circle variant={getUserActionStatusType(status)?.circleVariant} />
          </div>
        ),
        width: '12rem',
      },
      {
        title: translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.DATE'),
        dataIndex: 'finishedAt',
        key: 'finishedAt',
        render: (finishedAt: string) => (
          <span>
            {finishedAt
              ? format(new Date(finishedAt) || new Date(), 'P', {
                  locale: ptBR,
                })
              : '-'}
          </span>
        ),
        width: '10rem',
      },
    ];

    return (
      <Table
        loading={loading?.localAmount > 0}
        locale={{ emptyText: 'Nenhuma atividade' }}
        columns={columns}
        dataSource={content?.contentProgress?.activities}
        pagination={false}
      />
    );
  };

  const userInfos = user?.detail;
  const detailsElective = group?.statusElective;
  const groupInfos = group?.details;

  const handleExpand = (key: string) => {
    if (key === expandedRow) {
      setExpandedRow('');
    } else {
      setExpandedRow(key);
    }

    dispatch(getContentProgressByUser(key, userInfos?.id as string, true));
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.CLASSROOM'),
      dataIndex: 'title',
      key: 'title',
      width: '20rem',
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.STATUS'),
      dataIndex: 'isDone',
      key: 'isDone',
      render: (isDone: boolean) => (
        <div className="status">
          <span>
            {isDone
              ? translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.DONE')
              : translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.TODO')}
          </span>
          <Circle variant={isDone ? 'done' : 'todo'} />
        </div>
      ),
      width: '12rem',
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.TABLE.DATE'),
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      render: (finishedAt: string) => (
        <span>
          {finishedAt
            ? format(new Date(finishedAt) || new Date(), 'P', {
                locale: ptBR,
              })
            : '-'}
        </span>
      ),
      width: '10rem',
    },
  ];

  return (
    <Modal
      visible={openModal}
      onCancel={() => closeModal(false)}
      bodyStyle={{ padding: '0 0 2rem 0' }}
      footer={null}
      style={{ top: 20 }}
      width={750}
    >
      <Row>
        <img
          className="modal-status-elective__banner"
          src={groupInfos?.course?.photoUrl || EmptyBanner}
          alt="empty banner"
          width={'100%'}
        />
      </Row>

      <Col className="modal-status-elective__container mt-5 pl-5 pt-2">
        <Row>
          <span className="modal-status-elective__title">{returnPronounWithCourseTerm()?.status}</span>
        </Row>

        <Row className="mt-5">
          <span className="modal-status-elective__subtitle__bold">{group?.details?.course?.title}</span>
        </Row>

        <Row className="pt-2">
          <span className="modal-status-elective__subtitle__small">
            <span className="modal-status-elective__subtitle__small__bold">
              {translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.GROUP')}
            </span>
            {detailsElective?.name}
          </span>
        </Row>
        <Row className="pt-1">
          <span className="modal-status-elective__subtitle__small">
            <span className="modal-status-elective__subtitle__small__bold">
              {translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.STUDENT')}
            </span>
            {userInfos?.name}
          </span>
        </Row>

        <Row className="mt-5">
          {detailsElective?.course?.modules?.map((item: GroupDetailsElectiveStatusModules, index: number) => (
            <div className="display-flex">
              <Row className="mt-3 pb-2">
                <span className="modal-status-elective__subtitle__small__bold">
                  {translate('PAGES.GROUPS.GROUPS_PAGE.STATUS_ELECTIVE.SUBTITLE')} {index + 1}
                </span>
              </Row>
              <div className="modal-status-elective__table">
                <Table
                  rowKey="id"
                  columns={columns}
                  dataSource={item?.contents}
                  pagination={false}
                  expandedRowKeys={[expandedRow as Key]}
                  expandable={{
                    expandedRowRender,
                    expandIcon: ({ expanded, onExpand, record }) => {
                      if (expanded) {
                        return <DownOutlined onClick={(e) => onExpand(record, e)} />;
                      } else {
                        return <RightOutlined onClick={(e) => onExpand(record, e)} />;
                      }
                    },
                    onExpand: (expanded, record) => {
                      handleExpand(record?.id);
                    },
                  }}
                />
              </div>
            </div>
          ))}
        </Row>
      </Col>
    </Modal>
  );
};

export default StatusCourseModal;
