/* tslint:disable:object-literal-sort-keys */
const routes: models.route[] = [
  {
    id: 'COURSES',
    name: 'courses',
    route: '/courses',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'DETAILS',
        name: 'Detalhes',
        route: '/relatorio/detalhes',
      },
    ],
  },
  {
    id: 'HOME',
    name: 'home',
    route: '/home',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'DETAILS',
        name: 'Detalhes',
        route: '/relatorio/detalhes',
      },
    ],
  },
  {
    id: 'GROUPS',
    name: 'groups details',
    route: '/groups',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'DETAILS',
        name: 'details',
        route: '/details',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'COURSE_DETAILS',
    name: 'course details',
    route: '/course-details',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'COURSE_ID',
        name: 'id',
        route: '/:id',
      },
    ],
  },
  {
    id: 'CHAT',
    name: 'chat',
    route: '/chat',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'CONVERSATION',
        name: 'conversation',
        route: '/conversation',
      },
    ],
  },
  {
    id: 'PROFILE',
    name: 'profile',
    route: '/profile',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [],
  },
  {
    id: 'REDIRECT',
    name: 'redirect',
    route: '/redirecting',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [],
  },
  {
    id: 'REDIRECT_LMS',
    name: 'redirectLms',
    route: '/redirect',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [],
  },
  {
    id: 'AUTH',
    name: 'auth',
    route: '/auth',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [],
  },
  {
    id: 'CONTENT',
    name: 'content',
    route: '/content',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [],
  },
  {
    id: 'REDIRECT',
    name: 'redirect',
    route: '/redirect',
    icon: '',
    iconAlt: '',
    items: [],
  },
];

export const getRoutes = (): models.route[] => routes;

export const getRoutestack = (route: string): models.route => routes.find((o) => o.route === route) as models.route;

export const getStackPath = (stackId: string): string => {
  return `${routes.find((o) => o.id === stackId)?.route}`;
};

export const routeExist = (route: string): boolean => {
  const routeTop = routes.find((o) => route.startsWith(o.route));

  if (!routeTop) {
    return false;
  }
  if (routeTop.route.length === route.length) {
    return false;
  }

  return (routeTop.items.find((o) => `${routeTop.route}${o.route}` === route) && true) || false;
};

export const getRouteStackPath = (stackId: string, routeId: string): string => {
  const route = routes.find((o) => o.id === stackId);

  return `${route?.route}${route?.items.find((o) => o.id === routeId)?.route}`;
};
