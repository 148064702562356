import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import LmsNavigationStack from '@portal/pages/LMS/LMSNavigationStack';

const Router = () => {
  return (
    <BrowserRouter>
      <LmsNavigationStack />
    </BrowserRouter>
  );
};

export default Router;
