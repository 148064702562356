import { ArrowLeftOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserActions } from '@portal/actions';
import { COURSE_TYPE } from '@portal/enum/courseType';
import { UPDATE_PASSWORD_FIELDS } from '@portal/enum/updatePaswordFields';
import { useReduxState } from '@portal/hooks/useReduxState';
import { UpdatePasswordFields } from '@portal/models/module';
import { translate } from '@portal/services/i18n';
import UserIcon from '~/assets/svg/panel-top-bar/ic_user.svg';
import FaqIcon from '~/assets/svg/profilePage/ic_faq.svg';
import * as MessageService from '~/services/message';

const ProfilePage: React.FC = () => {
  const history = useHistory();
  const { me, courseType } = useReduxState().auth;
  const { amount } = useReduxState().loading;
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputFields, setInputFields] = useState<UpdatePasswordFields>(
    {} as UpdatePasswordFields
  );

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleOk = useCallback(() => {
    if (!inputFields.currentPassword) {
      MessageService.warn(
        'PAGES.PROFILE.CHANGE_PASSWORD.VALIDATION.CURRENT_PASS'
      );

      return;
    }

    if (!inputFields.password) {
      MessageService.warn('PAGES.PROFILE.CHANGE_PASSWORD.VALIDATION.NEW_PASS');

      return;
    }

    if (inputFields.password.length < 6) {
      MessageService.warn('PAGES.PROFILE.CHANGE_PASSWORD.VALIDATION.MIN_SIZE');

      return;
    }

    if (!inputFields.repeatPassword) {
      MessageService.warn(
        'PAGES.PROFILE.CHANGE_PASSWORD.VALIDATION.REPEAT_PASS'
      );

      return;
    }

    if (inputFields.password !== inputFields.repeatPassword) {
      MessageService.warn(
        'PAGES.PROFILE.CHANGE_PASSWORD.VALIDATION.DIFFERENT_PASS'
      );

      return;
    }

    if (me?.id && me?.profileType >= 0) {
      dispatch(
        UserActions.updatePassword(
          me?.id,
          {
            oldPassword: inputFields.currentPassword,
            password: inputFields.password,
          },
          courseType === COURSE_TYPE.ELECTIVE,
          () => {
            setIsModalVisible(false);
            setInputFields({
              currentPassword: '',
              password: '',
              repeatPassword: '',
            });
          }
        )
      );
    }
  }, [inputFields, me, courseType]);

  const handleUserSelectImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (!files) return;

      const file = files[0];

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (event) => {
        if (event.target) {
          dispatch(UserActions.updateUserAvatar(event.target.result as string));
        }
      };
    },
    []
  );

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setInputFields({
      currentPassword: '',
      password: '',
      repeatPassword: '',
    });
  }, []);

  const handleOnChange = useCallback(
    (value: string, type: number) => {
      switch (type) {
        case UPDATE_PASSWORD_FIELDS.CURRENT:
          setInputFields({ ...inputFields, currentPassword: value });
          break;
        case UPDATE_PASSWORD_FIELDS.NEW_PASS:
          setInputFields({ ...inputFields, password: value });
          break;

        case UPDATE_PASSWORD_FIELDS.REPEAT_PASS:
          setInputFields({ ...inputFields, repeatPassword: value });
          break;

        default:
      }
    },
    [inputFields]
  );

  return (
    <>
      <Modal
        title={translate('PAGES.PROFILE.CHANGE_PASSWORD.MODAL.TITLE')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={translate('PAGES.PROFILE.CHANGE_PASSWORD.MODAL.OK_TEXT')}
        cancelText={translate(
          'PAGES.PROFILE.CHANGE_PASSWORD.MODAL.CANCEL_TEXT'
        )}
        okButtonProps={{ disabled: !!amount }}
      >
        <div className="modal-password-container">
          <p>{translate('PAGES.PROFILE.CHANGE_PASSWORD.MODAL.DESCRIPTION')}</p>
          <input
            className="modal-password-container__input"
            type="password"
            value={inputFields.currentPassword}
            placeholder={translate(
              'PAGES.PROFILE.CHANGE_PASSWORD.MODAL.FIELDS.CURRENT_PASS'
            )}
            onChange={(e) =>
              handleOnChange(e.target.value, UPDATE_PASSWORD_FIELDS.CURRENT)
            }
          />
          <input
            className="modal-password-container__input"
            type="password"
            value={inputFields.password}
            placeholder={translate(
              'PAGES.PROFILE.CHANGE_PASSWORD.MODAL.FIELDS.NEW_PASS'
            )}
            onChange={(e) =>
              handleOnChange(e.target.value, UPDATE_PASSWORD_FIELDS.NEW_PASS)
            }
          />
          <input
            className="modal-password-container__input"
            type="password"
            value={inputFields.repeatPassword}
            placeholder={translate(
              'PAGES.PROFILE.CHANGE_PASSWORD.MODAL.FIELDS.REPEAT_PASS'
            )}
            onChange={(e) =>
              handleOnChange(e.target.value, UPDATE_PASSWORD_FIELDS.REPEAT_PASS)
            }
          />
        </div>
      </Modal>

      <Container className="profile-container">
        <header className="profile-container__header-container">
          <button
            onClick={history.goBack}
            className="profile-container__header-container__header-back"
          >
            <ArrowLeftOutlined className="profile-container__header-container__header-back-icon" />
            <p className="profile-container__header-container__header-back__header-back-text">
              {translate('PAGES.PROFILE.TITLE')}
            </p>
          </button>
          <img
            className="profile-container__header-container__header-faq"
            src={FaqIcon}
            alt="FAQ"
          />
        </header>
        <Col className="profile-container__profile-content">
          <label
            htmlFor="avatar-image"
            className="profile-container__profile-content__user-avatar"
          >
            <img
              className="profile-container__profile-content__user-avatar__user-avatar-img"
              src={me?.profilePictureUrl ? me.profilePictureUrl : UserIcon}
              alt={me?.name}
            />
            <input
              className="profile-container__profile-content__user-avatar__user-avatar-input"
              type="file"
              id="avatar-image"
              multiple
              accept=".jpg, .png, .jpeg"
              onChange={handleUserSelectImage}
            />
            <label
              htmlFor="avatar-image"
              className="profile-container__profile-content__user-avatar__user-avatar-label"
            >
              {translate('PAGES.PROFILE.CHANGE_AVATAR')}
            </label>
          </label>
          <div className="profile-container__profile-content__user-info-row">
            <label
              htmlFor="name"
              className="profile-container__profile-content__user-info-row__profile-label"
            >
              {translate('PAGES.PROFILE.FORM.NAME.LABEL')}
            </label>
            <p
              id="name"
              className="profile-container__profile-content__user-info-row__profile-data"
            >
              {me?.name || ''}
            </p>
          </div>

          <div className="profile-container__profile-content__user-info-row">
            <label
              htmlFor="email"
              className="profile-container__profile-content__user-info-row__profile-label"
            >
              {translate('PAGES.PROFILE.FORM.EMAIL.LABEL')}
            </label>
            <p
              id="email"
              className="profile-container__profile-content__user-info-row__profile-data"
            >
              {me?.email || ''}
            </p>
          </div>

          <div className="profile-container__profile-content__user-info-row">
            <label
              htmlFor="school"
              className="profile-container__profile-content__user-info-row__profile-label"
            >
              {translate('PAGES.PROFILE.FORM.SCHOOL.LABEL')}
            </label>
            <p
              id="school"
              className="profile-container__profile-content__user-info-row__profile-data"
            >
              {(me && me.school && me.school.name) || ''}
            </p>
          </div>
          {me && me.nameStudentClass && (
            <div className="profile-container__profile-content__user-info-row">
              <label
                htmlFor="class"
                className="profile-container__profile-content__user-info-row__profile-label"
              >
                {translate('PAGES.PROFILE.FORM.CLASS.LABEL')}
              </label>
              <p
                id="class"
                className="profile-container__profile-content__user-info-row__profile-data"
              >
                {me.nameStudentClass || ''}
              </p>
            </div>
          )}
          {courseType === COURSE_TYPE.ELECTIVE && (
            <button
              type="submit"
              className="profile-container__profile-content__change-password-button"
              onClick={showModal}
            >
              {translate('PAGES.PROFILE.FORM.CHANGE_PASSWORD.LABEL')}
            </button>
          )}
        </Col>
      </Container>
    </>
  );
};

export default ProfilePage;
