import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath, getStackPath } from '@portal/config/routes';
import HeaderChatWrapper from '@portal/hoc/HeaderChatWrapper';
import { PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import ChatPage from './ChatPage/ChatPage';
import ConversationPage from './ConversationPage/ConversationPage';

const ChatNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('CHAT')} exact>
        <HeaderChatWrapper
          Component={
            <PanelContent
              pageTitle={translate('PAGES.CHAT.TITLE')}
              pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
              removeMarginTop
            >
              <ChatPage />
            </PanelContent>
          }
        />
      </Route>
      <Route path={`${getRouteStackPath('CHAT', 'CONVERSATION')}/:privateChatId`}>
        <HeaderChatWrapper
          isConversation
          Component={
            <PanelContent
              pageTitle={translate('PAGES.CHAT.TITLE')}
              pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
              removeMarginTop
            >
              <ConversationPage />
            </PanelContent>
          }
        />
      </Route>
    </Switch>
  );
};

export default ChatNavigationStack;
