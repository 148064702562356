import { Form, Input, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useForm } from 'antd/lib/form/Form';

import { Btn } from '@portal/components';
import { translate } from '@portal/services/i18n';
import { Option } from 'antd/lib/mentions';
import { useDispatch } from 'react-redux';
import { CourseActions, GroupActions, UserActions } from '@portal/actions';
import { useReduxState } from '@portal/hooks/useReduxState';
import { ProfileType } from '@portal/enum/profileType';
import { COURSE_TYPE } from '@portal/enum/courseType';
import { Course } from '@portal/models/reducers';
import * as StorageService from '~/services/storage';
import { User } from '@portal/models/module';

interface INewGroupModal {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  groupListPagination: {
    limit: number;
    offset: number;
    type: number;
  };
}

const NewGroupModal: React.FC<INewGroupModal> = ({ openModal, closeModal, groupListPagination }) => {
  const dispatch = useDispatch();
  const { me } = useReduxState().auth;
  const [form] = useForm();
  const courseId = StorageService.getItem('courseId-redirect');

  const paramns: { id: string } = useParams();

  const searchParameters = {
    profileType: ProfileType.TEACHER,
    schoolId: me?.idSchool,
    limit: Number.MAX_SAFE_INTEGER,
    offset: 0,
  };

  const courseSearchParameters = {
    type: COURSE_TYPE.ELECTIVE,
    brandProductId: paramns.id,
    simpleList: true,
    limit: Number.MAX_SAFE_INTEGER,
    offset: 0,
  };

  const groupSearchParameters = {
    productId: paramns.id,
    status: 'active',
    schoolId: me?.idSchool,
    ...(courseId && { courseId }),
    ...groupListPagination,
  };

  const {
    course: { courses },
    user: { list },
  } = useReduxState();

  useEffect(() => {
    if (paramns.id) {
      dispatch(CourseActions.getAllCourses(courseSearchParameters));
    }
  }, [paramns.id]);

  useEffect(() => {
    dispatch(UserActions.getUserReport(searchParameters));
  }, []);

  const handleSubmit = (data: models.CreateGroup) => {
    const groupData = {
      brandProductId: paramns.id,
      schoolId: me?.idSchool as string,
      name: data.name,
      courseId: data.courseId,
      capacity: data.capacity,
      tutorIdList: data.tutorIdList,
      enrollmentStartDate: null,
      enrollmentEndDate: null,
      automaticStudentEnrollment: false,
      type: COURSE_TYPE.ELECTIVE,
    };

    closeModal(false);
    form.resetFields();
    dispatch(
      GroupActions.createGroup(groupData, () => {
        dispatch(GroupActions.getAllGroups(groupSearchParameters));
      })
    );
  };

  return (
    <Modal
      visible={openModal}
      onCancel={() => {
        closeModal(false);
        form.resetFields();
      }}
      footer={null}
      centered={true}
      width={750}
    >
      <div className="content">
        <Form form={form} name="basic" initialValues={{ remember: true }} onFinish={handleSubmit}>
          <Col>
            <Row className="content__columns">
              <span className="content__font-style">{translate('PAGES.COURSES.TITLE')}:</span>
              <Form.Item
                name="courseId"
                rules={[
                  {
                    required: true,
                    message: translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.ERROR_MESSAGE.NO_COURSE'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.COURSE_SELECT')}
                  style={{ width: '100%' }}
                  className="mt-1"
                >
                  {courses.rows.map((course: Course) => (
                    <Option value={course.id}>{course.title}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
            <Row className="content__columns content__gap">
              <span className="content__font-style mb-1">
                {translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.GROUP_DATA')}:
              </span>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.ERROR_MESSAGE.NO_GROUPNAME'),
                  },
                ]}
              >
                <Input placeholder={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.GROUP_NAME')} />
              </Form.Item>
              <div className="d-flex align-items-center">
                <Form.Item
                  name="tutorIdList"
                  rules={[
                    {
                      required: true,
                      message: translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.ERROR_MESSAGE.NO_TUTOR'),
                    },
                  ]}
                  style={{ width: '70%' }}
                >
                  <Select
                    mode="multiple"
                    placeholder={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.TUTOR_SELECT')}
                    style={{ width: '100%' }}
                    className="mt-1"
                  >
                    {list.map((user: User) => (
                      <Option value={user.id}>{user.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="capacity"
                  rules={[
                    {
                      required: true,
                      message: translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.ERROR_MESSAGE.NO_CAPACITY'),
                    },
                  ]}
                  style={{ width: '27%' }}
                  className="mt-1"
                >
                  <Input
                    placeholder={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.CAPACITY')}
                    style={{ marginLeft: 20 }}
                    type="number"
                  />
                </Form.Item>
              </div>
            </Row>
            <Row className="button-layout align-mobile">
              <Btn
                type="button"
                variant="secondary"
                title={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.BUTTONS.CANCEL')}
                onClick={() => {
                  form.resetFields();
                  closeModal(false);
                }}
              />
              <Btn variant="primary" title={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.BUTTONS.CREATE_GROUP')} />
            </Row>
          </Col>
        </Form>
      </div>
    </Modal>
  );
};

export default NewGroupModal;
