import { TOP_BAR_NAVIGATION } from '@portal/actions/actionTypes';

const initialState: reducers.TopbarReducer = {
  selected: 0,
};

const topBarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOP_BAR_NAVIGATION:
      state = {
        ...state,
        selected: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default topBarReducer;
