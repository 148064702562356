/* eslint-disable max-len */
import React, { ComponentProps } from 'react';

export const IcAddressBook = (props: ComponentProps<'svg'>) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 21" fill="none">
      <g clip-path="url(#clip0_25377_906)">
        <path
          d="M17.5179 6.75C17.783 6.75 18 6.53906 18 6.28125V4.71875C18 4.46094 17.783 4.25 17.5179 4.25H16.7143V2.375C16.7143 1.33984 15.8504 0.5 14.7857 0.5H1.92857C0.863839 0.5 0 1.33984 0 2.375V18.625C0 19.6602 0.863839 20.5 1.92857 20.5H14.7857C15.8504 20.5 16.7143 19.6602 16.7143 18.625V16.75H17.5179C17.783 16.75 18 16.5391 18 16.2812V14.7188C18 14.4609 17.783 14.25 17.5179 14.25H16.7143V11.75H17.5179C17.783 11.75 18 11.5391 18 11.2812V9.71875C18 9.46094 17.783 9.25 17.5179 9.25H16.7143V6.75H17.5179ZM8.35714 5.5C9.77545 5.5 10.9286 6.62109 10.9286 8C10.9286 9.37891 9.77545 10.5 8.35714 10.5C6.93884 10.5 5.78571 9.37891 5.78571 8C5.78571 6.62109 6.93884 5.5 8.35714 5.5ZM12.8571 14.75C12.8571 15.1641 12.4554 15.5 11.9571 15.5H4.75714C4.25893 15.5 3.85714 15.1641 3.85714 14.75V14C3.85714 12.7578 5.06652 11.75 6.55714 11.75H6.75804C7.25223 11.9492 7.79063 12.0625 8.35714 12.0625C8.92366 12.0625 9.46607 11.9492 9.95625 11.75H10.1571C11.6478 11.75 12.8571 12.7578 12.8571 14V14.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_25377_906">
          <rect width="18" height="20" fill="currentColor" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
