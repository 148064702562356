import { Dispatch } from 'redux';

import {
  DECREASE_LOADING,
  DECREASE_LOCAL_LOADING,
  INCREASE_LOADING,
  INCREASE_LOCAL_LOADING,
} from './actionTypes';

export const increaseLoading = (isLocalLoading?: boolean) => {
  if (isLocalLoading) {
    return {
      type: INCREASE_LOCAL_LOADING,
    };
  }

  return {
    type: INCREASE_LOADING,
  };
};

export const decreaseLoading = (isLocalLoading?: boolean) => {
  if (isLocalLoading) {
    return {
      type: DECREASE_LOCAL_LOADING,
    };
  }

  return {
    type: DECREASE_LOADING,
  };
};

export const addLoading = (isLocalLoading?: boolean) => (dispatch: Dispatch) =>
  dispatch(increaseLoading(!!isLocalLoading));

export const removeLoading =
  (isLocalLoading?: boolean) => (dispatch: Dispatch) =>
    dispatch(decreaseLoading(!!isLocalLoading));
