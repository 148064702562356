export const colors = {
  backgroundLight: '#eee9ee',
  backgroundDrag: '#D5DCE0',
  borderColor: '#ffffff',
  grayText: '#919191',
  grayTextSecondary: '#CAD3D8',
  grayTextDeadline: '#939393',
  grayTextLight: '#9b9aa3',
  grayTextDark: '#3e3e3e',
  grayTextDarkTitle: '#555555',
  gray50: '#EDF0F2',
  grayNavbar: 'rgba(114, 139, 151, 0.2)',
  gray300: '#D5D5D5',
  gray500: '#E1E1E1',
  gray600: '#728B97',
  gray700: '#878787',
  grayBg: '#f9f9f9',
  grayBgDeadline: '#dbdbdb',
  grayBgLight: '#e5e5e5ff',
  cardBg: '#f6f6f6',
  white: '#ffffff',
  lightPinkBg: '#fbdddc',
  headerBackground: '#fafbfc',
  indicatorRed: '#FF0000',
  primaryRed: '#ef4242',
  redDeadline: '#e65c65',
  secondaryRed: '#ee4b2b',
  salmon: '#FA8072',
  primaryYellow: '#ffcf27',
  primaryGray: '#f2f2f2',
  secondaryGray: '#dfdfdf',
  primaryPurple: '#dfb8e5',
  secondaryPurple: '#7e5a84',
  textGray: '#909090',
  primaryGreen: '#74d98a',
  secondaryYellow: '#e4f609',
  primaryOrange: '#ffa500',
  primaryBlue: '#0000CD',
  sidebarSelected: '#b7b7b7',
  sidebarDisable: '#e0e0e0',
  blackTextDark: '#0c0c0c',
  doneParameters: '#83FF6F',
  todoParameters: '#6F97FF',
};
