import { Dispatch } from 'redux';

import UserCommentApi from '@portal/repositories/userCommet';
import * as MessageService from '~/services/message';

import { decreaseLoading, increaseLoading } from './loading';

export const SendUserComment =
  (
    userComment: models.UserComment[],
    params?: advancedFilterModels.SendUserCommentFilter,
    isLocalLoading?: boolean,
    callback?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      await UserCommentApi.sendUserComment(userComment, params);
      MessageService.success(
        'PAGES.CONTENT.ACTIVITY_SECTION.REVIEW_TUTOR.SEND_SUCESS'
      );

      if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const SendTutorComment =
  (
    userComment: models.UserComment[],
    callback?: () => void,
    isLocalLoading?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      await UserCommentApi.sendUserComment(userComment);

      if (typeof callback === 'function') {
        callback();
      }
      MessageService.success(
        'PAGES.CONTENT.ACTIVITY_SECTION.REVIEW_TUTOR.SEND_SUCESS'
      );
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
