import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';
import * as StorageService from '~/services/storage';

const Banner: React.FC<any> = () => {
  const getHomeBanners = StorageService.getItem('brandProductsConfig')?.mainBannerUrl || '';

  return (
    <main className="banner" id="banner">
      {getHomeBanners ? (
        <div className="banner__banner-container">
          <div className="banner__banner-container__content" style={{ backgroundImage: `url(${getHomeBanners})` }} />
        </div>
      ) : (
        <img className="home-page__emptyBanner" src={EmptyBanner} alt="empty banner" />
      )}
    </main>
  );
};

export default Banner;
