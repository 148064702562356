import { Dispatch } from 'redux';

import ChatRequests from '@portal/repositories/chat';
import * as MessageService from '~/services/message';

import {
  ADD_CHAT_MESSAGES,
  CHAT_MESSAGES,
  CHAT_PARTICIPANTS,
  PRIVATE_CHATS,
  SEARCH_CHAT,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

interface ISendMessage {
  privateChatId: string;
  metadata?: string;
  message?: string;
  photoUrl?: string;
  userId: string;
}

export const createChat =
  (userIdList: string[], actorId: string, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await ChatRequests.createChat(userIdList, actorId);

      return payload.id;
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const sendMessage =
  (
    { privateChatId, metadata, message, photoUrl, userId }: ISendMessage,
    isLocalLoading?: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(increaseLoading(!!isLocalLoading));
      await ChatRequests.sendMessage(
        privateChatId,
        message,
        photoUrl,
        metadata,
      );

      dispatch({
        payload: {
          privateChatId,
          metadata,
          message,
          photoUrl,
          userId,
          createdAt: new Date().toISOString(),
        },
        type: ADD_CHAT_MESSAGES,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getChats =
  (isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await ChatRequests.getChats();

      dispatch({
        payload,
        type: PRIVATE_CHATS,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getChatMessages =
  (privateChatId: string) => async (dispatch: Dispatch) => {
    try {
      const payload = await ChatRequests.getChatMessages(privateChatId);
      dispatch({
        payload,
        type: CHAT_MESSAGES,
      });
    } catch (err) {
      MessageService.error(err.message);
    }
  };

export const reloadMessages =
  (privateChatId: string) => async (dispatch: Dispatch) => {
    try {
      const payload = await ChatRequests.getChatMessages(privateChatId);
      dispatch({
        payload,
        type: CHAT_MESSAGES,
      });
    } catch (err) {
      MessageService.error(err.message);
    }
  };

export const searchChat =
  (searchedChats: models.Chat[]) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        payload: searchedChats,
        type: SEARCH_CHAT,
      });
    } catch (err) {
      MessageService.error(err.message);
    }
  };

export const getParticipants =
  (privateChatId: string, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.ChatParticipants =
        await ChatRequests.getParticipants(privateChatId);
      dispatch({
        payload,
        type: CHAT_PARTICIPANTS,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
