import { getInstance } from './instance';

const BASE_URL = '/blink';

export const blinkLearningApi = {
  getUrl: async () => {
    const instance = getInstance({ api: 'new', version: 'v2' });

    const { data } = await instance.post(`${BASE_URL}/get-url`);

    return data;
  },
};
