import React, { ReactElement } from 'react';

import { BUTTON_SIZE } from '@portal/enum/buttonSize';
import CircularProgress from '@mui/material/CircularProgress';
interface IProps {
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  size?: number;
  title?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  icon?: ReactElement | null;
  isEnrollmentDisabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
}

const getButtonSize = (size: number | undefined) => {
  if (!size) return '';

  switch (size) {
    case BUTTON_SIZE.SMALLEST:
      return ' button__smallest';

    case BUTTON_SIZE.SMALLER:
      return ' button__smaller';

    case BUTTON_SIZE.SMALL:
      return ' button__small';

    case BUTTON_SIZE.MEDIUM:
      return ' button__medium';

    case BUTTON_SIZE.LARGE:
      return ' button__large';

    case BUTTON_SIZE.FULL:
      return ' button__full';

    case BUTTON_SIZE.FIT_CONTENT:
      return ' button__fit-content';

    default:
      return '';
  }
};

const Btn: React.FC<IProps> = ({
  onClick,
  variant,
  size,
  title,
  children,
  type,
  disabled,
  icon,
  isEnrollmentDisabled,
  isLoading,
}) => {
  return (
    <div className="button">
      <button
        className={`button${
          variant === 'secondary' || isLoading
            ? ` button__secondary${disabled ? '__disabled' : isEnrollmentDisabled ? '__isEnrollmentDisabled' : ''}`
            : ` button__primary${disabled ? '__disabled' : isEnrollmentDisabled ? '__isEnrollmentDisabled' : ''}`
        }${getButtonSize(size)}`}
        onClick={onClick}
        type={type}
        disabled={disabled || isEnrollmentDisabled || isLoading}
      >
        <div className={`button__content${icon || isLoading ? '__row' : ''}`}>
          {!isLoading ? (
            <>
              {icon && <div className="button__content__row__icon">{icon}</div>}
              <span
                className={`button__content__title${
                  variant === 'secondary' ? ' button__content__title__secondary' : ' button__content__title__primary'
                }`}
              >
                {title}
              </span>

              {children}
            </>
          ) : (
            <CircularProgress color="primary" size={20} />
          )}
        </div>
      </button>
    </div>
  );
};

export default Btn;
