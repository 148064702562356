import moment, { Moment } from 'moment';

export const formatDate = {
  endOfDay: (day: string | Date | Moment): string => {
    return moment(day).endOf('day').toISOString();
  },

  startOfDay: (day: string | Date | Moment): string => {
    return moment(day).startOf('day').toISOString();
  },

  format: (date: string, format: string): string => {
    const formatedDate = moment(date).format(format);

    return formatedDate || '';
  },
};
