import React, { useEffect, useState } from 'react';

import { Radio, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import RenderCondition from '@portal/utils/renderCondition';
import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import {
  SatisfactionSurveyQuestionAnswerType,
  SatisfactionSurveyQuestionChoiceType,
} from '@portal/enum/satisfactionSurvey';
import { translate } from '@portal/services/i18n';

interface ISatisfactionSurveyQuestion {
  options: models.SatisfactionSurveyOption[];
  setAnswers: (params: { questionId: string; alternativeAnswer?: string; descriptiveAnswer?: string }) => void;
  question: models.SatisfactionSurveyQuestion;
  isDisabled?: boolean;
}

const SatisfactionSurveyQuestion: React.FC<ISatisfactionSurveyQuestion> = ({
  options,
  setAnswers,
  question,
  isDisabled,
}) => {
  const [checkedValue, setCheckedValue] = useState<string[]>();
  const [descriptiveAnswer, setDescriptiveAnswer] = useState<string>('');

  useEffect(() => {
    if (checkedValue) {
      setAnswers({
        questionId: question?.id,
        alternativeAnswer: checkedValue.toString(),
        ...(question?.answerType === SatisfactionSurveyQuestionAnswerType.ALTERNATIVE_DESCRIPTIVE && {
          descriptiveAnswer: descriptiveAnswer,
        }),
      });
    }
  }, [checkedValue]);

  const onRadioOptionChange = (value: string) => {
    const itemExist = checkedValue?.some((item) => item === value);
    const filterArray = checkedValue?.filter((item) => item !== value) as string[];

    const isMultiple = question?.choiceType === SatisfactionSurveyQuestionChoiceType?.MULTIPLE;

    if (isMultiple && itemExist) {
      setCheckedValue(filterArray);
    }

    if (isMultiple && !itemExist) {
      setCheckedValue([...filterArray, value]);
    }

    if (!isMultiple) {
      setCheckedValue([value]);
    }
  };

  const onDescriptiveAnswerChange = (value: string) => {
    setDescriptiveAnswer(value);
    if (value) {
      setAnswers({
        questionId: question?.id,
        descriptiveAnswer: value,
        ...(question?.answerType === SatisfactionSurveyQuestionAnswerType.ALTERNATIVE_DESCRIPTIVE && {
          alternativeAnswer: checkedValue?.toString(),
        }),
      });
    }
  };

  const borderSize = (option: models.TestOptionQuestionDone) => {
    if (checkedValue?.includes(option.id)) {
      return '3px';
    }

    return '0.5px';
  };

  return (
    <>
      <div className="satisfaction-survey-question-container">
        <div className="satisfaction-survey-question-container__title">
          <RenderCondition condition={!!question?.description}>
            <div className="satisfaction-survey-question-container__title__label">
              <RenderHTML htmlText={question?.description as string} />
            </div>
          </RenderCondition>
        </div>

        <RenderCondition
          condition={[
            SatisfactionSurveyQuestionAnswerType.DESCRIPTIVE,
            SatisfactionSurveyQuestionAnswerType.ALTERNATIVE_DESCRIPTIVE,
          ]?.includes(question?.answerType)}
        >
          <div className="satisfaction-survey-question-container__descriptive">
            <TextArea
              name="answer textarea"
              id="answeer textarea"
              rows={5}
              placeholder={translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.ANSWER_TEXT_PLACEHOLDER')}
              onChange={(e) => {
                onDescriptiveAnswerChange(e.target.value);
              }}
              disabled={isDisabled}
            />
          </div>
        </RenderCondition>

        <RenderCondition
          condition={[
            SatisfactionSurveyQuestionAnswerType.ALTERNATIVE,
            SatisfactionSurveyQuestionAnswerType.ALTERNATIVE_DESCRIPTIVE,
          ]?.includes(question?.answerType)}
        >
          <div className="satisfaction-survey-question-container__options">
            {options?.map((option, optionIndex) => (
              <div
                key={option.id}
                className="satisfaction-survey-question-container__options__content"
                style={{
                  border: `${borderSize(option)} solid #D3D3D3`,
                }}
              >
                <Space direction="vertical">
                  <Radio
                    disabled={isDisabled}
                    checked={checkedValue?.includes(option?.id)}
                    onClick={() => onRadioOptionChange(option.id)}
                  >
                    <span className="satisfaction-survey-question-container__options__label__option">
                      {String.fromCharCode(optionIndex + 65)}
                      {') '}
                    </span>
                    <span className="satisfaction-survey-question-container__options__description">
                      {option.description}
                    </span>
                  </Radio>
                </Space>
              </div>
            ))}
          </div>
        </RenderCondition>
      </div>
    </>
  );
};

export default SatisfactionSurveyQuestion;
