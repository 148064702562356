/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getInstance } from './instance';

const MODULE_BASE_URL = '/student-class';

const StudentClass = {
  getAllStudentClasses: async (params?: models.StudentClassSearchParams) => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.get(`${MODULE_BASE_URL}`, {
      params,
    });

    return data;
  },
};

export default StudentClass;
