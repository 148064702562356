import { ACADEMIC_YEAR_LIST } from '@portal/actions/actionTypes';

const initialState: reducers.AcademicYearReducer = {
  academicYearList: null,
};

const academicYearReducer = (
  state = initialState,
  action: { type: string; payload: never }
): reducers.AcademicYearReducer => {
  switch (action.type) {
    case ACADEMIC_YEAR_LIST:
      state = {
        ...state,
        academicYearList: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default academicYearReducer;
