/* eslint-disable max-len */
import React, { ComponentProps } from 'react';

export const IcHamburguerMenu = (rest: ComponentProps<'svg'>) => (
  <svg {...rest} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16406 10.0002C5.16406 9.53997 5.53716 9.16687 5.9974 9.16687H18.9974C19.4576 9.16687 19.8307 9.53997 19.8307 10.0002C19.8307 10.4604 19.4576 10.8335 18.9974 10.8335H5.9974C5.53716 10.8335 5.16406 10.4604 5.16406 10.0002Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16406 2.99947C5.16406 2.53923 5.53716 2.16614 5.9974 2.16614H18.9974C19.4576 2.16614 19.8307 2.53923 19.8307 2.99947C19.8307 3.45971 19.4576 3.8328 18.9974 3.8328H5.9974C5.53716 3.8328 5.16406 3.45971 5.16406 2.99947Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16406 17.0003C5.16406 16.5401 5.53716 16.167 5.9974 16.167H18.9974C19.4576 16.167 19.8307 16.5401 19.8307 17.0003C19.8307 17.4606 19.4576 17.8337 18.9974 17.8337H5.9974C5.53716 17.8337 5.16406 17.4606 5.16406 17.0003Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.164062 17.0003C0.164062 16.5401 0.537159 16.167 0.997396 16.167H1.0074C1.46763 16.167 1.84073 16.5401 1.84073 17.0003C1.84073 17.4606 1.46763 17.8337 1.0074 17.8337H0.997396C0.537159 17.8337 0.164062 17.4606 0.164062 17.0003Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.164062 10.0002C0.164062 9.53997 0.537159 9.16687 0.997396 9.16687H1.0074C1.46763 9.16687 1.84073 9.53997 1.84073 10.0002C1.84073 10.4604 1.46763 10.8335 1.0074 10.8335H0.997396C0.537159 10.8335 0.164062 10.4604 0.164062 10.0002Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.164062 2.99935C0.164062 2.53911 0.537159 2.16602 0.997396 2.16602H1.0074C1.46763 2.16602 1.84073 2.53911 1.84073 2.99935C1.84073 3.45959 1.46763 3.83268 1.0074 3.83268H0.997396C0.537159 3.83268 0.164062 3.45959 0.164062 2.99935Z"
      fill="currentColor"
    />
  </svg>
);
