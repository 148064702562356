import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import { Modal, Tooltip } from 'antd';

import { Course } from '@portal/models/reducers';
import { TopBarActions } from '@portal/actions';
import * as CourseActions from '@portal/actions/course';
import { SHOW_MODAL_SATISFACTION_SURVEY } from '@portal/actions/actionTypes';

import EmptyCourse from '@portal/assets/png/components/empty_course.png';
import { Btn, Card, PanelSatisfactionSurvey } from '@portal/components';
import Tabs from '@portal/components/v1/Tabs';

import { translate } from '@portal/services';
import { useReduxState } from '~/hooks';
import { TAB_MENU_ITEMS, BUTTON_SIZE, isEducator, COURSE_TYPE } from '@portal/enum';
import * as StorageService from '~/services/storage';
import returnPronounWithCourseTerm from '@portal/utils/returnPronounWithCourseTerm';
import { RenderCondition } from '@portal/utils';
import { downloadTotalHoursCertificate } from '@portal/actions/certificate';

const Courses: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef<HTMLAnchorElement | null>(null);
  const {
    satisfactionSurvey: { showModal },
    course: { mineCourses, otherCourses, concludedCourses },
    auth: { me, courseType },
  } = useReduxState();

  const [currentCourseList, setCurrentCourseList] = useState<Course[]>([]);
  const [yearSelected, setYearSelected] = useState<number>(new Date().getFullYear());

  const courseListPagination = {
    limit: Number.MAX_SAFE_INTEGER,
    offset: 1,
    page: 1,
  };
  currentCourseList.sort((a, b) => {
    return moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();
  });

  const pathname = location.pathname.replace('/', '');
  const isStarted = pathname === 'courses/me';
  const isConcluded = pathname === 'courses/concluded';

  const productId = StorageService.getItem('brandProductsConfig')?.id;

  const years = Array.from(
    new Set(
      concludedCourses?.rows?.map((course) => new Date(course.courseUser?.[0]?.updatedAt as string).getFullYear())
    )
  )?.sort();

  const courseFilteredByYear = (year: number) => {
    return currentCourseList?.filter(
      (course) => new Date(course.courseUser?.[0]?.updatedAt as string).getFullYear() === year
    );
  };

  useEffect(() => {
    if (!years?.includes(yearSelected)) {
      setYearSelected(years?.[0]);
    }
  }, [yearSelected, years]);

  useEffect(() => {
    if (isStarted && productId && me?.idSchool) {
      dispatch(
        CourseActions.getMineCourses({
          ...courseListPagination,
          productId: productId,
          schoolId: me?.idSchool,
        })
      );
      dispatch(
        CourseActions.getOtherCourses(
          {
            ...courseListPagination,
            schoolId: me?.idSchool,
          },
          false,
          'true'
        )
      );

      dispatch(TopBarActions.setNavigationItem(TAB_MENU_ITEMS.MYCOURSES));
    } else if (isConcluded) {
      dispatch(
        CourseActions.getConcludedCourses({
          ...courseListPagination,
          productId,
        })
      );
      dispatch(TopBarActions.setNavigationItem(TAB_MENU_ITEMS.FINISHEDCOURSES));
    }
  }, [me, isConcluded, isStarted, productId]);

  useEffect(() => {
    switch (pathname) {
      case 'courses/me':
        setCurrentCourseList(mineCourses?.rows || []);
        break;
      case 'courses/concluded':
        setCurrentCourseList(concludedCourses?.rows || []);
        break;
      default:
        setCurrentCourseList(otherCourses?.rows || []);
        break;
    }
  }, [concludedCourses, mineCourses, otherCourses, pathname]);

  const getTitle = () => {
    let title;
    let emptyTitle;
    let emptySubtitle;

    switch (pathname) {
      case 'courses/me':
        title = returnPronounWithCourseTerm()?.my as string;
        emptySubtitle = translate('PAGES.COURSES.EMPTY_SUBTITLE_MY_COURSES');
        emptyTitle = translate('PAGES.COURSES.EMPTY_MY_COURSES');
        break;

      case 'courses/concluded':
        title = translate('PAGES.COURSES.EMPTY_FINISHED_COURSES.TITLE');
        emptyTitle = translate('PAGES.COURSES.EMPTY_FINISHED_COURSES.EMPTY_TITLE');
        emptySubtitle = translate('PAGES.COURSES.EMPTY_FINISHED_COURSES.SUBTITLE');
        break;
    }

    return { title, emptyTitle, emptySubtitle };
  };

  return (
    <Container fluid className="course-container ">
      <Row className="d-flex justify-content-between align-itens-center">
        <span className="course-container__title">{getTitle().title}</span>

        <RenderCondition condition={isEducator(me?.profileType) && courseType === COURSE_TYPE.TRAIL && isConcluded}>
          <RenderCondition condition={currentCourseList?.length >= 2}>
            <Btn size={BUTTON_SIZE.FULL} onClick={() => dispatch(downloadTotalHoursCertificate(ref))}>
              <span className="p-4 font-weight-bold">
                {translate('PAGES.COURSES.EMPTY_FINISHED_COURSES.DOWNLOAD_CERTIFICATE')}
              </span>
            </Btn>
          </RenderCondition>

          <RenderCondition condition={currentCourseList?.length < 2}>
            <Tooltip
              title="Você precisa concluir no mínimo 2 cursos para gerar um certificado de carga horária total."
              placement="bottom"
            >
              <div>
                <Btn size={BUTTON_SIZE.FULL} disabled>
                  <span className="p-4 font-weight-bold">
                    {translate('PAGES.COURSES.EMPTY_FINISHED_COURSES.DOWNLOAD_CERTIFICATE')}
                  </span>
                </Btn>
              </div>
            </Tooltip>
          </RenderCondition>
        </RenderCondition>
      </Row>
      <a ref={ref} target="_blank" download={`Certificado com carga horária total - ${me?.name}`} />
      <Row>
        <Col className="p-0">
          <div className="course-container__course-content">
            <RenderCondition condition={!!currentCourseList?.length && isStarted}>
              <div className="course-container__course-list">
                {currentCourseList?.map((course) => (
                  <Card key={course.id} cardData={course} isStarted={isStarted} isConcluded={isConcluded} />
                ))}
              </div>
            </RenderCondition>

            <RenderCondition condition={!!currentCourseList?.length && isConcluded}>
              <Tabs
                defaultActiveKey={String(yearSelected)}
                handleTab={(key) => setYearSelected(Number(key))}
                variant="secondary"
                items={years?.map((year) => ({
                  key: String(year),
                  label: String(year),
                  children: (
                    <div className="course-container__course-list">
                      {courseFilteredByYear(year)?.map((course) => (
                        <Card key={course.id} cardData={course} isStarted={isStarted} isConcluded={isConcluded} />
                      ))}
                    </div>
                  ),
                }))}
              />
            </RenderCondition>

            <RenderCondition condition={!currentCourseList?.length}>
              <div className="course-container__course-list__empty-container">
                <Col>
                  <Row className="d-flex justify-content-center align-itens-center">
                    <img
                      className="course-container__course-list__empty-container__image w-100"
                      src={EmptyCourse}
                      alt="empty course"
                    />
                  </Row>
                  <Row className="d-flex justify-content-center align-itens-center">
                    <span className="course-container__course-list__empty-container__title">
                      {getTitle().emptyTitle}
                    </span>
                  </Row>
                  <Row className="d-flex justify-content-center align-itens-center">
                    <span className="course-container__course-list__empty-container__subtitle">
                      {getTitle().emptySubtitle}
                    </span>
                  </Row>
                </Col>
              </div>
            </RenderCondition>
          </div>
        </Col>
      </Row>

      <Modal
        visible={showModal}
        onCancel={() => {
          dispatch({
            payload: false,
            type: SHOW_MODAL_SATISFACTION_SURVEY,
          });
        }}
        bodyStyle={{ padding: '1rem' }}
        footer={null}
        style={{ top: 20 }}
        width={800}
      >
        <PanelSatisfactionSurvey isModal />
      </Modal>
    </Container>
  );
};

export default Courses;
