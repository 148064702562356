import utilsAPI from '@portal/repositories/utils';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useSendFile = (
  options?: UseMutationOptions<{ url: string }, AxiosError<{ status: number; message: string }>, File, unknown>
) => {
  const mutate = useMutation({
    mutationFn: (file: File) => utilsAPI.sendFile(file),
    ...options,
  });

  return mutate;
};
