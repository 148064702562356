import React from 'react';
import Icon from './LoadingIcon';
import { useReduxState } from '@portal/hooks/useReduxState';

interface ILoading {
  whiteLoading?: boolean;
}

const Loading = ({ whiteLoading }: ILoading) => {
  const {
    brand: { brandConfig },
  } = useReduxState();

  return (
    <div
      className="loading"
      style={{ backgroundColor: !brandConfig?.primaryColor || whiteLoading ? 'white' : '#2C2E31E5' }}
    >
      <div style={{ color: !brandConfig?.primaryColor || whiteLoading ? 'white' : brandConfig?.primaryColor }}>
        <Icon />
      </div>
    </div>
  );
};

export default Loading;
