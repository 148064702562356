import { getInstance } from './instance';

const utilsAPI = {
  sendFile: async (file: File) => {
    const instance = getInstance();
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await instance.post('/utils/upload-image', formData);

    return data;
  },
};

export default utilsAPI;
