import React, { useState } from 'react';

import { translate } from '@portal/services/i18n';
import useWindowSize from '@portal/utils/getSizeWindow';

import SearchBar from '@portal/components/v1/SearchBar/SearchBar';
import SearchDropdown from '@portal/components/v1/SearchDropdown/SearchDropdown';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const FilterSearch: React.FC = () => {
  const mobile = useWindowSize();

  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <>
      {mobile ? (
        <SearchDropdown
          placeholder={translate('PAGES.HOME.SCREEN.SEARCH_SECTION.SEARCH')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      ) : (
        <SearchBar placeholder={translate('PAGES.HOME.SCREEN.SEARCH_SECTION.SEARCH')} />
      )}
    </>
  );
};

export default FilterSearch;
