import React from 'react';

import { ComponentProps } from 'react';

export const IcBroadcast = (rest: ComponentProps<'svg'>) => {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 48" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M13.1656 0.723111C14.1322 1.68729 14.1322 3.25049 13.1656 4.21464C2.21146 15.1418 2.21146 32.8583 13.1656 43.7852C14.1322 44.7494 14.1322 46.3127 13.1656 47.2769C12.1991 48.241 10.632 48.241 9.66547 47.2769C-3.22182 34.4212 -3.22182 13.5786 9.66547 0.723111C10.632 -0.241037 12.1991 -0.241037 13.1656 0.723111ZM56.3346 0.723111C69.2218 13.5786 69.2218 34.4212 56.3346 47.2769C55.3681 48.241 53.8009 48.241 52.8343 47.2769C51.8678 46.3127 51.8678 44.7494 52.8343 43.7852C63.7887 32.8583 63.7887 15.1418 52.8343 4.21464C51.8678 3.25049 51.8678 1.68729 52.8343 0.723111C53.8009 -0.241037 55.3681 -0.241037 56.3346 0.723111ZM22.4995 10.0339C23.466 10.998 23.466 12.5612 22.4995 13.5254C16.7002 19.3103 16.7002 28.6895 22.4995 34.4746C23.466 35.4387 23.466 37.002 22.4995 37.9662C21.5329 38.9304 19.9658 38.9304 18.9993 37.9662C11.2669 30.2528 11.2669 17.747 18.9993 10.0339C19.9658 9.06971 21.5329 9.06971 22.4995 10.0339ZM47.0006 10.0339C54.7331 17.747 54.7331 30.2528 47.0006 37.9662C46.034 38.9304 44.4672 38.9304 43.5006 37.9662C42.534 37.002 42.534 35.4387 43.5006 34.4746C49.2997 28.6895 49.2997 19.3103 43.5006 13.5254C42.534 12.5612 42.534 10.998 43.5006 10.0339C44.4672 9.06971 46.034 9.06971 47.0006 10.0339ZM33 19.0621C35.7337 19.0621 37.95 21.2729 37.95 23.9999C37.95 26.7269 35.7337 28.9377 33 28.9377C30.2663 28.9377 28.05 26.7269 28.05 23.9999C28.05 21.2729 30.2663 19.0621 33 19.0621Z"
        fill="currentColor"
      />
    </svg>
  );
};
