import React, { useCallback } from 'react';

interface IProps {
  currentStatus: number;
  doneStatus?: number;
  waitingReviewStatus?: number;
  redoStatus?: number;
  doneLabel?: string;
  waitingReviewLabel?: string;
  redoLabel?: string;
}

const StatusLabel: React.FC<IProps> = ({
  currentStatus,
  doneStatus,
  redoStatus,
  waitingReviewStatus,
  waitingReviewLabel,
  doneLabel,
  redoLabel,
}) => {
  const getStatusIcon = useCallback(
    (prefix: string, status: number) => {
      switch (status) {
        case waitingReviewStatus:
          return prefix.concat('--waiting');
        case doneStatus:
          return prefix.concat('--done');
        case redoStatus:
          return prefix.concat('--redo');
        default:
      }
    },
    [doneStatus, redoStatus, waitingReviewStatus]
  );

  return (
    <div className="status-label-container__status">
      <span
        className={`status-label-container__status__icon${getStatusIcon(
          ' status-label-container__status__icon',
          currentStatus
        )}`}
      ></span>

      {currentStatus === waitingReviewStatus && (
        <p className="status-label-container__status__label status-label-container__status__label--waiting">
          {waitingReviewLabel}
        </p>
      )}

      {currentStatus === doneStatus && (
        <p className="status-label-container__status__label status-label-container__status__label--done">
          {doneLabel}
        </p>
      )}

      {currentStatus === redoStatus && (
        <p className="status-label-container__status__label status-label-container__status__label--redo">
          {redoLabel}
        </p>
      )}
    </div>
  );
};

export default StatusLabel;
