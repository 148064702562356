import React from 'react';

import { Tooltip } from 'antd';

import * as StorageService from '~/services/storage';
import { ProductType } from '@portal/enum/productType';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getUserActionStatusType } from '@portal/enum/userActionType';

import { colors } from '@portal/styles/colors';

interface IProps {
  status?: number;
  count?: number;
}

const SideBarActivityMarker: React.FC<IProps> = ({ status = 0, count = 0 }) => {
  const {
    auth: { me },
  } = useReduxState();

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const isTutor = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;

  const getBackgroundCircle = () => {
    if (isTutor) {
      if (count > 0) {
        return colors?.gray700;
      } else {
        return colors?.white;
      }
    }

    return getUserActionStatusType(status)?.color;
  };

  const getBorderColor = () => {
    if (status) {
      return getUserActionStatusType(status)?.color;
    }

    return colors?.textGray;
  };

  const defaultStyledCircle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '20px',
    height: '20px',
    borderRadius: '100%',
    border: '1px solid',
    color: colors?.white,
    fontWeight: '600',
    cursor: 'pointer',
  };

  const Marker = (
    <div style={{ ...defaultStyledCircle, backgroundColor: getBackgroundCircle(), borderColor: getBorderColor() }}>
      {count > 0 && <span className="sidebar-activity-marker__count">{count}</span>}
    </div>
  );

  const tooltip = (
    <Tooltip placement="topLeft" title={isTutor ? 'Revisar' : getUserActionStatusType(status)?.text}>
      {Marker}
    </Tooltip>
  );

  return tooltip;
};

export default SideBarActivityMarker;
