import { ICourseTestAnswerRepository } from '@portal/models/repositories';

import { getInstance } from './instance';

const courseTestAPI = {
  getCourseTestById: async (testId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course-test/${testId}`);

    return data;
  },

  sendAnswers: async (courseId: string, answers: ICourseTestAnswerRepository[]) => {
    const instance = getInstance();
    const { data } = await instance.put(`/course-test/${courseId}/send-answers`, answers);

    return data;
  },

  getStudentTest: async (testId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course-test/${testId}/student`);

    return data;
  },

  getReviews: async (testId: string, virtualGroupId?: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course-test/${testId}/waiting-review`, { params: { virtualGroupId } });

    return data;
  },

  getHistory: async (testId: string, virtualGroupId?: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course-test/${testId}/history`, { params: { virtualGroupId } });

    return data;
  },

  getAnsweredReviews: async (testId: string, params?: advancedFilterModels.ActivityAnswersFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course-test/${testId}/answers`, {
      params,
    });

    return data;
  },
};
export default courseTestAPI;
