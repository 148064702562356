import * as MessageService from '~/services/message';
import { Constants } from '~/config/env';
import marketingApi from '@portal/repositories/marketing';

const formatUrl = (token: string, pnCode: string, nameSchool: string) => {
  return `${Constants?.portalMarketing?.url}/api/entrar?token=${token}&escola=${pnCode}&cad_escola=${nameSchool}`;
};

const responseFormat = (res: string) => {
  return JSON.parse(res.substring(res.indexOf('{') - 1));
};

export const redirectPortalMarketing =
  (user: models.User, school: models.School, brand: models.BrandConfig, callback: () => void) => async () => {
    try {
      let response;

      const pnCode = school?.schoolBrands?.find((schoolBrand) => schoolBrand?.idBrand === brand?.id)?.pnCode;

      const responseAuth = await marketingApi.authNse();

      if (!user.idMarketing) {
        const responsePost = await marketingApi.addUser(
          {
            nome: user?.name as string,
            login: user?.email as string,
            codigo: pnCode as string,
            escola: school?.name as string,
            email: user?.email as string,
            empresa: brand?.name as string,
            funcao: 'Parceiro',
          },
          responseAuth?.token
        );

        if (typeof responsePost === 'string') {
          response = responseFormat(responsePost);
        } else {
          response = responsePost;
        }

        await marketingApi.addIdMarketing(response?.id);
      } else {
        const responseGet = await marketingApi.getUserById(user?.idMarketing as string, responseAuth?.token);

        if (typeof responseGet === 'string') {
          response = responseFormat(responseGet);
        } else {
          response = responseGet;
        }
      }

      callback();

      window.open(formatUrl(response?.token, pnCode as string, school?.name as string), '_blank');
    } catch (err: any) {
      MessageService.error(err?.response?.data?.message || 'errors.marketing');
    }
  };
