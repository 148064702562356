import React from 'react';

import { ElementType } from 'react';

import { Box, Flex } from '../../atoms';

import { RenderCondition } from '~/utils';

import './CardProduct.less';

interface ICardProduct {
  title?: string;
  logoUrl?: string;
  icon?: ElementType;
  onClick?: () => void;
}

const CardProduct = ({ title = '', logoUrl = '', icon: Icon, onClick }: ICardProduct) => {
  return (
    <Flex className="card-product" onClick={onClick}>
      <RenderCondition condition={!!logoUrl && !Icon}>
        <img className="card-product__image" src={logoUrl || ''} alt="product" />
      </RenderCondition>

      {!!Icon && !logoUrl && (
        <Box className="card-product__icon">
          <Icon />
        </Box>
      )}

      <RenderCondition condition={!!title}>
        <span className="card-product__title">{title}</span>
      </RenderCondition>
    </Flex>
  );
};

export default CardProduct;
