/* eslint-disable max-len */
import React, { useMemo, RefAttributes, useRef } from 'react';
import ReactDOM from 'react-dom';

import JoditEditor, { IJoditEditorProps, Jodit } from 'jodit-react';
import { Button, Upload } from 'antd';

import { useSendFile } from '@portal/hooks';
import { formatUrlVideo } from '@portal/utils';

interface EditorInstance {
  create: {
    fromHTML: (html: string) => HTMLElement;
  };
  e: {
    on: (element: HTMLElement, event: string, callback: (e: Event) => void) => void;
  };
  s: {
    insertHTML: (html: string) => void;
  };
}

interface IEditorHTML extends IJoditEditorProps, RefAttributes<Jodit> {
  size?: 'sm' | 'md';
  isDisabled?: boolean;
  placeholder?: string;
}

const EditorHTML = ({ size = 'md', isDisabled = false, placeholder = '', value, onChange, ...rest }: IEditorHTML) => {
  const { mutate } = useSendFile();

  const buttons = useMemo(
    () => [
      'undo',
      'redo',
      '|',
      'bold',
      'underline',
      'italic',
      '|',
      'superscript',
      'subscript',
      '|',
      'align',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      '|',
      'link',
      'table',
      {
        iconURL: 'https://cdn-icons-png.flaticon.com/512/181/181603.png',
        tooltip: 'Inserir áudio',
        popup: (editor: EditorInstance) => {
          const form = editor.create.fromHTML(
            `
          <form style="max-width: 300px; padding: 5px; background-color: #f9f9f9; border-radius: 8px;">
            <div style="margin-bottom: 10px;">
              <label style="display: block; color: #a5a5a5; font-size: .8em">
                URL
              </label>
              <input 
                type="text" 
                placeholder="https://" 
                style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px;"
              />
            </div>
            <button 
              type="submit" 
              style="
                background-color: #007bff; 
                color: white; 
                padding: 5px 15px; 
                border: none; 
                border-radius: 4px; 
                cursor: pointer;
              "
            >
              Insert
            </button>
          </form>
          `
          );

          editor.e.on(form, 'submit', (e: Event) => {
            e.preventDefault();
            editor.s.insertHTML(`<audio controls src="${form?.querySelector('input')?.value}"></audio>`);
          });

          return form;
        },
      },
      {
        iconURL:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Picture_icon_BLACK.svg/1200px-Picture_icon_BLACK.svg.png',
        tooltip: 'Inserir Imagem',
        popup: (editor: EditorInstance) => {
          const form = editor.create.fromHTML(
            `
          <form  style="max-width: 300px; padding: 5px; background-color: #f9f9f9; border-radius: 8px;">
            <div style="margin-bottom: 10px;" id="upload-container"></div>

            <div style="margin-bottom: 10px;">
              <label style="display: block; color: #a5a5a5; font-size: .8em">
                URL
              </label>
              <input 
                type="text" 
                placeholder="https://" 
                style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px;"
                id="url-input"
              />
            </div>
            <div style="margin-bottom: 10px;">
              <label style="display: block; color: #a5a5a5; font-size: .8em">
                Text alternativo
              </label>
              <input 
                type="text" 
                style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px;"
                id="alt-input"
              />
            </div>
            <button 
              type="submit" 
              style="background-color: #007bff; color: white; padding: 5px 15px; border: none; border-radius: 4px; cursor: pointer;"
            >
              Insert
            </button>
          </form>
          `
          );

          const uploadContainer = form.querySelector('#upload-container');
          const urlInput = form.querySelector('#url-input') as any;
          const altInput = form.querySelector('#alt-input') as any;

          const UploadComponent = () => {
            return (
              <Upload
                name="file"
                showUploadList={false}
                onChange={(e) =>
                  e.file.originFileObj &&
                  mutate(e.file.originFileObj, { onSuccess: (response) => (urlInput.value = response.url) })
                }
                accept="image/*"
              >
                <Button
                  style={{ backgroundColor: '#007bff', color: '#fff', width: '100%', fontWeight: 400 }}
                  htmlType="button"
                >
                  Upload conteúdo
                </Button>
              </Upload>
            );
          };

          const uploadElement = document.createElement('div');

          uploadContainer?.appendChild(uploadElement);
          ReactDOM.render(<UploadComponent />, uploadElement);

          editor.e.on(form, 'submit', (e: Event) => {
            e.preventDefault();
            editor.s.insertHTML(`<img alt="${altInput?.value}" src="${urlInput?.value}"></img>`);
          });

          return form;
        },
      },
      '|',
      'eraser',
      'fullsize',
      'selectall',
      '|',
      'cut',
      'copy',
      'paste',
    ],
    []
  );

  const config: IJoditEditorProps['config'] = useMemo(
    () => ({
      buttons: buttons,
      style: { minHeight: size === 'sm' ? 200 : 400 },
      readonly: isDisabled,
      activeButtonsInReadOnly: [],
      allowCommandsInReadOnly: [],
      language: 'pt_br',
      placeholder: placeholder || '',
      disablePlugins: 'add-new-line',
      removeButtons: [
        'strikethrough',
        'outdent',
        'indent',
        'font',
        'brush',
        'paragraph',
        'file',
        'hr',
        'copyformat',
        'preview',
        'print',
        'find',
        'ai-assistant',
        'ai-commands',
        'speechRecognize',
        'spellcheck',
      ],
    }),
    [buttons, size, placeholder, isDisabled]
  );

  return <JoditEditor value={value} onBlur={onChange} config={config} {...rest} />;
};

export default EditorHTML;
