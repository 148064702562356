import {
  GROUPS_ALL,
  GROUPS_BY_ID,
  GROUPS_BY_TUTOR,
  GROUPS_SCHEDULE_BY_ID,
  GROUPS_STATUS_ELECTIVE,
  GROUPS_STUDENTS,
  SET_GROUP_DETAILS,
  UPDATE_GROUP,
  UPDATE_GROUP_SCHEDULE,
} from '~/actions/actionTypes';

const initialState: reducers.GroupReducer = {
  groupsAll: {
    rows: [],
    count: 0,
  },
  groupsByTutor: {
    rows: [],
    count: 0,
  },
  students: null,
  details: null,
  group: null,
  statusElective: null,
  updateGroup: null,
  groupSchedule: null,
  updateGroupSchedule: null,
};

const groupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GROUPS_ALL:
      state = {
        ...state,
        groupsAll: action.payload,
      };
      break;

    case SET_GROUP_DETAILS:
      state = {
        ...state,
        group: action.payload,
      };
      break;

    case GROUPS_BY_TUTOR:
      state = {
        ...state,
        groupsByTutor: action.payload,
      };
      break;

    case GROUPS_STATUS_ELECTIVE:
      state = {
        ...state,
        statusElective: action.payload,
      };
      break;

    case GROUPS_STUDENTS:
      state = {
        ...state,
        students: action.payload,
      };
      break;

    case GROUPS_BY_ID:
      state = {
        ...state,
        details: action.payload,
      };
      break;

    case UPDATE_GROUP:
      state = {
        ...state,
        updateGroup: action.payload,
      };
      break;

    case GROUPS_SCHEDULE_BY_ID:
      state = {
        ...state,
        groupSchedule: action.payload,
      };
      break;

    case UPDATE_GROUP_SCHEDULE:
      state = {
        ...state,
        updateGroupSchedule: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default groupReducer;
