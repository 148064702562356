import {
  ADD_CHAT_MESSAGES,
  CHAT_MESSAGES,
  CHAT_PARTICIPANTS,
  HAS_SEARCH,
  PRIVATE_CHATS,
  SEARCH_CHAT,
} from '~/actions/actionTypes';

const initialState: reducers.ChatReducer = {
  chats: [],
  messages: [],
  searchedChats: [],
  hasSearch: false,
  chatParticipants: {} as models.ChatParticipants,
};

const chatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PRIVATE_CHATS:
      state = {
        ...state,
        chats: action.payload,
      };
      break;

    case CHAT_MESSAGES:
      if (state.messages.length === action.payload.length) {
        break;
      }
      state = {
        ...state,
        messages: action.payload,
      };
      break;

    case ADD_CHAT_MESSAGES:
      state = {
        ...state,
        messages: [...state.messages, action.payload as models.Message],
      };
      break;

    case SEARCH_CHAT:
      state = {
        ...state,
        searchedChats: action.payload,
      };
      break;

    case HAS_SEARCH:
      state = {
        ...state,
        hasSearch: action.payload,
      };
      break;

    case CHAT_PARTICIPANTS:
      state = {
        ...state,
        chatParticipants: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default chatReducer;
