import React, { ComponentProps } from 'react';

export const IcChat = (rest: ComponentProps<'svg'>) => {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M13.448 0H1.92114C1.41162 0 0.922974 0.202406 0.56269 0.56269C0.202406 0.922974 0 1.41162 0 1.92114V10.5663C0 11.0758 0.202406 11.5645 0.56269 11.9247C0.922974 12.285 1.41162 12.4874 1.92114 12.4874H3.89992L1.92114 15.3691L7.68457 12.4874H13.448C13.9575 12.4874 14.4462 12.285 14.8065 11.9247C15.1667 11.5645 15.3691 11.0758 15.3691 10.5663V1.92114C15.3691 1.41162 15.1667 0.922974 14.8065 0.56269C14.4462 0.202406 13.9575 0 13.448 0ZM12.4874 9.60571H2.88171V8.64514H12.4874V9.60571ZM12.4874 6.724H2.88171V5.76343H12.4874V6.724ZM12.4874 3.84229H2.88171V2.88171H12.4874V3.84229Z"
        fill="currentColor"
      />
    </svg>
  );
};
