import { Dispatch } from 'redux';

import CourseRequests from '@portal/repositories/courseApi';
import { getCookie, setCookie } from '@portal/services/cookie';
import * as MessageService from '~/services/message';

import {
  ALL_COURSES,
  ALL_TAGS,
  CONCLUDED_COURSE,
  COURSE_DETAILS,
  COURSE_DETAILS_USERS,
  COURSE_KNOWLEDGE_AREA_FILTER_TAGS,
  COURSE_MINUTES_PROGRESS,
  COURSE_PENDING_ACTIVITIES,
  COURSE_PROGRESS,
  COURSE_TAG,
  COURSE_THEMATIC_FILTER_TAGS,
  MINE_COURSE,
  OTHER_COURSE,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

import { Constants } from '@portal/config/env';
import { COURSE_FILTER_TYPE } from '../enum/courseFilterType';

export const getAllCourses =
  (searchParams: advancedFilterModels.MineCourses, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.PaginationResponse<models.CourseList> = await CourseRequests.getAllCourses({
        ...searchParams,
        tagIdList: searchParams.tagIdList,
        offset: searchParams.offset > 0 ? searchParams.offset - 1 : searchParams.offset,
        limit: searchParams.limit,
        isDESC: searchParams.orderBy === 'desc' ? 'true' : 'false',
      });
      dispatch({
        payload,
        type: ALL_COURSES,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getMineCourses =
  (searchParams: advancedFilterModels.MineCourses, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.PaginationResponse<models.CourseList> = await CourseRequests.getMineCourses({
        ...searchParams,
        tagIdList: searchParams.tagIdList,
        offset: searchParams.offset > 0 ? searchParams.offset - 1 : searchParams.offset,
        limit: searchParams.limit,
        isDESC: searchParams.orderBy === 'desc' ? 'true' : 'false',
      });
      dispatch({
        payload,
        type: MINE_COURSE,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getOtherCourses =
  (searchParams: advancedFilterModels.OtherCourses, isLocalLoading?: boolean, studentEnrollmentPermit?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      searchParams = {
        ...searchParams,
        tagIdList: searchParams.tagIdList,
        offset: searchParams.offset > 0 ? searchParams.offset - 1 : searchParams.offset,
        limit: searchParams.limit,
        isDESC: searchParams.orderBy === 'desc' ? 'true' : 'false',
      };

      if (studentEnrollmentPermit && typeof studentEnrollmentPermit === 'string') {
        searchParams = {
          ...searchParams,
          studentEnrollmentPermit,
        };
      }

      const payload: models.OtherCoursesPaginated<models.CourseList> = await CourseRequests.getOtherCourses(
        searchParams
      );
      dispatch({
        payload,
        type: OTHER_COURSE,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getConcludedCourses =
  (searchParams: advancedFilterModels.ConcludedCourses, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.OtherCoursesPaginated<models.CourseList> = await CourseRequests.getConcludedCourses({
        ...searchParams,
        tagIdList: searchParams.tagIdList,
        offset: searchParams.offset > 0 ? searchParams.offset - 1 : searchParams.offset,
        limit: searchParams.limit,
        isDESC: searchParams.orderBy === 'desc' ? 'true' : 'false',
      });
      dispatch({
        payload,
        type: CONCLUDED_COURSE,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getCourseDetails =
  (id: string, schoolId?: string, productId?: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.CourseDetails = await CourseRequests.getCourseDetails(id, {
        schoolId,
        productId,
      });
      dispatch({
        payload,
        type: COURSE_DETAILS,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getCourseUsers = (id: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload = await CourseRequests.getCourseUsers(id);
    dispatch({
      payload,
      type: COURSE_DETAILS_USERS,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getCourseContentDetails =
  (id: string, productId: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.CourseDetails = await CourseRequests.getCourseDetails(id, {
        productId,
      });
      dispatch({
        payload,
        type: COURSE_DETAILS,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getUserMinutesProgress = (id: string[], isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload: models.CourseDetails = await CourseRequests.getUserMinutesProgress(id);
    dispatch({
      payload,
      type: COURSE_MINUTES_PROGRESS,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getCourseProgress =
  (params: { idCourse: string; isTutor?: boolean; isLocalLoading?: boolean }) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!params.isLocalLoading));
    try {
      const virtualGroupId = getCookie('virtual-group-id') ?? '';

      const payload: models.CourseProgress = await CourseRequests.getCourseProgress(params.idCourse, params.isTutor, {
        virtualGroupId,
      });

      dispatch({
        payload,
        type: COURSE_PROGRESS,
      });

      if (!params.isTutor && payload?.virtualGroup?.[0]?.id) {
        setCookie('virtual-group-id', payload?.virtualGroup?.[0]?.id, Constants.brandCookieDomain as string);
      }
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!params.isLocalLoading));
    }
  };

export const subscribeUserOnCourse =
  (
    idCourse: string,
    brandProductId?: string,
    options?: { onSuccess?: () => void; onError?: () => void; isLoacalLoading?: boolean }
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!options?.isLoacalLoading));
    try {
      await CourseRequests.accessCourse(idCourse, brandProductId || '');

      options?.onSuccess?.();
    } catch (err) {
      options?.onError?.();

      if (err?.response?.data?.error?.includes('enrollment_date_expired')) {
        MessageService.error('Período de matrículas encerrado!');

        return;
      }

      MessageService.error('Nenhum grupo disponível!');
    } finally {
      dispatch(decreaseLoading(!!options?.isLoacalLoading));
    }
  };

export const getThematicFilterTags = (isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const { rows: thematic }: models.OtherCoursesPaginated<models.Tag> = await CourseRequests.getFilterTags({
      type: COURSE_FILTER_TYPE.thematicBase,
      limit: 1000,
    });

    dispatch({
      payload: thematic,
      type: COURSE_THEMATIC_FILTER_TAGS,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getKnowledgeFilterTags = (isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const { rows: knowledgeArea }: models.OtherCoursesPaginated<models.Tag> = await CourseRequests.getFilterTags({
      type: COURSE_FILTER_TYPE.knowledgeArea,
      limit: 10,
    });

    dispatch({
      payload: knowledgeArea,
      type: COURSE_KNOWLEDGE_AREA_FILTER_TAGS,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getPendingActivities = () => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.CourseProgress = await CourseRequests.getPendingActivities();

    dispatch({
      payload,
      type: COURSE_PENDING_ACTIVITIES,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getAllTags = (isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload: models.PaginationResponse<models.Tag> = await CourseRequests.getFilterTags({
      limit: Number.MAX_SAFE_INTEGER,
    });

    dispatch({
      payload,
      type: ALL_TAGS,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getTagById = (id: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload: models.CourseDetails = await CourseRequests.getTagById(id);
    dispatch({
      payload,
      type: COURSE_TAG,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const subscribeTeacherOnCourse =
  (
    idCourse: string,
    brandProductId: string,
    schoolId: string,
    callback?: () => void,
    callAfterRequest?: boolean,
    isLocalLoading?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      await CourseRequests.addTeacher(idCourse, brandProductId, schoolId || '')
        .then(() => {
          if (callAfterRequest && typeof callback === 'function') {
            callback();
          }
        })
        .catch((err) => {
          MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
        });

      if (!callAfterRequest && typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
