import { ApplicationType } from '@portal/enum/applicationType';

import { getInstance } from './instance';

const notificationAPI = {
  getNotifications: async () => {
    const instance = getInstance();
    const { data } = await instance.get('/user-push-notification', {
      params: {
        applicationType: ApplicationType.LMS,
      },
    });

    return data;
  },
  setAllRead: async () => {
    const instance = getInstance();
    const { data } = await instance.post('/user-push-notification/mark-as-seen', {
      applicationType: ApplicationType.LMS,
    });

    return data;
  },
  getUnreadNotificationsCount: async () => {
    const instance = getInstance();
    const { data } = await instance.get('/user-push-notification/unviewed-count', {
      params: {
        applicationType: ApplicationType.LMS,
      },
    });

    return data;
  },
};

export default notificationAPI;
