import React, { ComponentProps } from 'react';

import classnames from 'classnames';

import Flex from '../Flex';

import './Avatar.less';

interface IAvatar extends ComponentProps<'div'> {
  imageUrl?: string;
  variant?: 'primary' | 'secondary';
  size?: 'lg' | 'md' | 'sm' | 'xs';
}

const Avatar = ({ imageUrl = '', variant = 'primary', size = 'md', className, ...rest }: IAvatar) => {
  const classNameDefault = 'atoms-avatar';

  const classNameSize = `${classNameDefault}--${size}`;
  const classNameVariant = `${classNameDefault}--${variant}`;

  return (
    <Flex
      {...rest}
      className={classnames(className, classNameDefault, classNameSize, classNameVariant)}
      style={{ backgroundImage: imageUrl && `url(${imageUrl})`, ...rest.style }}
    />
  );
};

export default Avatar;
