/* eslint-disable no-unused-vars */
export enum YearType {
  // -=-=-= Educação Infantil -=-=-=-
  TWOYEARS = 2,
  THREEYEARS = 3,
  FOURYEARS = 4,
  FIVEYEARS = 5,

  // -=-=-= Ensino fundamental 1 -=-=-=-
  FIRSTYEAR = 15,
  SECONDYEAR = 16,
  THIRDYEAR = 17,
  FOURTHYEAR = 18,
  FIFTHYEAR = 19,

  // -=-=-= Ensino fundamental 2 -=-=-=-
  SIXTHYEAR = 11,
  SEVENTHYEAR = 12,
  EIGHTHYEAR = 13,
  NINTHYEAR = 14,

  // -=-=-= Ensino médio -=-=-=-
  FIRSTGRADE = 6,
  SECONDGRADE = 7,
  THIRDGRADE = 8,
}

export const isFundTwo = (value?: number) => {
  return !!value && [YearType.SIXTHYEAR, YearType.SEVENTHYEAR, YearType.EIGHTHYEAR, YearType.NINTHYEAR].includes(value);
};

export const isHighSchool = (value?: number) => {
  return !!value && [YearType.FIRSTGRADE, YearType.SECONDGRADE, YearType.THIRDGRADE].includes(value);
};

export const underThirteen = (value?: number) => {
  return (
    !!value &&
    [
      YearType.TWOYEARS,
      YearType.THREEYEARS,
      YearType.FOURYEARS,
      YearType.FIVEYEARS,
      YearType.FIRSTYEAR,
      YearType.SECONDYEAR,
      YearType.THIRDYEAR,
      YearType.FOURTHYEAR,
      YearType.FIFTHYEAR,
      YearType.SIXTHYEAR,
      YearType.SEVENTHYEAR,
    ].includes(value)
  );
};
