/* eslint-disable max-len */
import { SendOutlined } from '@ant-design/icons';
import CircularProgress from '@mui/material/CircularProgress';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import theme from '@portal/config/theme';
import { translate } from '@portal/services/i18n';
import utilsAPI from '@portal/repositories/utils';
import { decreaseLoading, increaseLoading } from '@portal/actions/loading';
import imageIcon from '@portal/assets/svg/chat/ic_image.svg';
import reloadIcon from '@portal/assets/svg/chat/ic_reload.svg';
import closeIcon from '@portal/assets/svg/chat/ic_close.svg';

interface IProps {
  onSubmit: (message?: string, photoUrl?: string) => void;
  handleReload?: () => void;
}

const InputChat: React.FC<IProps> = ({ onSubmit, handleReload }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const isLocalLoading = useSelector((state: reducers.rootReducer) => state.loading.localAmount);

  const handleSelectImage = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) return;

    const file = files[0];

    setSelectedFile(file);
  }, []);

  const handleSubmit = useCallback(async () => {
    dispatch(increaseLoading(true));
    if (!selectedFile && value.trim() === '') return;
    setSelectedFile(undefined);
    setValue('');
    let response;
    if (selectedFile) {
      response = await utilsAPI.sendFile(selectedFile);
    }

    onSubmit(value, response?.url);
    dispatch(decreaseLoading(true));
  }, [dispatch, onSubmit, selectedFile, value]);

  return (
    <>
      <div className="m-0 input-chat">
        {selectedFile && (
          <Row className="mx-1 mx-sm-4 input-chat__file-container">
            <button className="input-chat__file-container__file-content" onClick={() => setSelectedFile(undefined)}>
              <img className="input-chat__file-container__file-content__icon" src={closeIcon} alt="remove file" />
            </button>
            <p>{selectedFile.name}</p>
          </Row>
        )}
        <Row>
          {handleReload && (
            <button onClick={handleReload} className="input-chat__button-refresh">
              <img src={reloadIcon} alt="reload icon" />
            </button>
          )}
          <Col className="m-0 p-0">
            <Row className="mx-1 mx-sm-4 input-chat__input-container">
              <textarea
                placeholder={translate('PAGES.CHAT.INPUT.TYPE_HERE')}
                className="input-chat__input-container__input"
                value={value}
                rows={2}
                wrap="hard"
                onChange={(event) => setValue(event.target.value)}
              />

              <div className="input-chat__input-container__buttons">
                <label htmlFor="image-picker" className="input-chat__input-container__buttons__button">
                  <img className="input-chat__icon" src={imageIcon} alt="import icon" />
                  <input
                    type="file"
                    id="image-picker"
                    onChange={handleSelectImage}
                    accept=".jpg, .png, .jpeg"
                    className="input-chat__input-container__buttons__button__file-picker"
                  />
                </label>
                <button
                  className="mr-2 d-flex align-items-center justify-content-center input-chat__input-container__input__send-icon"
                  onClick={handleSubmit}
                  disabled={value.trim() === '' && !selectedFile}
                >
                  {isLocalLoading > 0 ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : (
                    <SendOutlined
                      style={{
                        color:
                          value.trim() === '' && !selectedFile
                            ? theme['@text-gray']
                            : document.documentElement.style.getPropertyValue('@primary-color-lms'),
                      }}
                    />
                  )}
                </button>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InputChat;
