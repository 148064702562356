import { QUESTION_TYPE } from '@portal/enum/questionType';

export function isFileDownloadType(questionType: number | undefined) {
  return (
    questionType === QUESTION_TYPE.FILE_UPLOAD_AND_DOWNLOAD ||
    questionType === QUESTION_TYPE.DISCURSIVE_FILE_DOWNLOAD ||
    questionType === QUESTION_TYPE.FILE_UPLOAD ||
    questionType === QUESTION_TYPE.DISCURSIVE_AND_FILE_UPLOAD
  );
}
