import React from 'react';

import { Banner, NavbarLMS, Navbar } from '@portal/components';
import { useReduxState } from '@portal/hooks';
import { arrayAdminProfiles } from '@portal/enum';
import useWindowSize from '@portal/utils/getSizeWindow';

interface IProps {
  top?: boolean;
  banner?: boolean;
  navbar?: boolean;
  ComponentWrapped: React.ReactNode;
}

const TopBottomDefaultTemplate: React.FC<IProps> = ({ top, banner, navbar, ComponentWrapped }) => {
  const isMobile = useWindowSize();

  const {
    auth: { me },
  } = useReduxState();

  const isAdmin = me?.profileType && arrayAdminProfiles.includes(me?.profileType);

  return (
    <>
      {top && <Navbar />}
      <div style={{ width: '100%', ...(isAdmin && { paddingTop: isMobile ? '4rem' : '4.5rem' }) }}>
        {banner && <Banner />}
        {navbar && <NavbarLMS banner={banner} />}
        {ComponentWrapped}
      </div>
    </>
  );
};

export default TopBottomDefaultTemplate;
