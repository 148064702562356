export const TasksStatus = {
  NOT_AVAILABLE: 1,
  TODO: 2,
  WAITING_REVIEW: 3,
  DONE: 4,
  REDO: 5,
};

export const TasksStatusArray = [
  { id: TasksStatus.TODO, name: 'A fazer' },
  { id: TasksStatus.DONE, name: 'Concluído' },
  { id: TasksStatus.WAITING_REVIEW, name: 'Aguardando revisão' },
  { id: TasksStatus.REDO, name: 'Refazendo' },
];

export const getStatusTask = (value: string | number): string | undefined => {
  return TasksStatusArray.find((x) => x.id === value)?.name;
};
