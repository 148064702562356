import React from 'react';
import { Avatar } from 'antd';

import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import { useReduxState } from '@portal/hooks/useReduxState';
import { showFormattedDate } from '@portal/utils/format';
import { translate } from '@portal/services/i18n';

const PopoverNotification: React.FC = () => {
  const {
    brand: { brandConfig },
    homeNotification: { homeNotifications },
  } = useReduxState();

  return (
    <div className="popover-notification mt-2">
      {homeNotifications?.length > 0 &&
        homeNotifications.map((notification) => (
          <div className="mb-5">
            <div key={notification.id} className="d-flex">
              <Avatar className="mr-3" src={notification.productIconUrl || ''} />
              <RenderHTML htmlText={notification.title || ''} />
            </div>
            <div className="d-flex justify-content-end">
              <span className="popover-notification__date">
                {showFormattedDate(notification.createdAt || '', 'dd/MM/yyyy') || ''}
              </span>
            </div>
          </div>
        ))}

      {homeNotifications && homeNotifications.length === 0 && (
        <>
          <div className="d-flex  justify-content-around align-items-center">
            <Avatar src={brandConfig?.logoIconUrl || ''} />
            <span className="popover-notification__empty-label">
              {translate('COMPONENTS.NOTIFICATION_POPOVER.EMPTY_NOTIFICATION')}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default PopoverNotification;
