import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { GroupActions } from '@portal/actions';

import { Btn } from '@portal/components';

import { translate } from '@portal/services/i18n';
import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as StorageService from '~/services/storage';

interface IProps {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseGroupModal: React.FC<IProps> = ({ openModal, closeModal }) => {
  const paramns: { id: string } = useParams();
  const dispatch = useDispatch();
  const { group } = useReduxState();

  const brandProductId = StorageService.getItem('brandProductsConfig')?.id;

  const callBack = () => {
    window.location.reload();
    closeModal(false);
  };

  const groupInfos = group?.details;

  return (
    <Modal
      visible={openModal}
      onCancel={() => closeModal(false)}
      bodyStyle={{ padding: '0 0 2rem 0' }}
      footer={null}
      style={{ top: 20 }}
      width={750}
    >
      <div>
        <Row>
          <img
            src={groupInfos?.course?.photoUrl || EmptyBanner}
            alt="empty banner"
            width="100%"
            className="groups-page__banner-modal"
          />
        </Row>

        <Col className="modal-close-group__spacing mt-4 pl-5 pt-2">
          <Row>
            <span className="modal-close-group__title__bold">
              {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.TITLE')}
            </span>
          </Row>

          <Row className="pt-5">
            <span className="modal-close-group__subtitle">
              <span className="modal-close-group__subtitle__bold">
                {translate('PAGES.GROUPS.GROUPS_PAGE.SUBTITLE')}
              </span>
              {groupInfos?.name}
            </span>
          </Row>
        </Col>

        <div className="modal-close-group__container">
          <span className="modal-close-group__title__color d-flex align-items-center mb-4">
            <span className="mr-2 d-flex align-items-center">
              <WarningOutlined />
            </span>
            {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.SUBTITLE')}
          </span>

          <p>{translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.DESCRIPTION.PART_1')}</p>
          <p>{translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.DESCRIPTION.PART_2')}</p>
          <p>{translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.DESCRIPTION.PART_3')}</p>
          <p>{translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.DESCRIPTION.PART_4')}</p>
        </div>
      </div>

      <Row className="pt-5">
        <Col className="modal-close-group__buttons">
          <span className="modal-close-group__subtitle__color text-align mr-5 mb-2">
            {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.CLOSE_QUESTION')}
          </span>
          <Row className="modal-close-group__gap btn-responsive mr-5 mt-2 mb-4">
            <Btn
              variant="primary"
              onClick={() => dispatch(GroupActions.closeGroup(paramns.id, brandProductId, callBack))}
            >
              {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.BUTTON.YES')}
            </Btn>
            <Btn variant="secondary" onClick={() => closeModal(false)}>
              {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_CLOSE.BUTTON.NO')}
            </Btn>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default CloseGroupModal;
