import React from 'react';

import { Divider, Popover, Typography } from 'antd';

import { Box } from '~/components';

import { RenderCondition } from '~/utils';
import { translate } from '~/services';
import { useReduxState } from '~/hooks';
import { Constants } from '@portal/config/env';

import './PopoverSchoolManagement.less';
import { ProfileType } from '@portal/enum';

interface PropsPopoverSchoolManagement {
  children: React.ReactElement;
}

const PopoverSchoolManagement = ({ children }: PropsPopoverSchoolManagement) => {
  const {
    auth: { me },
    brand: { brandConfig },
  } = useReduxState();

  const options = [
    {
      title: translate('COMPONENTS.POPOVER_SCHOOL_MANAGEMENT.USERS'),
      link: `${Constants.dashboardUrl}/users`,
    },
    {
      title: translate('COMPONENTS.POPOVER_SCHOOL_MANAGEMENT.CLASSES'),
      link: `${Constants.dashboardUrl}/classes`,
    },
    {
      title: translate('COMPONENTS.POPOVER_SCHOOL_MANAGEMENT.ADVISER_AREA'),
      action: () => `${Constants.dashboardUrl}/adviser-area`,
      permissions: [ProfileType.ADVISER],
    },
  ];

  return (
    <Popover
      placement="bottom"
      content={
        <Box className="popover-management">
          {options
            ?.filter(
              (option) => option.permissions?.includes(me?.profileType as ProfileType) || !option.permissions?.length
            )
            ?.map((option, key) => (
              <a key={key} href={option.link}>
                <RenderCondition condition={key !== 0}>
                  <Divider />
                </RenderCondition>
                <Typography className="popover-management__text" style={{ color: brandConfig?.primaryColor }}>
                  {option.title}
                </Typography>
              </a>
            ))}
        </Box>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default PopoverSchoolManagement;
