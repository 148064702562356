/* eslint-disable max-len */
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IcAudio = (props: any) => (
  <svg
    {...props}
    viewBox="0 0 87 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.3422 2.31494H6.54596C4.80987 2.31494 3.14487 3.0046 1.91726 4.23221C0.689657 5.45981 0 7.12481 0 8.86091V102.624C0 104.36 0.689657 106.025 1.91726 107.252C3.14487 108.48 4.80987 109.17 6.54596 109.17H79.9427C81.6787 109.17 83.3438 108.48 84.5714 107.252C85.799 106.025 86.4886 104.36 86.4886 102.624V26.5841L62.3422 2.31494Z"
      fill="#39475C"
    />
    <path
      d="M86.4559 41.1161V24.6694L62.3422 0.555664H6.54596C4.80987 0.555664 3.14487 1.24532 1.91726 2.47293C0.689657 3.70053 0 5.36553 0 7.10163V41.1488L86.4559 41.1161Z"
      fill="#F08597"
    />
    <path
      d="M0.0327148 41.1157V100.234C0.0327148 101.97 0.722372 103.635 1.94998 104.863C3.17759 106.09 4.84258 106.78 6.57868 106.78H79.9753C81.7114 106.78 83.3764 106.09 84.604 104.863C85.8316 103.635 86.5212 101.97 86.5212 100.234V41.1157H0.0327148Z"
      fill="#EDEDED"
    />

    <path
      d="M62.375 0.555664V20.3899C62.3793 21.5214 62.8307 22.6053 63.6307 23.4054C64.4308 24.2055 65.5147 24.6569 66.6462 24.6612H86.4805L62.375 0.555664Z"
      fill="#F3A1AC"
    />
    <path
      d="M0.0327148 41.1157H86.464"
      stroke="white"
      strokeWidth="0.66"
      strokeMiterlimit="10"
    />
    <path
      d="M57.4656 57.3418L34.0229 62.2513V81.7091C32.5078 81.1148 30.83 81.0829 29.2934 81.6191C26.1186 82.6583 24.2121 85.514 25.0304 88.0096C25.8486 90.5053 29.1216 91.6754 32.2472 90.6362C34.972 89.7443 36.764 87.5023 36.6904 85.3012H36.7394V66.1052L54.7408 62.3331V78.8535C53.2344 78.2705 51.5699 78.2415 50.0441 78.7717C46.8775 79.8108 44.971 82.6746 45.7893 85.1703C46.6075 87.6659 49.8313 88.836 53.0061 87.7969C55.6409 86.9295 57.4002 84.7939 57.4411 82.6665L57.4656 57.3418Z"
      fill="white"
    />
    <path
      d="M57.4656 56.3682L34.0229 61.2776V80.7355C32.5078 80.1411 30.83 80.1092 29.2934 80.6455C26.1186 81.6847 24.2121 84.5485 25.0304 87.036C25.8486 89.5235 29.1216 90.7018 32.2472 89.6626C34.972 88.7707 36.764 86.5287 36.6904 84.3358H36.7394V65.1397L54.7408 61.3676V77.8716C53.2326 77.2956 51.5696 77.2695 50.0441 77.798C46.8775 78.8372 44.971 81.6929 45.7893 84.1885C46.6075 86.6842 49.8313 87.8542 53.0061 86.8151C55.6409 85.9477 57.4002 83.8121 57.4411 81.6847L57.4656 56.3682Z"
      fill="#B6B4C0"
    />
  </svg>
);

export default IcAudio;
