import React, { ComponentProps } from 'react';

import classNames from 'classnames';

import './Grid.less';

const Grid = ({ children, className, ...rest }: ComponentProps<'div'>) => {
  return (
    <div {...rest} className={classNames(className, 'atoms-grid')}>
      {children}
    </div>
  );
};

export default Grid;
