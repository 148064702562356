import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import { Row } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactPlayer from 'react-player';

import { Btn } from '@portal/components';
import { translate } from '@portal/services';

interface IVideoModal {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  cardData?: models.IVideoModal;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  currentIndex: number;
  lengthData: number;
}

const VideoModal: React.FC<IVideoModal> = ({
  openModal,
  closeModal,
  cardData,
  setCurrentIndex,
  currentIndex,
  lengthData,
}) => {
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  useEffect(() => {
    currentIndex === 0 ? setDisablePrevious(true) : setDisablePrevious(false);
    currentIndex === lengthData - 1 ? setDisableNext(true) : setDisableNext(false);
  }, [currentIndex]);

  return (
    <Modal
      visible={openModal}
      onCancel={() => closeModal(false)}
      footer={null}
      style={{ top: 50 }}
      width={750}
      centered
    >
      <Row className="video-content">
        <div className="video-content__container">
          <ReactPlayer
            url={cardData?.url}
            controls={true}
            playing={false}
            loop={true}
            muted={true}
            volume={0}
            className="video-content__container__react-player"
          />
        </div>

        <div className="video-content__text-container mt-1">
          <h2 className="font-weight-bold">{cardData?.title}</h2>
          <span>{cardData?.description}</span>
        </div>

        <div className="video-content__buttons-area mt-4 mb-4">
          <Btn variant="secondary" disabled={disablePrevious} onClick={() => setCurrentIndex(currentIndex - 1)}>
            {translate('PAGES.GROUPS.GROUPS_VIDEO.VIDEO_MODAL.PREVIOUS')}
          </Btn>
          <Btn variant="primary" disabled={disableNext} onClick={() => setCurrentIndex(currentIndex + 1)}>
            {translate('PAGES.GROUPS.GROUPS_VIDEO.VIDEO_MODAL.NEXT')}
          </Btn>
        </div>
      </Row>
    </Modal>
  );
};

export default VideoModal;
