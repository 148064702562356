import { apisLocalhost, apiUrls } from './apiUrls';

export const Constants: models.Constants = (() => {
  let constants: models.Constants = {};
  const { backNew, backOld, backJourney, backBffContentLib } = apisLocalhost();

  const hostname = window?.location?.hostname;

  const domain = hostname?.split('.')[0];

  const isLocalhost = domain === 'localhost';
  const brandDomainLocal = process.env.REACT_APP_BRAND_DOMAIN;

  const brandName = isLocalhost ? brandDomainLocal?.split('.')[0] : hostname?.split('.')[1];
  const brandDomain = isLocalhost ? brandDomainLocal : hostname?.split('.').slice(1).join('.');
  const brandCookieDomain = `.${brandDomain}`;

  const replaceBrandName = (url?: string) => {
    return url?.replace('brandName', brandName as string) ?? '';
  };

  switch (domain) {
    case 'localhost':
      constants = {
        backNew,
        backOld,
        backJourney,
        backBffContentLib,

        dashboardUrl: process.env.REACT_APP_DASHBOARD_BASE_URL_LOCAL,
        dashboardAppUrl: process.env.REACT_APP_DASHBOARD_APP_LOCALHOST,
        dashboardAngularUrl: process.env.REACT_APP_ANGULAR_BASE_URL_LOCAL,

        portalUrl: process.env.REACT_APP_PORTAL_BASE_URL_LOCAL,
        pijUrl: process.env.REACT_APP_PIJ_BASE_URL_LOCAL,
        lmsUrl: process.env.REACT_APP_LMS_BASE_URL_LOCAL,
        libUrl: process.env.REACT_APP_LIB_BASE_URL_LOCAL,
        journeyUrl: process.env.REACT_APP_JOURNEY_URL_LOCAL,

        brandCookieDomain: 'localhost',
      };
      break;

    case 'lmsdev':
      constants = {
        backNew: apiUrls.devApiNew,
        backOld: apiUrls.devApiOld,
        backJourney: apiUrls.devApiJourney,
        backBffContentLib: apiUrls.devApiBffContentLib,

        dashboardUrl: replaceBrandName(process.env.REACT_APP_DASHBOARD_BASE_URL_DEV),
        dashboardAngularUrl: replaceBrandName(process.env.REACT_APP_ANGULAR_BASE_URL_DEV),
        dashboardAppUrl: replaceBrandName(process.env.REACT_APP_DASHBOARD_APP_DEV),

        portalUrl: replaceBrandName(process.env.REACT_APP_PORTAL_BASE_URL_DEV),
        pijUrl: replaceBrandName(process.env.REACT_APP_PIJ_BASE_URL_DEV),
        lmsUrl: replaceBrandName(process.env.REACT_APP_LMS_BASE_URL_DEV),
        libUrl: replaceBrandName(process.env.REACT_APP_LIB_BASE_URL_DEV),
        journeyUrl: replaceBrandName(process.env.REACT_APP_JOURNEY_URL_DEV),

        brandCookieDomain,
      };
      break;

    case 'lmshmg':
      constants = {
        backNew: apiUrls.hmgApiNew,
        backOld: apiUrls.hmgApiOld,
        backJourney: apiUrls.hmgApiJourney,
        backBffContentLib: apiUrls.hmgApiBffContentLib,

        dashboardUrl: replaceBrandName(process.env.REACT_APP_DASHBOARD_BASE_URL_HMG),
        dashboardAngularUrl: replaceBrandName(process.env.REACT_APP_ANGULAR_BASE_URL_HOMOLOG),
        dashboardAppUrl: replaceBrandName(process.env.REACT_APP_DASHBOARD_APP_HOMOLOG),

        portalUrl: replaceBrandName(process.env.REACT_APP_PORTAL_BASE_URL_HOMOLOG),
        pijUrl: replaceBrandName(process.env.REACT_APP_PIJ_BASE_URL_HMG),
        lmsUrl: replaceBrandName(process.env.REACT_APP_LMS_BASE_URL_HMG),
        libUrl: replaceBrandName(process.env.REACT_APP_LIB_BASE_URL_HMG),
        journeyUrl: replaceBrandName(process.env.REACT_APP_JOURNEY_URL_HOMOLOG),

        brandCookieDomain,
      };
      break;

    case 'lms':
      constants = {
        backNew: apiUrls.prodApiNew,
        backOld: apiUrls.prodApiOld,
        backJourney: apiUrls.prodApiJourney,
        backBffContentLib: apiUrls.prodApiBffContentLib,

        dashboardUrl: replaceBrandName(process.env.REACT_APP_DASHBOARD_BASE_URL_PROD),
        dashboardAngularUrl: replaceBrandName(process.env.REACT_APP_ANGULAR_BASE_URL_PROD),
        dashboardAppUrl: replaceBrandName(process.env.REACT_APP_DASHBOARD_APP_PROD),

        portalUrl: replaceBrandName(process.env.REACT_APP_PORTAL_BASE_URL_PROD),
        pijUrl: replaceBrandName(process.env.REACT_APP_PIJ_BASE_URL_PROD),
        lmsUrl: replaceBrandName(process.env.REACT_APP_LMS_BASE_URL_PROD),
        libUrl: replaceBrandName(process.env.REACT_APP_LIB_BASE_URL_PROD),
        journeyUrl: replaceBrandName(process.env.REACT_APP_JOURNEY_URL_PROD),

        brandCookieDomain,
      };
      break;
  }

  return { ...constants, brandDomain, domain };
})();

// React configs
export const LANGUAGE: string = (process.env.REACT_APP_LANGUAGE as string) || 'pt-BR';
export const REACT_APP_APPLICATION_NAME: string = process.env.REACT_APP_APPLICATION_NAME_ELECTIVES as string;

// Whitelabel stuff
export const WL_COMPANY_PANEL_LOGO: string = process.env.REACT_APP_WL_COMPANY_PANEL_LOGO as string;
export const WL_COMPANY_PRIMARY_COLOR: string = process.env.REACT_APP_WL_COMPANY_PRIMARY_COLOR as string;
export const AUTH_API_KEY: string = process.env.REACT_APP_AUTH_API_KEY as string;
