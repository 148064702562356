import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IBrand } from '@portal/types';

import userApi from '@portal/repositories/user';

export const useGetUserBrands = (options?: UseQueryOptions<IBrand[]>): UseQueryResult<IBrand[]> => {
  const response = useQuery<IBrand[]>({
    queryKey: ['user-brands'],
    queryFn: () => userApi.getUserBrands(),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    ...options,
  });

  return response;
};
