export const apiUrls = {
  devApiJourney: process.env.REACT_APP_API_BASE_URL_DEV_JOURNEY,
  devApiNew: process.env.REACT_APP_API_BASE_URL_DEV_NEW,
  devApiOld: process.env.REACT_APP_API_BASE_URL_DEV_OLD,
  devApiBffContentLib: process.env.REACT_APP_API_BASE_URL_DEV_BFF_CONTENT_LIB,

  hmgApiJourney: process.env.REACT_APP_API_BASE_URL_HMG_JOURNEY,
  hmgApiNew: process.env.REACT_APP_API_BASE_URL_HMG_NEW,
  hmgApiOld: process.env.REACT_APP_API_BASE_URL_HMG_OLD,
  hmgApiBffContentLib: process.env.REACT_APP_API_BASE_URL_HMG_BFF_CONTENT_LIB,

  prodApiJourney: process.env.REACT_APP_API_BASE_URL_PROD_JOURNEY,
  prodApiNew: process.env.REACT_APP_API_BASE_URL_PROD_NEW,
  prodApiOld: process.env.REACT_APP_API_BASE_URL_PROD_OLD,
  prodApiBffContentLib: process.env.REACT_APP_API_BASE_URL_PROD_BFF_CONTENT_LIB,

  localhostApiJourney: process.env.REACT_APP_API_BASE_URL_LOCALHOST_JOURNEY,
  localhostApiNew: process.env.REACT_APP_API_BASE_URL_LOCALHOST_NEW,
  localhostApiOld: process.env.REACT_APP_API_BASE_URL_LOCALHOST_OLD,
  localhostApiBffContentLib: process.env.REACT_APP_API_BASE_URL_LOCALHOST_BFF_CONTENT_LIB,
};

export const apisLocalhost = (): Pick<
  models.Constants,
  'backNew' | 'backOld' | 'backJourney' | 'backBffContentLib'
> => {
  switch (process.env.REACT_APP_AMBIENT) {
    case 'DEV':
      return {
        backNew: apiUrls.devApiNew,
        backOld: apiUrls.devApiOld,
        backJourney: apiUrls.devApiJourney,
        backBffContentLib: apiUrls.devApiBffContentLib,
      };
    case 'HMG':
      return {
        backNew: apiUrls.hmgApiNew,
        backOld: apiUrls.hmgApiOld,
        backJourney: apiUrls.hmgApiJourney,
        backBffContentLib: apiUrls.hmgApiBffContentLib,
      };
    case 'PROD':
      return {
        backNew: apiUrls.prodApiNew,
        backOld: apiUrls.prodApiOld,
        backJourney: apiUrls.prodApiJourney,
        backBffContentLib: apiUrls.prodApiBffContentLib,
      };
    default:
      return {
        backNew: apiUrls.localhostApiNew,
        backOld: apiUrls.localhostApiOld,
        backJourney: apiUrls.localhostApiJourney,
        backBffContentLib: apiUrls.localhostApiBffContentLib,
      };
  }
};
