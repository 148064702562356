import axios from 'axios';
import { Dispatch } from 'redux';

import certificateAPI from '@portal/repositories/certificate';
import * as MessageService from '~/services/message';
import * as StorageService from '~/services/storage';

import { decreaseLoading, increaseLoading } from './loading';

export const downloadCertificate =
  (courseId: string, ref: React.MutableRefObject<HTMLAnchorElement | null>, userId?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const brandProductId = StorageService.getItem('brandProductsConfig')?.id || '';

    dispatch(increaseLoading());
    try {
      const url = await certificateAPI.getCertificateDownloadUrl({
        ...(userId && { userId }),
        courseId,
        brandProductId,
      });

      const responseUrl = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });

      const blob = new Blob([responseUrl.data], {
        type: 'application/pdf',
      });

      ref?.current?.setAttribute('href', window.URL.createObjectURL(blob));

      ref?.current?.click();
    } catch {
      MessageService.error('Não foi possível realizar o download do certificado.');
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const downloadTotalHoursCertificate =
  (ref: React.MutableRefObject<HTMLAnchorElement | null>) =>
  async (dispatch: Dispatch): Promise<void> => {
    const brandProductId = StorageService.getItem('brandProductsConfig')?.id || '';

    dispatch(increaseLoading());
    try {
      const url = await certificateAPI.getTotalHoursCertificateDownloadUrl({
        brandProductId,
      });

      const responseUrl = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });

      const blob = new Blob([responseUrl.data], {
        type: 'application/pdf',
      });

      ref?.current?.setAttribute('href', window.URL.createObjectURL(blob));

      ref?.current?.click();
    } catch {
      MessageService.error('Não foi possível realizar o download do certificado.');
    } finally {
      dispatch(decreaseLoading());
    }
  };
