import {
  SET_STEP_ACTIVE_LMS_ONBOARDING,
  SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING,
  SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING,
} from '@portal/actions/actionTypes';

const getInitialState = (): reducers.LmsOnboardingReducer => ({
  stepActive: 1,
  showModalStartOnboarding: false,
  showModalStepsOnboarding: false,
});

const lmsOnboardingReducer = (
  state = getInitialState(),
  action: { type: string; payload: never }
): reducers.LmsOnboardingReducer => {
  switch (action.type) {
    case SET_STEP_ACTIVE_LMS_ONBOARDING:
      state = {
        ...state,
        stepActive: action.payload,
      };
      break;

    case SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING:
      state = {
        ...state,
        showModalStartOnboarding: action.payload,
      };
      break;

    case SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING:
      state = {
        ...state,
        showModalStepsOnboarding: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default lmsOnboardingReducer;
