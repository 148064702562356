import {
  SATISFACTION_SURVEY,
  SATISFACTION_SURVEY_COURSE_ID,
  SHOW_MODAL_SATISFACTION_SURVEY,
} from '@portal/actions/actionTypes';

const initialState: reducers.SatisfactionSurveyReducer = {
  satisfactionSurvey: {} as reducers.SatisfactionSurvey,
  satisfactionSurveyCourseId: null,
  showModal: false,
};

const satisfactionSurveyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SATISFACTION_SURVEY:
      state = {
        ...state,
        satisfactionSurvey: action.payload,
      };
      break;

    case SHOW_MODAL_SATISFACTION_SURVEY:
      state = {
        ...state,
        showModal: action.payload,
      };
      break;

    case SATISFACTION_SURVEY_COURSE_ID:
      state = {
        ...state,
        satisfactionSurveyCourseId: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default satisfactionSurveyReducer;
