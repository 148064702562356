import React, { useCallback, useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Radio, RadioChangeEvent, Space } from 'antd';

import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import ReviewTutor from '@portal/components/v1/UI/Molecules/ReviewTutor/ReviewTutor';

import { translate } from '@portal/services/i18n';
import * as StorageService from '~/services/storage';
import { ANSWER_TABS } from '@portal/enum/answerTabs';
import { ProductType } from '@portal/enum/productType';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { QUESTION_TYPE } from '@portal/enum/questionType';
import { useReduxState } from '@portal/hooks/useReduxState';
import { REVIEW_STATUS_TYPE } from '@portal/enum/reviewStatusType';
import Feedback from '../Feedback/Feedback';
import RenderCondition from '@portal/utils/renderCondition';
import { UserActionType } from '@portal/enum/userActionType';

interface IProps {
  idUser?: string;
  answeredQuestion?: models.Questions | undefined;
  index: number;
  description: string;
  options: models.TestOptionQuestion[];
  setSelectedOptions?: (value: string[]) => void;
  setAnswerValues?: (
    id: string,
    answerType: number,
    value: string,
    referralId?: string,
    questionType?: number,
    activityQuestionTestOptionId?: string
  ) => void;
  disabled?: boolean;
  showCorectQuestions?: boolean;
  isSolution?: boolean;
  isUserCollapse?: boolean;
  isHistoric?: boolean;
  answersList?: models.Answers[];
  question?: models.Questions;
  setCommentValues?: (id: string, value: string, valueStatusQuestion: number, referralId: string) => void;
  readOnly?: boolean;
  isTemplateQuestion?: boolean;
}

const ObjectiveQuestion: React.FC<IProps> = ({
  idUser,
  description,
  options,
  setSelectedOptions,
  disabled,
  setAnswerValues,
  showCorectQuestions,
  isUserCollapse,
  isHistoric,
  question,
  setCommentValues,
  readOnly,
  answeredQuestion,
  isTemplateQuestion,
}) => {
  const [commentTutor, setCommentTutor] = useState<string>('');
  const [valueStatusQuestion, setValueStatusQuestion] = useState<number>(REVIEW_STATUS_TYPE.YES);
  const [disableReview, setDisableReview] = useState(isHistoric);
  const [checkedValue, setCheckedValue] = useState<string>('');

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const {
    activity: { activityAnswers, activityDetails },
    auth: { me },
  } = useReduxState();

  const isTutor = isDashTutor(me?.profileType);

  const idStudent = isTutor && productType === ProductType.LMS_STUDENT ? idUser : me?.id;

  const green = '#27AE60';
  const red = '#EB5757';
  const gray = '#D3D3D3';

  const currentAnswer: models.QuestionAnswer | undefined = activityAnswers?.questions
    ?.find((answer) => answer.id === question?.id)
    ?.answers?.filter((item) => item?.user?.id === idStudent)?.[0];

  const setAnswer = useCallback(
    (answerType: number, value: string, referralId: string) => {
      setAnswerValues && setAnswerValues(question?.id as string, answerType, value, referralId, question?.questionType);
    },
    [question?.id, setAnswerValues]
  );

  const setComment = useCallback(
    (value: string, valueStatusQuestion: number, referralId: string) => {
      setCommentValues && setCommentValues(question?.id as string, value, valueStatusQuestion, referralId);
    },
    [question?.id, setCommentValues]
  );

  const listOptions =
    options
      ?.sort((a, b) => {
        return Number(a?.order) - Number(b?.order);
      })
      ?.filter((item) => !!item?.description?.length) ?? [];

  const indexIsCorrect = listOptions?.findIndex((option) => option.isCorrect);

  const onRadioOptionChange = useCallback(
    (value: RadioChangeEvent) => {
      setCheckedValue(value.target.value);
      if (setSelectedOptions && value.target.value) {
        setAnswer(ANSWER_TABS.TEXT, value.target.value, currentAnswer?.id as string);
        setSelectedOptions([value.target.value as string]);
      }
    },
    [setSelectedOptions]
  );

  const getFeedbackStyle = useCallback(
    (prefix: string, isCorrect: boolean) => {
      if (isTemplateQuestion) {
        return isCorrect ? prefix.concat('--correct') : prefix.concat('--wrong');
      }
    },
    [isTemplateQuestion]
  );

  const showFeedBack = useCallback((title: string, descriptionProp: string) => {
    Modal.info({
      title,
      content: (
        <div className="question-modal-info">
          <p className="question-modal-info__description">{descriptionProp}</p>
        </div>
      ),
      onOk() {},
      centered: true,
    });
  }, []);

  useEffect(() => {
    if (valueStatusQuestion) {
      setComment(commentTutor, valueStatusQuestion, currentAnswer?.id as string);
    }
  }, [commentTutor, valueStatusQuestion, currentAnswer]);

  const colorOption = (option: models.TestOptionQuestionDone) => {
    if (showCorectQuestions && option?.isCorrect) {
      return green;
    }

    if (currentAnswer?.answer && option.id === currentAnswer?.answer && option?.isCorrect) {
      return green;
    }

    if (currentAnswer?.answer && option.id === currentAnswer?.answer && !option?.isCorrect) {
      return red;
    }

    return gray;
  };

  const colorOptionStudent = (option: models.TestOptionQuestionDone) => {
    if (activityDetails.status === UserActionType.ACTIVITY_FINISHED) {
      return colorOption(option);
    }

    if (
      currentAnswer?.answerStatus === UserActionType.QUESTION_FINISHED &&
      activityDetails.status === UserActionType.ACTIVITY_TO_REDO
    ) {
      return colorOption(option);
    }

    return gray;
  };

  const borderSize = (option: models.TestOptionQuestionDone) => {
    const sm = '0.5px';
    const md = '3px';

    if (showCorectQuestions && option?.isCorrect) {
      return md;
    }

    if (checkedValue) {
      if (option.id === checkedValue) {
        return md;
      }

      if (option.id === currentAnswer?.answer && option.id === checkedValue) {
        return sm;
      }
    } else {
      if (option.id === currentAnswer?.answer) {
        return md;
      }
    }

    return sm;
  };

  return (
    <>
      <div className="question-container">
        <div className="question-container__title">
          <h2 className="question-container__title__label mb-3">
            <RenderHTML htmlText={description} />
          </h2>
        </div>

        <div className="question-container__options">
          {!isDashTutor(me?.profileType) || productType === ProductType.LMS_EDUCATOR ? (
            <>
              {question?.questionType === QUESTION_TYPE.OBJECTIVE_QUESTION_IMAGES ? (
                <Radio.Group
                  onChange={onRadioOptionChange}
                  size="large"
                  disabled={disabled || readOnly}
                  style={{ width: '100%', gap: '2rem' }}
                  className="spacing-options"
                >
                  {listOptions?.map((option, optionIndex) => (
                    <div
                      key={option.id}
                      className="question-test-container__options__content"
                      style={{
                        border: `${borderSize(option)} solid ${colorOptionStudent(option)}`,
                      }}
                    >
                      <Space direction="vertical">
                        <Radio value={option.id}>
                          <p className="question-container__options__label">
                            <span className="question-container__options__label__option">
                              {String.fromCharCode(optionIndex + 65)})
                            </span>
                            <img src={option.fileUrl} width={200} alt="option" />
                          </p>
                        </Radio>
                      </Space>
                    </div>
                  ))}
                </Radio.Group>
              ) : (
                <Radio.Group
                  onChange={onRadioOptionChange}
                  size="large"
                  disabled={disabled || readOnly}
                  style={{ width: '100%' }}
                  value={checkedValue || currentAnswer?.answer}
                >
                  {listOptions?.map((option, optionIndex) => (
                    <div
                      key={option.id}
                      className="question-test-container__options__content"
                      style={{
                        border: `${borderSize(option)} solid ${colorOptionStudent(option)}`,
                      }}
                    >
                      <Space key={option.id} direction="vertical">
                        <div className="d-flex align-items-start justify-content-start">
                          <Radio value={option.id}></Radio>
                          <div>
                            <p className="question-container__options__label">
                              <span className="question-container__options__label__option">
                                {String.fromCharCode(optionIndex + 65)})
                              </span>
                              {option.description}
                            </p>
                          </div>
                        </div>
                      </Space>
                    </div>
                  ))}
                </Radio.Group>
              )}
            </>
          ) : (
            <Radio.Group
              style={{ width: '100%' }}
              onChange={onRadioOptionChange}
              value={currentAnswer?.answer || checkedValue}
              disabled
            >
              {listOptions?.map((option, optionIndex) => (
                <div
                  key={option.id}
                  className="question-test-container__options__content"
                  style={{
                    border: `${borderSize(option)} solid ${colorOption(option)}`,
                  }}
                >
                  <Space direction="vertical">
                    <Radio value={option.id}>
                      <p
                        className={`question-container__options__label ${getFeedbackStyle(
                          'question-container__options__label',
                          option.isCorrect
                        )}`}
                      >
                        <span className="question-container__options__label__option">
                          {String.fromCharCode(optionIndex + 65)})
                        </span>
                        {option.description}

                        {question?.questionType === QUESTION_TYPE.OBJECTIVE_QUESTION_IMAGES && (
                          <img src={option.fileUrl} width={80} alt="option" />
                        )}

                        {showCorectQuestions && option.answer && (
                          <button
                            onClick={() =>
                              showFeedBack(
                                translate('PAGES.CONTENT.FINAL_EVALUATION.QUESTIONS.MODAL.TITLE'),
                                option.answer
                              )
                            }
                            className="
                                question-container__options__label__icon question-container__options__label__icon--info
                              "
                          >
                            <InfoCircleOutlined />
                          </button>
                        )}
                      </p>
                    </Radio>
                  </Space>
                </div>
              ))}
            </Radio.Group>
          )}
          {isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT && (
            <>
              <div className="question-container__review-tutor">
                <div className="mt-4">
                  <span className="question-container__review-tutor__expected-answer">
                    {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.EXPECTED_ANSWER')}
                  </span>
                  <div>
                    <span className="question-container__review-tutor__expected-answer-student">
                      {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.ALTERNATIVE')}
                      {String.fromCharCode(Number(indexIsCorrect) + 65)}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="answer-question-container__review-tutor__radio-group">
        {(isUserCollapse || isHistoric) && (
          <ReviewTutor
            {...{ setDisableReview, isUserCollapse, isHistoric }}
            statusValue={valueStatusQuestion}
            isObjective
            onChangeStatus={setValueStatusQuestion}
            commentValue={commentTutor}
            onChangeComment={setCommentTutor}
            disabled={disableReview}
          />
        )}
      </div>

      {answeredQuestion?.answers
        ?.filter((item) => item?.user?.id === idStudent)?.[0]
        ?.comments?.map((comment) => (
          <RenderCondition condition={!!comment.comment}>
            <div key={comment.id} className="final-question-container__feedback">
              <Feedback
                description={comment.comment}
                name={comment.user.name}
                creatorId={comment.user.id}
                thumbSrc={comment.user.profilePictureUrl}
              />
            </div>
          </RenderCondition>
        ))}
    </>
  );
};

export default ObjectiveQuestion;
