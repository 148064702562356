import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CourseActions } from '@portal/actions';
import { useReduxState } from '@portal/hooks/useReduxState';

import { getLmsTrailsWithCourses } from '@portal/actions/lmsTrail';
import { COURSE_TYPE } from '@portal/enum/courseType';
import { ProductType } from '@portal/enum/productType';
import { translate } from '@portal/services/i18n';
import * as StorageService from '@portal/services/storage';
import { isEducatorStudent } from '@portal/utils/isEducatorStudent';

interface IProps {
  placeholder?: string;
}

const SearchBar: React.FC<IProps> = ({ placeholder }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useDispatch();

  const {
    auth: { me },
    brand: { brandProductConfig },
  } = useReduxState();
  const product = StorageService.getItem('brandProductsConfig');
  const productType = product?.product || '';

  const paramsToSearch = {
    limit: 10,
    offset: 1,
    title: searchValue,
    productId: product?.id || '',
    schoolId: me?.idSchool || '',
    type:
      isEducatorStudent(me?.profileType) && productType === ProductType.LMS_EDUCATOR
        ? COURSE_TYPE.TRAIL
        : COURSE_TYPE.ELECTIVE,
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClickSearch = () => {
    if (brandProductConfig?.id && brandProductConfig?.trailList) {
      dispatch(getLmsTrailsWithCourses({ idBrandProduct: brandProductConfig?.id, trailCourseName: searchValue }));

      return;
    }

    dispatch(CourseActions.getOtherCourses(paramsToSearch));
  };

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClickSearch();
    }
  };

  return (
    <div className="searchBar-container">
      <div className="searchBar-container__input">
        <Input
          className="searchBar-container__input__content"
          placeholder={placeholder || translate('COMPONENTS.SEARCHBAR.PLACEHOLDER')}
          style={{ border: 0, textAlign: 'left' }}
          onKeyUp={handleSearch}
          onChange={handleChange}
        />
      </div>
      <button className="searchBar-container__icon" onClick={handleClickSearch}>
        <SearchOutlined style={{ opacity: '0.4', fontSize: '25px' }} />
      </button>
    </div>
  );
};

export default SearchBar;
