import { LMS_TRAIL_LIST_WITH_COURSES } from '@portal/actions/actionTypes';

const initialState: reducers.LmsTrailReducer = {
  listWithCourses: [],
};

const lmsTrailReducer = (state = initialState, action: { type: string; payload: never }): reducers.LmsTrailReducer => {
  switch (action.type) {
    case LMS_TRAIL_LIST_WITH_COURSES:
      state = {
        ...state,
        listWithCourses: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default lmsTrailReducer;
