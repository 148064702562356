import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ChatActions } from '@portal/actions';
import {
  CHAT_PARTICIPANTS,
  HAS_SEARCH,
  SEARCH_CHAT,
} from '@portal/actions/actionTypes';
import { useReduxState } from '@portal/hooks/useReduxState';
import { IRouteParams } from '@portal/models/route-params';
import { translate } from '@portal/services/i18n';
import UserIcon from '~/assets/svg/panel-top-bar/ic_user.svg';

interface IProps {
  isConversation?: boolean;
}

const TopBarChat: React.FC<IProps> = ({ isConversation }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { chats, hasSearch, chatParticipants } = useReduxState().chat;
  const { me } = useReduxState().auth;

  const { privateChatId } = useParams<IRouteParams>();

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (privateChatId) {
      dispatch(ChatActions.getParticipants(privateChatId));
    }
  }, [dispatch, privateChatId]);

  useEffect(() => {
    if (chats.length) {
      const chatResult = chats.filter((chat) => {
        return chat.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase());
      });

      dispatch({
        type: SEARCH_CHAT,
        payload: chatResult,
      });
    }

    if (searchText && !hasSearch) {
      dispatch({
        type: HAS_SEARCH,
        payload: true,
      });
    } else if (!searchText && hasSearch) {
      dispatch({
        type: HAS_SEARCH,
        payload: false,
      });
    }

    return () => {
      dispatch({
        type: SEARCH_CHAT,
        payload: [],
      });

      dispatch({
        type: HAS_SEARCH,
        payload: false,
      });

      dispatch({
        type: CHAT_PARTICIPANTS,
        payload: {} as models.ChatParticipants,
      });
    };
  }, [searchText]);

  const getCurrentParticipant = useCallback(() => {
    if (
      chatParticipants &&
      chatParticipants.participants &&
      chatParticipants.participants.length
    ) {
      const participantsWithNoLoggedUser = chatParticipants.participants.filter(
        (participant) => participant.id !== me?.id
      );

      return participantsWithNoLoggedUser[0];
    }

    return undefined;
  }, [chatParticipants, me?.id]);

  const handleSearchChange = useCallback((text: string) => {
    setSearchText(text);
  }, []);

  const UserInfo = (
    <div className="top-bar-chat__profile-info">
      <img
        className="top-bar-chat__profile-info__thumb"
        src={getCurrentParticipant()?.profilePictureUrl || UserIcon}
        alt="user icon"
      />
      <span className="top-bar-chat__profile-info__name">
        {getCurrentParticipant()?.name}
      </span>
    </div>
  );

  return (
    <Container fluid="lg" className="p-4 top-bar-chat">
      <Row>
        <Col
          xl={3}
          lg={3}
          md={3}
          sm={4}
          xs={2}
          className="p-0 d-flex justify-content-start align-itens-center"
        >
          <button
            className="top-bar-chat__button"
            onClick={() => history.goBack()}
          >
            <ArrowLeftOutlined className="top-bar-chat__icon" />
          </button>
        </Col>
        <Col
          xl={9}
          lg={9}
          md={9}
          sm={8}
          xs={10}
          className="p-0 d-flex align-itens-center"
        >
          <SearchOutlined className="top-bar-chat__magnifier top-bar-chat__icon" />
          <input
            type="text"
            value={searchText}
            className="top-bar-chat__input"
            placeholder={translate('COMPONENTS.CHAT_TOP_BAR.INPUT_SEARCH')}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          {isConversation ? (
            UserInfo
          ) : (
            <span className="top-bar-chat__label">
              {translate('COMPONENTS.CHAT_TOP_BAR.CHAT')}
            </span>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TopBarChat;
