import { colors } from '@portal/styles/colors';

export const UserActionType = {
  ACTIVITY_FINISHED: 1,
  CONTENT_ACCESS: 2,
  CONTENT_FINISHED: 3,
  MODULE_FINISHED: 4,
  PENDING_FOR_REVIEW: 5,
  QUESTION_FINISHED: 6,
  QUESTION_NEED_TO_REDO: 7,
  ACTIVITY_TO_REDO: 8,
  ACTIVITY_REPROVED: 9,
  QUESTION_REPROVED: 10,
};

export const getUserActionStatusType = (status: number): { circleVariant: string; text: string; color: string } => {
  switch (status) {
    case UserActionType.ACTIVITY_FINISHED:
      return { circleVariant: 'done', text: 'Concluído', color: colors?.doneParameters };
    case UserActionType.ACTIVITY_REPROVED:
      return { circleVariant: 'reproved', text: 'Reprovado', color: colors?.primaryRed };
    case UserActionType.ACTIVITY_TO_REDO:
      return { circleVariant: 'redo', text: 'Refazer', color: colors?.primaryOrange };
    case UserActionType.PENDING_FOR_REVIEW:
      return { circleVariant: 'pending-review', text: 'Pendente de revisão', color: colors?.todoParameters };
    default:
      return { circleVariant: 'todo', text: 'A fazer', color: colors?.gray500 };
  }
};
