import moment from 'moment';

import { MARKER_STATUS } from '@portal/enum/markerStatus';

interface IProps {
  startAt: Date | string;
  endAt?: Date | string;
  leftDaysToMeetDeadline?: number;
}

export const isAvailableBySchedule = ({ startAt, endAt }: IProps): boolean => {
  if (startAt && endAt) {
    return moment().isSameOrAfter(startAt, 'day') && moment().isSameOrBefore(endAt, 'day');
  }

  if (startAt && !endAt) {
    return moment().isSameOrAfter(startAt, 'day');
  }

  return false;
};

export const isBeforeBySchedule = (startAt: Date | string): boolean => {
  if (startAt) {
    return moment().isBefore(startAt);
  }

  return false;
};

export const getContentStatusBySchedule = ({ endAt, leftDaysToMeetDeadline = 7 }: IProps): number => {
  if (moment().isAfter(endAt, 'day')) {
    return MARKER_STATUS.LATE;
  }

  const diffDate = Math.ceil(moment(endAt).diff(moment(), 'days', true));

  if (diffDate <= leftDaysToMeetDeadline) {
    return MARKER_STATUS.WARNING;
  }

  return MARKER_STATUS.NONE;
};
