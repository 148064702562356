import React from 'react';
import { useHistory } from 'react-router';

import { Constants } from '@portal/config/env';
import { Course } from '@portal/models/reducers';
import { setCookie } from '@portal/services/cookie';

interface IProps {
  cardData: Course;
  electiveNotSubscribed?: boolean;
  allowStudentEnrollment?: boolean;
}

const MinimalCard: React.FC<IProps> = ({ cardData }) => {
  const { title, photoUrl, virtualGroup } = cardData;

  const history = useHistory();

  const redirectToContentPage = () => {
    setCookie('virtual-group-id', virtualGroup?.[0]?.id, Constants.brandCookieDomain as string);
    history.push(`/content/${cardData.id}`);
  };

  return (
    <div className="minimal-card-container" onClick={redirectToContentPage}>
      <div>
        <img src={photoUrl} alt="background" className="minimal-card-container__background-image" />
      </div>

      <div className="minimal-card-container__info">
        <span className="minimal-card-container__title">{title}</span>
      </div>
    </div>
  );
};

export default MinimalCard;
