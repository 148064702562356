import { ProfileType } from '@portal/enum/profileType';
import { TUTOR_VIEW_TYPE } from '@portal/enum/tutorViewType';
import { useReduxState } from '@portal/hooks/useReduxState';

export function isDashTutor(userType: number | undefined): boolean {
  const {
    tutorView: { typeViewTutor },
  } = useReduxState();

  return (
    !!userType &&
    [ProfileType.TEACHER, ProfileType.COORDINATOR, ProfileType.ADVISER].includes(userType) &&
    typeViewTutor === TUTOR_VIEW_TYPE.DASH_TUTOR
  );
}
