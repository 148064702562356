import { ILmsTrail } from '@portal/models/module';

import { getInstance } from './instance';

const BASE_URL = '/lms-trail';

const lmsTrailApi = {
  getWithCourses: async (params: {
    idBrandProduct: string;
    name?: string;
  }): Promise<{ rows: ILmsTrail[]; count: number }> => {
    const instance = getInstance({ api: 'new', version: 'v2' });

    const { data } = await instance.get(`${BASE_URL}/with-courses`, {
      params: { ...params, limit: Number.MAX_SAFE_INTEGER },
    });

    return data;
  },
};

export default lmsTrailApi;
