import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Pagination } from 'antd';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { BrandActions, GroupActions } from '@portal/actions';

import { translate } from '@portal/services/i18n';
import * as StorageService from '~/services/storage';
import { COURSE_TYPE } from '@portal/enum/courseType';
import { ProfileType, arrayEducatorProfiles } from '@portal/enum/profileType';
import { TABS_STATUS } from '@portal/enum/mygroupsType';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getCookie } from '@portal/services/cookie';

import { Btn, CollapseTutor, NewGroupModal } from '@portal/components';
import GroupCard from '@portal/components/v1/Card/GroupCard/GroupCard';

import EmptyCourse from '@portal/assets/png/components/empty_course.png';
import RenderCondition from '@portal/utils/renderCondition';
import { StepsLmsOnboardingTutor } from '@portal/enum';

const MyGroups: React.FC = () => {
  const dispatch = useDispatch();

  const {
    auth: { me },
    group: { groupsAll },
    brand: { brandPlaylist },
    lmsOnboarding: { stepActive, showModalStepsOnboarding },
  } = useReduxState();

  const [currentTab, setCurrentTab] = useState<number>(TABS_STATUS.ACTIVES);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState<boolean>(false);

  const [groupListPagination, setGroupListPagination] = useState({
    limit: 12,
    offset: 1,
    type: COURSE_TYPE.ELECTIVE,
  });

  const productId = StorageService.getItem('brandProductsConfig')?.id;
  const courseId = StorageService.getItem('courseId-redirect');
  const isSaePartnership = me?.isSaePartnership || getCookie('user-integration');

  const handleChange = (newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (productId && !brandPlaylist?.length) {
      dispatch(BrandActions.getPlaylist(productId));
    }
  }, [currentTab, groupListPagination, productId, me?.profileType]);

  useEffect(() => {
    if (me?.idSchool && productId) {
      dispatch(
        GroupActions.getAllGroups({
          ...groupListPagination,
          productId,
          status: currentTab === TABS_STATUS.ACTIVES ? 'active' : 'finished',
          schoolId: me?.idSchool,
          ...(courseId && { courseId }),
        })
      );
    }
  }, [currentTab, groupListPagination, productId, me?.idSchool, courseId]);

  useEffect(() => {
    if (showModalStepsOnboarding) {
      handleChange(TABS_STATUS.ACTIVES);
    }
  }, [showModalStepsOnboarding]);

  return (
    <Container fluid className="my-groups">
      {currentTab === TABS_STATUS.ACTIVES &&
        me?.profileType &&
        arrayEducatorProfiles.includes(me?.profileType) &&
        !!brandPlaylist?.[0]?.videos?.length && <CollapseTutor />}

      <Row>
        <span
          className="my-groups__title"
          {...(stepActive === StepsLmsOnboardingTutor.MY_GROUPS && { style: { zIndex: 99999 } })}
        >
          {translate('PAGES.GROUPS.TITLES.MY_GROUPS')}
        </span>
      </Row>
      <Row>
        {currentTab === TABS_STATUS.ACTIVES && (
          <span className="my-groups__subtitle">{translate('PAGES.GROUPS.SUBTITLES.MY_GROUPS')}</span>
        )}
        {currentTab === TABS_STATUS.FINISHED && (
          <span className="my-groups__subtitle">{translate('PAGES.GROUPS.SUBTITLES_FINISHED.MY_GROUPS')}</span>
        )}
      </Row>

      <Row className="mb-2 mt-4 tab-layout">
        <div
          className="tab-layout__wrapper"
          {...(stepActive === StepsLmsOnboardingTutor.MY_GROUPS && {
            style: { zIndex: 99999, position: 'relative' },
          })}
        >
          <Tabs
            value={currentTab}
            onChange={(_, value) => handleChange(value)}
            aria-label="course details"
            variant="scrollable"
            scrollButtons="auto"
            className="tab-layout__tab-color"
            id="tabs-groups"
          >
            <Tab label={translate('PAGES.GROUPS.TABS.ACTIVES')} />
            <Tab label={translate('PAGES.GROUPS.TABS.FINISHED')} />
          </Tabs>
          <div className="tab-layout__divider-wrapper" />
        </div>
        <div>
          {(me?.profileType === ProfileType.COORDINATOR || me?.profileType === ProfileType.ADVISER) &&
            !isSaePartnership && (
              <>
                <Btn
                  onClick={() => {
                    setShowCreateGroupModal(true);
                  }}
                  variant="primary"
                  title={translate('PAGES.GROUPS.TABS.BUTTON.NEW_GROUP')}
                />
                <NewGroupModal
                  openModal={showCreateGroupModal}
                  groupListPagination={groupListPagination}
                  closeModal={setShowCreateGroupModal}
                />
              </>
            )}
        </div>
      </Row>

      <RenderCondition condition={currentTab === TABS_STATUS.ACTIVES}>
        <Row>
          <div className="w-100 p-0">
            <div className="my-groups__group-content">
              {groupsAll.rows.length > 0 ? (
                <div className="my-groups__group-list">
                  <div className="my-groups__group-list__inner">
                    {groupsAll.rows.map(
                      (group: models.VirtualGroup, key) =>
                        !group.closedAt && (
                          <div key={group.id} {...(key === 0 && { id: 'card-group' })}>
                            <GroupCard
                              isActive
                              cardData={group}
                              isInFocus={
                                stepActive === StepsLmsOnboardingTutor.MY_GROUPS ||
                                (stepActive === StepsLmsOnboardingTutor.CARD_GROUP && key === 0)
                              }
                              buttonsInFocus={stepActive === StepsLmsOnboardingTutor.CONFIG_GROUP && key === 0}
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <Row className="d-flex justify-content-center align-itens-center">
                    <img
                      className="my-groups__group-list__empty-container__image"
                      src={EmptyCourse}
                      alt="empty course"
                    />
                  </Row>
                  <Row className="d-flex justify-content-center align-itens-center">
                    <span className="my-groups__group-list__empty-container__title">
                      {translate('PAGES.GROUPS.EMPTY_GROUP.EMPTY_ACTIVE')}
                    </span>
                  </Row>
                </div>
              )}
            </div>
          </div>
        </Row>
      </RenderCondition>

      <RenderCondition condition={currentTab === TABS_STATUS.FINISHED}>
        <Row>
          <div className="w-100 p-0">
            <div className="my-groups__group-content">
              {groupsAll?.rows?.length > 0 && groupsAll.rows.some((group) => group.closedAt) ? (
                <div className="my-groups__group-list">
                  <div className="my-groups__group-list__inner">
                    {groupsAll.rows.map((group) => group.closedAt && <GroupCard key={group.id} cardData={group} />)}
                  </div>
                </div>
              ) : (
                <div className="my-groups__group-list__empty-container">
                  <div>
                    <Row className="d-flex justify-content-center align-itens-center">
                      <img
                        className="my-groups__group-list__empty-container__image"
                        src={EmptyCourse}
                        alt="empty course"
                      />
                    </Row>
                    <Row className="d-flex justify-content-center align-itens-center">
                      <span className="my-groups__group-list__empty-container__title">
                        {translate('PAGES.GROUPS.EMPTY_GROUP.EMPTY_FINISHED')}
                      </span>
                    </Row>
                    <Row className="d-flex justify-content-center align-itens-center">
                      <span className="my-groups__group-list__empty-container__subtitle">
                        {translate('PAGES.GROUPS.EMPTY_GROUP.EMPTY_FINISHED_TEXT')}
                      </span>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Row>
      </RenderCondition>

      <Pagination
        defaultCurrent={1}
        total={groupsAll.count}
        pageSize={groupListPagination.limit}
        onChange={(page) =>
          setGroupListPagination({
            ...groupListPagination,
            offset: page,
          })
        }
      />
    </Container>
  );
};

export default MyGroups;
