import React, { ComponentProps } from 'react';

export const IcShape = (rest: ComponentProps<'svg'>) => {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 17" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M5.85907 6.84755C7.75704 6.84755 9.29579 5.3142 9.29579 3.42377C9.29579 1.53308 7.75704 0 5.85907 0C3.96094 0 2.42153 1.53302 2.42153 3.42377C2.42158 5.3142 3.96094 6.84755 5.85907 6.84755ZM7.31708 7.08065H4.40096C1.97451 7.08065 0 9.04743 0 11.4649V15.018L0.00928301 15.0737L0.254767 15.1497C2.57053 15.8702 4.58239 16.1112 6.23846 16.1112C9.47237 16.1112 11.3476 15.1926 11.4631 15.1338L11.6928 15.018H11.7172V11.4649C11.7172 9.04743 9.74385 7.08065 7.31708 7.08065Z"
        fill="currentColor"
      />
    </svg>
  );
};
