import { translate } from '~/services/i18n';

export enum ProfileType {
  ADMIN = 1,
  TEACHER = 2,
  STUDENT = 3,
  DIRECTOR = 4,
  COORDINATOR = 5,
  FAMILY = 8,
  ADVISER = 9,
  EDOOLS = 12,
}

export const profileTypeArray = [
  { value: ProfileType.ADMIN, i18n: 'ENUMERATORS.PROFILE_TYPE.ADMIN.LABEL' },
  { value: ProfileType.TEACHER, i18n: 'ENUMERATORS.PROFILE_TYPE.TEACHER.LABEL' },
  { value: ProfileType.STUDENT, i18n: 'ENUMERATORS.PROFILE_TYPE.STUDENT.LABEL' },
  { value: ProfileType.DIRECTOR, i18n: 'ENUMERATORS.PROFILE_TYPE.DIRECTOR.LABEL' },
  { value: ProfileType.COORDINATOR, i18n: 'ENUMERATORS.PROFILE_TYPE.COORDINATOR.LABEL' },
  { value: ProfileType.FAMILY, i18n: 'ENUMERATORS.PROFILE_TYPE.FAMILY.LABEL' },
  { value: ProfileType.ADVISER, i18n: 'ENUMERATORS.PROFILE_TYPE.ADVISER.LABEL' },
  { value: ProfileType.EDOOLS, i18n: 'ENUMERATORS.PROFILE_TYPE.EDOOLS.LABEL' },
];

export const arrayAdminProfiles = [ProfileType.ADMIN, ProfileType.COORDINATOR, ProfileType.ADVISER];

export const arrayEducatorProfiles = [ProfileType.TEACHER, ProfileType.COORDINATOR, ProfileType.ADVISER];

export const arrayProfilesWithAccess = [
  ProfileType.STUDENT,
  ProfileType.FAMILY,
  ProfileType.TEACHER,
  ProfileType.COORDINATOR,
  ProfileType.ADVISER,
];

export const arrayRelatedStudentProfiles = [ProfileType.STUDENT, ProfileType.FAMILY];

export const getProfileTypeTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = profileTypeArray.find((o) => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};

export const isEducator = (profileType: number = -1) =>
  [ProfileType.TEACHER, ProfileType.COORDINATOR, ProfileType.ADVISER, ProfileType.DIRECTOR].includes(profileType);
