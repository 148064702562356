import Collapse, { CollapseProps } from 'antd/lib/collapse';
import React from 'react';

interface IProps extends CollapseProps {
  keyActive?: string[];
  onChange: (key: any) => void;
}

const AdvancedCollapse: React.FC<IProps> = ({
  keyActive,
  onChange,
  children,
}) => {
  return (
    <Collapse
      className="collapse-container"
      onChange={onChange}
      bordered={false}
      activeKey={keyActive}
      defaultActiveKey={keyActive ? keyActive : ['0']}
    >
      {children}
    </Collapse>
  );
};

export default AdvancedCollapse;
