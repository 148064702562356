import { blinkLearningApi } from '@portal/repositories/blinkLearning';
import { decreaseLoading, increaseLoading } from './loading';

export const blinkLearningActions = {
  redirectToBlink:
    (options?: { onSuccess?: (data?: string) => void; onError?: () => void }) => async (dispatch: any) => {
      dispatch(increaseLoading());
      try {
        const data = await blinkLearningApi.getUrl();

        window.open(data, '_blank');

        options?.onSuccess?.(data);
      } catch (err) {
        options?.onError?.();
      } finally {
        dispatch(decreaseLoading());
      }
    },
};
