import { Dispatch } from 'redux';

import { SatisfactionSurveyAnswerAPI } from '@portal/models/reducers';
import satisfactionSurveyAPI from '@portal/repositories/satisfactionSurvey';
import * as MessageService from '~/services/message';

import { SATISFACTION_SURVEY } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getSatisfactionSurvey =
  (
    satisfactionSurveyId: string,
    callback?: () => void,
    isLocalLoading?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.SatisfactionSurvey =
        await satisfactionSurveyAPI.getById(satisfactionSurveyId);

      dispatch({
        type: SATISFACTION_SURVEY,
        payload,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const sendSatisfactionSurveyAnswer =
  (
    satisfactionSurveyId: string,
    answerData: SatisfactionSurveyAnswerAPI[],
    questionId: string,
    callback?: () => void,
    isLocalLoading?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      await satisfactionSurveyAPI.sendAnswers(
        satisfactionSurveyId,
        answerData,
        questionId
      );
      if (callback && typeof callback === 'function') {
        callback();
      }
      MessageService.success(
        'COMPONENTS.SATISFACTION_SURVEY.MESSAGES.SUBMIT_SUCCESSFULLY'
      );
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
