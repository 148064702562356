import { getInstance } from './instance';
import { IDynamicButton } from '@portal/types';

const BASE_URL = '/dynamic-button';

export const dynamicButtonApi = {
  getMyDynamicButtons: async () => {
    const instance = getInstance({ api: 'new', version: 'v2' });
    const { data } = await instance.get<IDynamicButton[]>(`${BASE_URL}/my-list`);

    return data;
  },
};
