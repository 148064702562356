import { Dispatch } from 'react';

import userApi from '@portal/repositories/user';
import { IBrand, ICallbackOptions, IUser } from '@portal/types';
import { decreaseLoading, increaseLoading } from '../loading';

export const userActions = {
  changeActiveBrand:
    (brand: IBrand, profileType: number, options?: ICallbackOptions<IUser>) => async (dispatch: Dispatch<any>) => {
      dispatch(increaseLoading());

      try {
        const brandDomain = brand?.domainUrl;
        const brandName = brandDomain?.split('.')[0];

        const response = await userApi.changeActiveBrand(brand?.id as string);

        const hostname = window?.location?.hostname;
        const domain = hostname?.split('.')[0];

        const redirectToNewBrand = (url?: string) => {
          window.location.replace(
            `${url?.replace('brandName', brandName)}/redirect?token=${response.token}&profileType=${profileType}`
          );
        };

        switch (domain) {
          case 'lmsdev':
            redirectToNewBrand(process.env.REACT_APP_PORTAL_BASE_URL_DEV);
            break;
          case 'lmshmg':
            redirectToNewBrand(process.env.REACT_APP_PORTAL_BASE_URL_HOMOLOG);
            break;
          case 'lms':
            redirectToNewBrand(process.env.REACT_APP_PORTAL_BASE_URL_PROD);
            break;

          default:
            redirectToNewBrand(process.env.REACT_APP_PORTAL_BASE_URL_LOCAL);
            break;
        }

        options?.onSuccess?.();
      } catch {
        options?.onError?.();
      } finally {
        dispatch(decreaseLoading());
      }
    },
};
