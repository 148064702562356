import { getInstance } from './instance';

const baseUrl = '/content-library';

export const contentLibraryApi = {
  getAllContentLibraries: async () => {
    const instance = getInstance({ api: 'bffContentLibrary' });
    const { data } = await instance.get(`${baseUrl}/products`);

    return data;
  },
};
