/* eslint-disable max-len */
import React from 'react';

const IcUniqueArrowDown = (rest: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
      <path
        d="M2.17547 0.703125L9.25732 7.56928L16.3392 0.703125L18.5146 2.81694L9.25732 11.8119L0 2.81694L2.17547 0.703125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcUniqueArrowDown;
