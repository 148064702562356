/* eslint-disable max-len */
import React from 'react';

export const IcPortalMarketing = () => {
  return (
    <svg width="65" height="65" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65" fill="none">
      <path
        d="M54.1883 0L52.2375 1.94485L44.9696 9.21873L46.5754 15.88L43.4529 19.0084C38.8368 14.8971 32.7722 12.3769 26.1158 12.3769C11.7105 12.3769 0 24.0874 0 38.4926C0 52.8979 11.7105 64.6084 26.1158 64.6084C40.521 64.6084 52.2315 52.8979 52.2315 38.4926C52.2315 31.8378 49.7096 25.7772 45.6 21.1614L48.7225 18.033L55.3897 19.6389L64.6084 10.4201L56.1212 8.48718L54.1883 0ZM26.1158 15.422C31.9486 15.422 37.2534 17.5971 41.3118 21.1555L37.767 24.7062C34.6191 22.0416 30.5533 20.4299 26.1158 20.4299C16.156 20.4299 8.04706 28.5329 8.04706 38.4926C8.04706 48.4524 16.156 56.5614 26.1158 56.5614C36.0755 56.5614 44.1785 48.4524 44.1785 38.4926C44.1785 34.0658 42.5732 30.0099 39.9201 26.8652L43.4648 23.3026C47.0183 27.3598 49.1864 32.6642 49.1864 38.4926C49.1864 51.2522 38.8753 61.5633 26.1158 61.5632C13.3562 61.5632 3.04515 51.2522 3.04515 38.4926C3.04515 25.7331 13.3562 15.422 26.1158 15.422ZM26.1158 32.6105C27.2009 32.6105 28.1967 32.9209 29.0658 33.4253L24.2304 38.2726L26.3894 40.4256L31.2128 35.5902C31.7 36.4484 31.9979 37.4277 31.9979 38.4926C31.9979 41.7613 29.3844 44.3807 26.1158 44.3807C22.8471 44.3807 20.2277 41.7613 20.2277 38.4926C20.2277 35.224 22.8471 32.6105 26.1158 32.6105Z"
        fill="currentColor"
      />
    </svg>
  );
};
