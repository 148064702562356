import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';
import { ModalStartOnboarding, ModalStepsOnboarding, PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import {
  SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING,
  SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING,
} from '@portal/actions/actionTypes';
import { BrandDomain, ProductType, UserLog, arrayEducatorProfiles } from '@portal/enum';
import { useCreateUserLog, useGetUserLog, useReduxState } from '@portal/hooks';
import * as StorageService from '@portal/services/storage';
import { useDispatch } from 'react-redux';
import ContentMobilePage from './ContentMobilePage/ContentMobilePage';
import ContentPage from './ContentPage/ContentPage';

const ContentNavigationStack = () => {
  const dispatch = useDispatch();

  const {
    auth: { me },
    brand: { brandProductConfig, brandConfig },
  } = useReduxState();

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const { data: userLogs, isFetched } = useGetUserLog(
    {
      idReferral: brandProductConfig?.id ?? '',
      type: UserLog.VIEW_LMS_COURSE,
    },
    { enabled: !!brandProductConfig?.id && !!me?.profileType && arrayEducatorProfiles.includes(me?.profileType) }
  );
  const { mutate: createLog } = useCreateUserLog();

  const setShowModalStartOnboarding = (state: boolean) => {
    dispatch({
      payload: state,
      type: SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING,
    });
  };

  const setShowModalStepsOnboarding = (state: boolean) => {
    dispatch({
      payload: state,
      type: SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING,
    });
  };

  const onOkModalStartOnboarding = () => {
    setShowModalStartOnboarding(false);
    setShowModalStepsOnboarding(true);
    document?.getElementById('navbar-lms')?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (
      !!brandProductConfig?.id &&
      brandConfig?.domainUrl !== BrandDomain.PGS &&
      !userLogs?.length &&
      !!me?.profileType &&
      arrayEducatorProfiles.includes(me?.profileType) &&
      productType === ProductType.LMS_STUDENT &&
      isFetched
    ) {
      setShowModalStartOnboarding(true);
      createLog({
        idReferral: brandProductConfig?.id,
        type: UserLog.VIEW_LMS_COURSE,
      });
    }
  }, [brandProductConfig?.id, brandConfig?.domainUrl, me?.profileType, userLogs, isFetched]);

  return (
    <>
      <Switch>
        <Route path={`${getStackPath('CONTENT')}/mobile/:courseId/:moduleId?/:contentId?/:activityId?`}>
          <PanelContent
            pageTitle={translate('PAGES.CONTENT.TITLE')}
            pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
          >
            <ContentMobilePage />
          </PanelContent>
        </Route>
        <Route path={`${getStackPath('CONTENT')}/:courseId/:moduleId?/:contentId?/:activityId?`}>
          <PanelContent
            removeMarginTop
            pageTitle={translate('PAGES.CONTENT.TITLE')}
            pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
          >
            <ContentPage />
          </PanelContent>
        </Route>
      </Switch>

      <ModalStartOnboarding onCancel={() => setShowModalStartOnboarding(false)} onOk={onOkModalStartOnboarding} />

      <ModalStepsOnboarding
        onCancel={() => {
          setShowModalStepsOnboarding(false);
        }}
      />
    </>
  );
};

export default ContentNavigationStack;
