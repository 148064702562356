import React from 'react';

import { Image, Modal, ModalProps } from 'antd';

import { Box, Button, Flex } from '@portal/components/v2';

import { useReduxState } from '@portal/hooks';
import { translate } from '@portal/services';
import { ProductType, ProfileType, arrayEducatorProfiles } from '@portal/enum';
import { RenderCondition } from '@portal/utils';

import './ModalStartOnboarding.less';

const ModalStartOnboarding = ({
  onCancel,
  onOk,
  ...rest
}: Omit<ModalProps, 'footer' | 'width' | 'closeIcon' | 'centered' | 'bodyStyle' | 'open'>) => {
  const {
    auth: { me: userInfos },
    lmsOnboarding: { showModalStartOnboarding },
    brand: { brandProductConfig: lmsInfos },
  } = useReduxState();

  const renderI18n = (restRoute: string, params?: object) => {
    return translate(`PAGES.GROUPS.GROUPS_PAGE.MODAL_START_ONBOARDING.${restRoute}`, params);
  };

  const isStudent =
    userInfos?.profileType === ProfileType.STUDENT ||
    (!!userInfos?.profileType &&
      arrayEducatorProfiles.includes(userInfos?.profileType) &&
      lmsInfos?.product === ProductType.LMS_EDUCATOR);

  const isElectiveOnboarding = window.location.pathname.includes('content');

  return (
    <Modal
      {...rest}
      open={showModalStartOnboarding}
      onCancel={onCancel}
      width={850}
      footer={false}
      closeIcon
      bodyStyle={{ padding: 0 }}
      centered
    >
      <Box className="modal-start-onboarding__body">
        <RenderCondition condition={!isElectiveOnboarding}>
          <Flex className="modal-start-onboarding__header">
            <Flex className="modal-start-onboarding__card-logo">
              <Image className="modal-start-onboarding__logo" src={lmsInfos?.logoUrl} preview={false} />
            </Flex>

            <h2 className="modal-start-onboarding__title-product" style={{ color: lmsInfos?.primaryColor }}>
              {lmsInfos?.name}
            </h2>
          </Flex>
        </RenderCondition>

        <Box>
          <RenderCondition condition={!isElectiveOnboarding}>
            <h1 className="modal-start-onboarding__title" style={{ color: lmsInfos?.primaryColor }}>
              {renderI18n('TITLE')}
            </h1>
          </RenderCondition>

          <p style={{ color: '#576B75', fontSize: '16px' }}>
            <RenderCondition condition={isStudent && !isElectiveOnboarding}>
              {renderI18n('DESCRIPTIONS.STUDENT')}
            </RenderCondition>
            <RenderCondition condition={!isStudent && !isElectiveOnboarding}>
              {renderI18n('DESCRIPTIONS.TUTOR')}
            </RenderCondition>
            <RenderCondition condition={isElectiveOnboarding}>{renderI18n('DESCRIPTIONS.ELECTIVE')}</RenderCondition>
          </p>

          <Flex className="modal-start-onboarding__footer">
            <Button
              size="lg"
              variant="secondary"
              label={renderI18n('FOOTER.SKIP')}
              primaryColor={lmsInfos?.primaryColor}
              onClick={onCancel}
              className="modal-start-onboarding__footer__button"
            />
            <Button
              size="lg"
              label={renderI18n('FOOTER.SEE_TUTORIAL')}
              primaryColor={lmsInfos?.primaryColor}
              onClick={onOk}
              className="modal-start-onboarding__footer__button"
            />
          </Flex>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalStartOnboarding;
