import { getInstance } from './instance';

const MODULE_BASE_URL = '/academic-year';

const AcademicYearApi = {
  getAllAcademicYears: async (params?: { segmentKey?: number }) => {
    const instance = getInstance();
    const { data } = await instance.get(`${MODULE_BASE_URL}/selectives`, {
      params,
    });

    return data;
  },
};

export default AcademicYearApi;
