import { getInstance } from './instance';

const BASE_URL = '/virtual-group';

const virtualGroupAPI = {
  sendReport: async (virtualGroupId: string, addressee: string): Promise<boolean> => {
    const instance = getInstance({ api: 'new' });

    const { data } = await instance.post(`${BASE_URL}/${virtualGroupId}/send-report`, { addressee });

    return data;
  },
};

export default virtualGroupAPI;
