import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IDynamicButton } from '@portal/types';
import { dynamicButtonApi } from '@portal/repositories';

export const useGetMyDynamicButtons = (
  options?: UseQueryOptions<IDynamicButton[]>
): UseQueryResult<IDynamicButton[]> => {
  const response = useQuery<IDynamicButton[]>({
    queryKey: ['dynamic-button-my-list'],
    queryFn: () => dynamicButtonApi.getMyDynamicButtons(),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    ...options,
  });

  return response;
};
