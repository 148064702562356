import { userLogApi } from '@portal/repositories';
import { IUserLog } from '@portal/types';
import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

export const useGetUserLog = (
  params: Pick<IUserLog, 'idReferral' | 'type'>,
  options?: UseQueryOptions<IUserLog[], unknown, IUserLog[], QueryKey>
): UseQueryResult<IUserLog[]> => {
  const response = useQuery<IUserLog[]>({
    queryKey: ['get-user-log', params],
    queryFn: () => userLogApi.getLog(params),
    ...options,
  });

  return response;
};
