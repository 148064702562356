/* eslint-disable no-unused-vars */
export enum UserLog {
  AUTHENTICATION = 1,
  CONTENT_LIBRARY_ACCESS = 2,
  LMS_ACCESS = 3,
  PIJ_ACCESS = 4,
  PROJET_ACCESS = 5,
  IEA_ACCESS = 6,
  CONTENT_ACCESS = 7,
  START_CONTENT_LIBRARY_VIDEO = 8,
  END_CONTENT_LIBRARY_VIDEO = 9,
  STUDENT_JOINED_CLASS = 10,
  USER_BRAND_ACTIVE = 11,
  USER_BRAND_INACTIVE = 12,
  BC_DOWNLOAD_CONTENT = 13,
  BC_ACCESS = 14,
  VIEW_MANAGEMENT_LMS_STUDENT = 15,
  VIEW_LMS_STUDENT = 16,
  VIEW_LMS_EDUCATOR = 17,
  VIEW_LMS_COURSE = 18,
}
