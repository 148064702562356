import { Tag } from '@portal/models/module';
import {
  ALL_COURSES,
  ALL_TAGS,
  CONCLUDED_COURSE,
  COURSE_DETAILS,
  COURSE_DETAILS_USERS,
  COURSE_KNOWLEDGE_AREA_FILTER_TAGS,
  COURSE_MINUTES_PROGRESS,
  COURSE_PENDING_ACTIVITIES,
  COURSE_PROGRESS,
  COURSE_TAG,
  COURSE_THEMATIC_FILTER_TAGS,
  MINE_COURSE,
  OTHER_COURSE,
  SET_EMPTY_COURSES,
} from '~/actions/actionTypes';

const initialState: reducers.CourseReducer = {
  courses: {
    rows: [],
    count: 0,
  },
  mineCourses: {
    rows: [],
    count: 0,
  },
  otherCourses: {
    rows: [],
    count: 0,
    allowStudentEnrollment: false,
  },
  concludedCourses: {
    rows: [],
    count: 0,
  },
  courseDetails: {} as reducers.CourseDetails,
  courseUsers: [],
  courseProgress: {} as reducers.CourseProgress,
  courseMinutesProgress: {} as reducers.CourseMinutesProgress,
  thematicFilters: [],
  knowledgeAreaFilters: [],
  pendingActivities: [],
  courseTag: {} as Tag,
  allTags: {
    rows: [],
    count: 0,
  },
};

const courseListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ALL_COURSES:
      state = {
        ...state,
        courses: action.payload,
      };
      break;

    case MINE_COURSE:
      state = {
        ...state,
        mineCourses: action.payload,
      };
      break;

    case OTHER_COURSE:
      state = {
        ...state,
        otherCourses: action.payload,
      };
      break;

    case CONCLUDED_COURSE:
      state = {
        ...state,
        concludedCourses: action.payload,
      };
      break;

    case COURSE_DETAILS:
      state = {
        ...state,
        courseDetails: action.payload,
      };
      break;

    case COURSE_DETAILS_USERS:
      state = {
        ...state,
        courseUsers: action.payload.rows,
      };
      break;

    case COURSE_PROGRESS:
      state = {
        ...state,
        courseProgress: action.payload,
      };
      break;

    case COURSE_MINUTES_PROGRESS:
      state = {
        ...state,
        courseMinutesProgress: action.payload,
      };
      break;

    case COURSE_THEMATIC_FILTER_TAGS:
      state = {
        ...state,
        thematicFilters: action.payload,
      };
      break;

    case COURSE_KNOWLEDGE_AREA_FILTER_TAGS:
      state = {
        ...state,
        knowledgeAreaFilters: action.payload,
      };
      break;

    case SET_EMPTY_COURSES:
      state = initialState;
      break;

    case COURSE_PENDING_ACTIVITIES:
      state = {
        ...state,
        pendingActivities: action.payload,
      };
      break;

    case COURSE_TAG:
      state = {
        ...state,
        courseTag: action.payload,
      };
      break;

    case ALL_TAGS:
      state = {
        ...state,
        allTags: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default courseListReducer;
