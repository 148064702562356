import React, { ComponentProps } from 'react';

import classNames from 'classnames';

import './Box.less';

const Box = ({ children, className, ...rest }: ComponentProps<'div'>) => {
  return (
    <div {...rest} className={classNames(className, 'atoms-box')}>
      {children}
    </div>
  );
};

export default Box;
