import { Dispatch } from 'redux';

import * as MessageService from '~/services/message';

import {
  GROUPS_ALL,
  GROUPS_BY_ID,
  GROUPS_BY_TUTOR,
  GROUPS_SCHEDULE_BY_ID,
  GROUPS_STATUS_ELECTIVE,
  GROUPS_STUDENTS,
  SET_GROUP_DETAILS,
  UPDATE_GROUP,
  UPDATE_GROUP_SCHEDULE,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

import groupsAPI from '../repositories/group';
import { message } from 'antd';

export const getAllGroups =
  (searchParams: advancedFilterModels.GroupsByTutor, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.PaginationResponse<models.VirtualGroup> = await groupsAPI.getAllGroups({
        ...searchParams,
        offset: searchParams.offset > 0 ? searchParams.offset - 1 : searchParams.offset,
        limit: searchParams.limit,
        isDESC: searchParams.orderBy === 'desc' ? 'true' : 'false',
      });
      dispatch({
        payload,
        type: GROUPS_ALL,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.ENTITY_NOT_FOUND.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getGroupsByTutor =
  (searchParams: advancedFilterModels.GroupsByTutor, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.PaginationResponse<models.GroupsByTutor> = await groupsAPI.getGroupsByTutor({
        ...searchParams,
        offset: searchParams.offset > 0 ? searchParams.offset - 1 : searchParams.offset,
        limit: searchParams.limit,
        isDESC: searchParams.orderBy === 'desc' ? 'true' : 'false',
      });
      dispatch({
        payload,
        type: GROUPS_BY_TUTOR,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.ENTITY_NOT_FOUND.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getGroupScheduleById = (id: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload: models.GroupSchedule = await groupsAPI.getGroupScheduleById(id);
    dispatch({
      payload,
      type: GROUPS_SCHEDULE_BY_ID,
    });
  } catch (err) {
    MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getStudent =
  (id: string, searchParams: advancedFilterModels.BaseFilter, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await groupsAPI.getStudentsByGroups(id, {
        ...searchParams,
      });
      dispatch({
        payload,
        type: GROUPS_STUDENTS,
      });
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getGroupById = (id: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload = await groupsAPI.getGroupsById(id);
    dispatch({
      payload,
      type: GROUPS_BY_ID,
    });
  } catch (err) {
    MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const createGroup =
  (params: models.CreateGroup, callback?: () => void, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      await groupsAPI.createGroup(params);
      MessageService.success('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.SUCCESS_MESSAGE');
      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const addStudents =
  (id: string, idStudent: string[], callback: () => void, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await groupsAPI.addStudent(id, idStudent);

      dispatch({
        payload,
        type: SET_GROUP_DETAILS,
      });

      callback();

      message.success('Aluno(s) matriculados!');
    } catch (err) {
      if (err.response?.data?.error === 'limit_of_users_reached') {
        message.error('Desculpe, não há mais vagas disponíveis para esta eletiva.');

        return;
      }

      message.error('Não foi possível matricular o(s) aluno(s).');
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const closeGroup =
  (id: string, brandProductId: string, callback: () => void, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await groupsAPI.closeGroup(id, { brandProductId });
      dispatch({
        payload,
        type: SET_GROUP_DETAILS,
      });
      callback();
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getStatusElective =
  (idGroup: string, idUser: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await groupsAPI.getDetailsUserElectives(idGroup, idUser);
      dispatch({
        payload,
        type: GROUPS_STATUS_ELECTIVE,
      });
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const updateGroup =
  (id: string, params: models.CreateGroup, callback?: () => void, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await groupsAPI.updateGroup(id, params);
      dispatch({
        payload,
        type: UPDATE_GROUP,
      });
      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const updateGroupSchedule =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (id: string, params: any, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.GroupSchedule = await groupsAPI.updateGroupSchedule(id, params);
      dispatch({
        payload,
        type: UPDATE_GROUP_SCHEDULE,
      });
      MessageService.success('SHARED.MESSAGES.SCHEDULE_UPDATE_SUCCESS');
    } catch (err) {
      MessageService.error(`SHARED.API_ERROR_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const removeStudent =
  (id: string, idStudent: string[], callback: () => void, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await groupsAPI.removeStudent(id, idStudent);
      dispatch({
        payload,
        type: SET_GROUP_DETAILS,
      });
      callback();
    } catch (err) {
      MessageService.error('SHARED.API_ERROR_MESSAGES.'.concat(err.message));
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
