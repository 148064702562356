import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

interface IProps {
  placeholder?: string;
  handleClickSearch?: () => void;
  handleChange: (e: string) => void;
}

const SearchBar: React.FC<IProps> = ({ placeholder, handleClickSearch, handleChange }) => {
  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClickSearch?.();
    }
  };

  return (
    <div className="searchBar-container">
      <div className="searchBar-container__input">
        <Input
          className="searchBar-container__input__content"
          placeholder={placeholder}
          style={{ border: 0, textAlign: 'left' }}
          onKeyUp={handleSearch}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <SearchOutlined
        className="searchBar-container__icon"
        onClick={handleClickSearch}
        style={{ opacity: '0.4', fontSize: '25px', ...(!!handleClickSearch && { cursor: 'pointer' }) }}
      />
    </div>
  );
};

export default SearchBar;
