import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { CourseActions, SatisfactionSurveyActions } from '@portal/actions';

import Btn from '../../Btn';
import RenderHTML from '../../RenderHTML';
import StatusLabel from '../../ContentComponents/StatusLabel/StatusLabel';
import LockedContent from '../../ContentComponents/LockedContent/LockedContent';
import SatisfactionSurveyQuestion from '../SatisfactionSurveyQuestion';

import { useReduxState } from '@portal/hooks';
import { RenderCondition, isDashTutor } from '@portal/utils';
import { translate } from '@portal/services';
import { BUTTON_SIZE, ProductType, SatisfactionSurveyStatus } from '@portal/enum';
import { getStackPath } from '@portal/config/routes';
import * as StorageService from '@portal/services/storage';
import { SHOW_MODAL_SATISFACTION_SURVEY } from '@portal/actions/actionTypes';
import { downloadCertificate } from '@portal/actions/certificate';

interface IPanelSatisfactionSurvey {
  isModal?: boolean;
}

const PanelSatisfactionSurvey: React.FC<IPanelSatisfactionSurvey> = ({ isModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef<HTMLAnchorElement | null>(null);

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const productId = StorageService.getItem('brandProductsConfig')?.id || '';

  const {
    course: { courseProgress },
    satisfactionSurvey: { satisfactionSurvey, satisfactionSurveyCourseId },
    loading: { localAmount },
    auth: { me },
  } = useReduxState();

  const isTutorDashTutor = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;

  const courseId = isModal ? satisfactionSurveyCourseId : courseProgress?.id;

  const [questionAnswers, setQuestionAnswers] = useState<models.SatisfactionSurveyAnswerAPI[]>([]);

  useEffect(() => {
    if (courseProgress?.satisfactionSurveyId) {
      dispatch(SatisfactionSurveyActions.getSatisfactionSurvey(courseProgress.satisfactionSurveyId, () => {}, true));
    }
  }, [courseProgress]);

  const setAnswers = (params: { questionId: string; alternativeAnswer?: string; descriptiveAnswer?: string }) => {
    const newItem: models.SatisfactionSurveyAnswerAPI = {
      alternativeAnswer: params?.alternativeAnswer,
      questionId: params?.questionId,
      descriptiveAnswer: params?.descriptiveAnswer,
    };

    const verifyExistItem = questionAnswers?.filter((item) => item?.questionId === params?.questionId);

    const filterArray = questionAnswers?.filter((item) => item?.questionId !== params?.questionId);

    if (!verifyExistItem) {
      setQuestionAnswers((item) => [...item, newItem]);
    } else {
      setQuestionAnswers([...filterArray, newItem]);
    }
  };

  const callbackSubmit = () => {
    if (isModal) {
      dispatch({
        payload: false,
        type: SHOW_MODAL_SATISFACTION_SURVEY,
      });

      dispatch(downloadCertificate(courseId as string, ref));

      dispatch(
        CourseActions.getConcludedCourses({
          limit: 12,
          offset: 1,
          productId,
        })
      );

      return;
    }

    history.replace(`${getStackPath('COURSES')}/concluded`);
  };

  const handleSubmit = () => {
    dispatch(
      SatisfactionSurveyActions.sendSatisfactionSurveyAnswer(
        satisfactionSurvey?.id,
        questionAnswers,
        courseId as string,
        callbackSubmit,
        true
      )
    );
  };

  return (
    <div className="satisfaction-survey-container">
      <RenderCondition
        condition={
          (!courseProgress?.courseUser?.[0]?.satisfactionSurveyStatus ||
            courseProgress?.courseUser?.[0]?.satisfactionSurveyStatus === SatisfactionSurveyStatus.BLOCKED) &&
          !isTutorDashTutor &&
          !isModal
        }
      >
        <LockedContent isSatisfactionSurvey />
      </RenderCondition>

      <RenderCondition
        condition={
          courseProgress?.courseUser?.[0]?.satisfactionSurveyStatus === SatisfactionSurveyStatus.PENDING ||
          isTutorDashTutor ||
          isModal
        }
      >
        <div>
          <h5 className="satisfaction-survey-container__title mb-4">{satisfactionSurvey?.title}</h5>
          <RenderHTML htmlText={satisfactionSurvey?.description as string} />
        </div>
        <div>
          {!!satisfactionSurvey?.questions?.length &&
            satisfactionSurvey?.questions?.map((question, key) => (
              <div key={key}>
                <SatisfactionSurveyQuestion
                  options={question?.options}
                  question={question}
                  setAnswers={setAnswers}
                  isDisabled={isTutorDashTutor}
                />
              </div>
            ))}
        </div>
        <div className={`mt-5 ${!isModal && 'mb-5'}`}>
          <Btn
            title={translate('COMPONENTS.SATISFACTION_SURVEY.SUBMIT_BUTTON')}
            size={BUTTON_SIZE.FULL}
            onClick={handleSubmit}
            disabled={satisfactionSurvey?.questions?.length !== questionAnswers?.length || isTutorDashTutor}
            isLoading={localAmount > 0}
          />
        </div>
      </RenderCondition>

      <RenderCondition
        condition={courseProgress?.courseUser?.[0]?.satisfactionSurveyStatus === SatisfactionSurveyStatus.DONE}
      >
        <h5 className="satisfaction-survey-container__title mb-4">{satisfactionSurvey?.title}</h5>
        <StatusLabel
          currentStatus={SatisfactionSurveyStatus.DONE}
          doneStatus={SatisfactionSurveyStatus.DONE}
          doneLabel={translate('COMPONENTS.SATISFACTION_SURVEY.MESSAGES.ALREADY_DONE')}
        />
      </RenderCondition>

      <a ref={ref} target="_blank" download={`Certificado - ${courseProgress?.title}`} />
    </div>
  );
};

export default PanelSatisfactionSurvey;
