import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Modal, Popconfirm, Table, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { GroupActions, UserActions } from '@portal/actions';

import ModalSendReportStudents from '../ModalSendReportStudents';
import { Btn, Circle, CloseGroupModal, SearchBarUser, AddStudentsModal, StatusCourseModal } from '@portal/components';
import IcCertificate from '@portal/components/v1/Icon/IcCertificate';
import IcDeclaration from '@portal/components/v1/Icon/IcDeclaration';
import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';

import { translate } from '@portal/services';
import { useReduxState } from '@portal/hooks';
import { getStatusTask, TasksStatus } from '@portal/enum/taskStatus';
import { getStatusSatisfaction, SatisfactionSurveyStatus } from '@portal/enum/satisfactionSurvey';
import { getCookie } from '@portal/services/cookie';
import { RenderCondition, calcPercent } from '@portal/utils';
import { downloadCertificate } from '@portal/actions/certificate';
import returnPronounWithCourseTerm from '@portal/utils/returnPronounWithCourseTerm';

const Groups: React.FC = () => {
  const paramns: { id: string } = useParams();
  const dispatch = useDispatch();
  const {
    group,
    auth: { me },
  } = useReduxState();
  const [idStudents, setIdStudents] = useState<string[]>([]);
  const [idStudent, setIdStudent] = useState<string[]>([]);

  const ref = useRef<HTMLAnchorElement | null>(null);

  const studentsInfo = group?.students;
  const groupInfos = group?.details;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalAddStudents, setShowModalAddStudents] = useState<boolean>(false);
  const [showModalSendReportStudents, setShowModalSendReportStudents] = useState<boolean>(false);
  const [showModalRemoveStudents, setShowModalRemoveStudents] = useState<boolean>(false);
  const [showModalStatusCourse, setShowModalStatusCourse] = useState<boolean>(false);
  const [studentSearchValue, setStudentSearchValue] = useState<string>('');

  const [paginationStudentList, setPaginationStudentList] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    dispatch(GroupActions.getGroupById(paramns.id));
  }, []);

  useEffect(() => {
    if (me?.idSchool && groupInfos?.capacity && paramns.id && !studentSearchValue)
      dispatch(
        GroupActions.getStudent(paramns.id, {
          ...paginationStudentList,
          schoolId: me?.idSchool,
          orderBy: 'name',
        })
      );
  }, [groupInfos?.capacity, me, paramns.id, studentSearchValue, paginationStudentList?.offset]);

  const openPopRemoveStudent = (id: string) => {
    const index = idStudent.indexOf(id);

    if (index === -1) {
      setIdStudent([id]);
    }

    if (index > -1) {
      setIdStudent([]);
    }
  };

  const callBackRemoveStudent = () => {
    dispatch(
      GroupActions.getStudent(paramns.id, {
        limit: 10,
        offset: 0,
        schoolId: me?.idSchool,
      })
    );
  };

  const removeStudents = (arrayId: string[], setArrayIds: React.Dispatch<React.SetStateAction<string[]>>) => {
    dispatch(GroupActions.removeStudent(paramns.id, arrayId, callBackRemoveStudent));
    setArrayIds([]);
  };

  const studentSelected = (value: React.Key[]) => {
    setIdStudents([...(value as string[])]);
  };

  const showModalStatus = (id: string) => {
    setShowModalStatusCourse(true);
    dispatch(GroupActions.getStatusElective(paramns.id, id));
    dispatch(UserActions.getUserDetail(id));
  };

  const handleClickSearch = () => {
    dispatch(
      GroupActions.getStudent(paramns.id, {
        limit: groupInfos?.capacity as number,
        offset: 0,
        schoolId: me?.idSchool,
        orderBy: 'name',
        name: studentSearchValue,
      })
    );
  };

  const isSaePartnership = me?.isSaePartnership || getCookie('user-integration');

  const getStatus = (
    status: number,
    enumStatus: {
      BLOCKED?: number;
      PENDING?: number;
      DONE: number;
      TODO?: number;
      WAITING_REVIEW?: number;
    }
  ) => {
    switch (status) {
      case enumStatus.DONE:
        return 'done';

      case enumStatus.TODO:
        return 'todo';

      case enumStatus.PENDING:
        return 'todo';

      case enumStatus.BLOCKED:
        return 'blocked';

      case enumStatus.WAITING_REVIEW:
        return 'waitting';

      default:
        return 'done';
    }
  };

  const viewStatus = (
    status: number,
    functionGetStatus: (value: string | number) => string | undefined,
    enumStatus: {
      BLOCKED?: number;
      PENDING?: number;
      DONE: number;
      TODO?: number;
    }
  ) => {
    return (
      <div className="status">
        <span>{functionGetStatus(status)}</span>
        <Circle variant={getStatus(status, enumStatus)} />
      </div>
    );
  };

  const renderIconCertificate = (statusCourse: number, courseId: string, userId: string) => {
    return (
      <div className="certificate-icon" onClick={() => dispatch(downloadCertificate(courseId, ref, userId))}>
        {statusCourse === TasksStatus.DONE ? <IcCertificate /> : <IcDeclaration />}
      </div>
    );
  };

  const columns = [
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.NAME'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.EMAIL'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.CONTENT'),
      dataIndex: 'userCourses',
      key: 'userCourses',
      render: (item: models.GroupUsers[]) => (
        <div>
          {item.map(
            (o) =>
              `${calcPercent(o.contentCount as string, o.contentViewed as string)} - ${o.contentViewed}/${
                o.contentCount
              }`
          )}
        </div>
      ),
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.ACTIVITIES'),
      dataIndex: 'userCourses',
      key: 'userCourses',
      render: (item: models.GroupUsers[]) => (
        <div>
          {item.map(
            (o) =>
              `${calcPercent(o.activitiesCount as string, o.activitiesDoneCount as string)} - ${
                o.activitiesDoneCount
              }/${o.activitiesCount}`
          )}
        </div>
      ),
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.CLOSURE'),
      dataIndex: 'userCourses',
      key: 'userCourses',
      render: (item: models.GroupUsers[]) => (
        <div>{item.map((o) => viewStatus(o.testStatus as number, getStatusTask, TasksStatus))}</div>
      ),
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.SURVEY'),
      dataIndex: 'userCourses',
      key: 'userCourses',
      render: (item: models.GroupUsers[]) => (
        <div>
          {item.map((o) =>
            viewStatus(o.satisfactionSurveyStatus as number, getStatusSatisfaction, SatisfactionSurveyStatus)
          )}
        </div>
      ),
    },
    {
      title: returnPronounWithCourseTerm()?.status,
      key: 'id',
      dataIndex: 'id',
      render: (id: string) => (
        <span className="container_table__detailsElective" onClick={() => showModalStatus(id)}>
          {translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.STATUS_ELECTIVE.TEXT')}
        </span>
      ),
    },
    {
      title: '',
      key: 'userCourses',
      dataIndex: 'userCourses',
      render: (userCourses: models.GroupUsers[]) => {
        return (
          <RenderCondition
            condition={
              !!groupInfos?.closedAt && userCourses?.[0]?.satisfactionSurveyStatus === SatisfactionSurveyStatus.DONE
            }
          >
            {renderIconCertificate(
              Number(userCourses?.[0]?.testStatus),
              userCourses?.[0]?.courseId as string,
              userCourses?.[0]?.userId as string
            )}
          </RenderCondition>
        );
      },
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      render: (id: string) => {
        if (!isSaePartnership) {
          return (
            <>
              <Popconfirm
                title={translate('PAGES.GROUPS.GROUPS_PAGE.COLUMN_TABLE.POPCONFIRM')}
                onConfirm={() => removeStudents(idStudent, setIdStudent)}
                onCancel={() => setIdStudents([])}
              >
                <a onClick={() => openPopRemoveStudent(id)}>
                  <CloseOutlined />
                </a>
              </Popconfirm>
            </>
          );
        }
      },
    },
  ];

  return (
    <>
      <Container fluid className="groups-page">
        <Row>
          <img
            src={groupInfos?.course?.photoUrl ? groupInfos?.course?.photoUrl : EmptyBanner}
            alt="banner"
            width={'100%'}
            className="groups-page__banner"
          />
        </Row>

        <Col className="container_title mt-5">
          <Col className="column-responsive groups-page__center">
            <Col>
              <span className="groups-page__title">{groupInfos?.course?.title}</span>
            </Col>

            <Col className="groups-page__subtitle__container mt-4">
              <span className="groups-page__subtitle">
                <span className="groups-page__subtitle__bold">{translate('PAGES.GROUPS.GROUPS_PAGE.SUBTITLE')}</span>
                {groupInfos?.name}
              </span>
              {groupInfos?.closedAt && (
                <span className="groups-page__subtitle__closed">
                  {translate('PAGES.GROUPS.GROUPS_PAGE.CLOSEGROUP')}
                </span>
              )}
            </Col>
          </Col>

          <Col className="groups-page__title-bar column-responsive mt-3">
            <Row className="mt-2 ml-3">
              <span className="groups-page__title-bar__text">
                {translate('PAGES.GROUPS.GROUPS_PAGE.AVAILABILITY')}: {studentsInfo?.count}/{groupInfos?.capacity}
              </span>
            </Row>
            <Row className="mt-2">
              <span className="groups-page__title-bar__text">
                {`${translate('PAGES.GROUPS.GROUPS_PAGE.CREATION_DATE')}: ${format(
                  new Date((groupInfos?.createdAt as string) || new Date()),
                  'Pp',
                  {
                    locale: ptBR,
                  }
                )}`}
              </span>
            </Row>
            {groupInfos?.closedAt ? (
              <Row className="mt-2 gap">
                <Btn variant="primary" onClick={() => setShowModalSendReportStudents(true)}>
                  {translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.SEND_REPORT')}
                </Btn>

                <span className="groups-page__title-bar__text">
                  {`${translate('PAGES.GROUPS.GROUPS_PAGE.CLOSE_DATE')}: ${format(
                    new Date((groupInfos?.closedAt as string) || new Date()),
                    'Pp',
                    {
                      locale: ptBR,
                    }
                  )}`}
                </span>
              </Row>
            ) : (
              <Row className="groups-page__center gap mt-2">
                <Btn variant="primary" onClick={() => setShowModalSendReportStudents(true)}>
                  {translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.SEND_REPORT')}
                </Btn>
                <RenderCondition condition={!groupInfos?.schoolHasCertificateSettings}>
                  <Tooltip placement="top" title={translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.TOOLTIP')}>
                    <div>
                      <Btn variant="primary" disabled>
                        {translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.FINISH')}
                      </Btn>
                    </div>
                  </Tooltip>
                </RenderCondition>
                <RenderCondition condition={groupInfos?.schoolHasCertificateSettings}>
                  <Btn variant="primary" onClick={() => setShowModal(true)}>
                    {translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.FINISH')}
                  </Btn>
                </RenderCondition>
                <RenderCondition condition={!isSaePartnership}>
                  <RenderCondition
                    condition={
                      !!groupInfos?.enrollmentEndDate && new Date(groupInfos?.enrollmentEndDate as string) < new Date()
                    }
                  >
                    <Tooltip placement="top" title={translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.TOOLTIP_DATE')}>
                      <div>
                        <Btn variant="primary" disabled>
                          {translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.ENROLL')}
                        </Btn>
                      </div>
                    </Tooltip>
                  </RenderCondition>
                  <RenderCondition
                    condition={
                      !groupInfos?.enrollmentEndDate || new Date(groupInfos?.enrollmentEndDate as string) > new Date()
                    }
                  >
                    <Btn variant="primary" onClick={() => setShowModalAddStudents(true)}>
                      {translate('PAGES.GROUPS.GROUPS_PAGE.BUTTONS.ENROLL')}
                    </Btn>
                  </RenderCondition>
                </RenderCondition>
              </Row>
            )}
          </Col>
        </Col>

        <Col className="mt-4 container_table">
          <Row className="align-items-center">
            <div className="mr-3">
              <strong>Pesquisar aluno:</strong>
            </div>

            <SearchBarUser
              handleChange={setStudentSearchValue}
              handleClickSearch={handleClickSearch}
              placeholder="Digite o nome do aluno"
            />
          </Row>
          <div className="mt-2">
            <Table
              rowKey={(data) => data.id}
              rowSelection={{
                onChange: (rowsSelect) => {
                  studentSelected(rowsSelect);
                },
              }}
              columns={columns}
              dataSource={studentsInfo?.rows || []}
              pagination={{
                onChange: (e) => {
                  setPaginationStudentList({
                    limit: 10,
                    offset: e - 1,
                  });
                },
                total: studentsInfo?.count,
                defaultCurrent: 1,
                showSizeChanger: false,
              }}
            />
          </div>
          <div className="container_table__buttonRemove">
            {idStudents.length > 0 && (
              <Btn size={2} variant="primary" onClick={() => setShowModalRemoveStudents(true)}>
                {`${translate('PAGES.GROUPS.GROUPS_PAGE.REMOVE_STUDENTS.START')} ${
                  idStudents.length > 1
                    ? translate('PAGES.GROUPS.GROUPS_PAGE.REMOVE_STUDENTS.STUDENTS')
                    : translate('PAGES.GROUPS.GROUPS_PAGE.REMOVE_STUDENTS.STUDENT')
                }`}
              </Btn>
            )}
          </div>
        </Col>

        <CloseGroupModal openModal={showModal} closeModal={setShowModal} />

        <AddStudentsModal openModal={showModalAddStudents} closeModal={setShowModalAddStudents} />

        <StatusCourseModal openModal={showModalStatusCourse} closeModal={setShowModalStatusCourse} />

        <ModalSendReportStudents
          setShowModal={setShowModalSendReportStudents}
          showModal={showModalSendReportStudents}
        />

        <Modal
          visible={showModalRemoveStudents}
          onCancel={() => setShowModalRemoveStudents(false)}
          bodyStyle={{ padding: '0 1.5rem 1.5rem 0' }}
          footer={null}
          style={{ top: '20%' }}
          width={455}
        >
          <div>
            <Col className="modal-close-group__spacing mt-4">
              <Row>
                <span className="modal-close-group__title__bold mt-4">
                  {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_REMOVE.TITLE')}
                </span>
              </Row>

              <Row className="pt-5">
                <span className="modal-close-group__subtitle">
                  <span className="">
                    {`${translate('PAGES.GROUPS.GROUPS_PAGE.REMOVE_STUDENTS.MODAL')} ${idStudents.length} ${
                      idStudents.length > 1
                        ? translate('PAGES.GROUPS.GROUPS_PAGE.REMOVE_STUDENTS.STUDENTS')
                        : translate('PAGES.GROUPS.GROUPS_PAGE.REMOVE_STUDENTS.STUDENT')
                    }`}
                  </span>
                </span>
              </Row>
            </Col>
          </div>
          <Row className="pt-5">
            <Col className="modal-close-group__buttons">
              <Row className="modal-close-group__gap btn-responsive mt-2">
                <Btn
                  variant="primary"
                  onClick={() => {
                    removeStudents(idStudents, setIdStudents);
                    setShowModalRemoveStudents(false);
                  }}
                >
                  {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_REMOVE.BUTTON.REMOVE')}
                </Btn>
                <Btn variant="secondary" onClick={() => setShowModalRemoveStudents(false)}>
                  {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_REMOVE.BUTTON.CANCEL')}
                </Btn>
              </Row>
            </Col>
          </Row>
        </Modal>
      </Container>

      <a ref={ref} target="_blank" download={`Certificado - ${groupInfos?.course?.title}`} />
    </>
  );
};

export default Groups;
