import React, { ComponentProps } from 'react';

import classNames from 'classnames';

import './Flex.less';

const Flex = ({ children, className, ...rest }: ComponentProps<'div'>) => {
  return (
    <div {...rest} className={classNames(className, 'atoms-flex')}>
      {children}
    </div>
  );
};

export default Flex;
