/* eslint-disable max-len */
import React, { FC } from 'react';

const IcWelcomeCourse: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="68" viewBox="0 0 86 68" fill="none">
      <path
        d="M80.8447 1.1136C72.836 0.411084 64.5463 1.1136 56.6781 2.79964C52.3225 3.64266 53.587 9.96531 57.6616 10.1058C65.3893 10.6678 73.117 10.9488 80.8447 11.0893C87.3078 11.0893 87.1673 1.67561 80.8447 1.1136Z"
        fill="currentColor"
      />
      <path
        d="M26.8915 0.270431C18.8828 -0.432086 10.5931 0.270429 2.72496 1.95647C-1.63065 2.79949 -0.366114 9.12214 3.70848 9.26264C11.4362 9.82466 19.1639 10.1057 26.8915 10.2462C33.3547 10.2462 33.0737 0.832444 26.8915 0.270431Z"
        fill="currentColor"
      />
      <path
        d="M68.1993 34.9746C66.3728 36.5202 66.2323 37.7847 65.6702 39.8923C65.2487 41.9998 64.1247 43.8263 63.0007 45.6529C60.6121 49.4465 57.521 52.8186 53.7275 55.3476C46.2808 60.2652 37.2886 61.1083 28.7179 62.5133C27.5938 62.6538 27.5938 64.3398 28.4369 64.9018C37.8506 70.241 49.7934 67.4309 58.3641 61.8108C62.7197 59.0007 66.5133 55.2071 69.6043 50.992C72.1334 47.3389 76.489 41.2973 74.9435 36.8012C73.9599 33.7101 70.5879 33.0076 68.1993 34.9746Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcWelcomeCourse;
