import React from 'react';

import TopBarChat from '@portal/components/v1/TopBarChat/TopBarChat';

interface IProps {
  Component: React.ReactNode;
  isConversation?: boolean;
}

const HeaderChatWrapper: React.FC<IProps> = ({ Component, isConversation }) => {
  return (
    <>
      <TopBarChat isConversation={isConversation} />
      {Component}
    </>
  );
};

export default HeaderChatWrapper;
