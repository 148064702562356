import { COURSE_CONTENT, COURSE_CONTENT_PROGRESS, COURSE_CONTENT_SCHEDULE } from '@portal/actions/actionTypes';

const initialState: reducers.ContentReducer = {
  content: {} as reducers.ContentCourse,
  contentSchedule: {} as reducers.ContentCourseSchedule,
  contentProgress: {} as reducers.ContentCourseProgress,
};

const activityReducer = (state = initialState, action: { type: string, payload: never }): reducers.ContentReducer => {
  switch (action.type) {
    case COURSE_CONTENT:
      state = {
        ...state,
        content: action.payload,
      };
      break;

    case COURSE_CONTENT_SCHEDULE:
      state = {
        ...state,
        contentSchedule: action.payload,
      };
      break;

    case COURSE_CONTENT_PROGRESS:
      state = {
        ...state,
        contentProgress: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default activityReducer;
