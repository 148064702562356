export const INCREASE_LOADING = 'INCREASE_LOADING';
export const INCREASE_LOCAL_LOADING = 'INCREASE_LOCAL_LOADING';
export const DECREASE_LOADING = 'DECREASE_LOADING';
export const DECREASE_LOCAL_LOADING = 'DECREASE_LOCAL_LOADING';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ME = 'AUTH_ME';
export const AUTH_CHECK_LOGGED = 'AUTH_CHECK_LOGGED';

export const PUSH_NOTIFICATION_REPORT = 'PUSH_NOTIFICATION_REPORT';
export const PUSH_NOTIFICATION_DETAIL = 'PUSH_NOTIFICATION_DETAIL';

export const USER_REPORT = 'USER_REPORT';
export const USER_DETAIL = 'USER_DETAIL';
export const USER_PRODUCTS = 'USER_PRODUCTS';
export const USER_TEACHER_REVIEWS = 'USER_TEACHER_REVIEWS';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const USERS_ACTIVITIES = 'USERS_ACTIVITIES';
export const USERS_ACTIVITIES_HISTORY = 'USERS_ACTIVITIES_HISTORY';
export const USERS_ACTIVITIES_HISTORY_LOADING = 'USERS_ACTIVITIES_HISTORY_LOADING';
export const STUDENTS_DETAIL = 'STUDENTS_DETAIL';
export const PENDING_TEST_LIST = 'PENDING_TEST_LIST';
export const CHANGED_SCHOOL = 'CHANGED_SCHOOL';

export const ALL_COURSES = 'ALL_COURSES';
export const MINE_COURSE = 'MINE_COURSE_LIST';
export const OTHER_COURSE = 'OTHER_COURSE_LIST';
export const CONCLUDED_COURSE = 'CONCLUDED_COURSE';
export const SET_EMPTY_COURSES = 'SET_EMPTY_COURSES';
export const COURSE_DETAILS = 'COURSE_DETAILS';
export const COURSE_DETAILS_USERS = 'COURSE_DETAILS_USERS';
export const COURSE_PROGRESS = 'COURSE_PROGRESS';
export const COURSE_MINUTES_PROGRESS = 'COURSE_MINUTES_PROGRESS';

export const ACTIVITY_DETAILS = 'ACTIVITY_DETAILS';
export const ACTIVITY_QUESTIONS_ANSWERED = 'ACTIVITY_QUESTIONS_ANSWERED';
export const SPECIFIC_QUESTION = 'SPECIFIC_QUESTION';

export const COURSE_CONTENT = 'COURSE_CONTENT';
export const COURSE_CONTENT_SCHEDULE = 'COURSE_CONTENT_SCHEDULE';
export const COURSE_CONTENT_PROGRESS = 'COURSE_CONTENT_PROGRESS';

export const COURSE_TEST = 'COURSE_TEST';
export const COURSE_TEST_DONE = 'COURSE_TEST_DONE';
export const COURSE_TEST_ANSWERED = 'COURSE_TEST_ANSWERED';
export const COURSE_TEST_REVIEWS = 'COURSE_TEST_REVIEWS';
export const COURSE_TEST_REVIEWS_ANSWERED = 'COURSE_TEST_REVIEWS_ANSWERED';
export const COURSE_TEST_VIEW_HISTORY = 'COURSE_TEST_VIEW_HISTORY';
export const COURSE_TEST_HISTORY = 'COURSE_TEST_HISTORY';

export const CLOSURE_TASK = 'CLOSURE_TASK';
export const COURSE_THEMATIC_FILTER_TAGS = 'COURSE_THEMATIC_FILTER_TAGS';
export const COURSE_KNOWLEDGE_AREA_FILTER_TAGS = 'COURSE_KNOWLEDGE_AREA_FILTER_TAGS';

export const COURSE_PENDING_ACTIVITIES = 'COURSE_PENDING_ACTIVITIES';

export const COURSE_TAG = 'COURSE_TAG';
export const ALL_TAGS = 'ALL_TAGS';

export const HOME_BANNERS = 'HOME_BANNERS';

export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS';
export const UNREAD_NOTIFICATIONS_COUNT = 'UNREAD_NOTIFICATIONS_COUNT';
export const SET_NOTIFICATIONS_READ = 'SET_NOTIFICATION_READ';

export const CHAT_MESSAGES = 'CHAT_MESSAGES';
export const CHAT_PARTICIPANTS = 'CHAT_PARTICIPANTS';
export const ADD_CHAT_MESSAGES = 'ADD_CHAT_MESSAGES';
export const PRIVATE_CHATS = 'PRIVATE_CHATS';
export const SEARCH_CHAT = 'SEARCH_CHAT';
export const HAS_SEARCH = 'HAS_SEARCH';

export const SATISFACTION_SURVEY = 'SATISFACTION_SURVEY';
export const SATISFACTION_SURVEY_ANSWER = 'SATISFACTION_SURVEY_ANSWER';

export const TOP_BAR_NAVIGATION = 'TOP_BAR_NAVIGATION';

export const COURSE_TYPE_LOGGED = 'COURSE_TYPE_LOGGED';

export const SIDEBAR_CONTENT_HIDE = 'SIDEBAR_CONTENT_HIDE';

export const BRAND_CONFIG = 'BRAND_CONFIG';
export const BRAND_PRODUCT_CONFIG = 'BRAND_PRODUCT_CONFIG';
export const BRAND_PLAYLIST = 'BRAND_PLAYLIST';

export const USER_COMMENT = 'USER_COMMENT';

export const GROUPS_ALL = 'GROUPS_ALL';
export const GROUPS_BY_ID = 'GROUPS_BY_ID';
export const GROUPS_BY_TUTOR = 'GROUPS_BY_TUTOR';
export const GROUPS_STUDENTS = 'GROUPS_STUDENTS';
export const GROUPS_STATUS_ELECTIVE = 'GROUPS_STATUS_ELECTIVE';
export const GROUPS_STUDENTS_OUTSIDE = 'GROUPS_STUDENTS_OUTSIDE';
export const GROUPS_SCHEDULE_BY_ID = 'GROUPS_SCHEDULE_BY_ID';
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SCHEDULE = 'UPDATE_GROUP_SCHEDULE';

export const TUTOR_VIEW = 'TUTOR_VIEW';

export const SET_TUTOR_CONTENT_DETAIL = 'SET_TUTOR_CONTENT_DETAIL';

export const HOME_NOTIFICATIONS_ALL = 'HOME_NOTIFICATIONS_ALL';

export const CONFIG_PORTAL = 'CONFIG_PORTAL';

export const STUDENT_CLASS_LIST = 'STUDENT_CLASS_LIST';

export const ACADEMIC_YEAR_LIST = 'ACADEMIC_YEAR_LIST';

export const SHOW_MODAL_SATISFACTION_SURVEY = 'SHOW_MODAL_SATISFACTION_SURVEY';
export const SATISFACTION_SURVEY_COURSE_ID = 'SATISFACTION_SURVEY_COURSE_ID';

export const LMS_TRAIL_LIST_WITH_COURSES = 'LMS_TRAIL_LIST_WITH_COURSES';

export const SET_JOURNEY_ALL = 'SET_JOURNEY_ALL';

export const SET_STEP_ACTIVE_LMS_ONBOARDING = 'SET_STEP_ACTIVE_LMS_ONBOARDING';
export const SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING = 'SET_SHOW_MODAL_STEPS_ONBOARDING_LMS_ONBOARDING';
export const SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING = 'SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING';
