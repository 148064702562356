export enum StepsLmsOnboardingStudent {
  NAVIGATION_MENU = 1,
  ELECTIVES = 2,
  SEE_TUTORIAL = 3,
}

export enum StepsLmsOnboardingLmsEducator {
  NAVIGATION_MENU = 1,
  CERTIFICATES = 2,
  TRAILS = 3,
  SEE_TUTORIAL = 4,
}

export enum StepsLmsOnboardingTutor {
  NAVIGATION_MENU = 1,
  VIDEOS = 2,
  MY_GROUPS = 3,
  CARD_GROUP = 4,
  CONFIG_GROUP = 5,
  SEE_TUTORIAL = 6,
}

export enum StepsLmsOnboardingElective {
  REVIEW = 1,
  TEMPLATE = 2,
  PRE_REVIEW = 3,
  HISTORY = 4,
  SEE_TUTORIAL = 5,
}

export const ArrayStepsLmsOnboardingStudent = [
  StepsLmsOnboardingStudent.NAVIGATION_MENU,
  StepsLmsOnboardingStudent.ELECTIVES,
  StepsLmsOnboardingStudent.SEE_TUTORIAL,
];

export const ArrayStepsLmsOnboardingLmsEducator = [
  StepsLmsOnboardingLmsEducator.NAVIGATION_MENU,
  StepsLmsOnboardingLmsEducator.CERTIFICATES,
  StepsLmsOnboardingLmsEducator.TRAILS,
  StepsLmsOnboardingLmsEducator.SEE_TUTORIAL,
];

export const ArrayStepsLmsOnboardingTutor = [
  StepsLmsOnboardingTutor.NAVIGATION_MENU,
  StepsLmsOnboardingTutor.VIDEOS,
  StepsLmsOnboardingTutor.MY_GROUPS,
  StepsLmsOnboardingTutor.CARD_GROUP,
  StepsLmsOnboardingTutor.CONFIG_GROUP,
  StepsLmsOnboardingTutor.SEE_TUTORIAL,
];

export const ArrayStepsLmsOnboardingElective = [
  StepsLmsOnboardingElective.REVIEW,
  StepsLmsOnboardingElective.TEMPLATE,
  StepsLmsOnboardingElective.PRE_REVIEW,
  StepsLmsOnboardingElective.HISTORY,
  StepsLmsOnboardingElective.SEE_TUTORIAL,
];

export const firstStepsLmsOnboarding = [
  StepsLmsOnboardingLmsEducator.NAVIGATION_MENU,
  StepsLmsOnboardingStudent.NAVIGATION_MENU,
  StepsLmsOnboardingTutor.NAVIGATION_MENU,
  StepsLmsOnboardingElective.REVIEW,
];
