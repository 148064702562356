import axios from 'axios';
import { Constants } from '~/config/env';
import { getInstance } from './instance';

const baseUrl = '/marketing';

const marketingApi = {
  addIdMarketing: async (idMarketing: string) => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.put(`${baseUrl}/id`, { idMarketing });

    return data;
  },

  authNse: async () => {
    const { data } = await axios.post(`${Constants?.portalMarketing?.url}/api/auth`, {
      login: Constants?.portalMarketing?.login,
      pass: Constants?.portalMarketing?.pass,
    });

    return data;
  },

  addUser: async (body: models.IMarketingUser, token: string) => {
    const { data } = await axios.post(
      `${Constants?.portalMarketing?.url}/api/usuario`,
      new URLSearchParams({ ...body }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  },

  getUserById: async (id: string, token: string) => {
    const { data } = await axios.get(`${Constants?.portalMarketing?.url}/api/usuario`, {
      params: { id },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  },
};

export default marketingApi;
