import { ActivityAnswersFilter } from '@portal/models/advanced-filters';
import { ActivityAnswerQuestion } from '@portal/models/module';

import { getInstance } from './instance';

const activityAPI = {
  getById: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/activity/${id}`);

    return data;
  },

  sendAnswerQuestion: async (id: string, answerList: ActivityAnswerQuestion[]) => {
    const instance = getInstance();
    const { data } = await instance.put(`/activity/${id}/send-question-answers`, answerList);

    return data;
  },

  getQuestionAnswered: async (id: string, params?: ActivityAnswersFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(`/activity/${id}/answers`, { params });

    return data;
  },

  getSpecificQuestion: async (questionId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/activity-question/${questionId}`);

    return data;
  },
};

export default activityAPI;
