import { ClosureTaskAnswer } from '@portal/models/module';

import { getInstance } from './instance';

const closureTaskAPI = {
  sendClosureAnswer: async (productId: string, closureTaskAnswer: ClosureTaskAnswer) => {
    const instance = getInstance();
    const { data } = await instance.post('/course-closure-task-answer', closureTaskAnswer, {
      params: { brandProductId: productId },
    });

    return data;
  },

  getAnswer: async (courseUserId: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`/course-closure-task-answer/${courseUserId}`);

    return data;
  },
};

export default closureTaskAPI;
