import React from 'react';

import { CourseProgress } from '@portal/models/reducers';

import SideBarContentCard from './SideBarCard/SideBarCard';
import SideBarDropdown from './SideBarDropdown/SideBarDropdown';
import SideBarDropdownFinalTasks from './SideBarDropdownFinalTasks/SideBarDropdownFinalTasks';

interface IProps {
  courseProgress: CourseProgress;
}

const SideBarContent: React.FC<IProps> = ({ courseProgress }) => {
  return (
    <div className="sidebar">
      <div className="sidebar__container">
        <div className="sidebar__container__card">
          <SideBarContentCard />
        </div>
        <div className="sidebar__container__dropdown-list">
          {courseProgress &&
            courseProgress.modules &&
            courseProgress.modules.map((module, index) => (
              <SideBarDropdown
                key={module.id}
                index={index + 1}
                module={module}
              />
            ))}
          <SideBarDropdownFinalTasks />
        </div>
      </div>
    </div>
  );
};

export default SideBarContent;
