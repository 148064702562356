import { HOME_NOTIFICATIONS_ALL } from '~/actions/actionTypes';

const initialState: reducers.HomeNotificationsReducer = {
  homeNotifications: [],
};

const homeNotificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HOME_NOTIFICATIONS_ALL:
      state = {
        ...state,
        homeNotifications: action.payload,
      };
      break;
  }

  return state;
};

export default homeNotificationReducer;
