import React, { Dispatch, FC, SetStateAction } from 'react';

import Collapse, { CollapseProps } from 'antd/lib/collapse';

import ProfileImg from '../../Atoms/ProfileImg/ProfileImg';

interface IProps extends CollapseProps {
  idUser?: string;
  data: models.UserActivity[] | models.pendingListUsers[];
  setIndexUser?: Dispatch<SetStateAction<number>>;
  setIdUser: Dispatch<SetStateAction<string>>;
}

const CollapseUser: FC<IProps> = ({ data, children, setIdUser, setIndexUser, idUser }) => {
  const { Panel } = Collapse;

  const handleIdUser = (id: string) => {
    if (idUser === id) {
      setIdUser('');
    } else {
      setIdUser(id);
    }
  };

  return (
    <Collapse
      className="collapseUser-container"
      onChange={(e: string | string[]) => {
        if (e && setIndexUser) {
          setIndexUser(Number(e[e?.length - 1]));
        }

        handleIdUser(e as string);
      }}
      bordered={false}
      activeKey={idUser}
      accordion
    >
      {data?.map((questionAnswer) => (
        <Panel
          className="collapseUser-container__panel"
          key={questionAnswer?.id}
          header={
            <div className="d-flex align-items-center w-100 h-100">
              <div className="collapseUser-container__panel__extra__image">
                <ProfileImg userImgUrl={questionAnswer.profilePictureUrl} />
              </div>
              {questionAnswer.name}
            </div>
          }
        >
          <div className="collapseUser-content">{children}</div>
        </Panel>
      ))}
    </Collapse>
  );
};

export default CollapseUser;
