import React from 'react';

import { Popover, Typography } from 'antd';

import { Flex, ProductList } from '@portal/components';

import { RenderCondition } from '~/utils';

import './PopoverProducts.less';

interface PropsPopoverProducts {
  children: React.ReactElement;
}

const PopoverProducts = ({ children }: PropsPopoverProducts) => {
  return (
    <Popover
      placement="bottom"
      content={
        <Flex>
          <RenderCondition condition={true}>
            <Flex className="popover-products">
              <ProductList />
            </Flex>
          </RenderCondition>
          <RenderCondition condition={false}>
            <Typography>Sem recursos</Typography>
          </RenderCondition>
        </Flex>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default PopoverProducts;
