import React, { useRef, useEffect, useState } from 'react';

import IcUniqueArrowLeft from '../Icon/IcUniqueArrowLeft';
import IcUniqueArrowRight from '../Icon/IcUniqueArrowRight';

import { useReduxState } from '@portal/hooks/useReduxState';
import RenderCondition from '@portal/utils/renderCondition';

import './Carousel.less';

interface ICarousel {
  array?: any[];
  children: React.ReactNode;
}

const Carousel: React.FC<ICarousel> = ({ array, children }) => {
  const {
    brand: { brandProductConfig },
  } = useReduxState();

  const carousel = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  useEffect(() => {
    if (carousel.current) {
      setHasOverflow(carousel.current?.scrollWidth > carousel.current?.clientWidth);
    }
  }, [array]);

  const handleLeftClick = () => {
    if (carousel.current) {
      carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }
  };

  const handleRightClick = () => {
    if (carousel.current) {
      carousel.current.scrollLeft += carousel.current.offsetWidth;
    }
  };

  return (
    <div className="carousel__root">
      <RenderCondition condition={hasOverflow}>
        <button onClick={handleLeftClick}>
          <IcUniqueArrowLeft style={{ color: brandProductConfig?.primaryColor }} />
        </button>
      </RenderCondition>

      <div className="carousel__body" ref={carousel}>
        {children}
      </div>

      <RenderCondition condition={hasOverflow}>
        <button onClick={handleRightClick}>
          <IcUniqueArrowRight style={{ color: brandProductConfig?.primaryColor }} />
        </button>
      </RenderCondition>
    </div>
  );
};

export default Carousel;
