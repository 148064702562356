import React from 'react';
import { useDispatch } from 'react-redux';

import { Popover, Typography } from 'antd';

import { Avatar, Box, Flex } from '~/components';

import { useReduxState } from '~/hooks';
import { translate } from '~/services';
import { RenderCondition } from '~/utils';

import './PopoverNotifications.less';

interface PropsPopoverNotifications {
  children: React.ReactElement;
}

const PopoverNotifications = ({ children }: PropsPopoverNotifications) => {
  const {
    brand: { brandConfig },
    homeNotification: { homeNotifications: notifications },
  } = useReduxState();

  const dispatch = useDispatch();

  const getFormatedDate = (date: string) => {
    const dateFormated = new Date(date);
    const day = dateFormated.getDate() < 10 ? `0${dateFormated.getDate()}` : dateFormated.getDate();
    const month = dateFormated.getMonth() < 10 ? `0${dateFormated.getMonth()}` : dateFormated.getMonth();
    const year = dateFormated.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <Popover
      placement="bottom"
      content={
        <Flex>
          <RenderCondition condition={!notifications?.length}>
            <Flex>
              <>
                <Box>
                  <Flex className="popover-notifications">
                    <Avatar imageUrl={brandConfig?.logoIconUrl} variant="secondary" size="xs" />

                    <Typography className="popover-notifications__text">
                      {translate('COMPONENTS.NOTIFICATION_POPOVER.EMPTY_NOTIFICATION')}
                    </Typography>
                  </Flex>
                </Box>
              </>
            </Flex>
          </RenderCondition>

          <RenderCondition condition={!!notifications?.length}>
            <Flex>
              {notifications?.map((notification) => (
                <>
                  <Box>
                    <Flex className="popover-notifications">
                      <Avatar imageUrl={notification?.productIconUrl} variant="secondary" size="xs" />
                    </Flex>
                    <Box>{getFormatedDate(notification?.createdAt as string)}</Box>
                  </Box>
                </>
              ))}
            </Flex>
          </RenderCondition>
        </Flex>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default PopoverNotifications;
