import { Dispatch } from 'redux';

import { HomeNotificationsFilter } from '@portal/models/advanced-filters';
import HomeNotificationAPI from '@portal/repositories/homeNotification';
import * as MessageService from '~/services/message';

import { HOME_NOTIFICATIONS_ALL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getAllNotifications =
  (filters: HomeNotificationsFilter, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await HomeNotificationAPI.getAllNotifications(filters);
      dispatch({
        payload,
        type: HOME_NOTIFICATIONS_ALL,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const readNotifications = () => async () => {
  try {
    await HomeNotificationAPI.readNotifications();
  } catch (err) {
    MessageService.error(err.message);
  }
};
