import React, { MouseEventHandler, ReactNode, memo, useCallback, useState } from 'react';

import classNames from 'classnames';

interface IItemTab {
  key: string;
  label: string;
  children?: ReactNode;
}

interface ITabs {
  items: IItemTab[];
  defaultActiveKey?: string;
  variant?: 'primary' | 'secondary';
  handleTab?: (tab: string) => void;
}

const TabItem = memo(
  ({
    item,
    isActive,
    onClick,
    onKeyDown,
    variant = 'primary',
  }: {
    item: IItemTab;
    isActive: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
    variant?: 'primary' | 'secondary';
  }) => {
    const classNameDefault = 'molecules-tabs';
    const classNameVariant = `${classNameDefault}--${variant}`;

    return (
      <div
        key={item.key}
        className={classNames(`${classNameDefault}__item`, `${classNameVariant}__item`, {
          [`${classNameVariant}__item--active`]: isActive,
        })}
        role="tab"
        aria-selected={isActive}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={isActive ? 0 : -1}
      >
        <span
          className={classNames(`${classNameDefault}__item__label`, `${classNameVariant}__item__label`, {
            [`${classNameVariant}__item--active__label`]: isActive,
          })}
        >
          {item.label}
        </span>
      </div>
    );
  }
);

const Tabs = ({ items, defaultActiveKey = '', variant = 'primary', handleTab }: ITabs) => {
  const [keyActive, setKeyActive] = useState<string>(defaultActiveKey);

  const handleTabClick = useCallback((key: string) => {
    setKeyActive(key);
    handleTab?.(key);
  }, []);

  const handleTabKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault();

      const currentIndex = items.findIndex((item) => item.key === keyActive);

      let nextIndex: number;

      if (e.key === 'ArrowLeft') {
        nextIndex = currentIndex === 0 ? items.length - 1 : currentIndex - 1;
      } else {
        nextIndex = currentIndex === items.length - 1 ? 0 : currentIndex + 1;
      }

      const nextKey = items[nextIndex].key;
      setKeyActive(nextKey);
    }
  };

  const classNameDefault = 'molecules-tabs';
  const classNameVariant = `${classNameDefault}--${variant}`;

  return (
    <div className="molecules-tabs__root">
      <div className={classNames(`${classNameDefault}__items`, `${classNameVariant}__items`)}>
        {items?.map((item) => (
          <TabItem
            key={item.key}
            item={item}
            isActive={keyActive === item.key}
            onClick={() => handleTabClick(item.key)}
            onKeyDown={handleTabKeyDown}
            variant={variant}
          />
        ))}
      </div>

      <div className={classNames(`${classNameDefault}__body`, `${classNameVariant}__body`)}>
        {items?.find((item) => item.key === keyActive)?.children}
      </div>
    </div>
  );
};

export default Tabs;
