import { Form, Input, Modal, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useReduxState } from '@portal/hooks/useReduxState';

import { Btn } from '@portal/components';

import { translate } from '@portal/services/i18n';
import * as StorageService from '~/services/storage';
import { User } from '@portal/models/module';
import { COURSE_TYPE } from '@portal/enum/courseType';
import { ProfileType } from '@portal/enum/profileType';
import { InitialStateGroup } from '@portal/utils/inititalState';
import { CourseActions, GroupActions, UserActions } from '@portal/actions';

interface IEnrollmentGroup {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

const EnrollmentGroup: React.FC<IEnrollmentGroup> = ({ openModal, closeModal, id }) => {
  const dispatch = useDispatch();
  const { me } = useReduxState().auth;

  const [groupInfos, setGroupInfos] = useState<models.CreateGroup>(InitialStateGroup);

  const productId = StorageService.getItem('brandProductsConfig')?.id;
  const courseId = StorageService.getItem('courseId-redirect');

  const searchParameters = {
    profileType: ProfileType.TEACHER,
    schoolId: me?.idSchool,
    limit: Number.MAX_SAFE_INTEGER,
    offset: 0,
  };

  const courseSearchParameters = {
    type: COURSE_TYPE.ELECTIVE,
    brandProductId: productId,
    limit: Number.MAX_SAFE_INTEGER,
    offset: 0,
  };

  const groupSearchParameters = {
    type: COURSE_TYPE.ELECTIVE,
    productId,
    limit: 12,
    schoolId: me?.idSchool,
    status: 'active',
    offset: 0,
    ...(courseId && { courseId }),
  };

  const {
    user: { list },
    group: { details },
  } = useReduxState();

  useEffect(() => {
    dispatch(GroupActions.getGroupById(id));
  }, []);

  useEffect(() => {
    if (me?.idSchool) {
      dispatch(UserActions.getUserReport(searchParameters));
    }
  }, [me?.idSchool]);

  useEffect(() => {
    if (me?.idSchool && productId) {
      dispatch(CourseActions.getAllCourses(courseSearchParameters));
    }
  }, [me?.idSchool, productId]);

  useEffect(() => {
    setGroupInfos({
      name: details?.name as string,
      schoolId: details?.schoolId as string,
      capacity: details?.capacity as number,
      courseId: details?.course.id as string,
      tutorIdList: details?.users
        .filter((user) => user.profileType === ProfileType.TEACHER)
        .map((user) => user.id) as [],
    });
  }, [details]);

  const handleSubmit = () => {
    closeModal(false);
    dispatch(
      GroupActions.updateGroup(id, groupInfos, () => {
        dispatch(GroupActions.getAllGroups(groupSearchParameters));
      })
    );
  };

  return (
    <Modal visible={openModal} onCancel={() => closeModal(false)} footer={null} centered={true} width={750}>
      <div className="content">
        <Form name="basic" onFinish={handleSubmit}>
          <Col>
            <Row className="content__columns content__gap">
              <span className="content__font-style mb-2">
                {translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.GROUP_DATA')}:
              </span>
              <Input
                placeholder={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.GROUP_NAME')}
                onChange={(e) => {
                  setGroupInfos({ ...groupInfos, name: e.target.value });
                }}
                value={groupInfos.name}
              />
              <div className="d-flex align-items-center mt-3 mb-4">
                <Select
                  mode="multiple"
                  placeholder={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.TUTOR_SELECT')}
                  style={{ width: '100%' }}
                  className="mt-1"
                  onChange={(e) => {
                    setGroupInfos({ ...groupInfos, tutorIdList: e });
                  }}
                  value={groupInfos.tutorIdList}
                >
                  {list.map((user: User) => (
                    <Option value={user.id}>{user.name}</Option>
                  ))}
                </Select>
                <Input
                  placeholder={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.CAPACITY')}
                  style={{ marginLeft: 20 }}
                  type="number"
                  onChange={(e) => {
                    setGroupInfos({
                      ...groupInfos,
                      capacity: parseInt(e.target.value),
                    });
                  }}
                  value={groupInfos.capacity}
                />
              </div>
            </Row>
            <Row className="button-layout align-mobile">
              <Btn variant="secondary" title={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.BUTTONS.CANCEL')} />
              <Btn variant="primary" title={translate('PAGES.GROUPS.TABS.NEW_GROUP_MODAL.BUTTONS.EDIT_GROUP')} />
            </Row>
          </Col>
        </Form>
      </div>
    </Modal>
  );
};

export default EnrollmentGroup;
