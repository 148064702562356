import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';

import { BrandActions, CourseActions, SidebarContentActions } from '@portal/actions';
import { COURSE_CONTENT } from '@portal/actions/actionTypes';

import { getStackPath } from '@portal/config/routes';
import { ProductType } from '@portal/enum/productType';
import { isEducator, ProfileType } from '@portal/enum/profileType';
import { useReduxState } from '@portal/hooks/useReduxState';
import { IRouteParams } from '@portal/models/route-params';
import * as StorageService from '@portal/services/storage';
import { isDashTutor } from '@portal/utils/isDashTutor';

import ActivitySection from '@portal/components/v1/ContentComponents/ActivitySection/ActivitySection';
import ActivitySectionTutor from '@portal/components/v1/ContentComponents/ActivitySectionTutor/ActivitySectionTutor';
import NavigationButtons from '@portal/components/v1/ContentComponents/NavigationButtons/NavigationButtuns';
import RenderContent from '@portal/components/v1/ContentComponents/RenderContent/RenderContent';
import SideBarContent from '@portal/components/v1/ContentComponents/SideBarContent/SideBarContent';
import { PanelCourseTest, PanelCourseTestTutor } from '@portal/components/v1/CourseTest';
import { PanelSatisfactionSurvey } from '@portal/components/v1/SatisfactionSurvey';

import HideIcon from '@portal/assets/svg/content/sidebar/ic_hide_sidebar.svg';
import EmptyContent from '@portal/components/v1/ContentComponents/EmptyContent/EmptyContent';
import { setThemeProduct } from '@portal/utils/setTheme';

const ContentPage: React.FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const {
    sidebarContent: { isHide },
    course: { courseProgress },
    auth: { me },
    brand: { brandProductConfig },
  } = useReduxState();

  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get('productId');

  const isTutor = isDashTutor(me?.profileType) || null;
  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const { courseId, contentId, activityId } = useParams<IRouteParams>();

  const handleShowSidebar = () => {
    dispatch(SidebarContentActions.setIsHide(false));
  };

  useEffect(() => {
    if (courseId && !!isTutor && productType === ProductType.LMS_STUDENT) {
      dispatch(
        CourseActions.getCourseProgress({
          idCourse: courseId,
          isTutor: true,
          isLocalLoading: true,
        })
      );
    } else if (
      courseId &&
      (me?.profileType === ProfileType.STUDENT ||
        (isEducator(me?.profileType) && productType === ProductType.LMS_EDUCATOR))
    ) {
      dispatch(
        CourseActions.getCourseProgress({
          idCourse: courseId,
          isLocalLoading: true,
        })
      );
    }
  }, [contentId, activityId, courseId, isTutor, productType, me?.profileType]);

  useEffect(() => {
    return () => {
      dispatch({
        type: COURSE_CONTENT,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (productId && !brandProductConfig?.id) {
      dispatch(
        BrandActions.getBrandProductsConfig(productId, (config) => {
          setThemeProduct(config);
        })
      );
    }
  }, [productId]);

  return (
    <div className="content-container">
      <div className={`content-container__sidebar${isHide ? ' content-container__sidebar--hide' : ''}`}>
        <SideBarContent courseProgress={courseProgress} />
      </div>
      <div className="content-container__main">
        {isHide && (
          <div className="content-container__main__show-sidebar-container">
            <button className="content-container__main__show-sidebar-container__button" onClick={handleShowSidebar}>
              <img
                className="content-container__main__show-sidebar-container__button__icon"
                src={HideIcon}
                alt="hide button"
              />
            </button>
          </div>
        )}
        <Switch>
          <Route path={`${getStackPath('CONTENT')}/:courseId/satisfaction-survey`} exact>
            <PanelSatisfactionSurvey />
          </Route>
          <Route path={`${getStackPath('CONTENT')}/:courseId/test/:testId`} exact>
            {isTutor && productType === ProductType.LMS_STUDENT ? <PanelCourseTestTutor /> : <PanelCourseTest />}
          </Route>
          <Route path={`${getStackPath('CONTENT')}/:courseId/:moduleId/:contentId`} exact>
            <RenderContent />
          </Route>
          <Route path={`${getStackPath('CONTENT')}/:courseId`} exact>
            <EmptyContent />
          </Route>
          <Route path={`${getStackPath('CONTENT')}/:courseId/:moduleId/:contentId/:activityId`} exact>
            {isTutor && productType === ProductType.LMS_STUDENT ? <ActivitySectionTutor /> : <ActivitySection />}
          </Route>
        </Switch>
        {!match.url.includes('/test') && <NavigationButtons />}
      </div>
    </div>
  );
};

export default ContentPage;
