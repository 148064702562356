import { userLogApi } from '@portal/repositories';
import { IUserLog } from '@portal/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useCreateUserLog = (
  options?: UseMutationOptions<
    unknown,
    AxiosError<{ status: number; message: string }>,
    Pick<IUserLog, 'idReferral' | 'type'>,
    unknown
  >
) => {
  const mutate = useMutation({
    mutationFn: (body: Pick<IUserLog, 'idReferral' | 'type'>) => userLogApi.createLog(body),
    ...options,
  });

  return mutate;
};
