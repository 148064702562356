import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Collapse, Image } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import { BrandActions } from '@portal/actions';

import VideoModal from '../VideoModal/VideoModal';

import { useReduxState } from '@portal/hooks/useReduxState';
import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';
import * as StorageService from '~/services/storage';
import { StepsLmsOnboardingTutor } from '@portal/enum';

const CollapseTutor: React.FC = () => {
  const dispatch = useDispatch();
  const {
    brand: { brandPlaylist },
    lmsOnboarding: { stepActive, showModalStepsOnboarding },
  } = useReduxState();

  const [showModalVideo, setShowModalVideo] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [activeKey, setActiveKey] = useState(['1']);

  const productId = StorageService.getItem('brandProductsConfig')?.id;

  const { Panel } = Collapse;

  useEffect(() => {
    if (productId && !brandPlaylist?.length) {
      dispatch(BrandActions.getPlaylist(productId));
    }
  }, [productId]);

  useEffect(() => {
    if (showModalStepsOnboarding) {
      setActiveKey(['1']);
    }
  }, [showModalStepsOnboarding]);

  return (
    <div
      {...(stepActive === StepsLmsOnboardingTutor.VIDEOS && {
        style: { position: 'relative', zIndex: 99999, borderRadius: '8px', backgroundColor: 'white' },
      })}
    >
      <Collapse
        ghost
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key as string[])}
        className="collapse-videos"
        bordered={false}
      >
        <Panel className="collapse-videos__panel" header={brandPlaylist?.[0]?.title} key="1">
          <div className="carousel-content">
            {brandPlaylist?.[0]?.videos?.map((item, index) => (
              <a
                onClick={() => {
                  setCurrentIndex(index);
                  setShowModalVideo(true);
                }}
              >
                <Image
                  src={item.thumbnailUrl ? item.thumbnailUrl : EmptyBanner}
                  className="collapse-container__background-image"
                  preview={{
                    visible: false,
                    mask: (
                      <div className="collapse-container__mask">
                        <CaretRightOutlined className="collapse-container__play-button" />
                      </div>
                    ),
                  }}
                />
              </a>
            ))}
          </div>
        </Panel>
      </Collapse>

      <VideoModal
        openModal={showModalVideo}
        closeModal={setShowModalVideo}
        cardData={brandPlaylist?.[0]?.videos && brandPlaylist?.[0]?.videos?.[currentIndex]}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        lengthData={brandPlaylist?.[0]?.videos?.length ?? 0}
      />
    </div>
  );
};

export default CollapseTutor;
