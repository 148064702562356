import React from 'react';

import IcWelcomeCourse from '@portal/components/v1/Icon/IcWelcomeCourse';

import { translate } from '@portal/services/i18n';

const EmptyContent: React.FC = () => {
  return (
    <div className="empty-content">
      <div className="empty-content__content">
        <h1 className="empty-content__title">{translate('COMPONENTS.RENDER_CONTENT.WELCOME')}</h1>

        <p className="empty-content__description">{translate('COMPONENTS.RENDER_CONTENT.DESCRIPTION')}</p>
      </div>

      <div className="empty-content__image">
        <IcWelcomeCourse />
      </div>
    </div>
  );
};

export default EmptyContent;
