import { Dispatch } from 'redux';

import JourneyApi from '~/repositories/journey';
import UserApi from '~/repositories/user';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import {
  CHANGED_SCHOOL,
  PENDING_TEST_LIST,
  SET_JOURNEY_ALL,
  UPDATE_AVATAR,
  USERS_ACTIVITIES,
  USERS_ACTIVITIES_HISTORY,
  USERS_ACTIVITIES_HISTORY_LOADING,
  USER_DETAIL,
  USER_PRODUCTS,
  USER_REPORT,
  USER_TEACHER_REVIEWS,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import { contentLibraryApi } from '@portal/repositories/contentLibrary';

export const cleanUserDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: USER_DETAIL,
  });
};

export const getUserReport =
  (searchParams: advancedFilterModels.UserAdvancedFilter, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.PaginationResponse<models.User> = await UserApi.getReport({
        ...searchParams,
      });
      dispatch({
        payload,
        type: USER_REPORT,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getUserDetail = (id: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload: models.User = await UserApi.getDetail(id);

    dispatch({
      payload,
      type: USER_DETAIL,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getProducts = (isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const responseProductsList = await UserApi.getProducts();
    const responseContentLibraryList = await contentLibraryApi.getAllContentLibraries();

    const payload = {
      ...responseProductsList,
      contentLibraries: responseContentLibraryList || [],
    };

    dispatch({
      payload,
      type: USER_PRODUCTS,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getTeacherRevisionBySchool =
  (IdSchool: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload: models.Products[] = await UserApi.getTeacherRevisionBySchool(IdSchool);

      dispatch({
        payload,
        type: USER_TEACHER_REVIEWS,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const updateUser =
  (id: string, params: models.User, profile: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      await UserApi.update(id, params);

      MessageService.success(`PAGES.PANEL.${profile}.DETAILS.SUCCESS_EDIT_MESSAGE`);

      centralNavigationService.back();
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const addUser =
  (params: models.User, profile: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      await UserApi.add(params);

      MessageService.success(`PAGES.PANEL.${profile}.DETAILS.SUCCESS_ADD_MESSAGE`);

      centralNavigationService.back();
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const removeUser = (id: string, profile: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    await UserApi.remove(id);
    MessageService.success(`PAGES.PANEL.${profile}.DETAILS.SUCCESS_REMOVE_MESSAGE`);
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const updatePassword =
  (id: string, params: models.UpdatePassword, isStudent: boolean, callback: () => void, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      if (isStudent) {
        await UserApi.updateStudantPassword(id, params);
      } else {
        await UserApi.updateUserPassword(id, params);
      }
      MessageService.success('PAGES.PROFILE.CHANGE_PASSWORD.SUCCESS');
      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error(`SHARED.API_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const updateUserAvatar = (file: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading(!!isLocalLoading));
  try {
    const payload = await UserApi.updateUserAvatar(file);
    dispatch({
      payload,
      type: UPDATE_AVATAR,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading(!!isLocalLoading));
  }
};

export const getUsersActivities =
  (
    idActivity: string,
    virtualGroupId: string,
    params: advancedFilterModels.UserActivityFilter,
    isLocalLoading?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));

    try {
      const payload = await UserApi.getUsersActivities(idActivity, virtualGroupId, params);
      dispatch({
        payload,
        type: USERS_ACTIVITIES,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_MESSAGES.${err.message}`);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getUsersActivitiesHistory =
  (idActivity: string, virtualGroupId: string, params: advancedFilterModels.UserActivityHistoryFilter) =>
  async (dispatch: Dispatch) => {
    dispatch({
      payload: true,
      type: USERS_ACTIVITIES_HISTORY_LOADING,
    });

    try {
      const payload = await UserApi.getUsersActivitiesHistory(idActivity, virtualGroupId, params);
      dispatch({
        payload,
        type: USERS_ACTIVITIES_HISTORY,
      });
    } catch (err) {
      MessageService.error(`SHARED.API_MESSAGES.${err.message}`);
    } finally {
      dispatch({
        payload: false,
        type: USERS_ACTIVITIES_HISTORY_LOADING,
      });
    }
  };

export const getPendingTest =
  (courseId: string, productId: string, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await UserApi.getPendingTest(courseId, productId);
      dispatch({
        payload,
        type: PENDING_TEST_LIST,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const changeSchool =
  (idUser: string, infos: models.ParamsChangeSchool, callback?: () => void, isLocalLoading?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await UserApi.changeSchool(idUser, infos);
      dispatch({
        payload,
        type: CHANGED_SCHOOL,
      });
      if (callback) {
        callback();
      }
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };

export const getProductsUserJourney =
  (brandId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const responseProductsList = await UserApi.getJourneyProducts();

      const responseJourney = await JourneyApi.getAllJourney(brandId, responseProductsList);

      const folderList = responseJourney?.map((v) => {
        let minorAcademicYear = 100;
        let folderId = '';

        v.folders?.map((val) => {
          const minorVal = val?.schoolYears ? Math.min(...val?.schoolYears) : minorAcademicYear;
          if (minorVal < minorAcademicYear) {
            minorAcademicYear = minorVal;
            folderId = val?.id ?? '';
          }
        });

        return {
          ...v,
          minorFolderId: folderId,
        };
      });

      dispatch({
        payload: folderList,
        type: SET_JOURNEY_ALL,
      });
    } catch (err) {
      MessageService.error('errors.products.notLoadingBrandList');
    }
  };
