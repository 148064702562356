import { HomeNotificationsFilter } from '@portal/models/advanced-filters';

import { getInstance } from './instance';

const baseUrl = '/home-notification';

const HomeNotificationAPI = {
  getAllNotifications: async (filters: HomeNotificationsFilter) => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.get(`${baseUrl}`, {
      params: filters,
    });

    return data;
  },

  readNotifications: async () => {
    const instance = getInstance({ api: 'new' });
    const { data } = await instance.put(`${baseUrl}`);

    return data;
  },
};

export default HomeNotificationAPI;
