import { Constants } from '@portal/config/env';
import { BrandDomain, ProfileType, isFundTwo } from '~/enum';

export const hasBlinkLearning = (user: models.User, brand: models.BrandConfig): boolean => {
  const isStudent = user?.profileType === ProfileType.STUDENT;
  const isTeacher = user?.profileType === ProfileType.TEACHER;
  const isAdviser = user?.profileType === ProfileType.ADVISER;

  if (Constants.domain === 'lmshmg' || brand?.domainUrl === BrandDomain.PGS) {
    if (isStudent) {
      return isFundTwo(user?.studentClass as number);
    }

    if (isTeacher) {
      return !!user?.teacherClasses?.some((classe) => isFundTwo(classe?.year));
    }

    return isAdviser;
  }

  return false;
};
