export enum CourseTestType {
  MULTIPLE_QUESTIONS= 1,
  FILE_UPLOAD_ANSWER= 2,
}

export const courseTestTypeMap: Map<CourseTestType, string> = new Map<CourseTestType, string>([
    [CourseTestType.MULTIPLE_QUESTIONS, 'Múltipla escolha'],
    [CourseTestType.FILE_UPLOAD_ANSWER, 'Envio de arquivo'],
  ]);

export const courseTestList: { key: number; name: string }[] =
  Array.from(courseTestTypeMap).map((o: any[]) => ({
    key: o[0],
    name: o[1],
  }));