import { ACTIVITY_DETAILS, ACTIVITY_QUESTIONS_ANSWERED, SPECIFIC_QUESTION } from '@portal/actions/actionTypes';

const initialState: reducers.ActivityReducer = {
  activityDetails: {} as reducers.ActivityDetails,
  activityAnswers: {} as reducers.ActivityAnswered,
  questionAnswered: {} as reducers.Questions,
};

const activityReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case ACTIVITY_DETAILS:
      state = {
        ...state,
        activityDetails: action.payload,
      };
      break;

    case ACTIVITY_QUESTIONS_ANSWERED:
      state = {
        ...state,
        activityAnswers: action.payload,
      };
      break;

      case SPECIFIC_QUESTION:
      state = {
        ...state,
        questionAnswered: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;

};

export default activityReducer;