import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Popover } from 'antd';
import { Col, Container } from 'react-bootstrap';

import { CourseActions, TutorContentActions } from '@portal/actions';

import { Btn, Flex } from '@portal/components';
import { ReactComponent as ArrowIcon } from '~/assets/svg/homePage/ic_arrow.svg';
import MinimalCard from '../../Card/MinimalCard/MinimalCard';

import { SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING } from '@portal/actions/actionTypes';
import { getStackPath } from '@portal/config/routes';
import {
  BrandDomain,
  ProductType,
  ProfileType,
  StepsLmsOnboardingElective,
  StepsLmsOnboardingLmsEducator,
  StepsLmsOnboardingStudent,
  StepsLmsOnboardingTutor,
  TAB_MENU_ITEMS,
  arrayAdminProfiles,
  arrayEducatorProfiles,
  firstStepsLmsOnboarding,
} from '@portal/enum';
import { useReduxState } from '@portal/hooks';
import { translate } from '@portal/services';
import { getCookie } from '@portal/services/cookie';
import { RenderCondition, isDashTutor } from '@portal/utils';
import useWindowSize from '@portal/utils/getSizeWindow';
import returnPronounWithCourseTerm from '@portal/utils/returnPronounWithCourseTerm';
import * as StorageService from '~/services/storage';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface IProps {
  banner?: boolean;
}

const NavbarLMS: React.FC<IProps> = ({ banner }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useWindowSize();

  const [currentTabTutor, setCurrentTabTutor] = useState<number>(
    window.location.pathname.includes('/groups') ? TAB_MENU_ITEMS.MYGROUPS : TAB_MENU_ITEMS.HOME
  );
  const [CourseListPagination] = useState({
    limit: 4,
    offset: 1,
    page: 1,
  });

  const {
    course: { mineCourses, concludedCourses, otherCourses },
    group: { groupsAll },
    auth: { me },
    tutorContent,
    lmsOnboarding: { stepActive, showModalStepsOnboarding },
    brand: { brandConfig, brandProductConfig },
    lmsTrail: { listWithCourses },
  } = useReduxState();

  const isStudent = me?.profileType === ProfileType.STUDENT;
  const isAdmin = me?.profileType && arrayAdminProfiles.includes(me?.profileType);
  const isEducator = me?.profileType && arrayEducatorProfiles.includes(me?.profileType);
  const isTutor = isDashTutor(me?.profileType);
  const getBrandProduct = StorageService.getItem('brandProductsConfig') || '';
  const isElective = window.location.pathname.includes('content');

  useEffect(() => {
    if (isTutor && getBrandProduct.id) {
      dispatch(TutorContentActions.clearTutorContent());
      dispatch(TutorContentActions.getTutorContentByProductId(getBrandProduct.id));
    }
  }, [getBrandProduct?.id, isTutor]);

  useEffect(() => {
    if (window.location.pathname === `${getStackPath('COURSES')}/me`) {
      setCurrentTabTutor(TAB_MENU_ITEMS.MYCOURSES);
    }

    if (window.location.pathname === `${getStackPath('COURSES')}/concluded`) {
      setCurrentTabTutor(TAB_MENU_ITEMS.FINISHEDCOURSES);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    dispatch(
      CourseActions.getConcludedCourses({
        ...CourseListPagination,
        productId: getBrandProduct?.id,
      })
    );
  }, [CourseListPagination]);

  const setShowModalStartOnboarding = (state: boolean) => {
    dispatch({
      payload: state,
      type: SET_SHOW_MODAL_START_ONBOARDING_LMS_ONBOARDING,
    });
  };

  const isSaePartnership = me?.isSaePartnership || getCookie('user-integration');

  const isStepCertificate =
    stepActive === StepsLmsOnboardingLmsEducator.CERTIFICATES &&
    brandProductConfig?.product === ProductType.LMS_EDUCATOR;

  const isStepSeeTutorialToAdmin =
    ((stepActive === StepsLmsOnboardingLmsEducator.SEE_TUTORIAL &&
      brandProductConfig?.product === ProductType.LMS_EDUCATOR) ||
      (stepActive === StepsLmsOnboardingTutor.SEE_TUTORIAL &&
        brandProductConfig?.product === ProductType.LMS_STUDENT)) &&
    isEducator;

  const myCourses = (
    <div className="popover-container">
      <div className="popover-container__title-container">
        <span className="popover-container__title-container__title">{returnPronounWithCourseTerm()?.my}</span>
        <br />
        <span className="popover-container__title-container__subtitle">
          {translate('COMPONENTS.NAVIGATION_MENU.MOST_RECENT')}
        </span>
      </div>

      <Col className="m-0 p-0">
        <div>
          {mineCourses.rows.map((course, index) => (
            <div key={index} className="popover-container__content__list">
              <MinimalCard key={course.id} cardData={course} />
            </div>
          ))}
        </div>
      </Col>
    </div>
  );

  const coursesConcluded = (
    <div className="popover-container">
      <div className="popover-container__title-container">
        <span className="popover-container__title-container__title">
          {translate('COMPONENTS.NAVIGATION_MENU.CONCLUDED')}
        </span>
        <br />
        <span className="popover-container__title-container__subtitle">
          {translate('COMPONENTS.NAVIGATION_MENU.MOST_RECENT')}
        </span>
      </div>

      <Col className="m-0 p-0">
        <div className="">
          {concludedCourses.rows.map((course, index) => (
            <div key={index} className="popover-container__content__list">
              <MinimalCard key={course.id} cardData={course} />
            </div>
          ))}
        </div>
      </Col>
    </div>
  );

  const seeTutorial = ({ isActive }: { isActive: boolean }) => {
    return (
      <span
        className="navbar-lms__menu__link"
        onClick={() => setShowModalStartOnboarding(true)}
        {...(isActive && {
          style: { zIndex: 9999, padding: '1rem', borderRadius: '8px', backgroundColor: 'white' },
        })}
      >
        ver tutorial
      </span>
    );
  };

  const containerIsActive = () => {
    if (
      (firstStepsLmsOnboarding.includes(stepActive) || isStepCertificate || (isStepSeeTutorialToAdmin && !!isMobile)) &&
      showModalStepsOnboarding &&
      !isElective
    ) {
      return true;
    }

    if (isElective && stepActive === StepsLmsOnboardingElective.SEE_TUTORIAL) {
      return true;
    }

    return false;
  };

  return (
    <Container
      fluid
      className={`navbar-lms ${banner ? 'navbar-lms--remove-margin' : ''}`}
      {...(containerIsActive() && {
        style: { zIndex: 99999, position: 'relative', backgroundColor: 'white' },
      })}
      id="navbar-lms"
    >
      {isTutor && getBrandProduct.product !== ProductType.LMS_EDUCATOR ? (
        <div className="navbar-lms__teacher">
          <div className="navbar-lms__course-content__teacher">
            <div className="navbar-lms__course-content__teacher__button-wrapper" id="navbar-lms-menu">
              <div className="mr-4">
                <Btn
                  onClick={() => {
                    history.push(`${getStackPath('HOME')}/${getBrandProduct.id}/groups`);
                    setCurrentTabTutor(TAB_MENU_ITEMS.MYGROUPS);
                  }}
                  variant={currentTabTutor === TAB_MENU_ITEMS.MYGROUPS ? 'primary' : 'secondary'}
                  title={translate('COMPONENTS.NAVIGATION_MENU.MY_GROUPS')?.toUpperCase()}
                />
              </div>
              {!isSaePartnership ? (
                <div className="mr-4">
                  <Btn
                    onClick={() => {
                      history.push(`${getStackPath('HOME')}/${getBrandProduct.id}`);
                      setCurrentTabTutor(TAB_MENU_ITEMS.OTHERCOURSES);
                    }}
                    variant={currentTabTutor === TAB_MENU_ITEMS.OTHERCOURSES ? 'primary' : 'secondary'}
                    title={returnPronounWithCourseTerm()?.all}
                  />
                </div>
              ) : (
                <div className="mr-4">
                  <a href="https://ava.sae.digital/_n/ava/electives">
                    <Btn
                      variant={currentTabTutor === TAB_MENU_ITEMS.MYGROUPS ? 'secondary' : 'primary'}
                      title={translate('COMPONENTS.NAVIGATION_MENU.BACK_TO_AVA')?.toUpperCase()}
                    />
                  </a>
                </div>
              )}

              <RenderCondition
                condition={
                  ((window.location.pathname.includes('groups') && !!groupsAll?.rows?.length) || isElective) &&
                  brandConfig?.domainUrl !== BrandDomain.PGS &&
                  !!isMobile
                }
              >
                {seeTutorial({
                  isActive:
                    (stepActive === StepsLmsOnboardingTutor.SEE_TUTORIAL ||
                      (isElective && stepActive === StepsLmsOnboardingElective.SEE_TUTORIAL)) &&
                    !isAdmin,
                })}
              </RenderCondition>
            </div>
            <Flex style={{ gap: '3rem' }}>
              <RenderCondition condition={tutorContent.detail?.isActive}>
                <div>
                  <a
                    className="navbar-lms__teacher__tutorContent"
                    href={tutorContent?.detail?.fileUrl}
                    target="_blank"
                    download
                  >
                    <span>{translate('COMPONENTS.NAVIGATION_MENU.TUTOR_CONTENT')?.toUpperCase()}</span>
                  </a>
                </div>
              </RenderCondition>

              <RenderCondition
                condition={
                  ((window.location.pathname.includes('groups') && !!groupsAll?.rows?.length) || isElective) &&
                  brandConfig?.domainUrl !== BrandDomain.PGS &&
                  !isMobile
                }
              >
                {seeTutorial({
                  isActive:
                    stepActive === StepsLmsOnboardingTutor.SEE_TUTORIAL ||
                    (isElective && stepActive === StepsLmsOnboardingElective.SEE_TUTORIAL),
                })}
              </RenderCondition>
            </Flex>
          </div>
        </div>
      ) : (
        <Flex className="navbar-lms__menu" id="navbar-lms-menu">
          <div className="navbar-lms__course-content">
            <div className="navbar-lms__course-list">
              <div className="navbar-lms__course-list__inner">
                {isSaePartnership ? (
                  <>
                    <a href="https://ava.sae.digital/_n/ava/electives">
                      <Btn
                        variant={currentTabTutor === TAB_MENU_ITEMS.MYGROUPS ? 'primary' : 'secondary'}
                        title={translate('COMPONENTS.NAVIGATION_MENU.BACK_TO_AVA')}
                      />
                    </a>
                    {concludedCourses?.count > 0 ? (
                      <Popover
                        content={coursesConcluded}
                        overlayInnerStyle={{ borderRadius: '10px' }}
                        placement="bottom"
                      >
                        <Btn
                          onClick={() => {
                            history.push(`${getStackPath('COURSES')}/concluded`);
                            setCurrentTabTutor(TAB_MENU_ITEMS.FINISHEDCOURSES);
                          }}
                          variant={currentTabTutor === TAB_MENU_ITEMS.FINISHEDCOURSES ? 'primary' : 'secondary'}
                          title={returnPronounWithCourseTerm()?.concluded?.toUpperCase()}
                        >
                          <div className="navbar-lms__menu__content">
                            <ArrowIcon className="navbar-lms__menu__content__icon" />
                          </div>
                        </Btn>
                        <Fragment />
                      </Popover>
                    ) : (
                      <Btn
                        onClick={() => {
                          history.push(`${getStackPath('COURSES')}/concluded`);
                          setCurrentTabTutor(TAB_MENU_ITEMS.FINISHEDCOURSES);
                        }}
                        variant={currentTabTutor === TAB_MENU_ITEMS.FINISHEDCOURSES ? 'primary' : 'secondary'}
                        title={returnPronounWithCourseTerm()?.concluded}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Btn
                      onClick={() => {
                        history.push(`${getStackPath('HOME')}/${getBrandProduct.id}`);
                        setCurrentTabTutor(TAB_MENU_ITEMS.HOME);
                      }}
                      variant={currentTabTutor === TAB_MENU_ITEMS.HOME ? 'primary' : 'secondary'}
                      title={returnPronounWithCourseTerm()?.courseTerm?.toUpperCase()}
                    />
                    {mineCourses?.count > 0 ? (
                      <Popover content={myCourses} overlayInnerStyle={{ borderRadius: '10px' }} placement="bottom">
                        <Btn
                          onClick={() => {
                            history.push(`${getStackPath('COURSES')}/me`);
                            setCurrentTabTutor(TAB_MENU_ITEMS.MYCOURSES);
                          }}
                          variant={currentTabTutor === TAB_MENU_ITEMS.MYCOURSES ? 'primary' : 'secondary'}
                          title={returnPronounWithCourseTerm()?.my?.toUpperCase()}
                        >
                          <div className="navbar-lms__menu__content">
                            <ArrowIcon className="navbar-lms__menu__content__icon" />
                          </div>
                        </Btn>
                        <Fragment />
                      </Popover>
                    ) : (
                      <Btn
                        onClick={() => {
                          history.push(`${getStackPath('COURSES')}/me`);
                          setCurrentTabTutor(TAB_MENU_ITEMS.MYCOURSES);
                        }}
                        variant={currentTabTutor === TAB_MENU_ITEMS.MYCOURSES ? 'primary' : 'secondary'}
                        title={returnPronounWithCourseTerm()?.my?.toUpperCase()}
                      />
                    )}
                    {concludedCourses?.count > 0 ? (
                      <Popover
                        content={coursesConcluded}
                        overlayInnerStyle={{ borderRadius: '10px' }}
                        placement="bottom"
                      >
                        <Btn
                          onClick={() => {
                            history.push(`${getStackPath('COURSES')}/concluded`);
                            setCurrentTabTutor(TAB_MENU_ITEMS.FINISHEDCOURSES);
                          }}
                          variant={currentTabTutor === TAB_MENU_ITEMS.FINISHEDCOURSES ? 'primary' : 'secondary'}
                          title={translate('COMPONENTS.NAVIGATION_MENU.CONCLUDED')?.toUpperCase()}
                        >
                          <div className="navbar-lms__menu__content">
                            <ArrowIcon className="navbar-lms__menu__content__icon" />
                          </div>
                        </Btn>
                        <Fragment />
                      </Popover>
                    ) : (
                      <Btn
                        onClick={() => {
                          history.push(`${getStackPath('COURSES')}/concluded`);
                          setCurrentTabTutor(TAB_MENU_ITEMS.FINISHEDCOURSES);
                        }}
                        variant={currentTabTutor === TAB_MENU_ITEMS.FINISHEDCOURSES ? 'primary' : 'secondary'}
                        title={translate('COMPONENTS.NAVIGATION_MENU.CONCLUDED')?.toUpperCase()}
                      />
                    )}
                  </>
                )}

                <RenderCondition
                  condition={
                    window.location.pathname.includes('home') &&
                    (!!otherCourses?.rows?.length || !!listWithCourses?.length) &&
                    brandConfig?.domainUrl !== BrandDomain.PGS &&
                    !!isMobile
                  }
                >
                  {seeTutorial({
                    isActive:
                      (stepActive === StepsLmsOnboardingStudent.SEE_TUTORIAL && isStudent) ||
                      (stepActive === StepsLmsOnboardingLmsEducator.SEE_TUTORIAL && !isAdmin),
                  })}
                </RenderCondition>
              </div>
            </div>
          </div>

          <RenderCondition
            condition={
              window.location.pathname.includes('home') &&
              (!!otherCourses?.rows?.length || !!listWithCourses?.length) &&
              brandConfig?.domainUrl !== BrandDomain.PGS &&
              !isMobile
            }
          >
            {seeTutorial({
              isActive:
                (stepActive === StepsLmsOnboardingStudent.SEE_TUTORIAL && isStudent) ||
                stepActive === StepsLmsOnboardingLmsEducator.SEE_TUTORIAL,
            })}
          </RenderCondition>
        </Flex>
      )}
    </Container>
  );
};

export default NavbarLMS;
