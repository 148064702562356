import { BrandConfig } from '@portal/models/module';
import { BRAND_CONFIG, BRAND_PLAYLIST, BRAND_PRODUCT_CONFIG } from '~/actions/actionTypes';

const initialState: reducers.BrandReducer = {
  brandConfig: {} as BrandConfig,
  brandProductConfig: {} as BrandConfig,
  brandPlaylist: [],
};

const brandReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BRAND_CONFIG:
      state = {
        ...state,
        brandConfig: action.payload,
      };
      break;

    case BRAND_PRODUCT_CONFIG:
      state = {
        ...state,
        brandProductConfig: action.payload,
      };
      break;

    case BRAND_PLAYLIST:
      state = {
        ...state,
        brandPlaylist: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default brandReducer;
