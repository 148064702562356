import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';
import { PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import RedirectPage from './RedirectPage/RedirectPage';
import RedirectLMSPage from './RedirectLMSPage/RedirectLMSPage';

const RedirectNavigationStack = () => {
  return (
    <Switch>
      <Route path={`${getStackPath('REDIRECT')}/:courseId`}>
        <PanelContent
          pageTitle={translate('PAGES.REDIRECT.TITLE')}
          pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
          removeMarginTop
        >
          <RedirectPage />
        </PanelContent>
      </Route>

      <Route path={`${getStackPath('REDIRECT_LMS')}`}>
        <PanelContent
          pageTitle={translate('PAGES.REDIRECT.TITLE')}
          pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
          removeMarginTop
        >
          <RedirectLMSPage />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default RedirectNavigationStack;
