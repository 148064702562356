import { Dispatch } from 'redux';

import { SIDEBAR_CONTENT_HIDE } from './actionTypes';

export const setIsHide = (isHide: boolean) => async (dispatch: Dispatch) => {

  dispatch({
    payload: isHide,
    type: SIDEBAR_CONTENT_HIDE,
  });

};