import { TUTOR_VIEW } from '@portal/actions/actionTypes';
import { TUTOR_VIEW_TYPE } from '@portal/enum/tutorViewType';

const initialState: reducers.TutorViewReducer = {
  typeViewTutor: TUTOR_VIEW_TYPE.DASH_TUTOR,
};

const tutorViewReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TUTOR_VIEW:
      state = {
        ...state,
        typeViewTutor: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default tutorViewReducer;
