import {
  SET_NOTIFICATIONS_READ,
  UNREAD_NOTIFICATIONS_COUNT,
  USER_NOTIFICATIONS,
} from '~/actions/actionTypes';

const initialState: reducers.NotificationReducer = {
  notifications: [],
  unreadNotificationsCount: 0,
};

const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_NOTIFICATIONS:
      state = {
        ...state,
        notifications: action.payload,
      };
      break;

    case SET_NOTIFICATIONS_READ:
      state = {
        ...state,
        unreadNotificationsCount: 0,
      };
      break;

    case UNREAD_NOTIFICATIONS_COUNT:
      state = {
        ...state,
        unreadNotificationsCount: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default notificationReducer;
