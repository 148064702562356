import { format, parseISO } from 'date-fns';

import { Constants } from '@portal/config/env';
import { translate } from '@portal/services/i18n';
import { getJourneyUrl, getLMSUrl } from './getProductUrl';

export const showFormattedDate = (date: string, formatModule: string): string => {
  return format(parseISO(date), formatModule);
};

export const formatCPF = (CPF: string): string => {
  try {
    return CPF.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } catch {
    return '';
  }
};

export const formatCNPJ = (CNPJ: string): string => {
  try {
    return CNPJ.replace(/\D+/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } catch {
    return '';
  }
};

export const formatUrlVideo = (url: string) => {
  if (url?.includes('vimeo')) {
    const videoId = url?.split('/')?.[3];
    const videoH = url?.split('/')?.[4];

    return `https://player.vimeo.com/video/${videoId}?h=${videoH}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp`;
  }

  if (url?.includes('youtu.be')) {
    const videoId = url?.split('youtu.be/')[1];

    return `https://www.youtube.com/embed/${videoId}`;
  }

  return url;
};

export const moneyFormat = (value = 0): string => {
  let response = null;

  response = new Intl.NumberFormat(translate('shared.lang'), {
    currency: translate('shared.currency'),
    style: 'currency',
  }).format(value);

  return response;
};

export function emailValidate(email: string): boolean {
  const emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;

  return emailPattern.test(email);
}

export const formatProductList = ({
  profileType,
  contentLibraryList,
  journeyList,
  lmsList,
  pijList,
}: {
  profileType: number;
  lmsList?: models.Products[];
  contentLibraryList?: models.ContentLibrary[];
  pijList?: models.Pij[];
  journeyList?: models.Journey[];
}) => {
  const lmsProducts =
    lmsList?.map((lms) => ({
      id: lms?.id,
      logoUrl: lms?.logoUrl,
      title: lms?.name ?? '',
      createdAt: lms?.createdAt,
      url: getLMSUrl(lms, profileType),
    })) || [];

  const contentLibraryProducts =
    contentLibraryList?.map((contentLibrary) => ({
      id: contentLibrary?.id,
      logoUrl: contentLibrary?.logo,
      title: contentLibrary?.name ?? '',
      createdAt: contentLibrary?.createdAt,
      url: `${Constants.libUrl}/home/${contentLibrary?.id}`,
    })) || [];

  const pijProducts =
    pijList?.map((pij) => ({
      id: pij?.id,
      logoUrl: pij?.logo,
      title: pij?.name ?? '',
      createdAt: pij?.createdAt,
      url: `${Constants.pijUrl}/home/${pij?.id}`,
    })) || [];

  const journeyProducts =
    journeyList?.map((journey) => ({
      id: journey?.id,
      logoUrl: journey?.logo,
      title: journey?.name ?? '',
      createdAt: journey?.createdAt,
      url: getJourneyUrl(journey?.minorFolderId ?? '', profileType),
    })) || [];

  return [...lmsProducts, ...contentLibraryProducts, ...pijProducts, ...journeyProducts].sort((a, b) =>
    a.title.localeCompare(b.title)
  );
};
