import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconAdditionalInfo from '~/assets/svg/ic_additional_info.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

interface IProps {
  label: string;
  text: string;
}

const AdvancedPopOver = ({ label, text }: IProps) => {
  const classes = useStyles();
  const [currentRef, setCurrentRef] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setCurrentRef(event.currentTarget);
  };

  const handleClose = () => {
    setCurrentRef(null);
  };

  const isOpen = Boolean(currentRef);

  return (
    <div className="advanced-popover">
      <Typography onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        {label}
        <img src={IconAdditionalInfo} className="advanced-popover__icon" alt="advanced-popover-icon" />
      </Typography>
      <Popover
        open={isOpen}
        className={classes.popover}
        classes={{ paper: classes.paper }}
        anchorEl={currentRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <Typography>{text}</Typography>
      </Popover>
    </div>
  );
};

export default AdvancedPopOver;
