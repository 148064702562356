import { ProfileType } from '@portal/enum/profileType';

export function isEducatorStudent(userType: number | undefined): boolean {

  if (!userType) {
    return false;
  }

  return (
    (userType === ProfileType.TEACHER ||
      userType === ProfileType.ADVISER)
  );
}