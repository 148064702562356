import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CourseActions } from '@portal/actions';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as StorageService from '~/services/storage';
import { translate } from '@portal/services/i18n';

interface IProps {
  placeholder?: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  children?: React.ReactNode;
}

const SearchDropdown: React.FC<IProps> = ({ placeholder, searchValue, setSearchValue, children }) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { me } = useReduxState().auth;

  const paramsToSearch = {
    limit: 9,
    offset: 0,
    title: searchValue,
    schoolId: me?.idSchool || '',
  };

  const getBrandProduct = StorageService.getItem('brandProductsConfig') || '';

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const handleSearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const uri = window.location.pathname;
        if (uri.includes('/me')) {
          dispatch(CourseActions.getMineCourses(paramsToSearch));
        } else if (uri.includes('/all')) {
          dispatch(
            CourseActions.getOtherCourses({
              ...paramsToSearch,
              schoolId: me?.idSchool || '',
            })
          );
        } else if (uri.includes('/concluded')) {
          dispatch(
            CourseActions.getConcludedCourses({
              ...paramsToSearch,
              productId: getBrandProduct?.id,
            })
          );
        }
      }
    },
    [dispatch, me, searchValue]
  );

  return (
    <div className="searchDropdown-container">
      <div className="searchDropdown-container__search" onClick={() => setOpenDropdown(!openDropdown)}>
        <div className="searchDropdown-container__search__input">
          <Input
            className="searchDropdown-container__search__input__content"
            placeholder={placeholder || translate('COMPONENTS.SEARCHDBAR.PLACEHOLDER')}
            style={{ border: 0, textAlign: 'left' }}
            onKeyUp={handleSearch}
            onChange={handleChange}
          />
        </div>
        <div className="searchDropdown-container__search__icon">
          <SearchOutlined style={{ opacity: '0.7', fontSize: '23px' }} />
        </div>
      </div>
      {openDropdown && <div className="searchDropdown-container__search__content">{children}</div>}
    </div>
  );
};

export default SearchDropdown;
