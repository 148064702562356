import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PanelContent } from '~/components';
import { translate } from '~/services/i18n';

import CoursesPage from './CoursesPage/CoursesPage';

const CoursesNavigationStack = () => {
  return (
    <Switch>
      <Route path="/">
        <PanelContent
          pageTitle={translate('PAGES.COURSES.TITLE')}
          pageDescription={translate('PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION')}
        >
          <CoursesPage />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default CoursesNavigationStack;
