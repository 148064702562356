import React from 'react';

import LockedIcon from '@portal/assets/svg/content/activity-section/ic_locked.svg';
import { translate } from '@portal/services/i18n';
import { isBeforeBySchedule } from '@portal/utils/isAvailableBySchedule';
import { format } from 'date-fns';

interface ILockedActivityProps {
  schedule?: models.CourseProgressSchedule;
  isSatisfactionSurvey?: boolean;
}

const LockedContent: React.FC<ILockedActivityProps> = ({ schedule, isSatisfactionSurvey }) => {
  const startFormated = schedule && format(new Date(schedule.startAt), 'dd/MM/yyyy');
  const endFormated = schedule && format(new Date(schedule.endAt), 'dd/MM/yyyy');

  const text = (i18nKey: string) => {
    return translate(`PAGES.CONTENT.ACTIVITY_SECTION.LOCKED_ACTIVITY.${i18nKey}`);
  };

  return (
    <div className="locked-activity-container">
      <img className="locked-activity-container__image" src={LockedIcon} alt="locked activity icon" />
      <div className="locked-activity-container__content-date">
        {isSatisfactionSurvey ? (
          text('SATISFACTION_SURVEY')
        ) : (
          <>
            {schedule && isBeforeBySchedule(schedule.startAt) ? text('LABEL_BEFORE') : text('LABEL_AFTER')}

            <div className="locked-activity-container__content-date__bold">
              {schedule && isBeforeBySchedule(schedule.startAt)
                ? text('BOLD_LABEL_BEFORE') + startFormated
                : text('BOLD_LABEL_AFTER') + endFormated}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LockedContent;
