import { ProductType } from '@portal/enum/productType';
import * as StorageService from '~/services/storage';

interface PropsReturn {
  my: string;
  all: string;
  status: string;
  concluded: string;
  other: string;
  courseTerm: string;
}

const returnPronounWithCourseTerm = (): PropsReturn | undefined => {
  const productType =
    StorageService.getItem('brandProductsConfig')?.product || '';
  const getBrandProduct = StorageService.getItem('brandProductsConfig') || '';

  let courseTerm;

  if (getBrandProduct?.termCourse) {
    courseTerm = getBrandProduct?.termCourse;
  }

  if (productType && !getBrandProduct?.termCourse) {
    if (productType === ProductType.LMS_STUDENT) {
      courseTerm = 'Eletivas';
    }

    if (productType === ProductType.LMS_EDUCATOR) {
      courseTerm = 'Trilhas';
    }
  }

  const substantiveEnding = courseTerm?.toLowerCase()?.trim()?.slice(-2);

  switch (substantiveEnding) {
    case 'as':
      return {
        my: `Minhas ${courseTerm}`,
        all: `Todas as ${courseTerm}`,
        status: `Status da ${courseTerm}`,
        concluded: `${courseTerm} concluídas`,
        other: `${courseTerm} disponíveis`,
        courseTerm,
      };
    case 'os':
      return {
        my: `Meus ${courseTerm}`,
        all: `Todos os ${courseTerm}`,
        status: `Status do ${courseTerm}`,
        concluded: `${courseTerm} concluídos`,
        other: `${courseTerm} disponíveis`,
        courseTerm,
      };
    default:
      return {
        my: courseTerm,
        all: `Todos(as) ${courseTerm}`,
        status: `Status do(a) ${courseTerm}`,
        concluded: `${courseTerm} concluídos(as)`,
        other: `${courseTerm} disponíveis`,
        courseTerm,
      };
  }
};

export default returnPronounWithCourseTerm;
