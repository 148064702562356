import {
  COURSE_TEST,
  COURSE_TEST_ANSWERED,
  COURSE_TEST_DONE,
  COURSE_TEST_HISTORY,
  COURSE_TEST_REVIEWS,
  COURSE_TEST_REVIEWS_ANSWERED,
  COURSE_TEST_VIEW_HISTORY,
} from '~/actions/actionTypes';

const initialState: reducers.CourseTestReducer = {
  courseTest: {} as reducers.CourseTest,
  courseTestDone: {} as reducers.CourseTestDone,
  courseTestAnswered: {} as reducers.CourseTestAnswered,
  courseTestReviews: {} as any,
  courseTestHistory: {} as any,
  courseTestReviewsAnswered: {} as any,
  viewTestUserHistory: false,
};

const courseTestReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COURSE_TEST:
      state = {
        ...state,
        courseTest: action.payload,
      };
      break;

    case COURSE_TEST_DONE:
      state = {
        ...state,
        courseTestDone: action.payload,
      };
      break;
    case COURSE_TEST_ANSWERED:
      state = {
        ...state,
        courseTestAnswered: action.payload,
      };
      break;

    case COURSE_TEST_REVIEWS:
      state = {
        ...state,
        courseTestReviews: action.payload,
      };
      break;

    case COURSE_TEST_REVIEWS_ANSWERED:
      state = {
        ...state,
        courseTestReviewsAnswered: action.payload,
      };
      break;

    case COURSE_TEST_VIEW_HISTORY:
      state = {
        ...state,
        viewTestUserHistory: action.payload,
      };
      break;

    case COURSE_TEST_HISTORY:
      state = {
        ...state,
        courseTestHistory: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default courseTestReducer;
