import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import { BREAKPOINTS } from '@portal/enum/breakpoints';
import { useReduxState } from '@portal/hooks/useReduxState';
import ChatRequests from '@portal/repositories/chat';
import UserIcon from '~/assets/svg/panel-top-bar/ic_user.svg';

interface Iprops {
  id: string;
  isSelected?: boolean;
  hasNewMessage?: boolean;
  participant: models.Participant;
  setSelectedChat: React.Dispatch<React.SetStateAction<string>>;
  clickOnlyOnMobile?: boolean;
}
interface IhandleClick {
  widthContainer: number;
  breakpoint: number;
  idParam: string;
  setSelectedChatParam: React.Dispatch<React.SetStateAction<string>>;
}

const ContactProfile: React.FC<Iprops> = ({
  isSelected,
  hasNewMessage,
  participant,
  setSelectedChat,
  id,
  clickOnlyOnMobile = true,
}) => {
  const [widthScreen, setWidthScreen] = useState(document.body.clientWidth);

  const history = useHistory();
  const { me } = useReduxState().auth;

  const setWidthOnResize = useCallback(() => {
    setWidthScreen(document.body.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setWidthOnResize);

    return () => window.removeEventListener('resize', setWidthOnResize);
  }, [setWidthOnResize]);

  const handleClick = useCallback(
    async ({
      breakpoint,
      widthContainer,
      idParam,
      setSelectedChatParam,
    }: IhandleClick) => {
      if (widthContainer <= breakpoint || !clickOnlyOnMobile) {
        const { id: privateChatId } = await ChatRequests.createChat(
          [id],
          me?.id as string,
        );
        history.push(
          `${getRouteStackPath('CHAT', 'CONVERSATION')}/${privateChatId}`,
        );

        return;
      }
      setSelectedChatParam(idParam);
    },
    [history, id, me, clickOnlyOnMobile],
  );

  return (
    <Row
      className={`contact-profile-container${
        isSelected ? ' contact-profile-container--isSelected' : ''
      } py-3`}
      onClick={() =>
        handleClick({
          widthContainer: widthScreen,
          breakpoint: BREAKPOINTS.SM,
          idParam: id,
          setSelectedChatParam: setSelectedChat,
        })
      }
    >
      <Col className="d-flex justify-content-center align-items-center p-0 ">
        <div className="position-relative">
          <img
            src={participant.profilePictureUrl || UserIcon}
            alt={participant.name}
            className="contact-profile-container__thumb"
          />
        </div>
      </Col>
      <Col className="p-0 d-flex align-items-center">
        <Row>
          <Col className="p-0 d-flex justify-content-start">
            <span
              className={`contact-profile-container__user-name${
                hasNewMessage
                  ? ' contact-profile-container__user-name--new'
                  : ''
              }`}
            >
              {participant.name}
            </span>
          </Col>
        </Row>
      </Col>
      {hasNewMessage && (
        <Col className="col-1 d-flex justify-content-center align-items-center p-0">
          <div className="contact-profile-container__new-message-indicator" />
        </Col>
      )}
    </Row>
  );
};

export default ContactProfile;
