import {getInstance} from './instance';

const bannerAPI = {
  getHomeBanners: async (params: advancedFilterModels.BannerFilter) => {
    const instance = getInstance();
    const { data } = await instance.get('/banner/selectives', { params });

    return data;
  },
};

export default bannerAPI;
