import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Drawer } from 'antd';

import { blinkLearningActions } from '@portal/actions/blink-learning';
import { redirectPortalMarketing } from '@portal/actions/marketing';
import { getMe } from '@portal/actions/auth';

import { ReactComponent as HelpIcon } from '~/assets/Icon/help.svg';
import { Box, Flex, IcAddressBook, IcChat, IcHamburguerMenu, IcHouse, IcShape } from '@portal/components';

import { Constants } from '@portal/config/env';
import {
  ProfileType,
  arrayAdminProfiles,
  arrayEducatorProfiles,
  arrayProfilesWithAccess,
  brandsPortalMarketing,
} from '@portal/enum';
import { useReduxState } from '@portal/hooks';
import { translate } from '@portal/services';
import * as Toast from '@portal/services/message';
import { RenderCondition, formatProductList, hasBlinkLearning } from '@portal/utils';

import './MenuHamburguerMobile.less';

const MenuHamburguerMobile = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);

  const {
    auth: { me: user },
    user: { products: allProducts },
    journey: { list: journeyList },
    config: { configPortal },
    brand: { brandConfig: brandInfos },
  } = useReduxState();

  const isAdmin = !!user?.profileType && arrayAdminProfiles.includes(user.profileType);

  const onClickBlinkCard = () => {
    dispatch(
      blinkLearningActions.redirectToBlink({
        onError: () => Toast.error('Não foi possível completar o redirecionamento'),
      })
    );
  };

  const renderI18n = (restRoute: string) => {
    return translate(`COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.${restRoute}`);
  };

  const optionsNavigation = [
    {
      title: renderI18n('START'),
      icon: IcHouse,
      action: () => {
        window.open(`${Constants.portalUrl}/home`);
      },
      permissions: arrayProfilesWithAccess,
      isHidden: !isAdmin,
    },
    {
      title: renderI18n('FAQ'),
      icon: HelpIcon,
      action: () => {
        window.open(`${configPortal?.faq}`, '_blank');
      },
      permissions: arrayProfilesWithAccess,
      isHidden: !isAdmin,
    },
    {
      title: renderI18n('CHAT'),
      icon: IcChat,
      action: () => {
        window.open(`${Constants.portalUrl}/chat`);
      },
      permissions: [ProfileType.TEACHER, ProfileType.STUDENT],
      isHidden: !configPortal?.showChat,
    },
    {
      title: renderI18n('PERFIL'),
      icon: IcShape,
      action: () => {
        window.open(`${Constants.portalUrl}/perfil/${user?.id}`);
      },
      permissions: arrayProfilesWithAccess,
    },
    {
      title: renderI18n('CLASSES'),
      icon: IcAddressBook,
      action: () => {
        window.open(`${Constants.portalUrl}/home?menu=3`);
      },
      permissions: [ProfileType.TEACHER],
    },
  ];

  const showBlinkLearning = !!user && hasBlinkLearning(user, brandInfos);

  const productList = user?.defaultProfileType
    ? formatProductList({
        profileType: user?.defaultProfileType,
        journeyList: journeyList ?? [],
        contentLibraryList: allProducts?.contentLibraries,
        lmsList: allProducts?.brandProducts,
        pijList: allProducts?.pijs,
      })
    : [];

  const hasPortalMarketing =
    brandsPortalMarketing?.includes(Constants?.brandDomain as string) &&
    !!user?.profileType &&
    arrayEducatorProfiles.includes(user?.profileType);

  const hasProducts =
    !!allProducts?.brandProducts?.length ||
    !!allProducts?.pijs?.length ||
    !!allProducts?.contentLibraries?.length ||
    !!journeyList?.length ||
    !!configPortal?.showBroadcast ||
    hasPortalMarketing ||
    showBlinkLearning;

  return (
    <>
      <Box onClick={() => setOpen(true)}>
        <IcHamburguerMenu color="#FFFFFF" width="1.25rem" height="1.25rem" />
      </Box>

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        placement="left"
        contentWrapperStyle={{ maxWidth: '70vw' }}
        bodyStyle={{ backgroundColor: brandInfos?.primaryColor }}
        closable={false}
      >
        <Box className="menu-hamburguer-mobile__body">
          <Flex className="menu-hamburguer-mobile__close-icon" onClick={() => setOpen(false)}>
            x
          </Flex>
          <Flex className="menu-hamburguer-mobile__options">
            {optionsNavigation
              .filter(
                ({ permissions, isHidden }) =>
                  !!user?.defaultProfileType && permissions.includes(user.defaultProfileType) && !isHidden
              )
              ?.map(({ icon: Icon, title, action }, key) => (
                <Flex className="menu-hamburguer-mobile__options__item" key={key} onClick={action}>
                  <Icon color="#FFFFFF" width="1rem" height="1rem" />

                  <span>{title}</span>
                </Flex>
              ))}
          </Flex>

          <Box className="menu-hamburguer-mobile__products">
            <span className="mt-4 mb-2 font-weight-bold">{renderI18n('PRODUCTS')}:</span>

            <Box>
              {productList?.map((product) => (
                <Box key={product.id}>
                  <span onClick={() => window.open(product.url)}>{product.title}</span>
                  <hr />
                </Box>
              ))}

              <RenderCondition condition={showBlinkLearning}>
                <Flex onClick={onClickBlinkCard}>
                  <span>Let’s Imagine!</span>
                </Flex>
                <hr />
              </RenderCondition>

              <RenderCondition condition={configPortal?.showBroadcast}>
                <Flex onClick={() => window.open(`${Constants.portalUrl}/broadcast`)}>
                  <span>LIVES</span>
                </Flex>
                <hr />
              </RenderCondition>

              <RenderCondition condition={hasPortalMarketing}>
                <Flex
                  onClick={() => {
                    if (!!user && !!user?.school && !!brandInfos) {
                      dispatch(redirectPortalMarketing(user, user?.school, brandInfos, () => dispatch(getMe())));
                    }
                  }}
                >
                  <span>{renderI18n('MARKETING')}</span>
                </Flex>
                <hr />
              </RenderCondition>
            </Box>
          </Box>

          <span className="mt-4 font-weight-bold">{renderI18n('LOGOUT')}</span>
        </Box>
      </Drawer>
    </>
  );
};

export default MenuHamburguerMobile;
