import React, { ElementType } from 'react';

import { Flex } from '~/components';

import { RenderCondition } from '@portal/utils';
import useWindowSize from '@portal/utils/getSizeWindow';

import './OptionNavbar.less';

interface IOptionNavbar {
  title: string;
  link?: string;
  Icon: ElementType;
}

const OptionNavbar = ({ title, Icon, link }: IOptionNavbar): JSX.Element => {
  const isMobile = useWindowSize();

  return (
    <Flex className="option-navbar" {...(link && { onClick: () => window.open(link) })}>
      <Icon className="option-navbar__icon" width="1rem" height="1rem" />
      <RenderCondition condition={!isMobile}>
        <span className="option-navbar__label">{title}</span>
      </RenderCondition>
    </Flex>
  );
};

export default OptionNavbar;
