export const InitialStateAuth: models.AuthRequest = {
  email: '',
  password: '',
};

export const InitialStateGroup: models.CreateGroup = {
  name: '',
  schoolId: '',
  capacity: 0,
  enrollmentStartDate: '',
  enrollmentEndDate: '',
  automaticStudentEnrollment: false,
  tutorIdList: [],
};