import React, { ComponentProps } from 'react';

export const IcArrowLeft = (props: ComponentProps<'svg'>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="none" viewBox="0 0 41 40">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M32.167 20H8.833M20.5 31.667L8.834 20 20.5 8.334"
    />
  </svg>
);
