import React, { ReactElement } from 'react';
import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

import { translate } from '@portal/services/i18n';
import RenderCondition from '@portal/utils/renderCondition';

interface IRenderStatusDateProps {
  date: string | Date | Moment;
  isAnswerDate?: boolean;
  isContentDeadline?: boolean;
  fontSizeAnswer?: string;
  isSolution?: boolean;
}

interface IContainerDeadlieneProps {
  children: React.ReactNode;
  icon?: ReactElement;
  color?: string;
  backgroundIcon?: string;
}

const RenderStatusDate: React.FC<IRenderStatusDateProps> = ({
  date,
  isAnswerDate,
  isContentDeadline,
  fontSizeAnswer,
  isSolution,
}) => {
  const dateLocal = moment();
  const dateFormated = moment(date).format('DD/MM/YYYY');
  const dateHourFormated = moment(date).format('DD/MM/YYYY HH:mm');

  const attentionDate = dateLocal.isSameOrBefore(date) && dateLocal.isSameOrAfter(moment(date).subtract(7, 'days'));

  const missedData = dateLocal.isAfter(date);

  const ContainerDeadliene = ({ children, color, icon, backgroundIcon }: IContainerDeadlieneProps) => (
    <div className="status-container__content-deadline" style={{ color }}>
      <div
        className="status-container__content-deadline__icon"
        style={{ backgroundColor: backgroundIcon, border: 'none' }}
      >
        {icon}
      </div>
      {children}
    </div>
  );

  const renderI18n = (restRoute: string) => {
    return translate(`PAGES.CONTENT.ACTIVITY_SECTION.${restRoute}`);
  };

  return (
    <div className="status-container">
      {isAnswerDate && !isSolution && (
        <div className="status-container__sent-answer" style={{ fontSize: fontSizeAnswer }}>
          <div className="status-container__sent-answer__check">
            <CheckOutlined style={{ color: '#fff' }} />
          </div>
          {renderI18n('ANSWER_SECTION.SENT_ANSWER')}

          {dateHourFormated}
        </div>
      )}
      {isContentDeadline && (
        <div className="status-container__content-deadline">
          <RenderCondition condition={!attentionDate && !missedData}>
            <ContainerDeadliene>
              <>
                {renderI18n('DEADLINE_STATUS.SAFE')}

                {dateFormated}
              </>
            </ContainerDeadliene>
          </RenderCondition>

          <RenderCondition condition={!!attentionDate}>
            <ContainerDeadliene
              color="#9eaa0b"
              backgroundIcon="#e4f609"
              icon={<ExclamationOutlined style={{ color: '#000' }} />}
            >
              <>
                {renderI18n('DEADLINE_STATUS.ATTENTION')}

                {dateFormated}
              </>
            </ContainerDeadliene>
          </RenderCondition>

          <RenderCondition condition={!!missedData}>
            <ContainerDeadliene
              color="#e65c65"
              backgroundIcon="#e65c65"
              icon={<ExclamationOutlined style={{ color: '#000' }} />}
            >
              <div className="status-container__content-deadline__container-content">
                {renderI18n('DEADLINE_STATUS.MISSED.TITLE')}

                {dateFormated}
              </div>
            </ContainerDeadliene>
          </RenderCondition>
        </div>
      )}
    </div>
  );
};

export default RenderStatusDate;
