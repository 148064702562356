import React, { ComponentProps, ElementType } from 'react';

import { Spin } from 'antd';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';

import Flex from '../Flex';

import { useReduxState } from '~/hooks/useReduxState';

import './Button.less';

interface IButton extends Omit<ComponentProps<'button'>, 'disabled'> {
  variant?: 'primary' | 'secondary';
  label?: string;
  size?: 'lg' | 'md' | 'sm';
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: ElementType;
  primaryColor?: string;
}

const Button = ({
  variant = 'primary',
  label = '',
  size = 'md',
  isDisabled = false,
  isLoading = false,
  className,
  icon: Icon,
  primaryColor,
  ...rest
}: IButton) => {
  const { brand } = useReduxState();

  const classNameDefault = 'atoms-button';
  const classNameVariant = `${classNameDefault}--${variant}`;
  const classNameSize = `${classNameDefault}--${size}`;

  const loadingIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: variant === 'primary' ? 'white' : brand?.brandConfig?.primaryColor }}
      spin
    />
  );

  const dinamicStyle = (): React.CSSProperties | undefined => {
    switch (variant) {
      case 'primary':
        return {
          backgroundColor: primaryColor ?? brand?.brandConfig?.primaryColor,
        };
      case 'secondary':
        return {
          color: primaryColor ?? brand?.brandConfig?.primaryColor,
          borderColor: primaryColor ?? brand?.brandConfig?.primaryColor,
        };
    }
  };

  return (
    <Flex className={classNames('atoms-button__root', { ['atoms-button__root' + '--disabled']: isDisabled })}>
      <button
        {...rest}
        disabled={isDisabled || isLoading}
        className={classNames(className, classNameDefault, classNameVariant, classNameSize, {
          [classNameDefault + '--disabled']: isDisabled,
        })}
        style={{ ...dinamicStyle(), ...rest?.style }}
      >
        {isLoading ? (
          <Spin indicator={loadingIcon} />
        ) : (
          <Flex className="atoms-button__content">
            {label}
            {Icon && <Icon />}
          </Flex>
        )}
      </button>
    </Flex>
  );
};

export default Button;
