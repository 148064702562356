import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ReactPlayer from 'react-player/lazy';
import AppBar from '@material-ui/core/AppBar';
import { Modal } from 'antd';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Col, Container, Row } from 'react-bootstrap';
import { WarningOutlined } from '@ant-design/icons';

import { CourseActions } from '@portal/actions';
import { useReduxState } from '@portal/hooks';

import { Btn, RenderHTML } from '@portal/components';

import { translate } from '@portal/services/i18n';
import centralNavigationService from '~/services/navigation';
import {
  isEducator,
  BUTTON_SIZE,
  ProductType,
  COURSE_TYPE,
  COURSE_DETAILS_TABS,
  ENROLLMENT_BUTTON_TYPE,
} from '@portal/enum';

import * as StorageService from '~/services/storage';
import { deleteCookie } from '@portal/services';
import { Constants } from '@portal/config/env';
import { RenderCondition, isAvailableBySchedule } from '@portal/utils';

import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';

const CourseDetailsPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const brandProduct: models.BrandProduct = StorageService.getItem('brandProductsConfig');
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    auth: { courseType },
    course: { courseDetails },
  } = useReduxState();

  const { me } = useReduxState().auth;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getId = () => {
    const pathname = window.location.pathname;
    const routes = pathname.split('/');

    return routes.pop();
  };

  useEffect(() => {
    const id = getId() || '';

    if (me?.idSchool) {
      dispatch(CourseActions.getCourseDetails(id, me?.idSchool, brandProduct?.id));
    }
  }, [me]);

  const handleAccessContent = () => {
    if (courseDetails?.isAbleToEnroll) {
      if (me && isEducator(me?.profileType)) {
        dispatch(
          CourseActions.subscribeTeacherOnCourse(
            courseDetails.id,
            brandProduct?.id,
            me?.idSchool,
            () => {
              setShowModal(true);
            },
            true
          )
        );
      } else {
        dispatch(
          CourseActions.subscribeUserOnCourse(courseDetails.id, brandProduct?.id, {
            onSuccess: () => {
              setShowModal(true);
            },
          })
        );
      }
    } else {
      history.push(`/content/${courseDetails.id}`);
    }
  };

  const returnCurrentContent = useCallback(
    (current: number) => {
      switch (current) {
        case COURSE_DETAILS_TABS.DESCRIPTION:
          return courseDetails.description;
        case COURSE_DETAILS_TABS.PROGRAM:
          return courseDetails.program;
        case COURSE_DETAILS_TABS.SKILLS:
          return courseDetails.skills;
        case COURSE_DETAILS_TABS.REFERENCES:
          return courseDetails.references;
        case COURSE_DETAILS_TABS.CLOSURE_TASK_DESCRIPTION:
          return courseDetails.closureTaskDescription;
        case COURSE_DETAILS_TABS.TEST_DESCRIPTION:
          return courseDetails.testDescription;
        default:
          return courseDetails.description;
      }
    },
    [courseDetails]
  );

  const currentEnrollmentButton = (isStudentEnrollmentPermit: boolean, virtualGroup?: models.VirtualGroup) => {
    if (isEducator(me?.profileType)) {
      if (brandProduct?.product === ProductType.LMS_STUDENT) {
        return ENROLLMENT_BUTTON_TYPE.SEE_ELECTIVE;
      } else {
        return ENROLLMENT_BUTTON_TYPE.SUBUSCRIBE;
      }
    }

    if (virtualGroup) {
      if (courseDetails?.isAbleToEnroll === null) {
        if (!isStudentEnrollmentPermit && isStudentEnrollmentPermit !== undefined)
          return ENROLLMENT_BUTTON_TYPE.AUTOMATIC_ENROLLMENT_NOT_AVAILABLE;

        if ((virtualGroup?.enrollmentStartDate && virtualGroup?.enrollmentEndDate) !== null) {
          if (
            !isAvailableBySchedule({
              startAt: virtualGroup?.enrollmentStartDate as Date,
              endAt: virtualGroup?.enrollmentEndDate as Date,
            })
          )
            return ENROLLMENT_BUTTON_TYPE.ENROLLMENT_PERIOD_ENDED;
        }

        if (virtualGroup?.userVirtualGroups && virtualGroup?.userVirtualGroups.length > virtualGroup?.capacity) {
          return ENROLLMENT_BUTTON_TYPE.MAX_CAPACITY;
        }
      } else {
        return ENROLLMENT_BUTTON_TYPE.SUBUSCRIBE;
      }
    } else {
      return ENROLLMENT_BUTTON_TYPE.AUTOMATIC_ENROLLMENT_NOT_AVAILABLE;
    }
  };

  const virtualGroupAvailableScheduleFilter = courseDetails?.virtualGroup?.filter(
    (group) =>
      group?.enrollmentStartDate &&
      isAvailableBySchedule({
        startAt: group?.enrollmentStartDate,
        endAt: group?.enrollmentEndDate,
      })
  );

  const renderButton = (size?: number) => {
    let variant,
      title = '';
    let disabled,
      isRenderIcon,
      isEnrollmentDisabled = true;

    const isStudentEnrollmentPermit = courseDetails.schoolCourse?.some(
      (schoolCourse) => !!schoolCourse.studentEnrollmentPermit
    );

    switch (
      currentEnrollmentButton(
        isStudentEnrollmentPermit,
        virtualGroupAvailableScheduleFilter?.[virtualGroupAvailableScheduleFilter?.length - 1]
      )
    ) {
      case ENROLLMENT_BUTTON_TYPE.SUBUSCRIBE: {
        title = 'COMPONENTS.CARD.BUTTONS.SUBUSCRIBE';
        variant = 'primary';
        disabled = false;
        isRenderIcon = false;
        isEnrollmentDisabled = false;
        break;
      }
      case ENROLLMENT_BUTTON_TYPE.MAX_CAPACITY: {
        title = 'COMPONENTS.CARD.BUTTONS.MAX_CAPACITY';
        break;
      }
      case ENROLLMENT_BUTTON_TYPE.ENROLLMENT_PERIOD_ENDED: {
        title = 'COMPONENTS.CARD.BUTTONS.ENROLLMENT_PERIOD_ENDED';
        break;
      }
      case ENROLLMENT_BUTTON_TYPE.AUTOMATIC_ENROLLMENT_NOT_AVAILABLE: {
        title = 'COMPONENTS.CARD.BUTTONS.AUTOMATIC_ENROLLMENT_NOT_AVAILABLE';
        size = BUTTON_SIZE.FULL;
        break;
      }
      case ENROLLMENT_BUTTON_TYPE.SEE_ELECTIVE: {
        title = 'COMPONENTS.CARD.BUTTONS.SEE_DETAILS';
        variant = 'primary';
        disabled = false;
        isRenderIcon = false;
        isEnrollmentDisabled = false;
        break;
      }
    }

    return (
      <RenderCondition condition={!!title}>
        <Btn
          title={translate(title)}
          onClick={() => handleAccessContent()}
          variant={variant ? 'primary' : 'secondary'}
          size={size}
          disabled={disabled}
          icon={isRenderIcon ? <WarningOutlined /> : null}
          isEnrollmentDisabled={isEnrollmentDisabled}
        />
      </RenderCondition>
    );
  };
  return (
    <Container fluid>
      <Row className="d-flex flex-column w-100">
        <Col className="p-0 position-relative">
          <div className="course-details__player-wrapper">
            {courseDetails.videoUrl ? (
              <ReactPlayer
                style={{ backgroundColor: 'black' }}
                width="100%"
                height="100%"
                url={courseDetails.videoUrl}
                playing={isPlaying}
                controls
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
                onEnded={() => setIsPlaying(false)}
              />
            ) : (
              <img className="course-details__banner" src={courseDetails?.photoUrl} alt="empty banner" />
            )}
          </div>
        </Col>

        <div className="course-details__content">
          <Row className="mb-2">
            <Col xs={12} sm={6} md={6} lg={6}>
              <div className="course-details__content__title-container">
                <h1 className="course-details__content__title-container__title">{courseDetails?.title}</h1>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              {renderButton(BUTTON_SIZE.FIT_CONTENT)}
            </Col>
          </Row>

          <Col className="course-details__navigation py-2">
            <AppBar position="static">
              <Tabs
                value={currentTab}
                onChange={handleChange}
                aria-label="course details"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={translate('PAGES.COURSE_DETAILS.NAVIGATION.DETAILS')}
                  disabled={!courseDetails.description}
                />
                <Tab label={translate('PAGES.COURSE_DETAILS.NAVIGATION.PROGRAM')} disabled={!courseDetails.program} />
                <Tab label={translate('PAGES.COURSE_DETAILS.NAVIGATION.SKILLS')} disabled={!courseDetails.skills} />
                <Tab
                  label={translate('PAGES.COURSE_DETAILS.NAVIGATION.REFERENCE')}
                  disabled={!courseDetails.references}
                />
                <Tab
                  label={
                    courseType === COURSE_TYPE.TRAIL
                      ? translate('PAGES.COURSE_DETAILS.NAVIGATION.CLOSURE_TASK_DESCRIPTION_TECER')
                      : translate('PAGES.COURSE_DETAILS.NAVIGATION.CLOSURE_TASK_DESCRIPTION')
                  }
                  disabled={!courseDetails.closureTaskDescription}
                />
                {courseType === COURSE_TYPE.TRAIL && courseDetails.testDescription && (
                  <Tab label={translate('PAGES.COURSE_DETAILS.NAVIGATION.TEST_DESCRIPTION')} />
                )}
              </Tabs>
              <div className="course-details__divider-wrapper" />
            </AppBar>
          </Col>
          <Col>
            <div className="course-details__content__html">
              <Row className="course-details__content__html__wrapper mt-4">
                <Col>
                  <RenderHTML htmlText={returnCurrentContent(currentTab)} />
                </Col>
              </Row>

              <Row className="d-flex justify-content-center mt-5">
                <Col xs={12} sm={4} md={4} lg={2}>
                  <Btn
                    title={translate('PAGES.COURSE_DETAILS.BACK')}
                    onClick={() => centralNavigationService.back()}
                    variant="secondary"
                  />
                </Col>
                <Col xs={8} sm={8} md={8} lg={10}>
                  {renderButton(BUTTON_SIZE.FULL)}
                </Col>
              </Row>
            </div>
          </Col>
        </div>

        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          bodyStyle={{ padding: '0 0 2rem 0' }}
          footer={null}
        >
          <div className="course-details__modal-container">
            <Row>
              <Col>
                <img
                  className="course-details__modal-container__banner"
                  src={courseDetails.photoUrl || EmptyBanner}
                  alt="empty banner"
                />
              </Col>
            </Row>
            <div className="mt-5">
              <Col>
                <Row className="d-flex justify-content-center">
                  <span className="course-details__modal-container__title">
                    {translate('PAGES.COURSE_DETAILS.MODAL.TITLE')}
                  </span>
                </Row>
                <Row className="d-flex justify-content-center mt-4">
                  <span>{translate('PAGES.COURSE_DETAILS.MODAL.SUBTITLE')}</span>
                </Row>
                <Row className="d-flex justify-content-center mt-4">
                  <Btn
                    onClick={() => {
                      history.push(`/content/${courseDetails.id}`),
                        deleteCookie('virtual-group-id', '/', Constants.brandCookieDomain);
                    }}
                    title={translate('PAGES.COURSE_DETAILS.MODAL.BUTTON')}
                  />
                </Row>
              </Col>
            </div>
          </div>
        </Modal>
      </Row>
    </Container>
  );
};
export default CourseDetailsPage;
